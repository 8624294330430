import React from 'react'

import styles from './detailInfoQuadrant.module.scss'
import { getRangeColor2, getScore, getLetterGrade } from '../../utils'

interface DetailInfoQuadrantProps {
  stringType?: any,
  counts: any,
  data?: any
}

export const DetailInfoQuadrant: React.FC<DetailInfoQuadrantProps> = React.memo(({stringType, counts}) => {
  const score = counts && getScore(counts.categoryScore);

  const showGrade = score != null;
  const showRank = counts?.ratingRank?.outOf > 1;
  const possibleQuadrants = [showGrade, showRank];
  const showExtraQ = (possibleQuadrants.filter(a => a).length % 2) === 1;

  const style: any = {'--color': getRangeColor2(score)}

  return <div className={styles.quadrants}>
    {showGrade && <div className={styles.quadrant}>
      <div className={styles.scoreBox} style={style}>{getLetterGrade(score)}</div>
      <div>Harmonize Grade</div>
    </div>}
    {showRank && <div className={styles.quadrant}>
      <div className={styles.spacer} />
      {counts?.ratingRank != null && <div className={styles.rank}>
        <strong>{counts.ratingRank.value}</strong>
        <div> of {counts.ratingRank.outOf}</div>
      </div>}
      {(counts == null || counts.ratingRank == null) && <div className={styles.rank}>
        <strong>N/A</strong>
      </div>}
      <div className={styles.spacer} />
      <div>Rank</div>
      <div>{stringType} Surveyed</div>
    </div>}
    {showExtraQ && <div className={styles.quadrant} />}
  </div>
})

export default DetailInfoQuadrant