import React from 'react';
import {Button} from '../button/button'
import Input  from '../input/input'
import { DeleteIcon } from '../icons/icons'
import { sourcesById } from '../../utils'

import styles from './sourceFileUpload.module.scss'

interface SourceFileUploadProps {
  brandName: any,
  closeFn: any,
  provider: any,
  createSource: any
}

export const SourceFileUpload: React.FC<SourceFileUploadProps> = ({ brandName, closeFn, provider, createSource}) => {
  const [sourceName, setSourceName] = React.useState(brandName);
  const [filesToUpload, setFilesToUpload] = React.useState(null);
  const [hoveringFileDrop, setHoveringFileDrop] = React.useState(false);
  const onChange = (e: any) => {
    setSourceName(e.target.value);
  }

  const onDragEnter = (e: any) => {
    if(e.dataTransfer.effectAllowed === 'move') return;
    e.preventDefault();
    e.stopPropagation();
    setHoveringFileDrop(true)
  }
  const onDragLeave = (e: any) => {
    if(e.dataTransfer.effectAllowed === 'move') return;
    e.preventDefault();
    e.stopPropagation();
    setHoveringFileDrop(false)
  }
  const onDrop = (e: any) => {
    if(e.dataTransfer.effectAllowed === 'move') return;
    e.preventDefault();
    e.stopPropagation();
    setHoveringFileDrop(false)

    const files = Array.from(e.dataTransfer.files).filter((f: any) => f.type === 'text/csv');
    setFilesToUpload((filesToUpload || []).concat(files));
  }
  const onDragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation();
  }

  const deleteFileAtIndex = (indexToRemove: any) => {
    const files = (filesToUpload || []).filter((_: any, i: number) => i !== indexToRemove);
    setFilesToUpload(files);
  }

  const _createSource = () => {
    if(createSource) createSource(sourceName, filesToUpload, provider);
  }

  const brandSource = sourcesById[provider]

  return <div className={styles.container}>
    <Input placeholder="Name your provider" value={sourceName || ""} onChange={onChange} />
    {filesToUpload && <div className={styles.files}>
      {filesToUpload.map((file: any, i: number) => <div key={i} className={styles.file}>
        {brandSource && React.createElement(brandSource.icon)}
        <div>{file.name}</div>
        <div className={styles.spacer} />
        <DeleteIcon className={styles.deleteIcon} onClick={() => deleteFileAtIndex(i)} />
      </div>)}
    </div>}
    <div className={[styles.filedrop, hoveringFileDrop ? styles.hovering: null].join(' ')} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop} onDragOver={onDragOver}>
      <div className={styles.filedropContent}>drag and drop reviews file</div>
    </div>
    <div className={styles.buttons}>
      <Button title="Create source" disabled={!brandName || !filesToUpload || filesToUpload.length === 0} onClick={_createSource} />
      <Button title="Cancel" onClick={closeFn} />
    </div>
  </div>
}

export default SourceFileUpload