import { useLocalStorage } from '@rehooks/local-storage';
import React from 'react';
import { useConceptsContext, useReportsContext } from '../../contexts';
import { useSearch } from '../../hooks';
import { TogglePanel } from '../../toneIcons';
import ConceptsSection from '../conceptsSection/conceptsSection';
import FilterSection from '../filterSection/filterSection';
import SentimentRatingSection from '../sentimentRatingSection/sentimentRatingSection';
import StarRatingSection, { NpsRatingSection } from '../starRatingSection/starRatingSection';
import TopicSection, { TopicWithCountSection } from '../topicSection/topicSection';
import styles from './brandFilters.module.scss';


interface FiltersProps {
  permission: any,
  report: any,
  data: any,
  concepts: any[]
}

export const Filters: React.FC<FiltersProps> = React.memo(({permission, report, data, concepts}) => {
  const [search,] = useSearch()

  const [filtersMinimized, setFiltersMinimized] = useLocalStorage('filtersMinimized', false);
  const [, {updateReportData, updateReportDataLocally}] = useReportsContext();
  const [showingMore, setShowingMore] = React.useState(false);
  const [showingMoreTFIDF, setShowingMoreTFIDF] = React.useState(false);
  const [, {updateConceptsOrder}] = useConceptsContext();

  const [domainConcepts, judgementConcepts] = React.useMemo(() => {
    let domainConcepts: any[] = [];
    let judgementConcepts: any[] = [];
    concepts?.forEach(concept => {
      if(concept.catType === 'judgement') {
        judgementConcepts.push(concept);
      } else if(concept.catType === 'domain') {
        domainConcepts.push(concept);
      }
    })
    return [domainConcepts, judgementConcepts]
  }, [concepts])
  
  const updateTopic = (searches: any) => {
    const _id = report?.data?._id;
    const newReport = { _id, searches };
    updateReportDataLocally(newReport);
    updateReportData(newReport)
  }

  const phraseWords = report?.phrases?.map((p: any) => ({value: p.phrase, count: p.count}))
  const tfidf = data?.tfidf?.map((tfidf: any) => ({
    value: tfidf?.phrases?.[0],
    phrases: tfidf?.phrases,
    sentiment: tfidf?.mean,
  }));

  const appProvider = data?.data?.appProvider || data?.data?.apps?.[0]?.appProvider;
  const showNpsRating = appProvider === 'csvNPS'

  const _updateConceptsOrder = (conceptIds: any) => updateConceptsOrder(report?.data?._id, conceptIds);
  
  const countsData = (search?.category == null) ? data?.voiceV2?.["_review"]?.counts : data?.voiceV2?.[search.category]?.counts 

  const isSuperAdmin = permission?.isSuperAdmin();
  return <div className={[styles.container, !filtersMinimized ? styles.opened : null].join(" ")}>
    <div className={styles.header}>
      <div className={styles.headerText}>Filters</div>
      <div className={styles.togglePanel} onClick={() => setFiltersMinimized(!filtersMinimized)}>
        <TogglePanel />
      </div>
    </div>
    <div className={styles.filters}>
      {!filtersMinimized && <>
        <FilterSection name="SAVED SEARCHES">
          <TopicSection report={report} permission={permission} tags={report?.data?.searches} updateTopic={updateTopic} />
        </FilterSection>
      
        {isSuperAdmin && <FilterSection name="SUGGEST SEARCHES" showingMore={showingMore} setShowingMore={setShowingMore}>
          <TopicWithCountSection tags={phraseWords} showingMore={showingMore} />
        </FilterSection>}
        {isSuperAdmin && <FilterSection name="COMMON PHRASES" showingMore={showingMoreTFIDF} setShowingMore={setShowingMoreTFIDF}>
          <TopicWithCountSection tags={tfidf} showingMore={showingMoreTFIDF} />
        </FilterSection>}
      </>}

      {countsData && <FilterSection name="RATING" minimized={filtersMinimized}>
        {!showNpsRating && <StarRatingSection data={countsData} minimized={filtersMinimized} />}
        {showNpsRating && <NpsRatingSection data={countsData} minimized={filtersMinimized} />}
      </FilterSection>}

      {countsData && <FilterSection name="SENTIMENT" minimized={filtersMinimized}>
        <SentimentRatingSection data={countsData} minimized={filtersMinimized} />
      </FilterSection>}


      {!filtersMinimized && <>
        {(domainConcepts?.length > 0 || permission.canModifyBrand(report)) && <FilterSection name="DOMAIN CONCEPTS" minimized={filtersMinimized}>
          <ConceptsSection report={report} concepts={domainConcepts} permission={permission} catType='domain' updateConceptsOrder={permission.canModifyBrand(report) && _updateConceptsOrder} data={data?.voiceV2} />
        </FilterSection>}

        {(judgementConcepts?.length > 0 || permission.canModifyBrand(report)) && <FilterSection name="VALENCE CONCEPTS" minimized={filtersMinimized}>
          <ConceptsSection report={report} concepts={judgementConcepts} permission={permission} catType='judgement' updateConceptsOrder={permission.canModifyBrand(report) && _updateConceptsOrder} data={data?.voiceV2} />
        </FilterSection>}
      </>}
    </div>
  </div>
})

export default Filters