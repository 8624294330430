import React from 'react'
import commaNumber from 'comma-number'

import { getBubbleColors } from '../../utils'

import styles from './conceptSummaryTooltip.module.scss'

interface ConceptSummaryTooltipProps {
  data: any
}

export const ConceptSummaryTooltip: React.FC<ConceptSummaryTooltipProps> = ({data}) => {
  const neutralCount = data.count - (data.posCount + data.negCount)
  return <div className={styles.container}>
    <div className={styles.header}>{data.label}</div>
    <div className={styles.text} style={{color: getBubbleColors(100)}}>{commaNumber(data.posCount)} / {commaNumber(data.count)} ({Math.round(100*data.posValue)}%)</div>
    <div className={styles.text} style={{color: getBubbleColors(50)}}>{commaNumber(neutralCount)} / {commaNumber(data.count)} ({Math.round(100*neutralCount/data.count)}%)</div>
    <div className={styles.text} style={{color: getBubbleColors(0)}}>{commaNumber(data.negCount)} / {commaNumber(data.count)} ({Math.round(100*data.negValue)}%)</div>
  </div>
}

export default ConceptSummaryTooltip