import React from 'react'
import {starColor} from '../../utils'

import styles from './stars.module.scss'

interface StarsProps extends React.HTMLProps<HTMLDivElement> {
  total?: number,
  value?: number,
  shape?: string,
  inactiveShape?: string,
}

const Stars: React.FC<StarsProps> = ({total = 5, value = 0, shape = "\u2605", inactiveShape = "\u2605", className, ...props}) => {
  const stars = Array.from({length: total}, (_, i) => i);
  const intValue = Math.min(value, total);
  const remainingStar = Number.parseFloat((value - intValue).toFixed(1));

  const activeStarStyle = {
    width: `${remainingStar*100}%`,
    overflow: 'hidden'
  }

  return <div className={[className, styles.container].join(' ')} {...props}>
    <div className={styles.stars}>
      {stars.map(s => <div key={s} className={styles.star}>
        {inactiveShape}
        {(s < intValue) && <div className={styles.activeStar}>{shape}</div>}
        {(s === intValue && remainingStar > 0) && <div className={styles.activeStar} style={activeStarStyle}>{shape}</div>}
      </div>)}
    </div>
  </div>
}

interface ShortStarsProps extends React.HTMLProps<HTMLDivElement> {
  value?: string,
  shape?: string,
  toFixed?: number
}

export const ShortStars: React.FC<ShortStarsProps> = ({value, shape = "\u2605", toFixed = 0, className}) => {
  const style: any = {}
  const _value = Number.parseFloat(value);
  const showStars = !Number.isNaN(_value) && _value > 0;
  if(showStars) {
    style['--star-rating'] = starColor(Number.parseInt(_value.toFixed()));
  }

  return <div className={[className, styles.shortStarsContainer].join(' ')} style={style}>
    {!showStars && "N/A"}
    {showStars && <>
      {shape}
      <span>{_value.toFixed(toFixed)}</span>
    </>}
  </div>
}

export default Stars;