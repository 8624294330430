import React from 'react'
import { getRangeColor2, getHoverHighlightRangeColor } from '../../utils'
import { sentimentWords } from '../../utils2'
import RatingBar from '../ratingBar/ratingBar'
import Tooltip from '../../libs/context-tooltip/tooltip'

import styles from './sentimentRatingSection.module.scss'
import { useSearch } from '../../hooks'
import { SmileBox } from '../../toneIcons'

export const SentimentRatingSection = ({data, minimized}: any) => {
  const [search, setSearch] = useSearch()
  if(data == null) return null;
  

  const toggleSearch = (v: any) => {
    setSearch((s: any) => ({ ...s, sRating: ((s.sRating === v) ? undefined : v), page: undefined }));
  }


  const SENTIMENTS = 5;
  const counts = Array.from({length: SENTIMENTS}, (_, i) => SENTIMENTS - i);

  if(minimized) {
    return <div className={styles.minRatings}>
      {counts?.map((s, i) => {
        const score = (-2*i + counts.length - 1)/(counts.length - 1);

        const className = [styles.minRatingsRow, (search?.sRating === `${s}`) ? styles.selected : null].join(' ');
        const color = getRangeColor2(s*20);
        const fadedColor = getHoverHighlightRangeColor(s*20);
        const count = data?.byReviewSentiment?.[s - 1];
        const ratingValue = (data?.sentimented > 0) ? 100*count/data.sentimented : 0;

        const style: any = {
          '--color': color,
          '--faded-color': fadedColor,
        }

        return <div key={s} className={className} style={style} onClick={() => toggleSearch(`${s}`)}>
          <div className={styles.background} style={{width: `${ratingValue}%` }} />
          <SmileBox score={score} className={styles.box} />
        </div>
      })}
    </div>
  }

  return <div className={styles.ratings}>
    {counts?.map((s, i) => {
      const color = getRangeColor2(s*20);
      const count = data?.byReviewSentiment?.[s - 1];
      const ratingValue = (data?.sentimented > 0) ? 100*count/data.sentimented : 0;
      const style = {
        gridRowStart: i+1,
        gridRowEnd: 'span 1',
      }
      const outerStyle: any = { '--color': color }

      const className = [styles.row, (search?.sRating === `${s}`) ? styles.selected : null].join(' ');
      return <div key={s} className={className} style={outerStyle} onClick={() => toggleSearch(`${s}`)}>
        <div className={styles.rowName} style={style}>
          {sentimentWords[s]}
        </div>
        <div className={styles.ratingBar} style={style}>
          <RatingBar value={ratingValue} />
        </div>
        <div className={styles.rowCount} style={style}>{count}</div>
        <Tooltip content={<SentimentRatingTooltip sentiment={s} />} className={styles.tooltipRow} style={style} />
      </div> 
    })}
  </div>
}

const SentimentRatingTooltip = ({sentiment}: any) => {
  return <div>
    <div className={styles.tooltipRowFooter}>Click to see {sentimentWords[sentiment]} Reviews</div>
  </div>
}

export default SentimentRatingSection