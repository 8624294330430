import React from 'react'
import {DataProvider} from './data.context'
import {ReportsProvider} from './reports.context'
import {AuthProvider} from './auth.context'
import {AppsProvider} from './apps.context'
import {UsersProvider} from './users.context'
import {ReviewsProvider} from './reviews.context'
import {ConceptsProvider} from './concepts.context'
import {EnhancedUsersProvider} from './enhancedUsers.context'
import {MediaQueryProvider} from './mediaQuery'

export {ReportsProvider, useReportsContext} from './reports.context'
export {DataProvider, useDataContext} from './data.context'
export {AuthProvider, useAuthContext} from './auth.context'
export {AppsProvider, useAppsContext} from './apps.context'
export {UsersProvider, useUsersContext} from './users.context'
export {ReviewsProvider, useReviewsContext} from './reviews.context'
export {ConceptsProvider, useConceptsContext} from './concepts.context'
export {EnhancedUsersProvider, useEnhancedUsersContext} from './enhancedUsers.context'
export { useMediaQueryContext } from './mediaQuery'

export const Providers: React.FC<React.HTMLProps<HTMLDivElement>> = ({children}) => {
  return <MediaQueryProvider>
    <AuthProvider>
      <ReportsProvider>
        <ConceptsProvider>
          <ReviewsProvider>
            <DataProvider>
              <AppsProvider>
                <UsersProvider>
                  <EnhancedUsersProvider>
                    {children}
                  </EnhancedUsersProvider>
                </UsersProvider>
              </AppsProvider>
            </DataProvider>
          </ReviewsProvider>
        </ConceptsProvider>
      </ReportsProvider>
    </AuthProvider>
  </MediaQueryProvider>
}