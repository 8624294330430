import React from 'react'

import styles from './cellHeader.module.scss'

interface CellHeaderProps extends React.HTMLProps<HTMLOrSVGElement> {
  sticky?: boolean,
  tagName?: any,
}

export const CellHeader: React.FC<CellHeaderProps> = ({tagName, sticky, className, children, ...props}) => {
  const Tag = tagName || 'div'

  const classList = [
    styles.header,
    sticky ? styles.sticky : undefined,
    className,
  ].join(' ');

  return <Tag {...props} className={classList}>{children}</Tag>
}

export default CellHeader;