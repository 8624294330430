import React from 'react'
import Color from 'color'
import { getRangeColor2, getScore } from '../../utils'
import Highlighter from '../../libs/highlighter/highlighter'

import styles from './reviewsSummary.module.scss'


interface ReviewsSummaryProps {
  reviews?: any,
  conceptsById?: any,
  search?: any,
  reviewsById?: any,
  reportSyntaxByReviewId?: any,
  suggestConcepts?: any,
}

export const ReviewsSummary: React.FC<ReviewsSummaryProps> = ({reviews, conceptsById, search, reviewsById, reportSyntaxByReviewId}) => {
  const reviewsKeys = reviews?.groups && Object.keys(reviews.groups).sort();
  const concept = search?.category;
  return <div className={styles.scrollable}>
    <div className={styles.scrollableContent}>
      {reviewsKeys?.map(p => <React.Fragment key={p}>
        {reviews?.groups?.[p]?.map((id: any) =>
          <ReviewSentence key={id} review={reviewsById?.[id]} conceptsById={conceptsById} concept={concept} syntax={reportSyntaxByReviewId?.[id]} />
        )}
      </React.Fragment>)}
    </div>
  </div>
}

export default ReviewsSummary

const matchClass = (search: any) => search?.matchClass;
const matchStyle = (search: any) => search?.matchStyle;
const searchTransform = (search: any) => search?.word;

interface ReviewSentenceProps {
  review: any,
  concept: any,
  conceptsById: any,
  syntax?: any
}

const ReviewSentence: React.FC<ReviewSentenceProps> = ({review, concept, conceptsById}) => {
  const words = conceptsById?.[concept]?.words;
  const sentences: any[] = React.useMemo(() => {
    if(review?.joinedRanges) {
      const hits = review.joinedRanges.map((range: any) => {
        if(range?.cats?.find((c:any) => c.c === concept) != null) return range;
        else return null;
      }).filter((a:any) => a);
      
      return hits?.map((hit: any) => {
        const highlights = words?.map((w: any) => ({
          word: w, matchClass: styles.matchingWord,
        }))
        const text = [review.title, review.text].join('. ');
        const slicedText = text.slice(hit.b, hit.e);
        const catHitScore = getScore(hit.s);
        return {
          ...hit,
          text: <Highlighter search={highlights}
            matchClass={matchClass}
            matchStyle={matchStyle}
            searchTransform={searchTransform}>{slicedText}</Highlighter>,
          score: catHitScore,
        }
      })
    }
    return []
  }, [review, concept, words])
  
  return <div className={styles.review}>
    {sentences?.map((s, i) => {
      const style: any = {
        '--highlight-color':  Color(getRangeColor2(s.score)).fade(0.8).toString(),
      }
      return <div key={i} style={style} className={styles.sentence}>{s.text}</div>
    })}
  </div>;
}