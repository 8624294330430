import React from 'react';
import { useSearch } from '../../hooks'

import { useEnhancedUsersContext, useReviewsContext, useReportsContext, useAuthContext, useConceptsContext } from '../../contexts'
import Detail from '../detail/detail'
import ConceptDetail from '../conceptDetail/conceptDetail'
import AddConceptDetail from '../addConceptDetail/addConceptDetail'
import MachineDetail from '../machineDetail/machineDetail'
import UserDetail from '../userDetail/userDetail'
import DetailHeader from '../detailHeader/detailHeader'
import UserHeader from '../userHeader/userHeader'
import MachineHeader from '../machineHeader/machineHeader'
import ConceptHeader from '../conceptHeader/conceptHeader'
import AddConceptHeader from '../addConceptHeader/addConceptHeader'
import Reviews from '../reviews/reviews'
import ReviewsSummary from '../reviewsSummary/reviewsSummary'
import VersionHistory from '../versionHistory/versionHistory'
import ReviewsHeader from '../reviewsHeader/reviewsHeader'
import {filterConcepts} from '../../utils'
import Filters from '../brandFilters/filters'

import styles from './reportBrand.module.scss'


interface ReportBrandProps {
  report: any,
  brand: any,
  demoReportId: string,
  shared?: any,
  conceptsById?: any,
  reportConcepts?: any,
  versionHistoryIntervalType?: any
}

const ReportBrand: React.FC<ReportBrandProps> = React.memo(({ report, brand, demoReportId, shared, conceptsById, reportConcepts, versionHistoryIntervalType }) => {
  const [search, setSearch] = useSearch();
  const [{ permission }] = useAuthContext();
  const [{libraryConcepts, suggestConceptsByReport, similarWordsByReport}, {updateConcepts, createConcept}] = useConceptsContext();
  const [{groupedReviews, reviewsById, commentsByReviewId}, {fetchBrandReviews, fetchPublicBrandReviews, fetchDemoBrandReviews}] = useReviewsContext();
  const [, {reportUpdateBrand}] = useReportsContext();
  
  const [{groupedDelightedUsersByEmail}] = useEnhancedUsersContext();

  const [conceptName, setConceptName] = React.useState(null);
  const [suggestActiveConcept, setSuggestActiveConcept] = React.useState(0);
  const reportId = report?.data?._id;
  const reportPublicId = report?.data?.publicId;
  const brandId = brand?.data?._id;

  const [groupedCatTypes] = React.useMemo(() => {
    const groupedCatTypes: any = {}
    for(let i=0; i < reportConcepts?.length; i++) {
      const catType = reportConcepts[i].catType;
      if(groupedCatTypes[catType] == null) groupedCatTypes[catType] = 1;
      else groupedCatTypes[catType] += 1;
    }
    return [groupedCatTypes];
  }, [reportConcepts])
  React.useEffect(() => { setSuggestActiveConcept(0); }, [conceptName])

  const reportGroupedReviews = groupedReviews?.[[reportId, brandId].join("_")]
  React.useEffect(() => {
    if(brandId == null) return;
    const data: any = {}
    if (search.category) {
      data.category = search.category;
      data.conceptAspect = search.conceptAspect;
    }
    if (search.query) data.query = search.query;
    if (search.rating) data.rating = search.rating;
    if (search.sRating) data.sRating = search.sRating;
    if (search.page) data.page = search.page;

    if (demoReportId) {
      fetchDemoBrandReviews(demoReportId, brandId, data);
    } else if (shared) {
      fetchPublicBrandReviews(reportPublicId, brandId, data);
    } else {
      if(reportGroupedReviews == null) fetchBrandReviews(reportId, brandId, data);
      else if(reportGroupedReviews.isDifferent(data)) fetchBrandReviews(reportId, brandId, data);
    }
  }, [reportId, reportPublicId, brandId, demoReportId, shared, search.category, search.query, search.rating, search.sRating, search.page, search.conceptAspect, fetchBrandReviews, fetchPublicBrandReviews, fetchDemoBrandReviews, reportGroupedReviews]);

  const suggestConcepts = suggestConceptsByReport?.[reportId];

  const onUpdateConcept = (cId: string, data: any) => { updateConcepts(reportId, cId, data) }
  const onCreateConcept = async (data: any) => {
    const body:any = {};
    if(data.name) body.name = data.name;
    if(data.words) body.words = data.words;
    if(data.positive) body.positive = data.positive;
    if(data.negative) body.negative = data.negative;
    if(data.countInScore) body.countInScore = data.countInScore;
    if(data._id) body.blueprintId = data._id;
    
    if(data.catType) body.catType = data.catType;
    else body.catType = search.addConcept;
    
    const category: any = await createConcept(reportId, body);
    if(!category) return;
    
    setSearch((s:any) => ({...s, pinnedConcept: category._id, page: undefined, addConcept: undefined, category: undefined}));
  }
  const onUploadIcon = (imageUrl: string) => reportUpdateBrand(reportId, brandId, {imageUrl});

  let key = null;
  if(demoReportId) key = [demoReportId, brandId].join('_');
  else if(shared) key = [reportPublicId, brandId].join('_');
  else key = [reportId, brandId].join('_');

  const reviews = groupedReviews?.[key];
  const firstReview = reviews?.first();

  const closed = search.hideDetail !== undefined;
  
  const selectedConcept = reportConcepts?.find((c: any) => c._id === search.category);
  const pinnedConcept = search.pinnedConcept && reportConcepts?.find((c: any) => c._id === search.pinnedConcept);
  
  const filteredLibraryConcepts = filterConcepts(libraryConcepts, search.addConcept, conceptName);

  let mainSection = null;
  if(search.section === 'versionHistory') {
    mainSection = <VersionHistory data={brand?.history} intervalType={versionHistoryIntervalType} />
  } else if(search.section === 'sentences'){
    mainSection = <ReviewsSummary reviews={reviews}
      conceptsById={conceptsById}
      search={search}
      reviewsById={reviewsById}
      suggestConcepts={suggestConcepts} />
  } else {
    mainSection = <Reviews report={report}
      id={brandId}
      reviews={reviews}
      conceptsById={conceptsById}
      demoId={demoReportId}
      reviewsById={reviewsById}
      commentsByReviewId={commentsByReviewId}
      permission={permission}
      groupedCatTypes={groupedCatTypes} />
  }
  let detailHeader = null;
  let detailSection = null;
  if(search.user !== undefined) {
    const data = groupedDelightedUsersByEmail?.[search.user];
    detailHeader = <UserHeader closed={closed} data={data} />
    detailSection = <UserDetail search={search} />
  } else if(search.addConcept !== undefined) {
    detailHeader = <AddConceptHeader closed={closed} onCreateConcept={onCreateConcept} setConceptName={setConceptName} conceptName={conceptName} suggestActiveConcept={suggestActiveConcept} setSuggestActiveConcept={setSuggestActiveConcept} filteredLibraryConcepts={filteredLibraryConcepts} />;
    detailSection = <AddConceptDetail
      suggestConcepts={suggestConcepts}
      filteredLibraryConcepts={filteredLibraryConcepts}
      onCreateConcept={onCreateConcept}
      suggestActiveConcept={suggestActiveConcept}
      conceptName={conceptName}
      permission={permission} />
  } else if(search.machine != null && permission.canMachineLearn(report)) {
    detailHeader = <MachineHeader closed={closed} reviews={reviews}  />
    detailSection = <MachineDetail
      reportId={reportId}
      brandId={brandId}
      data={selectedConcept}
      concepts={reportConcepts}
      reviews={reviews}
      reviewsById={reviewsById}
      conceptsById={conceptsById} />
  } else if(search.pinnedConcept && permission.canModifyBrand(report)) {
    const similarWords = similarWordsByReport?.[[reportId, pinnedConcept?._id].join('_')]
    detailHeader = <ConceptHeader data={pinnedConcept} closed={closed} onUpdateConcept={onUpdateConcept} />;
    detailSection = <ConceptDetail
      reportId={reportId}
      data={pinnedConcept}
      categorizedPhrases={report?.keyConceptsByCat}
      similarWords={similarWords}
      permission={permission} />
  } else if(search.category && permission.canModifyBrand(report)) {
    const similarWords = similarWordsByReport?.[[reportId, selectedConcept?._id].join('_')]
    detailHeader = <ConceptHeader data={selectedConcept} closed={closed} onUpdateConcept={onUpdateConcept} />;
    detailSection = <ConceptDetail
      reportId={reportId}
      data={selectedConcept}
      categorizedPhrases={report?.keyConceptsByCat}
      similarWords={similarWords}
      permission={permission} />
  } else {
    detailHeader = <DetailHeader data={brand} closed={closed} onUploadIcon={permission.canModifyBrand(report) && onUploadIcon} rounded />;
    detailSection = <Detail brand={brand} permission={permission} reportCounts={report?.dataCounts} />;
  }

  return <>
    <ReviewsHeader report={report} permission={permission} firstReview={firstReview} />
    <div className={styles.container}>  
      <div className={styles.mainSection}>
        {mainSection}
      </div>
    </div>
    <div className={styles.detailHeader}>
      {detailHeader}
    </div>
    <div className={styles.detail}>
      {!closed && detailSection}
    </div>

    <Filters report={report} permission={permission} concepts={reportConcepts} data={brand} />
  </>
})

export default ReportBrand