import React from 'react';

import { MaddenIcon } from '../icons/icons'
import { useReviewsContext, useAuthContext } from '../../contexts'
import Review from '../review/review'

import styles from './reportComments.module.scss'
import Permission from '../../permission';

interface ReportCommentsProps {
  report: any,
  conceptsById: any,
  path?: string
}

const ReportComments: React.FC<ReportCommentsProps> = React.memo(({ report, conceptsById }) => {
  const [{reviewsWithCommentsByReportId, reviewsById, commentsByReviewId}, {fetchComments, fetchReview}] = useReviewsContext();
  const [{ permission }] = useAuthContext();
  const reportId = report?.data?._id;
  React.useEffect(() => { fetchComments(reportId); }, [reportId, fetchComments])

  const reviewIds: any[] | undefined = reviewsWithCommentsByReportId?.[reportId];
  
  return <div className={styles.container}>
    <div className={styles.comments}>
      {reviewIds?.map(reviewId => {
        return <ReviewContainer key={reviewId} report={report} reviewId={reviewId} commentsByReviewId={commentsByReviewId} reviewsById={reviewsById} permission={permission} conceptsById={conceptsById} fetchReview={fetchReview} />
      })}
    </div>
  </div>
})

export default ReportComments

interface ReviewContainerProps {
  report: any,
  reviewId: string,
  commentsByReviewId: any,
  permission: Permission,
  reviewsById: {[reviewId: string]: any},
  fetchReview: any,
  conceptsById: any
}

const ReviewContainer: React.FC<ReviewContainerProps> = ({report, reviewId, commentsByReviewId, permission, reviewsById, conceptsById, fetchReview}) => {
  const comments = commentsByReviewId?.[reviewId];
  const review = reviewsById?.[reviewId];
  const reportId = report?.data?._id;

  const shouldFetchReview = review == null;
  React.useEffect(() => {
    if(shouldFetchReview && fetchReview) {
      fetchReview(reportId, reviewId);
    }
  }, [shouldFetchReview, fetchReview, reportId, reviewId])
  if(comments == null || review == null) return null;

  
  const appKey = [reportId, review.metaId].join('_');
  const app: any = undefined //appsByReportKey?.[appKey];
  
  const firstComment = comments?.[0];
  const lastComments = comments?.slice(1);

  return <>
    {firstComment && <>
      <div className={styles.insight}>
        <div className={styles.insightTitle}>
          <MaddenIcon className={styles.insightIcon} />
          <span>INSIGHT</span>
        </div>
        <div className={styles.insightDesc}>
          {firstComment?.text}
        </div>
      </div>
      <Review review={review} comments={comments} permission={permission} report={report} conceptsById={conceptsById} app={app} />
    </>}
    
    {lastComments?.length > 0 && <Review review={review} comments={lastComments} permission={permission} report={report} conceptsById={conceptsById} showComments app={app} />}
  </>
}