import React from 'react'
import { Link } from "@reach/router"
import logo from '../../images/cards/harmonize-wordmark.svg'
import styles from './demoViz.module.scss'

export const DemoViz = () => {
  return <div className={styles.viz}>
    <Link className={styles.logo} to='/'>
      <img src={logo} alt='Harmonize' />
    </Link>
    <div className={styles.vizContent}>
      <div className={styles.description}>
        Understand your customer’s needs, motivations, values, and purchase drivers faster than your competitors using NLP + AI
      </div>
    </div>
    <svg viewBox="0 0 646 527" className={styles.waves} preserveAspectRatio="none">
      <path d="M646,267.100986 L646,527 L0,527 L0,0.0176643051 C1.31067683,0.00591395966 2.61866919,0 3.92397845,0 C196.466163,0 189.727225,269.965595 554.817459,269.964066 C583.959626,269.963944 614.353806,269.009584 646,267.100986 L646,267.100986 Z" fillOpacity="0.35" fill="#ADAAFD" style={{ mixBlendMode: 'multiply' }} />
      <path d="M646,40.8096279 L646,527 L0,527 L0,243.454109 C55.1871836,261.236714 107.310882,270.128016 156.371094,270.128016 C282.239064,270.128016 477.748803,122.483027 646,40.8096279 L646,40.8096279 Z" fillOpacity="0.15" fill="#2B9DF8" />
    </svg>
  </div>
}

export default DemoViz