import React from 'react'

import SuggestConcept from '../suggestConcept/suggestConcept'

import styles from './addConceptDetail.module.scss'
import { useSearch } from '../../hooks';

interface AddConceptDetailProps {
  data?: any,
  suggestConcepts?: any,
  onCreateConcept?: any,
  suggestActiveConcept?: any,
  filteredLibraryConcepts?: any,
  conceptName?: any,
  permission?: any
}

export const AddConceptDetail: React.FC<AddConceptDetailProps> = React.memo(({data, suggestConcepts, onCreateConcept, suggestActiveConcept, filteredLibraryConcepts, conceptName }) => {
  const [search, setSearch] = useSearch();
  const scrollRef = React.useRef(null);
  
  const onCancelNewConcept = () => {
    setSearch((s: any) => ({...s, page: undefined, addConcept: undefined, category: undefined}));
  }

  const filteredSuggestConcepts = search?.addConcept && suggestConcepts?.filter((c: any) => c.catType === search.addConcept);

  return <div className={styles.detail}>
    <div className={styles.scrollable} ref={scrollRef}>
      {filteredLibraryConcepts && <>
        <div className={styles.sectionHeader}>LIBRARY CONCEPTS</div>
        <div className={styles.suggestConcepts}>
          {filteredLibraryConcepts?.map((concept: any, i: number) => <SuggestConcept key={i} data={concept} onCreateConcept={onCreateConcept} active={i === suggestActiveConcept} scrollRef={scrollRef} />)}
        </div>
      </>}
      {conceptName != null && conceptName.length > 0 && <SuggestConcept onCreateConcept={() => onCreateConcept({name: conceptName})} active={filteredLibraryConcepts.length === suggestActiveConcept} scrollRef={scrollRef} />}
      {filteredSuggestConcepts?.length > 0 && <>
        <div className={styles.sectionHeader}>SUGGESTED CONCEPTS</div>
        <div className={styles.suggestConcepts}>
          {filteredSuggestConcepts.map((concept: any, i: number) => <SuggestConcept key={i} data={concept} onCreateConcept={onCreateConcept} />)}
        </div>
      </>}
    </div>
    {!data && <div className={styles.deleteButton} onClick={onCancelNewConcept}>Cancel</div>}
  </div>
})

export default AddConceptDetail;