import React from 'react';

const getSize = (el) => {
  return {
    width: el?.offsetWidth || 0,
    height: el?.offsetHeight || 0,
    top: el?.offsetTop || 0,
    left: el?.offsetLeft || 0,
    bbox: el?.getBoundingClientRect() || {},
    scrollTop: el?.children?.[0]?.scrollTop,
    scrollLeft: el?.children?.[0]?.scrollLeft,
  }
}

export const useComponentSize = (ref) => {
  const refCurrent = ref?.current;
  const [size, setSize] = React.useState(getSize(ref ? ref.current : {}));
  const handleResize = React.useCallback(() => {
    if (refCurrent) setSize(getSize(refCurrent))
  }, [refCurrent]);
  React.useLayoutEffect(() => {
    if (refCurrent == null) return;
    handleResize();
    if (typeof ResizeObserver === 'function') {
      let resizeObserver = new ResizeObserver(() => { handleResize(); })
      resizeObserver.observe(refCurrent)

      return () => {
        resizeObserver.disconnect(refCurrent)
        resizeObserver = null
      }
    } else {
      window.addEventListener('resize', handleResize)

      return () => { window.removeEventListener('resize', handleResize); }
    }
  }, [refCurrent, handleResize])

  return size
}

export default useComponentSize