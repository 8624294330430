import React from 'react';
import Tooltip from '../../libs/context-tooltip/tooltip'

import {EnhancedUser} from '../../models/enhancedUser.model'
import { MaleIcon, FemaleIcon } from '../icons/icons'
import styles from './identityIcon.module.scss'

interface IdentityIconProps {
  data?: EnhancedUser,
  username?: string
}

export const IdentityIcon: React.FC<IdentityIconProps> = ({data, username}) => {
  if(data == null) return null;
  
  const content = <div className={styles.userTooltip}>
    {data.icon && <div className={styles.userIcon}>
      <img src={data.icon} alt={`Enhanced ${username}`} />
    </div>}
    {data.icon == null && <>
      {data.gender === 'F' && <FemaleIcon className={styles.userIcon} />}
      {data.gender === 'M' && <MaleIcon className={styles.userIcon} />}
    </>}
    {data.name != null && <span>{data.name}</span>}
    {data.gender != null && <strong>({data.gender})</strong>}
    {data.age != null && <span>{data.age}</span>}
    {data.accuracy != null && <span className={styles.accuracy}>{data.accuracy}% accuracy</span>}
    {data.social != null && data.social.length > 0 && <span>{data.social.join(' ')}</span>}
  </div>

  const props = {
    background: '#FFFBFB',
    tooltipClassName: styles.tooltipContainer,
    content
  }
  if(data.icon) {
    return <Tooltip className={styles.userIcon} {...props}>
      <img src={data.icon} alt={`Enhanced ${username}`} />
    </Tooltip>
  }
  if(data.gender === 'F') return <Tooltip tagName={FemaleIcon} className={styles.userIcon} {...props} />
  if(data.gender === 'M') return <Tooltip tagName={MaleIcon} className={styles.userIcon} {...props} />
  return null;
}

export default IdentityIcon