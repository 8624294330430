import React from 'react'

import { isMac } from '../../utils'
import Textarea from '../expandingTextarea/expandingTextarea'
import { CloseIcon } from '../icons/icons'

import styles from './comment.module.scss'

interface CommentProps {
  author: string,
  text: string,
  deleteFn: any
}

export const Comment: React.FC<CommentProps> = ({author, text, deleteFn}) => {
  const letter = author?.[0]?.toUpperCase();
  return <div className={styles.comment}>
    <div className={styles.icon}>{letter}</div>
    <div className={styles.content}>
      {deleteFn && <div onClick={deleteFn} className={styles.delete}><CloseIcon /></div>}
      <div className={styles.header}>{author}</div>
      <div className={styles.text}>{text}</div>
    </div>
  </div>
}

export default Comment


interface NewCommentProps {
  submitPost: any
}

export const NewComment: React.FC<NewCommentProps> = ({submitPost}) => {
  const [value, setValue] = React.useState('');
  const onChange = (e: any) => { setValue(e.target.value) }

  const _submitPost = () => {
    if(value == null || value.length === 0) return;
    if(submitPost) {
      submitPost(value);
      setValue('');
    } else {
      setValue('');
    }
  }

  const onKeyDown = (e: any) => {
    if((e.ctrlKey || e.metaKey) && (e.keyCode === 13 || e.keyCode === 10)) {
      _submitPost();
    }
  }

  return <div className={styles.newComment}>
    <Textarea className={styles.textarea} value={value} placeholder="Add Comment" autoFocus onChange={onChange} onKeyDown={onKeyDown} />

    <button className={styles.newCommentButton} disabled={value == null || value.length === 0} onClick={_submitPost}>
      Post
      <div className={styles.shortcut}>{isMac() ? '⌘+enter' : 'ctrl+enter'}</div>
    </button>
  </div>
}