import React from 'react';
import useMedia from 'use-media';

export const MediaQueryContext = React.createContext(null);

const mediaQueries = {
  mobile: '(max-width: 767px)',
};

export const MediaQueryProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({children}) => {
  const isMobileView = useMedia(mediaQueries.mobile);
  const value = React.useMemo(() => ({isMobileView}), [isMobileView]);

  return <MediaQueryContext.Provider value={value}>
    {children}
  </MediaQueryContext.Provider>
}

export default MediaQueryProvider;

export const useMediaQueryContext = () => React.useContext(MediaQueryContext)