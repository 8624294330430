import React from 'react'
import {useDebouncedCallback} from 'use-debounce';
import { ToastContext } from '../../libs/context-toast/toast'

import { SaveToClipboard } from '../../toneIcons';

import styles from './inputClipboard.module.scss'

interface InputClipboardProps extends React.HTMLProps<HTMLDivElement> {
  onInstantChange?: any,
  onSubmit?: any
}

export const InputClipboard: React.FC<InputClipboardProps> = ({className, value, placeholder, onInstantChange, onChange, onSubmit, autoFocus}) => {
  const ref = React.useRef<any>();
  const [internalValue, setInternalValue] = React.useState<string>(`${value}`);

  const debounced = useDebouncedCallback(value => onChange(value), 600)

  const {showToast} = React.useContext(ToastContext);
  React.useEffect(() => { setInternalValue(`${value}`); }, [value])

  const onInputChange = (e: any) => {
    debounced.callback(e.target.value);
    setInternalValue(e.target.value);
    if(onInstantChange) onInstantChange(e.target.value)
  }

  const _onSubmit = (e: any) => {
    e.preventDefault();
    if(onSubmit) onSubmit(internalValue);
  }

  const saveToClipboard = () => {
    ref.current.select();
    document.execCommand("copy");
    ref.current.blur();
    showToast('copied to clipboard')
  }

  return <form className={[className, styles.container, internalValue?.length > 0 ? styles.hasValue : null].join(' ')} onSubmit={_onSubmit}>
    <input className={styles.input} placeholder={placeholder} value={internalValue || ""} onChange={onInputChange} autoFocus={autoFocus} ref={ref} />
    <div className={styles.saveToClipboard} onClick={saveToClipboard}>
      <SaveToClipboard />
    </div>
  </form>
}

export default InputClipboard