import React from 'react';
import firebase from 'firebase/compat/app';
import { Link } from "@reach/router"

import Input from '../../components/input/input'
import {Button} from '../../components/button/button'
import {LogoIcon2} from '../../components/icons/icons'
import useForm from '../../hooks/useForm'

import styles from './auth.module.scss'

interface ForgotPasswordProps {
  location: any
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = React.memo(({location}) => {
  const { values, errors, hasErrors, isSubmitting, handleChange, registerSubmit }: any = useForm({email: ''})
  const className = [styles.content, hasErrors ? styles.errorShake : null].join(' ');
  const isDisabled = !values.email;

  const handleSubmit = async ({values, setIsSubmitting, setErrors}: any) => {
    try {
      const email = values.email.replace(/(^\s+|\s+$)/g,'');
      await firebase.auth().sendPasswordResetEmail(email)
      setIsSubmitting(false);
    } catch(error) {
      setErrors({email: true, global: error.message});
      setIsSubmitting(false);
    }
  }

  return <div className={styles.container}>
    <LogoIcon2 className={styles.logo} />
    <form className={className} onSubmit={registerSubmit(handleSubmit)}>
      {errors?.global && <div className={styles.error}>{errors.global}</div>}
      <Input className={styles.input} autoFocus
        title="Email"
        placeholder="me@email.com"
        type="text"
        name="email"
        value={values.email}
        error={errors.email}
        onChange={handleChange}
        hideCloseButton
        autoCapitalize="none"
        autoComplete="password" />
      <Button title="Send reset link" disabled={isDisabled} loading={isSubmitting} className={styles.button} tagName="button" />
    </form>
    <div className={styles.footer}>
      <div className={styles.footerButtons}>
        <div className={styles.spacer} />
        <Link to={`../login${location.search}`} className={styles.footerButton}>Login to existing account</Link>
      </div>
    </div>
  </div>
})