import React from 'react'
import { createPortal } from 'react-dom';
import usePortal from '../usePortal'
import { useKey } from '../../hooks/useKey'

import styles from './popup.module.scss'

interface PopupProps extends React.HTMLProps<HTMLDivElement> {
  popupClassName?: string,
  popupContentClassName?: string,
  onClose: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  icon?: any
}

export const Popup: React.FC<PopupProps> = ({popupClassName, popupContentClassName, onClose, icon, onSubmit, children, style}) => {
  React.useEffect(() => {
    document.body.classList.add("popupShown")
    return () => {
      document.body.classList.remove("popupShown")
    }
  }, [])
  useKey(['Escape'], onClose);

  const Tag = onSubmit != null ? 'form' : 'div';
  const props: any = {}
  if(onSubmit) {
    props.onSubmit = onSubmit;
  }

  return <PopupPortal>
    <div className={[styles.container, popupClassName].join(' ')} style={style}>
      <div className={styles.background} onClick={onClose} />
      <Tag className={styles.content} {...props}>
        {icon != null && <div className={styles.circle}>
          {icon}
        </div>}
        <div className={[styles.contentBody, popupContentClassName].join(" ")}>
          {children}
        </div>
      </Tag>
    </div>
  </PopupPortal>
}

export default Popup;


export const PopupPortal: React.FC<React.HTMLProps<HTMLDivElement>> = ({ id, children }) => {
  const target = usePortal(id || 'modal-portal');
  return createPortal(children, target);
}