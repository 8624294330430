import React from 'react'
import BubbleFooterBar from '../../components/bubbleFooterBar/bubbleFooterBar'
import FooterBar from '../../components/footerBar/footerBar'
import { LoadingContainer } from '../../components/loading/loading'
import NextPrevArrows from '../../components/nextPrevArrows/nextPrevArrows'
import { Slide } from '../../components/slide/slide'
import { useMediaQueryContext, useReportsContext } from '../../contexts'
import styles from './presentation.module.scss'



interface PresentationProps {
  demoId: string
}

export const Presentation: React.FC<PresentationProps> = ({demoId}) => {
  const [{reportsById}, {fetchDemoReport}] = useReportsContext();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const {isMobileView} = useMediaQueryContext();

  const report = React.useMemo(() => {
    if(demoId && reportsById) return reportsById?.[demoId];
  }, [reportsById, demoId]);

  const shouldFetchDemoReport = demoId != null && (report == null || (report != null && report.status !== 'DETAIL_FETCHED'))
  React.useEffect(() => {
    if(shouldFetchDemoReport) { fetchDemoReport(demoId); }
  }, [demoId, shouldFetchDemoReport, fetchDemoReport]);

  const onPrev = React.useCallback((e, currentIndex) => {
    if(e) e.preventDefault();
    if(e) e.stopPropagation();

    if(currentIndex != null) setActiveIndex(currentIndex - 1);
    else setActiveIndex(i => i - 1);
  }, [])
  const onNext = React.useCallback((e, currentIndex) => {
    if(e) e.preventDefault();
    if(e) e.stopPropagation();

    if(currentIndex != null) setActiveIndex(currentIndex + 1);
    else setActiveIndex(i => i + 1);
  }, [])

  if(report == null) return <LoadingContainer />
  if(report.status !== 'DETAIL_FETCHED') return <LoadingContainer />
  
  const style: any = {
    '--progess-bar-height': '13px',
    '--bubble-progess-bar-height': '44px',
    '--z-index-hud': 1000,
  }

  const showIntro = false;
  const showResults = false;
  const slides = [{type: 'intro'}, {type: 'intro'}];

  const hideHeaderLogo = (showIntro && activeIndex === 0) || (showResults && activeIndex === (slides.length - 1))
  return <div className={styles.container} style={style}>
    <header className={[styles.header, (hideHeaderLogo) ? styles.hidden : null].join(' ')}>
      {/* <img src={namespace.data.logoUrl} alt={namespace.data.name} className={styles.logo} /> */}
    </header>
    {slides?.map((slide, index) => {
      let left: any = 0;
      if(activeIndex < index) left = '100%';
      else if(activeIndex > index) left = '-100%';

      const props: any = {
        focus: activeIndex === index,
        report,
        data: slide,
        style: {
          zIndex: index,
          left
        }
      }
      if(index > 0) props.onPrev = onPrev;
      if(index < (slides.length - 1)) props.onNext = onNext;
      
      
      if(slide.type === 'intro') {
        return <Slide {...props} key={index} />
      }
      
      return null;
    })}
    {!isMobileView && <FooterBar showIntro={showIntro} showResults={showResults} index={activeIndex} length={slides.length} />}
    {!isMobileView && <NextPrevArrows index={activeIndex} slides={slides} onNext={onNext} onPrev={onPrev} />}
    {isMobileView && <BubbleFooterBar showIntro={showIntro} index={activeIndex} length={slides.length} />}
  </div>
}

export default Presentation