import React from 'react';
import ReactDOM from 'react-dom';
import 'array-flat-polyfill';
import App from './App';

import './index.module.css';
import './fonts/fonts.module.css'

ReactDOM.render(<App />, document.getElementById("root"));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
