import React from 'react';

import { getRangeColor, getScore } from '../../utils'
import {ScoreTooltip} from '../scoreTooltip/scoreTooltip'
import Tooltip from '../../libs/context-tooltip/tooltip'

import styles from './cell.module.scss'

interface ScoreCellProps extends React.HTMLProps<HTMLDivElement> {
  score: number,
  demoId: string,
  isDemo: boolean,
  concepts: any[],

}

const ScoreCell: React.FC<ScoreCellProps> = React.memo(({ score, demoId, isDemo, concepts, className }) => {
  const percentScore = (demoId != null && !isDemo) ? null : getScore(score);

  const style = {
    '--score-color': getRangeColor(percentScore),
    opacity: (demoId != null && !isDemo) ? 0.1 : 1
  }

  if (percentScore == null || Number.isNaN(percentScore) || percentScore == null) {
    return <div className={[className, styles.nan].join(' ')} style={style}>
      {demoId == null && <span>N/A</span>}
      {demoId != null && <div className={styles.score}>&nbsp;</div>}
    </div>
  }

  const conceptNames = concepts?.filter(c => c.countInScore).map(c => c.name);

  if(conceptNames == null || conceptNames.length === 0 ) {
    return <div className={[className, styles.centeredCell].join(' ')} style={style}>
      <div className={styles.score}>
        {percentScore}
      </div>
    </div>
  }

  return <div className={[className, styles.centeredCell].join(' ')} style={style}>
    <Tooltip content={<ScoreTooltip conceptNames={conceptNames} />} background="#fff" offset={5} className={styles.score}>
      {percentScore}
    </Tooltip>
  </div>
})

export default ScoreCell