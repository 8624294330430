import React from 'react';

import styles from './cell.module.scss'

interface ReviewCountCellProps extends React.HTMLProps<HTMLDivElement> {
  isDemo: boolean,
  demoId: string,
  count: number
}

export const ReviewCountCell: React.FC<ReviewCountCellProps> = React.memo(({isDemo, demoId, className, count}) => {
  const style = {
    opacity: (demoId != null && !isDemo) ? 0.1 : 1
  }

  return <div className={[className, styles.centeredCell].join(' ')} style={style}>
    {count != null && <>
      {demoId != null && !isDemo && <div className={styles.demoText}>****</div>}
      {demoId != null && isDemo && <div className={styles.text}>{count}</div>}
      {demoId == null && <div className={styles.text}>{count}</div>}
    </>}
  </div>
})

export default ReviewCountCell