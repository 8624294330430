import { useEffect } from "react";

const defaultOptions = {
  when: true,
  eventTypes: ["keydown"]
};


export const useKey = (keyList: string[] = [], handler: (any: any) => void, opts?: any) => {
  const options = { ...defaultOptions, ...opts};
  const { when, eventTypes } = options;
  const { target } = options;
  let targetNode: any;
  if (typeof window !== "undefined") targetNode = window;
  if (target?.current) targetNode = target.current;

  useEffect(() => {
    const handle = (e: any) => {
      if (keyList.includes(e.key) || keyList.includes(e.keyCode)) {
        handler(e);
      }
    }
    if (when) {
      eventTypes.forEach((eventType: any) => {
        targetNode.addEventListener(eventType, handle);
      });
      return () => {
        eventTypes.forEach((eventType: any) => {
          targetNode.removeEventListener(eventType, handle);
        });
      };
    }
  }, [when, eventTypes, keyList, targetNode, handler]);
}


export const useGlobalKey = (key: string, handler: any) => {
  useEffect(() => {
    const handle = (e: any) => {
      if(key === e.key || key === e.keyCode) handler(e);
    }
    ['keydown'].forEach(eventType => {
      window.addEventListener(eventType, handle);
    })
    return () => {
      ['keydown'].forEach(eventType => {
        window.removeEventListener(eventType, handle);
      })
    }
  }, [key, handler])
}