import React from 'react'
import { useConceptsContext } from '../../contexts'
import TopicTag, {TopicAddTag} from '../topicTag/topicTag'
import EditableLabel from '../editableLabel/editableLabel'

import styles from './conceptDetail.module.scss'
import { useSearch } from '../../hooks'

interface ConceptDetailProps {
  reportId: string,
  report?: any,
  permission?: any,
  data?: any,
  similarWords?: any,
  categorizedPhrases?: any
}

export const ConceptDetail: React.FC<ConceptDetailProps> = React.memo(({reportId, report, permission, data, similarWords }) => {
  const [search, setSearch] = useSearch();
  const [, {addConceptsToLibrary, updateConcepts, updateConceptsToLibrary, fetchConceptsLibrary, deleteConcept, addWordToConcepts, deleteWordFromConcepts, fetchSimilarWordsConcepts, fetchSimilarTerms}] = useConceptsContext()
  const [suggestGlobal, setSuggestGlobal] = React.useState<any>();
  const searchQuery = search?.query;
  React.useEffect(() => {
    if(data && data.catType === 'domain') {
      if(searchQuery != null && searchQuery.length > 0) {
        fetchSimilarTerms(reportId, data && data._id, searchQuery, suggestGlobal);
      } else {
        fetchSimilarWordsConcepts(reportId, data && data._id);
      }
    }
  }, [reportId, data, searchQuery, suggestGlobal, fetchSimilarTerms, fetchSimilarWordsConcepts])

  const toggleSearch = (query: any) => {
    setSearch((s:any) => ({...s, query: ((searchQuery === query) ? undefined : query), page: undefined}));
  }

  const onDeleteConcept = async () => {
    await deleteConcept(reportId, data._id)
    setSearch((s: any) => ({...s, page: undefined, addConcept: undefined, category: undefined, pinnedConcept: undefined}));
  }
  const addWord = (cId?: string, w?: any, aspect?: any) => addWordToConcepts(reportId, cId, w, aspect);

  const _addConceptsToLibrary = async () => {
    const newConcept: any = {};
    if(data.name) newConcept.name = data.name;
    if(data.catType) newConcept.catType = data.catType;
    if(data.words) newConcept.words = data.words;
    if(data.positive) newConcept.positive = data.positive;
    if(data.negative) newConcept.negative = data.negative;

    const libraryConcept: any = await addConceptsToLibrary(newConcept);
    updateConcepts(reportId, data._id, {blueprintId: libraryConcept?.concept?._id})
  }

  const pullConceptFromLibrary = async () => {
    const libraryConcepts: any = await fetchConceptsLibrary();
    const libConcept = libraryConcepts?.concepts.find((c: any) => c._id === data.blueprintId)
    
    const newConcept: any = {};
    if(libConcept.name) newConcept.name = libConcept.name;
    if(libConcept.catType) newConcept.catType = libConcept.catType;
    if(libConcept.countInScore) newConcept.countInScore = libConcept.countInScore;
    if(libConcept.positive) newConcept.positive = libConcept.positive;
    if(libConcept.negative) newConcept.negative = libConcept.negative;

    updateConcepts(reportId, data._id, newConcept);
    if(libConcept.words) {
      addWord(data._id, libConcept.words);
    }
    if(libConcept?.positive?.words) {
      addWord(data._id, libConcept.positive.words, 'positive');
    }
    if(libConcept?.negative?.words) {
      addWord(data._id, libConcept.negative.words, 'negative');
    }
  }

  const updateConceptInLibrary = () => {
    const newConcept: any = {};
    if(data.name) newConcept.name = data.name;
    if(data.catType) newConcept.catType = data.catType;
    if(data.words) newConcept.words = data.words;
    if(data.positive) newConcept.positive = data.positive;
    if(data.negative) newConcept.negative = data.negative;
    if(data.countInScore) newConcept.countInScore = data.countInScore;

    updateConceptsToLibrary(data.blueprintId, newConcept);
  }

  const onUpdate = (updateData: any) => {
    updateConcepts(reportId, data?._id, updateData)
  }

  const onDeleteWord = (permission.canModifyConcepts(report)) ? ((w: any, aspect: any) => deleteWordFromConcepts(reportId, data._id, w, aspect)) : undefined;

  const hasQuery = search?.query?.length > 0;
  const hasSearch = hasQuery && data?.words?.indexOf(search?.query) >= 0;
  const hasPositiveSearch = hasQuery && data?.positive?.words?.indexOf(search?.query) >= 0;
  const hasNegativeSearch = hasQuery && data?.negative?.words?.indexOf(search?.query) >= 0;

  const dataId = data?._id;
  const catType = data?.catType;

  return <div className={styles.detail}>
    <div className={styles.scrollable}>
      {(data || hasQuery) && <>
        {catType === 'domain' && <>
          <div className={styles.sectionHeader}>
            <span>Concept related phrases</span>
          </div>
          <div className={styles.section}>
            {hasQuery && !hasSearch && <TopicAddTag value={search.query} onClick={() => addWord(data._id, search.query)} />}
            {data?.words?.map((word: any,i: number) => 
              <TopicTag key={word + i}
                value={word}
                selected={(search && word === search.query)}
                onClick={toggleSearch}
                onDelete={onDeleteWord} />
            )}
          </div>
          {similarWords && <>
            {!hasQuery && <div className={styles.sectionHeader}>Phrase Suggestions</div>}
            {hasQuery && <div className={styles.sectionHeader}>
              SUGGESTED WORDS FOR <strong>&lsquo;{search.query}&rsquo;</strong>
              <div className={styles.spacer} />
              <label>use global <input type="checkbox" value={suggestGlobal} onChange={(e) => setSuggestGlobal(e.target.checked)} /></label>
            </div>}
            <div className={styles.section}>
              {similarWords.map((w: any,i: number) => <TopicTag key={w.word + i}
                value={w.word}
                selected={(search && w.word === search.query)}
                onAdd={(w: any) => addWord(data._id, w.replace('_', ' '))}
                onClick={(w: any) => toggleSearch(w.replace('_', ' '))} />)}
            </div>
          </>}
        </>}
        {catType === 'judgement' && <>
          <JudgementWords dataId={dataId}
            defaultTitle="POSITIVE WORDS"
            aspect="positive"
            data={data}
            onUpdate={onUpdate}
            hasQuery={hasQuery}
            search={search}
            toggleSearch={toggleSearch}
            onDeleteWord={onDeleteWord}
            addWord={addWord}
            hasSearch={hasPositiveSearch} />

          <JudgementWords dataId={dataId}
            defaultTitle="NEGATIVE WORDS"
            aspect='negative'
            data={data}
            onUpdate={onUpdate}
            hasQuery={hasQuery}
            search={search}
            toggleSearch={toggleSearch}
            onDeleteWord={onDeleteWord}
            addWord={addWord}
            hasSearch={hasNegativeSearch} />
        </>}
      </>}

      {/* <KeyConcepts report={report} data={data} phrases={phrases} hasQuery={hasQuery} hasPhrase={hasPhrase} search={search} toggleSearch={toggleSearch} permission={permission} deleteKeyConcept={deleteKeyConcept} addKeyConcept={addKeyConcept} /> */}

      {data && permission.canModifyConcepts(report) && <label className={styles.checkboxLabel}>
        <div className={styles.checkboxLabelText}>Use score in brand comparison</div>
        <input checked={data.countInScore || false} type="checkbox" onChange={(e) => onUpdate({countInScore: e.target.checked})} />
      </label>}

    </div>
    {data && !data.blueprintId && permission.canModifyLibraryConcepts() && <div className={styles.button} onClick={_addConceptsToLibrary}>Create into Library</div>}
    {data?.blueprintId && permission.canModifyLibraryConcepts() && <div className={styles.button} onClick={pullConceptFromLibrary}>↓ Sync (append) phrases from library</div>}
    {data?.blueprintId && permission.canModifyLibraryConcepts() && <div className={styles.button} onClick={updateConceptInLibrary}>↑ Update library with new phrases</div>}    
    {data && permission.canModifyConcepts(report) && <div className={styles.deleteButton} onClick={onDeleteConcept}>Delete</div>}
  </div>
})

export default ConceptDetail;

interface JudgementWordsProps {
  dataId: any,
  aspect: any,
  data: any,
  defaultTitle: any,
  onUpdate: any,
  hasQuery: any,
  search: any,
  toggleSearch: any,
  onDeleteWord: any,
  addWord: any,
  hasSearch: any
}

const JudgementWords: React.FC<JudgementWordsProps> = ({dataId, aspect, data, defaultTitle, onUpdate, hasQuery, search, toggleSearch, onDeleteWord, addWord, hasSearch}) => {
  return <>
    <div className={styles.sectionHeader}>
      <EditableLabel value={data?.[aspect]?.name || defaultTitle} onChange={ onUpdate ? ((name) => onUpdate({[aspect]: {name}})) : undefined} className={styles.wordLabel} key={`${dataId}_${aspect}`} />
      {/* <div className={styles.spacer} /> */}
      {/* {data && data[aspect] && data[aspect].words && data[aspect].words.length > 0 && <InputClipboard className={styles.clipboard} value={data[aspect].words.join(',')} />} */}
    </div>
    <div className={styles.section}>
      {hasQuery && !hasSearch && <TopicAddTag value={search.query} onClick={() => addWord(data._id, search.query, aspect)} />}
      {data?.[aspect]?.words?.map((word: string,i: number) => 
        <TopicTag key={word + i}
          value={word}
          selected={(search && word === search.query)}
          onClick={toggleSearch}
          onDelete={onDeleteWord && ((word: any) => onDeleteWord(word, aspect))} />
      )}
      {/* {data && <TopicAddTag value="Upload CSV words from clipboard" onClick={() => uploadCSVClipboard(data._id, aspect)} />} */}
    </div>
  </>
}