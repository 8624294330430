import React from 'react'
import InputClipboard from '../inputClipboard/inputClipboard'
import { DeletablePill } from '../pills/pills'
import styles from './suggestConcept.module.scss'


interface SuggestConceptProps {
  data?: any,
  active?: any,
  onCreateConcept?: any,
  onDeleteConcept?: any,
  onEdit?: any,
  scrollRef?: any,
  showClipboard?: any
}

export const SuggestConcept: React.FC<SuggestConceptProps> = ({data, active, onCreateConcept, onDeleteConcept, onEdit, scrollRef, showClipboard}) => {
  const dataId = data?._id;
  React.useEffect(() => {
    if(!active || scrollRef == null) return;
    const id = `${dataId}_suggest`;
    
    const element = document.getElementById(id);
    const elementRect = element.getBoundingClientRect();
    const parent = element.offsetParent;
    const top = elementRect.top - parent.getBoundingClientRect().top + parent.scrollTop;
    const bottom = top + elementRect.height;
    const parentBottom = parent.scrollTop + parent.getBoundingClientRect().height;

    let newScrollTop: any = null;
    if( (top < parent.scrollTop) || bottom > parentBottom || top > parentBottom) {
      newScrollTop = top - 10;
    }
    

    if(newScrollTop != null) {
      setTimeout(() => {
        scrollRef && scrollRef.current.scrollTo({top: newScrollTop, left: 0, behavior: 'smooth'});
      }, 100)
    }
  }, [active, scrollRef, dataId])

  const dataWords: any[] = data?.words;
  const dataPositiveWords: any[] = data?.positive?.words
  const dataNegativeWords: any[] = data?.negative?.words
  return <div className={styles.suggestConceptWrap} id={`${dataId}_suggest`}>
    <div className={styles.suggestConcept}>
      <div className={styles.buttons}>
        {dataId && onEdit && <button onClick={() => onEdit(data._id)}>edit</button>}
        {dataId && onDeleteConcept != null && <button className={styles.conceptDelete} onClick={() => onDeleteConcept(data._id)}>delete permanently</button>}
      </div>
      {data != null && <div className={styles.conceptName}>{data.name}</div>}
      {data == null && <div className={styles.conceptName}>new concept</div>}
      <div className={styles.suggestConceptWords}>
        {dataWords?.map( (w, i) => <DeletablePill text={w} key={w + i} />)}
      </div>
      {data?.positive && <>
        <div className={styles.suggestConceptWordsTitle}>{data.positive.name || "POSITIVE WORDS"}</div>
        <div className={styles.suggestConceptWords}>
          {dataPositiveWords?.map( (w, i) => <DeletablePill text={w} key={w + i} />)}
        </div>
      </>}
      {data?.negative && <>
        <div className={styles.suggestConceptWordsTitle}>{data.negative.name || "NEGATIVE WORDS"}</div>
        <div className={styles.suggestConceptWords}>
          {dataNegativeWords?.map( (w, i) => <DeletablePill text={w} key={w + i} />)}
        </div>
      </>}
      {onCreateConcept != null && active && <div className={styles.activeConceptCreate} onClick={() => onCreateConcept(data)}>
        <div className={styles.activeConceptCreateButton}>Create Concept [enter]</div>
      </div>}
      {onCreateConcept != null && !active && <div className={styles.suggestConceptCreate} onClick={() => onCreateConcept(data)}>
        <div className={styles.suggestConceptCreateButton}>Create Concept</div>
      </div>}
    </div>
    {showClipboard && data?.words?.length > 0 && <InputClipboard className={styles.clipboard} value={data.words.join(',')} />}
  </div>
}

export default SuggestConcept