
export type ContextPropsState = {
  searchedApps: any,
  searchedAppsMeta: any,

  suggestedApps: any,
  suggestedAppsMeta: any,
}

export type OrdersAction = 
  | { type: "SEARCHED_START", reportId: string, appProvider: string, brandId: string, query: string }
  | { type: "SEARCHED", reportId: string, appProvider: string, brandId: string, searchedApps: any, query: string }

  | { type: "SUGGESTED_START", reportId: string, appProvider: string, brandId: string, query: string }
  | { type: "SUGGESTED", reportId: string, appProvider: string, brandId: string, query: string, suggestedApps: any }
  | { type: "RESET" }


export const initialState: ContextPropsState = {
  searchedApps: undefined,
  searchedAppsMeta: undefined,

  suggestedApps: undefined,
  suggestedAppsMeta: undefined,
};


export const reducer = (state: ContextPropsState, action: OrdersAction) => {
  if('SEARCHED_START' === action.type) {
    const key = [action.reportId, action.appProvider, action.brandId].join('_');
    const searchedAppsMeta = {...state.searchedAppsMeta, [key]: { loading: true }};
    return { ...state, searchedAppsMeta }
  } else if('SEARCHED' === action.type) {
    const key = [action.reportId, action.appProvider, action.brandId].join('_');
    let searchedApps = {...state.searchedApps, [key]: action.searchedApps };
    if(action.searchedApps == null) {
      delete searchedApps[key];
    }
    const searchedAppsMeta = {...state.searchedAppsMeta, [key]: { loading: false }};
    return { ...state, searchedApps, searchedAppsMeta }
  } else if('SUGGESTED_START' === action.type) {
    const key = [action.reportId, action.query, action.appProvider, action.brandId].join('_');
    const suggestedAppsMeta = {...state.suggestedAppsMeta, [key]: { loading: true }};
    return { ...state, suggestedAppsMeta }
  } else if('SUGGESTED' === action.type) {
    
    const key = [action.reportId, action.query, action.appProvider, action.brandId].join('_');
    const suggestedApps = {...state.suggestedApps, [key]: action.suggestedApps };
    const suggestedAppsMeta = {...state.suggestedAppsMeta, [key]: { loading: false }};
    return { ...state, suggestedApps, suggestedAppsMeta }
  }
  else if('RESET' === action.type) {
    return initialState
  } else {
    throw new Error();
  }
}