import React from 'react';

import { Loading } from '../../components/loading/loading'
import { useAuthContext, useReportsContext, useConceptsContext, useReviewsContext, useDataContext, useAppsContext, useUsersContext, useEnhancedUsersContext } from '../../contexts'

export const Logout = () => {
  const [, {reset: authActionsReset}] = useAuthContext();

  const [, {reset: reportsActionsReset}] = useReportsContext();
  const [, {reset: conceptsActionsReset}] = useConceptsContext();
  const [, {reset: reviewsActionsReset}] = useReviewsContext();
  const [, {reset: dataActionsReset}] = useDataContext();
  const [, {reset: appsActionsReset}] = useAppsContext();
  const [, {reset: usersActionsReset}] = useUsersContext();
  const [, {reset: enhancedUsersActionsReset}] = useEnhancedUsersContext();
  
  React.useEffect(() => {
    const fn = async () => {
      reportsActionsReset();
      conceptsActionsReset();
      reviewsActionsReset();
      dataActionsReset();
      appsActionsReset();
      usersActionsReset();
      enhancedUsersActionsReset();
      await authActionsReset();
    }
    fn()
  }, [reportsActionsReset, conceptsActionsReset, reviewsActionsReset, dataActionsReset, appsActionsReset, usersActionsReset, enhancedUsersActionsReset, authActionsReset]);
  return <Loading />
}