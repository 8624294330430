import React from 'react'

import { Chevron3Icon } from '../icons/icons'

import styles from './addConceptHeader.module.scss'

interface AddConceptHeaderProps {
  toggleOpened?: any,
  closed?: any,
  onCreateConcept?: any,
  conceptName?: any,
  setConceptName?: any,
  suggestActiveConcept?: any,
  setSuggestActiveConcept?: any,
  filteredLibraryConcepts?: any
}

export const AddConceptHeader: React.FC<AddConceptHeaderProps> = ({toggleOpened, closed, onCreateConcept, conceptName, setConceptName, suggestActiveConcept, setSuggestActiveConcept, filteredLibraryConcepts}) => {
  const onInputChange = (e: any) => {
    setConceptName(e.target.value);
  }

  const formSubmit = (e: any) => {
    e.preventDefault();
    onCreateConcept({name:conceptName});
  }

  const onKeyDown = (e: any) => {
    if(e.key === 'ArrowDown') {
      const hasInputValue = conceptName?.length > 0
      const index = Math.min((filteredLibraryConcepts.length || 0) + (hasInputValue ? 0 : -1), suggestActiveConcept + 1);
      setSuggestActiveConcept(index);
    } else if(e.key === 'ArrowUp') {
      setSuggestActiveConcept( Math.max(suggestActiveConcept - 1, 0));
    } else if(e.key === 'Enter') {
      e.preventDefault();
      if(suggestActiveConcept < filteredLibraryConcepts.length) {
        const concept = filteredLibraryConcepts[suggestActiveConcept];
        onCreateConcept({...concept, blueprintId: concept._id});
      } else if(suggestActiveConcept === filteredLibraryConcepts.length) {
        onCreateConcept({name:conceptName});
      }
    }
  }
  
  return <div className={[styles.detailHeader, !closed ? styles.opened : null].join(' ')}>
    <form className={[styles.title, styles.hoverable].join(' ')} onSubmit={formSubmit}>
      <input placeholder="Concept title" value={conceptName || ""} autoFocus onChange={onInputChange} onKeyDown={onKeyDown} />
    </form>
    {toggleOpened && <div className={styles.chevron} onClick={() => toggleOpened(!closed)}>
      <Chevron3Icon />
    </div>}
  </div>
}

export default AddConceptHeader;