import React from 'react';
import escapeStringRegexp from 'escape-string-regexp'
import 'array.prototype.flatmap'

const isFunction = (functionToCheck: any) => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
const isScalar = (children: any) => (/string|number|boolean/).test(typeof children);
const getSearch = (search: any, caseSensitive?: any) => {
  if(search instanceof RegExp) return search;
  if(search == null || search.length === 0) return null;

  let flags = '';
  if (!caseSensitive) flags +='i';

  if (typeof search === 'string') return new RegExp(escapeStringRegexp(search), flags);
  else return new RegExp(search, flags);
}
const convertChild = (child: any, key: any) => {
  if(isScalar(child)) return <span key={key}>{child}</span>;
  else return React.cloneElement(child, {key})
}
const getMatchBoundaries = (subject: any, search: any, useMatchGroup: any) => {
  const matches = search.exec(subject);
  let length = 0;
  for(var i = 1; i < useMatchGroup; i++) {
    length = length + matches[i].length;
  }
  if (matches) return {first: matches.index + length, last: matches.index + length + matches[useMatchGroup].length};
  else return null;
}


interface HighlighterProps {
  search: any,
  caseSensitive?: boolean,
  matchElement?: any,
  matchClass: any,
  matchStyle: any,
  matchElementProps?: any,
  searchTransform?: any,
  useMatchGroup?: any
}

const Highlighter: React.FC<HighlighterProps> = ({search, children, caseSensitive, matchElement= () => 'strong', matchClass='highlight', matchStyle={}, matchElementProps={}, searchTransform, useMatchGroup}) => {
  
  const matchClassFn = (search: any) => {
    if(isFunction(matchClass)) return matchClass(search)
    else return matchClass
  }
  const matchStyleFn = (search: any) => {
    if(isFunction(matchStyle)) return matchStyle(search)
    else return matchStyle
  }
  const searchTransformFn = (search: any) => {
    if(searchTransform) return searchTransform(search);
    else return search;
  }
  const highlightChildrens = (children: any, search: any, caseSensitive: any) =>
    React.Children.toArray(children).flatMap(child => highlightChildren(child, search, caseSensitive))

  const highlightChildren = (subject: any, _search: any, caseSensitive: any) => {
    let children = [];
    let remaining = subject;
    const search = getSearch(searchTransformFn(_search), caseSensitive);
    if(search == null) return subject;

    while (remaining) {
      if(typeof remaining !== 'string') {
        children.push(remaining);
        return children;
      }
      if(useMatchGroup >= 0) {
        const match = remaining.match(search);
        if((match && match[useMatchGroup]) == null) {
          children.push(remaining);
          return children;
        }
      } else {
        if(!search.test(remaining)) {
          children.push(remaining);
          return children;
        }
      }

      const boundaries = getMatchBoundaries(remaining, search, _search.useMatchGroup || useMatchGroup || 0);

      // Capture the string that leads up to a match...
      const nonMatch = remaining.slice(0, boundaries.first);
      if (nonMatch) {
        children.push(nonMatch);
      }

      // Now, capture the matching string...
      const match = remaining.slice(boundaries.first, boundaries.last);
      if (match) {
        const matchClass = matchClassFn(_search);
        const matchStyle = matchStyleFn(_search);

        const Tag = matchElement(_search)
        let _matchElementProps = null;
        if( typeof(matchElementProps) === 'function' ) {
          _matchElementProps = matchElementProps(_search);
        } else {
          _matchElementProps = matchElementProps;
        }
        children.push(<Tag className={matchClass} style={matchStyle} {..._matchElementProps}>{match}</Tag>)
      }

      // And if there's anything left over, recursively run this method again.
      remaining = remaining.slice(boundaries.last);
    }
    return children;
  }

  if(isScalar(children)) {
    if(Array.isArray(search)) {
      return search.reduce((acc, s) => highlightChildrens(acc, s, caseSensitive), children)
                   .map(convertChild)
    } else {
      return highlightChildrens(children, search, caseSensitive).map(convertChild)
    }
  } else return children
}

export default Highlighter


const highlightSearch = (subject: any, search: any, itemFn: any) => {
  const searchRegex = getSearch(search.value);
  let remaining = subject;
  const splitSubject = []

  let i = 0;
  let searchOffset = 0;
  while(remaining) {
    const matchObj = remaining.match(searchRegex);
    if(matchObj) {
      const start = matchObj.index;
      const end = start + matchObj[0].length;
      if(start > 0) {
        splitSubject.push(itemFn(remaining.slice(0, start), i, searchOffset));
        i+=1;
      }
      if(end > start) {
        splitSubject.push(<span className={search.className} key={i}>{remaining.slice(start, end)}</span>);
        i += 1;
        searchOffset += end;
      }
      
      remaining = remaining.slice(end);
    } else {
      splitSubject.push(itemFn(remaining, i, searchOffset));
      i += 1;
      remaining = null;
    }
  }
  return splitSubject;
}

const highlightRange = (subject: string, ranges: any[] | undefined, key: number, searchOffset: number) => {
  let arr = [];
  let start = 0;
  ranges?.forEach(r => {
    const begin = Math.max(r.b - searchOffset, 0);
    const end = Math.max(r.e - searchOffset, 0);
    if(start >= 0 && begin >= start) {
      if(begin !== start) arr.push(<span key={`${key}.${start}.${begin}`}>{subject.slice(start, begin)}</span>);
      
      if(begin < end ) {
        const value = subject.slice(begin, end);
        if(value.length > 0) {
          const Tag = r.matchTag || "span";
          const props = r.matchProps || { className: r.matchClass, style: r.matchStyle };
          arr.push(<Tag key={`${key}.${begin}.${end}`} {...props}>{value}</Tag>);
        }
      }
      start = end;
    }
  });
  if(start < subject.length) arr.push(<span key={`${start}.${subject.length}`}>{subject.slice(start)}</span>);
  return arr;
}

interface RangeHighlighterProps {
  children: any,
  ranges: any,
  search: any,
  searchClassName: string,
}

export const RangeHighlighter: React.FC<RangeHighlighterProps> = React.memo(({children, ranges, search, searchClassName}) => {
  if(typeof children === 'string') {
    const searchObj = {value: search, className: searchClassName};
    return highlightSearch(children, searchObj, (child: any, i: number, searchOffset: any) => highlightRange(child, ranges, i, searchOffset));
  }
  return children;
})