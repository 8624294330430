import copy from 'clipboard-copy';
import EmojiFlags from 'emoji-flags';
import moment from 'moment';
import React from 'react';
import UtfString from 'utfstring';
import { useAuthContext, useEnhancedUsersContext, useReviewsContext } from '../../contexts';
import { ToastContext } from '../../libs/context-toast/toast';
import Tooltip from '../../libs/context-tooltip/tooltip';
import Permission from '../../permission';
import { Watermark, SaveToClipboard } from '../../toneIcons';
import { getCountryCode, getFadedHighlightRangeColor, getHighlightRangeColor, getHoverHighlightRangeColor, getNPSColor, getNPSScore, getRangeColor2, getScore, getSearch, isMac, sourcesById } from '../../utils';
import Comment, { NewComment } from '../comment/comment';
import DownloadButton from '../downloadButton/downloadButton';
import Icon from '../icon/icon';
import { CommentIcon } from '../icons/currentColorIcons';
import { BotIcon, ChevronRight, StarIcon } from '../icons/icons';
import * as NpsSmiles from '../icons/npsSmiles';
import IdentityIcon from '../identityIcon/identityIcon';
import KeyButtons from '../keyButtons/keyButtons';
import ReviewFooter from '../reviewFooter/reviewFooter';
import SentimentTooltip from '../sentimentTooltip/sentimentTooltip';
import styles from './review.module.scss';


const splitChar = "\n";

interface ReviewProps {
  report?: any,
  review?: any,
  comments?: any[],
  app?: any,
  search?: any,
  conceptsById?: any,
  setSearch?: any,
  showPrevMachineButton?: any,
  showNextMachineButton?: any,
  groupedCatTypes?: any,
  permission?: Permission,
  noWrap?: boolean,
  tooltip?: any,
  showComments?: boolean,
}

export const Review: React.FC<ReviewProps> = React.memo(({ report, review, comments, app, search, conceptsById, setSearch, showPrevMachineButton, showNextMachineButton, groupedCatTypes, permission, noWrap, tooltip, showComments }) => {
  const {showToast} = React.useContext(ToastContext);
  const [{ me }] = useAuthContext();
  const [, {addComment, deleteComment}] = useReviewsContext();
  const [{groupedDelightedUsers}] = useEnhancedUsersContext();
  const exportable = React.useRef();

  if(review == null) return null;
  if(review.loading) return null;
  
  const conceptId = search?.category;
  const query = search?.query;
  const rating = search && Number.parseInt(search.rating);
  const machine = search?.machine;
  const concept = conceptsById?.[conceptId];
  const reviewCommentId = search?.reviewCommentId;
  const machineSplit = machine?.split('_');

  const isMachine = machineSplit && (machineSplit?.[0] === review._id);

  const selectClassification = (range: any) => {
    const machine = [review?._id, range.b, range.e, range._id].join('_');
    setSearch((s: any) => ({...s, machine}))
  }

  const copyVerbatim = async () => {
    if(permission == null || !permission.canComment(report)) return;
    await copy(review._id);
    showToast('copied Verbatim id to clipboard')
  }

  const _addComment = () => {
    if(permission == null || !permission.canComment(report)) return;
    setSearch((s: any) => ({ ...s, reviewCommentId: ((s.reviewCommentId === review._id) ? undefined : review._id)}));
  }

  const toggleBot = () => {
    setSearch((s: any) => ({ ...s, machine: ((search.machine?.split('_')?.[0]) === review._id) ? undefined : review._id }));
  }

  const submitPost = (value: string) => { addComment(report?.data?._id, review._id, value); }
  const deleteFn = (comment: any) => { deleteComment(report?.data?._id, review._id, comment._id); }

  const className = [
    styles[review.appProvider],
    styles.reviewCommentsWrap,
    (machineSplit != null && !isMachine) ? styles.machineInactive : null,
    review.appProvider === 'csvDelighted' ? styles.blurry : null,
  ].join(' ')

  return <div className={className}>
    <div className={[styles.reviewWrap, noWrap ? styles.noReviewWrap : null].join(' ')} id={review._id}>
      <div className={styles.review} ref={exportable}>
        <ReviewHeader review={review} app={app} ranges={review.joinedRanges} rating={rating} groupedDelightedUsers={groupedDelightedUsers} query={query} concept={concept} conceptsById={conceptsById} machineSplit={machineSplit} search={search} setSearch={setSearch} groupedCatTypes={groupedCatTypes} />
        <ReviewText text={review.text} title={review.title} id={review._id} ranges={review.joinedRanges} query={query} conceptsById={conceptsById} concept={concept} machineSplit={machineSplit} search={search} setSearch={setSearch} appProvider={review?.appProvider} reviewId={review.reviewId} groupedCatTypes={groupedCatTypes} tooltip={tooltip} />
        <ReviewFooter concepts={review?.categories} appProvider={review?.appProvider} sourcesById={sourcesById} conceptsById={conceptsById} href={review.url} />
        <Watermark className={styles.watermark} />
      </div>
      <div className={styles.watermarkCover} />
      {review.appProvider !== 'csvDelighted' && <DownloadButton className={styles.downloadButton} fileName={`review_${review._id}.png`} exportableRef={exportable} />}
      {review.appProvider !== 'csvDelighted' && <Buttons copyVerbatim={copyVerbatim} addComment={_addComment} permission={permission} report={report} />}
    </div>
    {machineSplit != null && !isMachine && <div className={styles.machineOverlayButton} onClick={toggleBot}>
      <BotIcon />
      {showPrevMachineButton && <div className={styles.machineOverlayText}>
        <KeyButtons keys={isMac() ? ['shift', '⌘', 'enter'] : ['shift', 'ctrl', 'enter']} />
      </div>}
      {showNextMachineButton && <div className={styles.machineOverlayText}>
        <KeyButtons keys={isMac() ? ['⌘', 'enter'] : ['ctrl', 'enter']} />
      </div>}
    </div>}
    {(reviewCommentId === review._id || showComments) && <div className={styles.comments}>
      {comments?.map((comment, i) => <Comment key={i} author={comment.email} text={comment.text} deleteFn={() => deleteFn(comment)} />)}
      <NewComment submitPost={submitPost} />
    </div>}
  </div>
})

interface ReviewHeaderProps {
  review: any,
  ranges: any,
  app: any,
  rating: any,
  query: any,
  concept: any,
  machineSplit: any,
  conceptsById: any,
  search: any,
  setSearch: any,
  groupedCatTypes: any,
  groupedDelightedUsers: any
}

const ReviewHeader: React.FC<ReviewHeaderProps> = React.memo(({review, ranges, app, rating, query, concept, machineSplit, conceptsById, search, setSearch, groupedCatTypes, groupedDelightedUsers}) => {
  let date = review?.date && moment(review.date);
  if (date) date = date.isValid() ? date : null;
  const countryCode = getCountryCode(review?.url)
  const flag = countryCode && EmojiFlags.countryCode(countryCode);
  const isRatingSelected = rating === review.score;
  const delightedUser = groupedDelightedUsers?.[review?.userName];

  const isMachine = machineSplit && (machineSplit[0] === review._id);

  const delightedUserEmail = delightedUser?.email;
  const clickChevron = () => {
    if(search == null || setSearch == null) return;
    setSearch((s: any) => ({ ...s, user: ((s.user === delightedUserEmail) ? undefined : delightedUserEmail) }))
  }

  let scoreType = null;
  if(review.appProvider === 'csvDelighted' && review.score != null) scoreType = 'nps'
  else if(review.appProvider === 'csvNPS' && review.score != null) scoreType = 'nps'
  else if(review.score != null && review.score > 0) scoreType = 'star'


  const npsStyle: any = {
    '--color': getNPSColor(getNPSScore(review.score))
  }

  const getNpsSmile = (score: number) => {
    switch(getNPSScore(score)) {
      case 0: return NpsSmiles.Smile0
      case 1: return NpsSmiles.Smile1
      case 2: return NpsSmiles.Smile2
      case 3: return NpsSmiles.Smile3
    }
  }

  return <div className={[styles.header, isMachine ? styles.machineActive : null].join(' ')}>
    {(app != null && app !== false) && <Icon src={app.iconUrl} className={styles.headerAppIcon} />}
    <div className={styles.headerLeft}>
      <div className={[styles.title, concept != null ? styles.hasConcept : null].join(' ')}>
        <ReviewTextOverlay offset={0} line={review.title} ranges={ranges} concept={concept} conceptsById={conceptsById} isMachine={isMachine} groupedCatTypes={groupedCatTypes} />
        <ReviewHighlight line={review.title} query={query} />
        <MachineOverlay offset={0} id={review._id} line={review.title} machineSplit={machineSplit} />
      </div>
      {scoreType === 'nps' && <div className={styles.delightedScore} style={npsStyle}>
        {React.createElement(getNpsSmile(review.score))}
        <div className={styles.divider} />
        <span>{review.score}</span>
      </div>}
      {scoreType === 'star' && <div className={[styles.starRating, isRatingSelected ? styles.starRatingSelected : null].join(' ')}>
        {Array.from({length: review.score}, (_, i) => <StarIcon key={i} />)}
      </div>}
    </div>

    <div className={styles.headerRight}>
      <div className={styles.headerDateText}>
        {date != null && <span className={styles.date}>{date.fromNow()}</span>}
        {flag != null && <span className={styles.flag}>{flag.emoji}</span>}
      </div>
      <div className={styles.headerUserText}>
        {delightedUser != null && <IdentityIcon data={delightedUser} />}
        <div className={styles.author}>{review.userName}</div>
        {delightedUser != null && <div className={styles.chevron} onClick={clickChevron}>
          <ChevronRight />
        </div>}
      </div>
    </div>
  </div>
})

interface ReviewTextProps {
  id: string,
  title: string,
  text: string,
  ranges: any,
  query: any,
  concept: any,
  machineSplit: any,
  conceptsById: any,
  search: any,
  setSearch: any,
  appProvider: any,
  reviewId: string,
  groupedCatTypes: any,
  tooltip: any
}

const ReviewText: React.FC<ReviewTextProps> = React.memo(({id, title, text, ranges, query, concept, machineSplit, conceptsById, search, setSearch, appProvider, reviewId, groupedCatTypes, tooltip}) => {
  let offset = title != null ? UtfString.length(title) + 2 : 0;
  let lines = text?.split(splitChar);

  const isYoutube = appProvider === 'csvYoutube';

  if(isYoutube) {
    lines = text?.split('___');
  }


  const isMachine = machineSplit && (machineSplit[0] === id);
  return <div className={[styles.textContainer, isMachine ? styles.machineActive : null].join(' ')}>
    {isYoutube && <div className={styles.youtube}>
      <iframe src={`https://www.youtube.com/embed/${reviewId}`} title={title} />
    </div>}
    {lines?.filter(l => l != null).map((line, i) => {
      offset += (i > 0) ? (UtfString.length(lines[i-1]) + 1) : 0;
      return <div key={i} className={[styles.line, concept != null ? styles.hasConcept : null].join(' ')}>
        <ReviewTextOverlay line={line} ranges={ranges} concept={concept} offset={offset} conceptsById={conceptsById} isMachine={isMachine} groupedCatTypes={groupedCatTypes} />
        <ReviewHighlight line={line} query={query} />
        <MachineOverlay id={id} line={line} offset={offset} machineSplit={machineSplit} />
        <MachineTooltip id={id} line={line} offset={offset} tooltip={tooltip} />
      </div>
    })}
  </div>
})

export default Review

interface ButtonsProps extends React.HTMLProps<HTMLDivElement> {
  report: any,
  copyVerbatim: any,
  addComment: any,
  permission: Permission
}

const Buttons: React.FC<ButtonsProps> = ({className, report, copyVerbatim, addComment, permission}) => {
  const buttons = [];
  if(addComment && permission?.canComment(report))
    buttons.push(<Tooltip key='add comment' className={styles.button} content="Add Comment" onClick={addComment}><CommentIcon /></Tooltip>)
  if(copyVerbatim && permission?.canModifyExecSummary())
    buttons.push(<Tooltip key='copy verbatim' className={styles.button} content="Copy Verbatim" onClick={copyVerbatim}><SaveToClipboard /></Tooltip>)

  if(buttons.length === 0) return null;
  return <div className={[styles.buttons, className].join(' ')}>
    {buttons}
  </div>
}

interface ReviewHighlightProps {
  line?: any,
  query?: any
}

const ReviewHighlight: React.FC<ReviewHighlightProps> = React.memo(({line, query}) => {
  if(query == null) return null;
  if(line == null) return null;

  const searchRegex = getSearch(query);
  const matchObj = line.match(searchRegex);
  if(matchObj == null) return null;
  
  const matchStart = matchObj.index;
  const matchEnd = matchStart + (matchObj?.[0]?.length || 0);

  const bLine = UtfString.slice(line, 0, matchStart);
  const hLine = UtfString.slice(line, matchStart, matchEnd);
  const eLine = UtfString.slice(line, matchEnd);

  return <div className={styles.highlightOverlay}>
    <span>{bLine}</span>
    <span className={styles.highlight}>{hLine}</span>
    <span>{eLine}</span>
  </div>;
})

interface ReviewTextOverlayProps {
  ranges: any,
  offset: number,
  line: any,
  concept: any,
  isMachine: boolean,
  conceptsById: any,
  groupedCatTypes: any
}

const ReviewTextOverlay: React.FC<ReviewTextOverlayProps> = React.memo(({ranges, offset, line, concept, isMachine, conceptsById, groupedCatTypes}) => {
  if(line == null) return null;
  const lines = [];

  let index = 0;
  let rangeStart = 0;

  while(ranges != null && index < ranges.length) {
    const range = ranges[index];
    const start = range.b - (offset || 0);
    const end = range.e - (offset || 0);

    if(start === end) {
      index += 1;
      continue;
    }

    if(end < rangeStart) {
      index += 1;
      continue
    }
    
    if(start <= rangeStart) {
      if(UtfString.length(line) < start) break;
      const lineSplit = UtfString.slice(line, start < rangeStart ? rangeStart : start, end);

      if(UtfString.length(lineSplit) > 0) {
        let cats = range?.cats?.map((cat: any) => {
          const _concept = conceptsById && conceptsById[cat.c];
          if(groupedCatTypes && (groupedCatTypes['domain'] == null || groupedCatTypes['domain'] === 0) ) return cat;
          if((concept == null || concept.catType !== 'judgement') && _concept != null && _concept.catType === 'judgement' ) return null;
          if(concept != null && concept.catType === 'judgement' && (_concept == null || _concept.catType !== concept.catType)) return null;
          if(cat?.c != null) return cat;
          if(cat?.s > 0.6 || cat?.s < -0.6) return cat;
          return null;
        }).filter((c: any) => c);

        const hasActiveConcept = cats?.find((cat: any) => (concept?._id) ===  cat?.c) != null;
        
        if(cats == null || cats.length === 0) {
          lines.push(<span key={`${index}_${rangeStart}`}>{lineSplit}</span>);
        } else {
          const sentiment = cats?.[0]?.s;
          const score = getScore(sentiment);
          const color = getRangeColor2(score);
          const hightlightColor = getHighlightRangeColor(score);
          const fadedHighlightColor = getFadedHighlightRangeColor(score);
          const hoverHightlightColor = getHoverHighlightRangeColor(score);
          const style: any = {
            '--color': color,
            '--highlight-color': hightlightColor,
            '--highlight-faded-color': fadedHighlightColor,
            '--hover-highlight-color': hoverHightlightColor,
            '--hover-highlight-faded-color': fadedHighlightColor,
          }
          const content = (cats != null && conceptsById != null) && <SentimentTooltip cats={cats} conceptsById={conceptsById} />
          const className = [styles.sentiment, hasActiveConcept ? styles.activeConcept : null, isMachine ? styles.machineActive : null].join(' ');
          lines.push(<Tooltip tagName="span" content={content} key={`${index}_${rangeStart}`} className={className} style={style} background={color}>{lineSplit}</Tooltip>);
        }
      }
      index += 1;
      rangeStart = end;
      continue;
    } else {
      if(UtfString.length(line) < rangeStart) break;
      const lineSplit = UtfString.slice(line, rangeStart, start);
      if(UtfString.length(lineSplit) > 0) lines.push(<span key={`${index}_${rangeStart}`}>{lineSplit}</span>);
      rangeStart = start;
      continue;
    }
  }

  const lineSplit = UtfString.slice(line, rangeStart);
  if(lineSplit) lines.push(<span key={`${index}_${rangeStart}`}>{lineSplit}</span>);
  return <div className={[styles.overlay, concept != null ? styles.hasConcept : null, isMachine ? styles.noEvents : null].join(' ')}>{lines}</div>
})


interface MachineOverlayProps {
  id: string,
  line: any,
  machineSplit: any,
  offset: any
}

const MachineOverlay: React.FC<MachineOverlayProps> = React.memo(({id, line, machineSplit, offset}) => {
  if(machineSplit == null || machineSplit[0] !== id) return null;
  if(line == null) return null;

  if(machineSplit.length >= 3) {
    const start = Number.parseInt(machineSplit[1]) - (offset || 0);
    const end = Number.parseInt(machineSplit[2]) - (offset || 0);
    const maxEnd = UtfString.length(line) + (offset || 0);

    const correctedStart = Math.max(0, start);
    const correctedEnd = Math.max(Math.min(end, maxEnd), 0);

    const bLine = UtfString.slice(line, 0, correctedStart);
    const cLine = UtfString.slice(line, correctedStart, correctedEnd);
    const eLine = UtfString.slice(line, correctedEnd);

    return <div className={styles.machineOverlay}>
      {bLine != null && UtfString.length(bLine) > 0 && <span>{bLine}</span>}
      {cLine != null && UtfString.length(cLine) > 0 && <span className={styles.selected}>{cLine}</span>}
      {eLine != null && UtfString.length(eLine) > 0 && <span>{eLine}</span>}
    </div>
  } else {
    return <div className={styles.machineOverlay}>
      {line}
    </div>
  }
})

interface MachineTooltipProps {
  id: string,
  line: any,
  tooltip: any,
  offset: any
}

const MachineTooltip: React.FC<MachineTooltipProps> = React.memo(({id, line, tooltip, offset}) => {
  if(tooltip == null || tooltip.reviewId !== id) return null;
  if(line == null) return null;

  if(tooltip.reviewId && tooltip.startOffset != null && tooltip.endOffset != null) {
    const start = Number.parseInt(tooltip.startOffset) - (offset || 0);
    const end = Number.parseInt(tooltip.endOffset) - (offset || 0);
    const maxEnd = UtfString.length(line) + (offset || 0);

    const correctedStart = Math.max(0, start);
    const correctedEnd = Math.max(Math.min(end, maxEnd), 0);

    const bLine = UtfString.slice(line, 0, correctedStart);
    const cLine = UtfString.slice(line, correctedStart, correctedEnd);
    const eLine = UtfString.slice(line, correctedEnd);

    return <div className={styles.machineOverlay}>
      {bLine != null && UtfString.length(bLine) > 0 && <span>{bLine}</span>}
      {cLine != null && UtfString.length(cLine) > 0 && <span>{cLine}</span>}
      {eLine != null && UtfString.length(eLine) > 0 && <span>{eLine}</span>}
    </div>
  } else {
    return <div className={styles.machineOverlay}>
      {line}
    </div>
  }
})

interface PlaceholderReviewProps {
  opacity?: any,
  noWrap?: boolean
}

export const PlaceholderReview: React.FC<PlaceholderReviewProps> = ({opacity, noWrap}) => <div className={[styles.reviewWrap, noWrap ? styles.noReviewWrap : null].join(' ')} style={{opacity}}>
  <div className={[styles.review, styles.placeholderReview].join(' ')}>
    <div className={styles.header}>
      <div className={styles.title} />
      <div className={styles.spacer} />
      <div className={styles.headerRight}>
        <div className={styles.headerText} />
        <div className={styles.author} />
      </div>
    </div>
    <div className={styles.textContent} />
  </div>
</div>