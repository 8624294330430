import React from 'react';
import { Link } from "@reach/router"
import firebase from 'firebase/compat/app';


import Input from '../../components/input/input'
import {Button} from '../../components/button/button'
import {LogoIcon2} from '../../components/icons/icons'
import useForm from '../../hooks/useForm'

import styles from './auth.module.scss'


export const Signup = React.memo(({location}: any) => {
  const { values, errors, hasErrors, isSubmitting, handleChange, registerSubmit }: any = useForm({email: '', password: ''})
  const className = [styles.content, hasErrors ? styles.errorShake : null].join(' ');
  const isDisabled = !values.email || !values.password;

  const handleSubmit = async ({values, setIsSubmitting, setErrors}: any) => {
    try {
      const email = values.email.replace(/(^\s+|\s+$)/g,'');
      await firebase.auth().createUserWithEmailAndPassword(email, values.password);
      setIsSubmitting(false);
    } catch(error) {
      if(error.code === 'auth/weak-password') { setErrors({password: "Invalid"}); }
      else if(error.code === 'auth/invalid-email') { setErrors({email: "Invalid"}); }
      else if(error.code === 'auth/email-already-in-use') { setErrors({email: "Already in use"}); }
      setIsSubmitting(false);
    }
  }

  return <div className={styles.container}>
    <LogoIcon2 className={styles.logo} />
    <form className={className} onSubmit={registerSubmit(handleSubmit)}>  
      {errors?.global && <div className={styles.error}>{errors.global}</div>}
      <Input className={styles.input} autoFocus
        title="Email"
        placeholder="me@email.com"
        type="text"
        name="email"
        value={values.email}
        error={errors.email}
        onChange={handleChange}
        hideCloseButton
        autoCapitalize="none"
        autoComplete="email" />
      <Input className={styles.input}
        title="Create a Password"
        placeholder="Enter in a new password"
        type="password"
        name="password"
        value={values.password}
        error={errors.password}
        onChange={handleChange}
        hideCloseButton
        autoCapitalize="none" 
        autoComplete="new-password" />
      <Button title="Sign Up" disabled={isDisabled} loading={isSubmitting} className={styles.button} tagName="button" />
    </form>
    <div className={styles.footer}>
      <div className={styles.footerButtons}>
        <Link to='../forgotPassword' className={styles.footerButton}>Forgot Password</Link>
        <div className={styles.spacer} />
        <Link to={`../login${location.search}`} className={styles.footerButton}>Login</Link>
      </div>
    </div>
  </div>
})