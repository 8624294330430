import React from 'react'
import { getRangeColor2, getHoverHighlightRangeColor, getNPSColor, getNPSScore } from '../../utils'
import RatingBar from '../ratingBar/ratingBar'
import { StarIcon } from '../icons/icons'
import Tooltip from '../../libs/context-tooltip/tooltip'
import * as NpsSmiles from '../icons/npsSmiles'

import styles from './starRatingSection.module.scss'
import { useSearch } from '../../hooks'


interface StarRatingSectionProps {
  data: any,
  minimized: any
}
export const StarRatingSection: React.FC<StarRatingSectionProps> = ({data, minimized}) => {
  const [search, setSearch] = useSearch();

  if(data == null) return null;
  if(data.byRating == null) return null;
  if(data.raited == null) return null;
  const STARS = 5;
  const starCounts = Array.from({length: STARS}, (_, i) => STARS - i);

  const toggleSearch = (v: any) => {
    setSearch( (s: any) => ({ ...s, rating: ((search.rating === v) ? undefined : v), page: undefined } ));
  }
  
  if(minimized) {
    return <div className={styles.minRatings}>
      {starCounts.map((s, i) => {
        const className = [styles.minRatingsRow, (search?.rating === `${s}`) ? styles.selected : null].join(' ');
        const color = getRangeColor2(s*20);
        const fadedColor = getHoverHighlightRangeColor(s*20);
        const count = data?.byRating?.[s*2];
        const ratingValue = (data?.raited > 0) ? 100*count/data.raited : 0;

        const style: any = {
          '--color': color,
          '--faded-color': fadedColor,
        }

        return <div key={s} className={className} style={style} onClick={() => toggleSearch(`${s}`)}>
          <div className={styles.background} style={{width: `${ratingValue}%` }} />
          <div className={styles.star}>
            <span>{s}</span>
            <StarIcon />
          </div>
        </div>
      })}
    </div>
  }

  return <div className={styles.ratings}>
    {starCounts.map((s, i) => {
      const color = getRangeColor2(s*20);
      const count = data?.byRating?.[s*2];
      const ratingValue = (data?.raited > 0) ? 100*count/data.raited : 0
      const style = {
        gridRowStart: i+1,
        gridRowEnd: 'span 1',
      }
      const className = [styles.row, (search?.rating === `${s}`) ? styles.selected : null].join(' ');
      const outerStyle: any = {
        '--color': color
      }
      return <div key={s} className={className} style={outerStyle} onClick={() => toggleSearch(`${s}`)}>
        <div className={styles.rowName} style={style}>
          {Array.from({length: s}, (_, i) => <StarIcon key={i} />)}
        </div>
        <div className={styles.ratingBar} style={style}>
          <RatingBar value={ratingValue} />
        </div>
        <div className={styles.rowCount} style={style}>{count}</div>
        <Tooltip content={<StarRatingTooltip star={s} wordCounts={data[`wordCounts${s}`]} />} className={styles.tooltipRow} style={style} />
      </div> 
    })}
  </div>
}

interface NpsRatingSectionProps {
  data: any,
  minimized: any,
}

export const NpsRatingSection: React.FC<NpsRatingSectionProps> = ({data}) => {
  const [search, setSearch] = useSearch();
  if(data == null) return null;
  if(data.byRating == null) return null;
  if(data.raited == null) return null;
  const STARS = 11;
  const counts = Array.from({length: STARS}, (_, i) => STARS - i - 1);


  const toggleSearch = (v: any) => {
    setSearch((s: any) => ({ ...s, rating: ((search.rating === v) ? undefined : v), page: undefined } ));
  }

  const getNpsSmile = (score: number) => {
    switch(getNPSScore(score)) {
      case 0: return NpsSmiles.Smile0
      case 1: return NpsSmiles.Smile1
      case 2: return NpsSmiles.Smile2
      case 3: return NpsSmiles.Smile3
    }
  }

  return <div className={styles.ratings}>
    {counts.map((s, i) => {
      const color = getRangeColor2(s*20);
      const count = data?.byRating?.[s];
      let ratingValue = 0;
      if(data?.raited > 0) ratingValue = 100*count/data.raited;
      const style = {
        gridRowStart: i+1,
        gridRowEnd: 'span 1',
      }
      const className = [styles.row, (search?.rating === `${s}`) ? styles.selected : null].join(' ');
      const outerStyle: any = {'--color': color}
      const scoreStyle: any = {'--color': getNPSColor(getNPSScore(s))}
      return <div key={s} className={className} style={outerStyle} onClick={() => toggleSearch(`${s}`)}>
        <div className={styles.rowName} style={style}>
          <div className={styles.delightedScore} style={scoreStyle}>
            {React.createElement(getNpsSmile(s))}
            <div className={styles.divider} />
            <span>{s}</span>
          </div>
        </div>
        <div className={styles.ratingBar} style={style}>
          <RatingBar value={ratingValue} />
        </div>
        <div className={styles.rowCount} style={style}>{count}</div>
        <Tooltip content={<StarRatingTooltip star={s} wordCounts={data[`wordCounts${s}`]} />} className={styles.tooltipRow} style={style} />
      </div> 
    })}
  </div>
}


interface StarRatingTooltipProps {
  star: any,
  wordCounts: any[]
}
const StarRatingTooltip: React.FC<StarRatingTooltipProps> = ({star, wordCounts}) => {
  const words = wordCounts?.slice(0,5)?.map(w => `"${w.words?.[0]}"`).join(', ');
  return <div>
    {words && <div className={styles.tooltipRowWords}>{words}</div>}
    <div className={styles.tooltipRowFooter}>Click to see {star} Star Reviews</div>
  </div>
}

export default StarRatingSection