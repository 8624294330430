import React from 'react';
import { DropdownChevronIcon } from '../icons/currentColorIcons'
import Tooltip from '../../libs/context-tooltip/tooltip'

import styles from './cell.module.scss'

interface HeadercellProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  name: string,
  sortOrder?: any,
  onClick?: any,
  tooltip?: any,
}

const Headercell: React.FC<HeadercellProps> = React.memo(({className, title, name, sortOrder, onClick, tooltip}) => {
  const _className = [
    className,
    styles.headerCell,
    sortOrder === -1 ? styles.desc : null,
    sortOrder === 1 ? styles.asc : null
  ].join(' ');

  const _onClick = () => {
    if(onClick) onClick(name);
  }

  let props: any = {
    className: _className,
    onClick: _onClick
  }
  let Tag: any = 'div'
  if(tooltip) {
    Tag = Tooltip;
    props = {
      className: _className,
      onClick: _onClick,
      arrowOffset: -5,
      content: tooltip,
      position: 'bottom',
      tooltipClassName: styles.tooltip
    }
  }

  return <Tag {...props}>
    <div className={styles.headerCellContent}>
      <div className={styles.headerText}>{title}</div>
      <DropdownChevronIcon className={styles.headerChevron} />
    </div>
  </Tag>
})

export default Headercell