import React from 'react';
import { Redirect } from '@reach/router'

import { useAuthContext } from '../../contexts'
import {LoadingContainer} from '../loading/loading'


interface Props {
  as: any,
  redirectTo?: string
}

export const SuperadminRoute: React.FC<Props> = ({ as: Comp, redirectTo, ...props }) => {
  const [{initialising, user, me, fetching}, {fetchMe}] = useAuthContext();
  
  const shouldFetch = !initialising && !me;
  React.useEffect(() => {
    if (shouldFetch && fetchMe) fetchMe();
  }, [shouldFetch, fetchMe])


  if(initialising) return <LoadingContainer />
  else if(user) {
    if(fetching) return <LoadingContainer />
    else if(me) {
      if(me.isAdmin) return <Comp {...props} />
      else return <Redirect to={redirectTo} noThrow />
    }
    else return <Redirect to={redirectTo} noThrow />
  }
  else return <Redirect to={redirectTo} noThrow />
}

export const AuthedRoute: React.FC<Props> = ({ as: Comp, redirectTo, ...props }) => {
  const [{initialising, user, me, fetching}, {fetchMe}] = useAuthContext();

  const shouldFetch = !initialising && !me;
  React.useEffect(() => {
    if (shouldFetch && fetchMe) fetchMe();
  }, [shouldFetch, fetchMe])

  if(initialising) return <LoadingContainer />
  else if(user) {
    if(fetching) return <LoadingContainer />
    else if(me) return <Comp {...props} />
    else return <Redirect to={redirectTo} noThrow />
  }
  else return <Redirect to={redirectTo} noThrow />
}

export const OnlyUnauthed: React.FC<Props> = ({ as: Comp, redirectTo, ...props }) => {
  const [{initialising, user, me, fetching}, {fetchMe}] = useAuthContext();
  
  const shouldFetch = !initialising && !me;
  React.useEffect(() => {
    if (shouldFetch && fetchMe) fetchMe();
  }, [shouldFetch, fetchMe])

  if(initialising) return <LoadingContainer />
  else if(user) {
    if(fetching) return <LoadingContainer />
    else if(me) return <Redirect to={redirectTo} noThrow />
    else return <Comp {...props} />
  }
  else return <Comp {...props} />
}