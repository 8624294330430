import React from 'react'
import { useConceptsContext } from '../../contexts'
import useForm from '../../hooks/useForm'
import { Input } from '../input/input'
import { DeletablePill } from '../pills/pills'
import Popup from '../popup/popup'
import styles from './editConceptPopup.module.scss'

interface AddWordInputProps {
  words: any,
  onSubmit: any,
}
const AddWordInput: React.FC<AddWordInputProps> = ({words, onSubmit}) => {
  const { values, handleChange, registerSubmit } = useForm({words, word: ''})

  const _onSubmit = async ({values}: any) => {
    try {
      const wordsSet = new Set(values.words);
      values.word.split(',').forEach((word: any) => {
        wordsSet.add(word);
      })
      
      const words = Array.from(wordsSet);
      onSubmit(words)
    } catch(error) {
      
    }
  }

  return <form onSubmit={registerSubmit(_onSubmit)}>
    <Input value={values.word} name="word" onChange={handleChange} />
  </form>
}

export const EditConceptPopup = ({conceptId, onClose}: any) => {
  const [{libraryConceptsById}] = useConceptsContext();

  const concept = libraryConceptsById?.[conceptId];
  if(concept == null) return null;
  return <EditConceptWithDataPopup concept={concept} onClose={onClose} />
}

export const EditConceptWithDataPopup = ({concept, onClose}: any) => {
  const { values, handleChange, registerSubmit } = useForm({name: concept.name})
  const [, {updateConceptsToLibrary}] = useConceptsContext();

  const conceptId = concept._id;

  const updateWords = (data: any) =>{
    updateConceptsToLibrary(conceptId, data)
  }

  const deleteWord = (word: string) => {
    const words = concept?.words?.filter((w: any) => w !== word);
    updateWords({words})
  }

  const deleteNegativeWord = (word: string) => {
    const words = concept?.negative?.words?.filter((w: any) => w !== word);
    updateWords({negative: {words}})
  }
  const deletePositiveWord = (word: string) => {
    const words = concept?.positive?.words?.filter((w: any) => w !== word);
    updateWords({positive: {words}})
  }


  const dataWords: any[] = concept?.words;
  const dataPositiveWords: any[] = concept?.positive?.words;
  const dataNegativeWords: any[] = concept?.negative?.words;
  const dataPositiveName = concept?.positive?.name;
  const dataNegativeName = concept?.negative?.name;

  const isJudgement = concept.catType === 'judgement'

  const onSubmit = async ({values}: any) => {
    try {
      updateConceptsToLibrary(conceptId, values)
    } catch(error) {
    }
  }

  return <Popup onClose={onClose}>
    <h4>Edit concept</h4>

    <form onSubmit={registerSubmit(onSubmit)}>
      <Input value={values.name} name="name" onChange={handleChange} />
    </form>

    {!isJudgement && <>
      <div className={styles.words}>
        {dataWords?.map( (w, i) => <DeletablePill text={w} key={w + i} onDelete={() => deleteWord(w)} />)}
      </div>
      <AddWordInput words={dataWords} onSubmit={(words: any) => updateWords({words})} />
    </>}

    {isJudgement && <>
      <div className={styles.wordsTitle}>{dataPositiveName || "POSITIVE WORDS"}</div>
      <div className={styles.words}>
        {dataPositiveWords?.map( (w, i) => <DeletablePill text={w} key={w + i} onDelete={() => deletePositiveWord(w)} />)}
      </div>
      <AddWordInput words={dataPositiveWords} onSubmit={(words: any) => updateWords({positive: {words}})} />

      <div className={styles.wordsTitle}>{dataNegativeName || "NEGATIVE WORDS"}</div>
      <div className={styles.words}>
        {dataNegativeWords?.map( (w, i) => <DeletablePill text={w} key={w + i} onDelete={() => deleteNegativeWord(w)} />)}
      </div>
      <AddWordInput words={dataNegativeWords} onSubmit={(words: any) => updateWords({negative: {words}})} />
    </>}
    
  </Popup>
}


export default EditConceptPopup;