import React from 'react'
import {Link} from '@reach/router'

import { MinimizeIcon } from '../icons/icons'

import styles from './draftReportSections.module.scss'

interface SectionProps extends React.HTMLProps<HTMLDivElement> {
  opened: boolean,
  title: any,
  icon: any,
  onSubmit?: any,
  onClick?: any,
  minimizeable: string,
  error?: any,
}

export const Section: React.FC<SectionProps> = ({opened, title, icon, onSubmit, onClick, children, minimizeable, error}) => {
  const TagName = (opened && onSubmit) ? 'form' : 'section'
  const props: any = {};
  if(opened) props.onSubmit = onSubmit;
  else props.onClick = onClick;

  return <TagName className={[styles.card, opened ? styles.opened : null, (error && !opened) ? styles.error : null].join(' ')} {...props}>
    <header className={styles.cardHeader}>
      {icon && <div className={styles.cardIcon}>{icon}</div>}
      <div className={styles.cardHeaderText}>{title}</div>
      {minimizeable && <Link className={styles.minimizeIcon} to={minimizeable}>
        <MinimizeIcon />
      </Link>}
    </header>
    {children != null && opened && <div className={styles.cardContent}>
      {children}
    </div>}
  </TagName>
}

export default Section