import {useEffect, useContext, useRef} from 'react';
import {AmplitudeContext} from './AmplitudeProvider'


interface LogEvent {
  eventType: string,
  eventProperties?: any
}

interface ReportLogEvent extends LogEvent {
  eventType: string,
  shared?: boolean,
  report: any,
  location: any,
  demoId?: string,
  extraEventProperties?: any,
  demoReportId?: any
}

// logs an event normally
export const useLogEffect = ({eventType, eventProperties}: LogEvent, deps: any) => {
  const {amplitudeInstance} = useContext(AmplitudeContext);

  useEffect(() => {
    amplitudeInstance.logEvent(eventType, eventProperties)
  }, [amplitudeInstance, eventType, eventProperties, ...deps]);
}

// logs an event normally
export const useReportLogEffect = ({report, location, demoId, shared, eventType, extraEventProperties}: ReportLogEvent, deps: any) => {
  const {amplitudeInstance} = useContext(AmplitudeContext);

  const reportId = report?.data?._id;
  const href = location?.href;
  const publicId = report?.data?.publicId;
  
  useEffect(() => {
    const eventProperties = {
      ...(extraEventProperties || {}),
      reportId,
      href,
      ...(shared ? { publicId } : {}),
      ...(demoId ? { demoId } : {}),
    }
    amplitudeInstance.logEvent(eventType, eventProperties)
  }, [amplitudeInstance, eventType, reportId, href, demoId, publicId, extraEventProperties, shared, ...deps]);
}

// sets the user id
export const useSetUserId = (userId: string, user: any) => {
  const {amplitudeInstance} = useContext(AmplitudeContext);
  useEffect(() => {
    amplitudeInstance.setUserId(userId);
    if(user != null && user.email != null) { amplitudeInstance.setUserProperties( { email: user.email } ) }
    if(userId == null) amplitudeInstance.regenerateDeviceId();
  }, [userId, amplitudeInstance, user]);
}


const usePrevious = (value: any) => {
  const ref = useRef<any>();
  useEffect(() => { ref.current = value; });
  return ref.current;
}