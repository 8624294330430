import React from 'react'

import Icon from '../icon/icon';
import { ShortStars } from '../stars/stars';
import { TrashIcon } from '../icons/icons'
import Tooltip from '../../libs/context-tooltip/tooltip'

import { sourcesById } from '../../utils'

import styles from './draftBrandsSection.module.scss'

interface AppRowProps extends React.HTMLProps<HTMLDivElement> {
  data: any,
  addApp?: (data: any) => void,
  deleteApp?: (data: any) => void,
  suggested?: any,
}

export const AppRow: React.FC<AppRowProps> = ({data, addApp, deleteApp, suggested, className, ...props}) => {
  if(data == null) return null;
  const starValue = data.averageUserRating || data.score;
  const genre = data?.genres?.[0];
  const provider = sourcesById[data.appProvider];
  const icon = data?.iconUrl;
  
  const _className = [
    styles.row,
    className,
    (addApp != null || props.onDragStart != null) ? styles.hoverable : null,
    suggested ? styles.suggested : null
  ].join(' ');

  return <div className={_className} onClick={addApp != null ? () => addApp(data): undefined} {...props}>
    {provider?.icon && <div className={styles.appProvider}>
      {React.createElement(provider.icon)}
    </div>}
    <div className={styles.appIcon} >
      <Icon src={icon} />
    </div>
    <div className={styles.appTitle}>{data.title}</div>
    <div className={styles.spacer} />
    <div className={styles.genre}>{genre}</div>
    <ShortStars value={starValue} className={styles.stars} />
    {deleteApp != null && <div className={styles.trashIcon}>
      <Tooltip tagName={TrashIcon} content={`remove ${provider?.name} source from report`} onClick={() => deleteApp(data)} />
    </div>}
  </div>
}

export default AppRow