import React from 'react'
import { useReportsContext, useConceptsContext } from '../../contexts'
import { uniqueBy } from '../../utils'

import styles from './draftReportConceptsSection.module.scss'
import { useNavigate } from '@reach/router'


interface DraftReportConceptsSectionProps {
  active: any,
  seen: any,
  report: any,
  onClick: any,
}

export const DraftReportConceptsSection: React.FC<DraftReportConceptsSectionProps> = ({active, seen, report, onClick}) => {
  const [, {requestReport}] = useReportsContext();
  const reportId = report?.data?._id;
  const [{libraryConceptGroups, libraryConceptsById, conceptsByReportId}, {createConcept, deleteConcept, deleteConcepts}] = useConceptsContext();
  const navigate = useNavigate()

  const reportApps = report?.data?.apps;
  const appProviders = Array.from(new Set(reportApps?.map((a: any) => a.appProvider)));

  const groups = React.useMemo(() => {
    if(libraryConceptGroups == null) return null;
    const groups = libraryConceptGroups.filter((group: any) => {
      for(var j=0; j < appProviders.length; j++) {
        if(group.triggerProviders.indexOf(appProviders[j]) >= 0) return true;
      }
      return false;
    })
    return groups;
  }, [libraryConceptGroups, appProviders]);
  const reportConcepts = conceptsByReportId?.[reportId];
  const groupedConcepts = React.useMemo(() => uniqueBy(reportConcepts, (v: any) => v.blueprintId, (a: any) => true), [reportConcepts]);

  if(!active && !seen) return null;  

  const onSelect = (concept: any) => {
    const selected = reportConcepts?.find((v:any) => v.blueprintId === concept._id);
    if(selected != null) {
      deleteConcept(reportId, selected._id)
    } else {
      const body: any = {};
      if(concept.name) body.name = concept.name;
      if(concept.words) body.words = concept.words;
      if(concept.positive) body.positive = concept.positive;
      if(concept.negative) body.negative = concept.negative;
      if(concept.countInScore) body.countInScore = concept.countInScore;
      if(concept._id) body.blueprintId = concept._id;
      if(concept.catType) body.catType = concept.catType;
      
      createConcept(reportId, body);
    }
  }

  const addAll = (group: any) => {
    const newConcepts = group.conceptIds.filter((id: string) => !(groupedConcepts?.[id]))
      .map((id: string) => {
        const concept = libraryConceptsById[id];
        const body: any = {};
        if(concept.name) body.name = concept.name;
        if(concept.words) body.words = concept.words;
        if(concept.positive) body.positive = concept.positive;
        if(concept.negative) body.negative = concept.negative;
        if(concept.countInScore) body.countInScore = concept.countInScore;
        if(concept._id) body.blueprintId = concept._id;      
        if(concept.catType) body.catType = concept.catType;

        return body;
      })
    createConcept(reportId, newConcepts);
  }

  const removeAll = (group: any) => {
    const conceptIds = group.conceptIds.map((id: string) => {
      return reportConcepts?.find((v: any) => v.blueprintId === id)?._id;
    }).filter((a: any) => a);

    deleteConcepts(reportId, conceptIds)
  }

  const _requestReport = async (cardId: string) => {
    await requestReport(report.data._id, report.data.apps, cardId);
    if (navigate) navigate(`/reports/${report.data._id}`)
  }

  return <div className={[styles.section, seen ? styles.seen : null].join(' ')} onClick={!active ? onClick : null}>
    <h1 className={styles.header}>Ok, let's add some Concepts</h1>
    <h2 className={styles.subheader}>These will help you group similar verbatims together.</h2>
    <h2 className={styles.subheader}>But you can always add more later after creating the report</h2>

    <div className={styles.concepts}>
      {groups?.map((group: any) => {
        return <div key={group._id}>
          <div className={styles.conceptsHeader}>
            <span>{group.name}</span>
            <button onClick={() => addAll(group)}>add all</button>
            <button onClick={() => removeAll(group)}>remove all</button>
          </div>
          <div className={styles.conceptsData}>
            {group?.conceptIds?.map((id: string) => {
              const concept = libraryConceptsById[id];
              const selected = reportConcepts?.findIndex((v: any) => v.blueprintId === id) >= 0;
              return <div key={id} className={[styles.tag, selected ? styles.selected : null].join(' ')} onClick={() => onSelect(concept)} >
                {concept.name}
              </div>
            })}
          </div>
        </div>
      })}
    </div>

    <button onClick={() => _requestReport('admin')}>Create the report</button>
  </div>
}

export default DraftReportConceptsSection;