import React from 'react';
import { useAuthContext } from '../../contexts';
import { useSearch } from '../../hooks';
import DraftBrandGroupsSection from '../draftBrandGroupsSection/draftBrandGroupsSection';
import { ReportAdminSection, ReportBrandsSection, ReportConceptsSection, ReportDemoSection, ReportNameSection } from '../draftReportSections/draftReportSections';
import styles from './reportAdmin.module.scss';

interface ReportAdminProps {
  report: any,
  navigate?: any,
  brandsById: any,
  path?: any,
}

export const ReportAdmin: React.FC<ReportAdminProps> = ({ report, navigate, brandsById }) => {
  const [search] = useSearch();
  const [{ permission }] = useAuthContext();
  const isSuperAdmin = permission.isSuperAdmin();

  const data = [
    {step: 'name', Component: ReportNameSection},
    {step: 'brands', Component: ReportBrandsSection},
    isSuperAdmin ? {step: 'demo', Component: ReportDemoSection} : undefined,
    {step: 'concepts', Component: ReportConceptsSection},
    {step: 'groups', Component: DraftBrandGroupsSection}
  ].filter(a => a)
  
  return <div className={styles.container}>
    <ReportAdminSection report={report} navigate={navigate} permission={permission} />
    {data?.map(({Component, step}, i) => {
      const opened = search.step === step;
      const to = (step == null) ? '' : `?step=${step}`;
      const next = ((i+1) < data.length) ? `?step=${data[i+1].step}` : undefined;
      return <Component key={i} report={report} navigate={navigate} opened={opened} to={to} next={next} minimizeable="?" brandsById={brandsById} />
    })}
  </div>
}

export default ReportAdmin;