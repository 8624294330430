import { useLocation } from '@reach/router';
import classnames from 'classnames';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useAppsContext, useConceptsContext, useReportsContext } from '../../contexts';
import Tooltip from '../../libs/context-tooltip/tooltip';
import { Brain, Link as LinkIcon, Mail, People, Preview, Trash, Web } from '../../toneIcons';
import { sources, sourcesById, uniqueBy } from '../../utils';
import BrandsMinimizedSubSection from '../brandsMinimizedSubSection/brandsMinimizedSubSection';
import BrandSource from '../brandSource/brandSource';
import DeletePopup from '../deletePopup/deletePopup';
import AppRow from '../draftBrandsSection/appRow';
import EditableLabel from '../editableLabel/editableLabel';
import EditTeamsPopup from '../editTeamsPopup/editTeamsPopup';
import { AppsStepIcon, NameStepIcon, PaymentStepIcon } from '../icons/icons';
import Input from '../input/input';
import Loading from '../loading/loading';
import Search from '../search/search';
import SourceFileUpload from '../sourceFileUpload/sourceFileUpload';
import SourceUrlUpload from '../sourceUrlUpload/sourceUrlUpload';
import TopicTag from '../topicTag/topicTag';
import styles from './draftReportSections.module.scss';
import Section from './section';

interface IconButtonProps extends React.HTMLProps<HTMLDivElement> {
  tagName?: any,
  icon: any,
  text: string
}

const IconButton: React.FC<IconButtonProps> = ({ className, tagName, icon, text, onClick, ...props}) => {
  const Tag = tagName || 'button';
  return <Tag className={classnames(styles.button, className)} onClick={onClick} {...props}>
    {icon}
    <span>{text}</span>
  </Tag>
}

interface ReportAdminSectionProps {
  report: any,
  navigate: any,
  permission: any,
}

export const ReportAdminSection: React.FC<ReportAdminSectionProps> = ({report, navigate, permission}) => {
  const [showUsersPopup, setShowUsersPopup] = React.useState(false);
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);
  const [, {publishReport, shareReport, unshareReport, deleteReport, resendReportPublished, processReport, analyzeReport}] = useReportsContext();

  const publicId = report?.data?.publicId;
  const reportId = report?.data?._id;
  const reportStatus = report?.data?.status;

  const isSuperAdmin = permission.isSuperAdmin();

  const closeDeletePopup = async (shouldDelete?: boolean) => {
    if(shouldDelete) {
      await deleteReport(reportId);
      setShowDeletePopup(false);
      navigate('/reports')
    } else {
      setShowDeletePopup(false);
    }
  }

  return <div className={styles.adminButtons}>
    {report?.data?.publicId != null && <IconButton icon={<Preview />} tagName="a" href={`/sharedReport/${publicId}`} text="Preview" />}
    {isSuperAdmin && <>
      {(reportStatus === 'published') && report.data.publicId != null && <IconButton icon={<LinkIcon />} text="Delete Link" onClick={() => unshareReport(reportId)} />}
      {(reportStatus === 'published') && report.data.publicId == null && <IconButton icon={<LinkIcon />} text="Create Link" onClick={() => shareReport(reportId)} />}
      {(reportStatus === 'published') && <IconButton icon={<Mail />} text="Resend publish email" onClick={() => resendReportPublished(reportId)} />}
      {(reportStatus !== 'published') && <IconButton icon={<LinkIcon />} text="Publish Report (only public reports can be shared)" onClick={() => publishReport(reportId)} />}
    </>}
    <IconButton icon={<Web />} text="Scan Sources" onClick={() => processReport(reportId)} />
    <IconButton icon={<Brain />} text="Analyze" onClick={() => analyzeReport(reportId)} />
    <IconButton icon={<People />} text="Edit Team" onClick={() => setShowUsersPopup(!showUsersPopup)} />
    <IconButton className={styles.delete} icon={<Trash />} onClick={() => setShowDeletePopup(true)} text="Delete Report" />

    {showDeletePopup && <DeletePopup onClose={() => closeDeletePopup()} onSubmit={() => closeDeletePopup(showDeletePopup)} />}
    {showUsersPopup && <EditTeamsPopup onClose={() => setShowUsersPopup(false)} report={report} />}
  </div>
}


interface SectionProps {
  navigate: any,
  next: any,
  opened: any,
  report: any,
  to: any,
  minimizeable?: string
}

export const ReportNameSection: React.FC<SectionProps> = ({navigate, next, opened, report, to, minimizeable}) => {
  const [, {updateReportData, updateReportDataLocally}] = useReportsContext();

  const debounced = useDebouncedCallback((data, doNotUpdateCache) => updateReportData(data, doNotUpdateCache), 600)

  const reportName = report?.data?.name;

  const updateReportName = (e: any) => {
    const _id = report?.data?._id;
    const newReport = { _id, name: e.target.value };
    updateReportDataLocally(newReport);
    debounced.callback(newReport, true)
  }
  

  if(report == null) return null;

  const onKeyDown = (e: any) => {
    if(e.key === 'Enter' && next != null) {
      navigate(next);
    }
  }

  const title = opened ? "Report Title" : reportName;
  return <Section title={title} opened={opened} icon={<NameStepIcon />} onClick={() => navigate(to)} minimizeable={minimizeable} error={reportName == null || reportName.length === 0}>
    <Input placeholder="Report Name" value={(reportName) || ""} onChange={updateReportName} name='name' autoFocus onKeyDown={onKeyDown} />
  </Section>
}


export const ReportDemoSection: React.FC<SectionProps> = ({navigate, next, opened, to, report, minimizeable}) => {
  const location = useLocation();
  const [{brandsById}, {deleteDemoLink, createDemoLink}] = useReportsContext();
  const title = "Create demo link"


  const _createDemoReport = (brandId: string, data: any) => {
    createDemoLink(report.data._id, {brandId, ...data});
  }

  const reportBrandIds: any[] = report?.data?.brandIds;
  return <>
    <Section title={title} opened={opened} icon={"D"} minimizeable={minimizeable} onClick={() => navigate(to)} />
    {opened && reportBrandIds?.map((brandId, i) => {
      const brand = brandsById?.[brandId];
      const brandName = brand?.data?.name;
      const demoMapping = report?.demoMappings?.[brandId];

      return <div className={styles.brand} key={brandId}>
        <div className={styles.brandImage}>
          {brand.data.imageUrl && <img src={brand.data.imageUrl} alt={brandName} />}
        </div>
        <div className={styles.brandContent}>
          <DemoAppsSection demoMapping={demoMapping} origin={location.origin}
            deleteDemoLink={deleteDemoLink} />
          <DemoAddRow createDemo={(d: any) => _createDemoReport(brandId, d)} />
        </div>
      </div>
    })}
  </>
}

interface DemoAddRowProps {
  createDemo: any
}

const DemoAddRow: React.FC<DemoAddRowProps> = ({createDemo}) => {
  const [data, setData] = React.useState<any>({});

  const submit = (e: any) => {
    if(e) e.preventDefault();
    if(data.description == null || data.description.length === 0) {
      return;
    }
    if(data.price == null || data.price <= 0) {
      return;
    }

    if(createDemo) {
      createDemo(data);
      setData({});
    }
  }

  return <form onSubmit={submit}>
    <Input className={styles.input} placeholder="Prospect or channel name" onChange={(e: any) => setData({...data, description: e.target.value})} value={data.description || ''} />
    <Input className={styles.input} placeholder="Price (Enter 0 for demo)" onChange={(e: any) => setData({...data, price: Number.parseFloat(e.target.value)})} value={data.price || ''} />
    <input type="submit" value="Create unique link" />
    
  </form>
}

interface DemoAppsSectionProps {
  demoMapping: any[],
  origin: any,
  deleteDemoLink: any
}

const DemoAppsSection: React.FC<DemoAppsSectionProps> = ({demoMapping, origin, deleteDemoLink}) => {
  if(demoMapping == null) return null;

  return <div>
    {demoMapping.map((data, i) => {
      const url = [origin, 'demo', data._id].join('/');
      return <div className={styles.demoRow} key={i}>
        <button className={styles.trashIcon}>
          <Tooltip content="remove demo link" onClick={() => deleteDemoLink(data._id)}>Delete demo link</Tooltip>
        </button>
        <div className={styles.name}>{data.description} &mdash; ${data.price}</div>
        <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
      </div>
    })}
  </div>
}




export const ReportBrandsSection: React.FC<SectionProps> = ({navigate, next, opened, to, report, minimizeable}) => {
  const [{searchedApps, searchedAppsMeta, suggestedApps, suggestedAppsMeta}, {searchSuggestedApps, searchApps}] = useAppsContext();
  const [{appsById, appIdsByBrandId, brandsById}, {reportAddBrand, reportDeleteBrand, reportUpdateBrand, reportAddApp, reportDeleteApp, createSourceFromFile}] = useReportsContext();

  const [addingToBrandId, setAddingToBrandId] = React.useState<string>(null);
  const [activeBrandSource, setActiveBrandSource] = React.useState<any>(null);

  const reportId = report?.data?._id;
  const reportApps: any[] = report?.data?.apps;
  const reportBrandIds = report?.data?.brandIds;
  const brandSources = Array.from(new Set(reportApps?.map(a => a.appProvider) || []))

  const title = <span>Add <Tooltip className={styles.tooltipUnderline} tagName="span" content="We define this as a group of voice of customer data sources for the same product">brand or product name</Tooltip> to prepare your analysis</span>

  const _reportUpdateBrand = (brandId: string, data: any) => reportUpdateBrand(reportId, brandId, data);
  const _reportDeleteBrand = (brandId: string) => reportDeleteBrand(reportId, brandId);
  const selectSource = (brandId: string) => (source: any) => {
    if(source == null) {
      setAddingToBrandId(null);
      setActiveBrandSource(null);
    } else {
      setActiveBrandSource(source);
      if(source?.searchable) {
        const brand = brandsById?.[brandId];
        if(brand?.data) {
          searchApps(reportId, brand.data.name, source.provider, brand.data._id);
        }
      }
    }
  }
  const addAppData = (appData: any, brandId: string) => {
    const appIds = appIdsByBrandId?.[brandId]
    const brand = brandsById[brandId];

    if (appIds == null || appIds.length === 0 || (brand?.data?.imageUrl) == null) {
      if(appData?.iconUrl) {
        reportUpdateBrand(reportId, brandId, { imageUrl: appData.iconUrl })
      }
    }

    const newAppData: any = {
      appId: appData.appId,
      appProvider: appData.appProvider,
      data: appData,
    }

    // @TODO: FIX THIS GIAN FUCKING HACK
    if(appData?.url && (appData.appProvider === 'amazon' || appData.appProvider === 'googleMaps') ){
      newAppData.url = appData.url;
    }
    if(brandId) newAppData.brandId = brandId;
    if(newAppData.locale == null && appData?.appId) newAppData.locale = 'us';
    reportAddApp(reportId, newAppData)

    setActiveBrandSource(null);
    setAddingToBrandId(null);
  }
  const addSearchedApp = (brandId: string) => (data: any) => {
    addAppData(data, brandId);

    setAddingToBrandId(null);
    setActiveBrandSource(null);
  }
  const deleteAppId = (metaId: string) => reportDeleteApp(reportId, { metaId });
  const createSourceFromFiles = (brandId: string, title: string, files: any, appProvider: any) => {
    if(title && files?.length > 0) {
      createSourceFromFile(reportId, brandId, title, files[0], appProvider);
    }
    setActiveBrandSource(null);
    setAddingToBrandId(null);
  }

  return <>
    <Section title={title} opened={opened} icon={<AppsStepIcon />} minimizeable={minimizeable} onClick={() => navigate(to)} />
    {opened && reportBrandIds?.map((id: string) => {
      const brand = brandsById?.[id];
      if(brand == null || brand.data == null) return null;

      const searchKey = [reportId, activeBrandSource?.provider, id].join('_');
      return <Brand key={id} brand={brand}
        reportUpdateBrand={_reportUpdateBrand}
        reportDeleteBrand={_reportDeleteBrand}
        appIdsByBrandId={appIdsByBrandId}
        appsById={appsById}
        addingToBrandId={addingToBrandId}
        setAddingToBrandId={setAddingToBrandId}
        selectSource={selectSource(id)}
        activeBrandSource={activeBrandSource}
        searchedApps={searchedApps?.[searchKey]}
        searchedAppsMeta={searchedAppsMeta?.[searchKey]} 
        reportId={reportId}
        searchApps={searchApps}
        addSearchedApp={addSearchedApp(id)}
        deleteAppId={deleteAppId}
        brandSources={brandSources}
        searchSuggestedApps={searchSuggestedApps}
        suggestedApps={suggestedApps}
        suggestedAppsMeta={suggestedAppsMeta}
        addAppData={addAppData}
        createSourceFromFiles={createSourceFromFiles} />
      })}
    {opened && <BrandAdd reportId={reportId}
      brandSources={brandSources}
      searchApps={searchApps}
      reportAddApp={reportAddApp}
      reportAddBrand={reportAddBrand}
      setActiveBrandSource={setActiveBrandSource}
      activeBrandSource={activeBrandSource}
      searchedApps={searchedApps}
      searchedAppsMeta={searchedAppsMeta} />}
    {!opened && <BrandsMinimizedSubSection brandIds={reportBrandIds} brandsById={brandsById} />}
  </>
}


interface BrandProps {
  brand: any,
  appIdsByBrandId: any,
  appsById: any,
  reportUpdateBrand: any,
  reportDeleteBrand: any,
  addingToBrandId: any,
  setAddingToBrandId: any,
  selectSource: any,
  activeBrandSource: any,
  searchedApps: any,
  searchedAppsMeta: any,
  reportId: string,
  searchApps: any,
  addSearchedApp: any,
  deleteAppId: any,
  brandSources: any[],
  searchSuggestedApps: any,
  suggestedApps: any,
  suggestedAppsMeta: any,
  addAppData: any,
  createSourceFromFiles: any
}

const Brand: React.FC<BrandProps> = ({brand, appIdsByBrandId, appsById, reportUpdateBrand, reportDeleteBrand, addingToBrandId, setAddingToBrandId, selectSource, activeBrandSource, searchedApps, searchedAppsMeta, reportId, searchApps, addSearchedApp, deleteAppId, brandSources, searchSuggestedApps, suggestedApps, suggestedAppsMeta, addAppData, createSourceFromFiles}) => {
  const brandId = brand?.data?._id
  const brandName = brand?.data?.name;

  const sourcesToSuggest = React.useMemo(() => {
    const appIds: any[] = appIdsByBrandId?.[brandId];
    const appProviders = appIds?.map(id => appsById?.[id]?.data?.appProvider);
    const sourcesToSuggest = brandSources?.filter(brandSource => {
      const searchableBrand = sourcesById?.[brandSource]?.searchable;
      return (appProviders == null || appProviders.indexOf(brandSource) < 0) && searchableBrand;
    });
    return Array.from(new Set(sourcesToSuggest));
  }, [appIdsByBrandId, appsById, brandId, brandSources])

  const onChangeLabel = (name: any) => reportUpdateBrand(brand.data._id, { name })
  const onDeleteBrand = () => reportDeleteBrand(brand.data._id);
  const setAddingToBrand = () => setAddingToBrandId(brand.data._id);
  const setSearch = (value: any) => searchApps(reportId, value, activeBrandSource.provider);
  const createSource = () => {
    createSourceFromFiles();
  }

  const appIds: any[] = appIdsByBrandId?.[brand.data._id];
  const isAdding = addingToBrandId === brand.data._id;

  const isCsv = activeBrandSource?.provider?.startsWith('csv');

  console.log("activeBrandSource", isAdding, activeBrandSource?.provider)
  return <div className={styles.brand}>
    <div className={styles.brandImage}>
      {brand.data.imageUrl && <img src={brand.data.imageUrl} alt={brandName} />}
    </div>
    <div className={styles.brandContent}>
      <EditableLabel value={brandName} onChange={onChangeLabel} onDelete={onDeleteBrand} deleteTooltip="remove this brand from report" />
      {appIds?.map(id =>
        <AppRow key={id} data={appsById?.[id]?.data} className={styles.appRow} deleteApp={(d: any) => deleteAppId(d.metaId)} />
      )}

      {sourcesToSuggest?.length > 0 && <div>
        <div className={styles.suggestedLabel}><span>suggested apps</span></div>
        {sourcesToSuggest.map(source => <SuggestedApp key={source} reportId={reportId} brandName={brand.data.name} brandId={brand.data._id} source={source} addAppData={addAppData} searchSuggestedApps={searchSuggestedApps} suggestedApps={suggestedApps} suggestedAppsMeta={suggestedAppsMeta} />
        )}
      </div>}

      {!isAdding && <div className={styles.addSourcesWrap}>
        <div className={styles.addSourcesButton} onClick={setAddingToBrand}>+ Add source</div>
      </div>}
      {isAdding && <>
        {!activeBrandSource && <BrandSourcesSection selectSource={selectSource} />}
        {activeBrandSource?.searchable && <div>
          <Search placeholder={`+ Add ${activeBrandSource.provider} source`} value={brandName} autoFocus className={styles.searchInput} onChange={setSearch} />
          {searchedAppsMeta?.loading && <Loading />}
          {searchedApps?.map((app: any) =>
            <AppRow key={[app.appId, app.appProvider].join('.')} data={app} className={styles.searchAppRow} addApp={addSearchedApp} />
          )}
        </div>}
        
        {isCsv && <SourceFileUpload brandName={brandName}
          createSource={(sourceName: any, filesToUpload: any, provider: any) => createSourceFromFiles(brandId, sourceName, filesToUpload, provider)}
          provider={activeBrandSource.provider}
          closeFn={() => createSourceFromFiles()} />}
      
        {activeBrandSource?.viaUrl && <SourceUrlUpload
          brand={brand}
          createSource={addAppData}
          provider={activeBrandSource.provider}
          closeFn={() => createSource()} />}

      </>}
    </div>
  </div>
}

interface BrandAddProps {
  reportId: string,
  brandSources: any[],
  searchApps: any,
  setActiveBrandSource: React.Dispatch<any>,
  searchedApps: any,
  searchedAppsMeta: any,
  reportAddApp: any,
  reportAddBrand: any,
  activeBrandSource: any
}

const BrandAdd: React.FC<BrandAddProps> = ({reportId, brandSources, searchApps, setActiveBrandSource, searchedApps, searchedAppsMeta, reportAddApp, reportAddBrand, activeBrandSource}) => {
  const [brandSearch, setBrandSearch] = React.useState("");

  const setSearch = (value: any) => {
    brandSources?.forEach(source => {
      if(sourcesById?.[source]?.searchable) {
        searchApps(reportId, value, source);
      }
    })
  }
  const createBrand = (name: string) => {
    reportAddBrand(reportId, { name });
    setBrandSearch("");
    setSearch(null);
  }

  const createBrandFromApp = (appData: any) => {
    reportAddApp(reportId, {
      appId: appData.appId,
      appProvider: appData.appProvider,
      locale: appData.locale || (appData.appId ? 'us' : null),
      brandName: appData.title,
      brandImageUrl: appData.iconUrl,
      data: appData,
    })
    setActiveBrandSource(null);
    setBrandSearch("");
    setSearch(null);
  }

  const selectSource = (source?: any) => {
    console.log("selectSource", source)
    if(!source) return;
    setActiveBrandSource(source)
    if(source.searchable) {
      searchApps(reportId, brandSearch, source.provider);
    }
  }

  console.log("activeBrandSource !", activeBrandSource)

  const activeBrandSearchKey = [reportId, activeBrandSource?.provider, ''].join('_');
  const activeBrandSearchedAppsMeta = searchedAppsMeta?.[activeBrandSearchKey];
  const activeBrandSearchedApps: any[] = searchedApps?.[activeBrandSearchKey];

  return <div className={[styles.brand, styles.lastBrand].join(' ')}>
    <div className={styles.brandImageAdd}>+</div>
    <div className={styles.addBrandContent}>
      <Search autoFocus placeholder="start typing here for brand suggestions"
        value={brandSearch || ''}
        className={styles.search}
        onChange={setSearch}
        onInstantChange={setBrandSearch}
        onSubmit={createBrand} />
      
      {activeBrandSearchedAppsMeta?.loading && <Loading />}
      {activeBrandSearchedApps?.map(app =>
        <AppRow key={[app.appId, app.appProvider].join('.')} data={app} className={styles.searchAppRow} addApp={createBrandFromApp} />
      )}
      {!activeBrandSource && brandSearch?.length > 0 && <>
        <div className={styles.createBrand} onClick={() => createBrand(brandSearch)}>+ Create brand "{brandSearch}"</div>
        {(brandSources == null || brandSources.length === 0) && <div className={styles.card}>
          <BrandSourcesSection selectSource={selectSource} />
        </div>}
      </>}
      {brandSources?.map(s => {
        const searchKey = [reportId, s, ''].join('_');
        const meta = searchedAppsMeta?.[searchKey]
        const data = searchedApps?.[searchKey];
        if(meta?.loading) {
          return <Loading key={s} />
        } else if(data) {
          return <React.Fragment key={s}>
            {data?.map((d: any) => 
              <AppRow key={[d.appId, d.appProvider].join('.')} data={d} className={styles.searchAppRow} addApp={createBrandFromApp} />
            )}
          </React.Fragment>
        } return null;
      })}
      
    </div>
  </div>
}

interface SuggestedAppProps {
  reportId: string,
  brandId: string,
  source: any,
  suggestedApps: any,
  suggestedAppsMeta: any,
  searchSuggestedApps: any,
  addAppData: any,
  brandName: string,
}

const SuggestedApp: React.FC<SuggestedAppProps> = ({reportId, brandId, source, suggestedApps, suggestedAppsMeta, searchSuggestedApps, addAppData, brandName}) => {
  React.useEffect(() => {
    if(reportId == null || brandId == null || brandName == null) return;

    searchSuggestedApps(reportId, brandName, source, brandId)
  }, [reportId, brandId, brandName, source, searchSuggestedApps])

  const suggestKey = [reportId, brandName, source, brandId].join('_');
  const meta = suggestedAppsMeta?.[suggestKey];
  
  
  if(meta?.loading) return <div className={styles.suggestLoading} key={suggestKey}>
    <Loading />
  </div>
  
  return <AppRow data={suggestedApps?.[suggestKey]?.[0]} key={suggestKey} addApp={(app: any) => addAppData(app, brandId)} suggested />
}

export const ReportConceptsSection: React.FC<SectionProps> = ({navigate, next, opened, to, report, minimizeable}) => {
  const [{libraryConceptGroups, libraryConceptsById, conceptsByReportId}, {createConcept, deleteConcept, deleteConcepts}] = useConceptsContext();

  const reportId = report?.data?._id;
  const reportApps = report?.data?.apps;
  const appProviders = Array.from(new Set(reportApps?.map((a: any) => a.appProvider)));

  console.log("libraryConceptGroups", libraryConceptGroups)
  const groups = React.useMemo(() => {
    if(libraryConceptGroups == null) return null;
    const groups = libraryConceptGroups.filter((group: any) => {
      for(var j=0; j < appProviders.length; j++) {
        if(group.triggerProviders.indexOf(appProviders[j]) >= 0) return true;
      }
      return false;
    })
    return groups;
  }, [libraryConceptGroups, appProviders]);

  const reportConcepts = conceptsByReportId?.[reportId];
  const groupedConcepts = React.useMemo(() => uniqueBy(reportConcepts, (v: any) => v.blueprintId, () => true), [reportConcepts]);

  if(report == null) return null;


  const onSelect = (concept: any) => {
    const selected = reportConcepts?.find( (v: any) => v.blueprintId === concept._id);
    if(selected != null) {
      deleteConcept(reportId, selected._id)
    } else {
      const body: any = {};
      if(concept.name) body.name = concept.name;
      if(concept.words) body.words = concept.words;
      if(concept.positive) body.positive = concept.positive;
      if(concept.negative) body.negative = concept.negative;
      if(concept.countInScore) body.countInScore = concept.countInScore;
      if(concept._id) body.blueprintId = concept._id;
      if(concept.catType) body.catType = concept.catType;
      
      createConcept(reportId, body);
    }
  }

  const addAll = (group: any) => {
    const newConcepts = group.conceptIds.filter((id: any) => !(groupedConcepts?.[id]))
      .map((id: any) => {
        const concept = libraryConceptsById[id];
        const body: any = {};
        if(concept.name) body.name = concept.name;
        if(concept.words) body.words = concept.words;
        if(concept.positive) body.positive = concept.positive;
        if(concept.negative) body.negative = concept.negative;
        if(concept.countInScore) body.countInScore = concept.countInScore;
        if(concept._id) body.blueprintId = concept._id;      
        if(concept.catType) body.catType = concept.catType;

        return body;
      })
    createConcept(reportId, newConcepts);
  }

  const removeAll = (group: any) => {
    const conceptIds = group.conceptIds.map((id: any) => {
      return reportConcepts?.find((v: any) => v.blueprintId === id)?._id;
    }).filter((a: any) => a);

    deleteConcepts(reportId, conceptIds)
  }

  const title = "Add default concepts"
  return <Section title={title} icon={<PaymentStepIcon />} onClick={() => navigate(to)} opened={opened} minimizeable={minimizeable}>
    <div className={styles.conceptsSubtitle}>
       <p>Pick from an initial list of concepts.</p>
       <p>Customize or add more later from your dashboard.</p>
     </div>
     {groups?.map((group: any) => {
        return <div key={group._id}>
          <div className={styles.conceptsHeader}>
            <span>{group.name}</span>
            <button onClick={() => addAll(group)}>add all</button>
            <button onClick={() => removeAll(group)}>remove all</button>
          </div>
          <div className={styles.conceptsData}>
            {group?.conceptIds?.map((id: any) => {
              const concept = libraryConceptsById[id];
              const selected = reportConcepts?.findIndex((v: any) => v.blueprintId === id) >= 0;
              return <TopicTag value={concept.name} selected={selected} key={id} onClick={() => onSelect(concept)} />
            })}
          </div>
        </div>
      })}
  </Section>
}


interface BrandSourcesSectionProps {
  selectSource: (source?: any) => void
}

const BrandSourcesSection: React.FC<BrandSourcesSectionProps> = ({ selectSource }) => {
  return <div className={styles.brandSources}>
    <div className={styles.brandSourcesHeader}>
      <span>Pick a data source</span>
      <button onClick={() => selectSource()}>cancel</button>
    </div>
    {sources.map(b => {
      const style: any = b.style
      if(b.hidden) return null;
      else if(b.premium) {
        // onClick={() => selectSource(b)}
        return <Tooltip content="Premium feature" className={classnames(styles.brandSource, styles.premium)} key={b?.provider}>
          <BrandSource name={b?.name} icon={b.icon} style={style} />
        </Tooltip>  
      }
      else return <div className={styles.brandSource} key={b?.provider} onClick={() => selectSource(b)}>
        <BrandSource name={b?.name} icon={b.icon} style={style} />
      </div>
    })}
  </div>
}