import React from 'react';

import { useReportLogEffect } from '../../libs/react-amplitude'
import { useReportsContext, useConceptsContext, useReviewsContext } from '../../contexts'
import PolarCard from '../../marksyComponents/polarCard/polarCard'
import HeatmapCard from '../../marksyComponents/heatmapCard/heatmapCard'
import LoversHatersCard from '../../marksyComponents/loversHatersCard/loversHatersCard'
import ConceptSummaryCard from '../../marksyComponents/conceptSummaryCard/conceptSummaryCard'
import { uniqueBy, splitOutBadBrands, getSortedBrands } from '../../utils'
import Loading from '../loading/loading';

import styles from './reportExecSummary.module.scss'

interface ReportExecSummaryProps {
  location?: any,
  report?: any,
  sharedReportId?: string,
  demoReportId?: string,
  navigate?: any,
  path?: any,
}

export const ReportExecSummary: React.FC<ReportExecSummaryProps> = ({ location, report, sharedReportId, demoReportId, navigate }) => {
  useReportLogEffect({eventType: 'viewInfo', report, location, demoId: demoReportId, shared: sharedReportId != null}, [report]);
  const [{brandsById}] = useReportsContext();
  const [{conceptsByReportId}] = useConceptsContext();
  const [{reviewsByTags}, {fetchTaggedReviews}] = useReviewsContext();
  const [topicId, setTopicId] = React.useState(null);
  
  const reportId = report?.data?._id;
  const reportApps = report?.data?.apps;
  const reportBrandIds = report?.data?.brandIds;
  const reportCounts = report?.dataCounts;
  const brandGroups = report?.data?.brandGroups;

  const [filter, setFilter] = React.useState(reportBrandIds?.length === 2 ? `_${reportBrandIds[0]}` : '');
  const conceptsReport = conceptsByReportId?.[reportId];
  const conceptsById = React.useMemo(() => uniqueBy(conceptsReport, (c: any) => c._id), [conceptsReport]);
  const reviewsFromTag = reviewsByTags?.[`${reportId}_${topicId}`];

  React.useEffect(() => {
    if (conceptsReport) { setTopicId(conceptsReport?.[0]?._id); }
  }, [conceptsReport])
  React.useEffect(() => {
    fetchTaggedReviews(reportId, topicId);
  }, [reportId, reportApps, topicId, demoReportId, fetchTaggedReviews]);

  const filteredBrandIds = React.useMemo(() => {
    if (filter.length > 0) {
      const filterArray = filter.split('_');
      if (filterArray.length === 2) { return [filterArray[1]]; }
      else if(filterArray.length === 1) { return brandGroups?.[filterArray[0]]; }
      return [];
    }
    return null;
  }, [filter, brandGroups]);
  const allReviews = React.useMemo(() => {
    if (reviewsFromTag && filteredBrandIds != null) {
      return reviewsFromTag.filter((r: any) => filteredBrandIds.indexOf(r?.brandId) >= 0);
    } else {
      return reviewsFromTag
    }
  }, [reviewsFromTag, filteredBrandIds])

  const sortedBrands = React.useMemo(() => {
    const sortedBrandIds = getSortedBrands(reportBrandIds, brandsById, reportCounts, 'score', 1);
    const sortedBrands = sortedBrandIds?.map((id: any) => brandsById?.[id]);
    const [topRatingBrands, lowRatingBrands] = splitOutBadBrands(sortedBrands, reportCounts)
    return topRatingBrands.concat(lowRatingBrands);
  }, [reportBrandIds, brandsById, reportCounts])

  if(report == null) return <Loading />

  return <div className={styles.container}>
    <div className={styles.cards}>
      <PolarCard demoReportId={demoReportId} report={report} concepts={conceptsReport} sortedBrands={sortedBrands} navigate={navigate} brandsById={brandsById} />
      <ConceptSummaryCard report={report} concepts={conceptsReport} filteredBrandIds={filteredBrandIds} filter={filter} setFilter={setFilter} brandsById={brandsById} />
      <HeatmapCard demoReportId={demoReportId} report={report} concepts={conceptsReport} filter={filter} setFilter={setFilter} filteredBrandIds={filteredBrandIds} brandsById={brandsById} />
      <LoversHatersCard demoReportId={demoReportId} report={report} concepts={conceptsReport}
       filter={filter} setFilter={setFilter}
       topicId={topicId} setTopicId={setTopicId}
       allReviews={allReviews}
       conceptsById={conceptsById}
       sharedReportId={sharedReportId}
       brandsById={brandsById} />
    </div>
  </div>;
}

export default ReportExecSummary;