import { uniqueBy } from '../utils'

export type ContextPropsState = {
  libraryConcepts?: any,
  conceptsByReportId?: any,
  fetchedConcepts?: any,
  libraryConceptGroups?: any,
  libraryConceptsById?: any,
  suggestConceptsByReport?: any,
  similarWordsByReport?: any,
}

export type OrdersAction =
  | { type: "FETCH_REPORT_CONCEPTS_START"}
  | { type: "FETCH_REPORT_CONCEPTS", categories: any, reportId: string }
  | { type: "FETCH_LIBRARY_CONCEPTS", data: any }
  | { type: "ADD_LIBRARY_CONCEPT", data: any }
  | { type: "UPDATE_LIBRARY_CONCEPT", conceptId: string, concept: any }
  | { type: "DELETE_LIBRARY_CONCEPT", conceptId: string }
  | { type: "UPDATE_LIBRARY_GROUP", data: any }
  | { type: "DELETE_LIBRARY_GROUP", conceptGroupId: string }
  | { type: "ADD_REPORT_CONCEPTS", reportId: string, data: any }
  | { type: "UPDATE_REPORT_CONCEPTS", reportId: string, categoryId: string, category: any }
  | { type: "DELETE_REPORT_CONCEPT", reportId: string, categoryId: string }
  | { type: "DELETE_REPORT_CONCEPTS", reportId: string, categoryIds: string[] }
  | { type: "UPDATE_WORD_TO_CONCEPT", reportId: string, category: any }
  | { type: "FETCH_SIMILAR_TERMS", reportId: string, categoryId: string, data: any }
  | { type: "FETCH_REPORT_SUGGEST_CONCEPTS", reportId: string, data: any }
  | { type: "RESET" }

export const initialState: ContextPropsState = {
  libraryConcepts: undefined,
  conceptsByReportId: undefined,
  fetchedConcepts: undefined,
  libraryConceptGroups: undefined,
  libraryConceptsById: undefined,
  suggestConceptsByReport: undefined
};

export const reducer = (state: ContextPropsState, action: OrdersAction): ContextPropsState => {
  if('FETCH_REPORT_CONCEPTS_START' === action.type) {
    return { ...state, fetchedConcepts: false }
  }
  if('FETCH_REPORT_CONCEPTS' === action.type) {
    const sortedCategories = action.categories
    const conceptsByReportId = { ...state.conceptsByReportId, [action.reportId]: sortedCategories }
    
    return { ...state, conceptsByReportId, fetchedConcepts: true }
  }
  if('FETCH_LIBRARY_CONCEPTS' === action.type) {
    const libraryConcepts =  action.data.concepts;
    const libraryConceptsById = uniqueBy(libraryConcepts, (v: any) => v._id);
    return { ...state, libraryConcepts, libraryConceptsById, libraryConceptGroups: action.data.conceptGroups }
  }
  if('ADD_LIBRARY_CONCEPT' === action.type) {
    const libraryConcepts = (state.libraryConcepts || []);
    libraryConcepts.push(action.data)
    const libraryConceptsById = uniqueBy(libraryConcepts, (v: any) => v._id);
    return { ...state, libraryConcepts, libraryConceptsById }
  }
  if('UPDATE_LIBRARY_CONCEPT' === action.type) {
    const libraryConcepts = (state.libraryConcepts || []).map((c: any) => (c._id === action.conceptId) ? action.concept : c);
    const libraryConceptsById = uniqueBy(libraryConcepts, (v: any) => v._id);
    return { ...state, libraryConcepts, libraryConceptsById }
  }
  if('DELETE_LIBRARY_CONCEPT' === action.type) {
    const libraryConcepts = (state.libraryConcepts || []).filter((c: any) => c._id !== action.conceptId);
    const libraryConceptsById = uniqueBy(libraryConcepts, (v: any) => v._id);
    return { ...state, libraryConcepts, libraryConceptsById }
  }
  if('UPDATE_LIBRARY_GROUP' === action.type) {
    const libraryConceptGroups = [...state.libraryConceptGroups];
    const libConceptIndex = libraryConceptGroups.findIndex(v => v._id === action.data._id);
    if(libConceptIndex < 0) libraryConceptGroups.push(action.data);
  
    libraryConceptGroups[libConceptIndex] = action.data;
    return { ...state, libraryConceptGroups };
  }
  if('DELETE_LIBRARY_GROUP' === action.type) {
    const libraryConceptGroups = state.libraryConceptGroups.filter((v: any) => v._id !== action.conceptGroupId);
    return { ...state, libraryConceptGroups };
  }
  if ('ADD_REPORT_CONCEPTS' === action.type) {
    const categories = (state.conceptsByReportId?.[action.reportId]) || [];
    const conceptsByReportId = { ...state.conceptsByReportId, [action.reportId]: categories.concat(action.data) }
    return { ...state, conceptsByReportId }
  }
  if ('UPDATE_REPORT_CONCEPTS' === action.type) {
    const categories = (state.conceptsByReportId?.[action.reportId]) || [];
    const sortedCategories = categories.map((c: any) => (c._id === action.category._id) ? { ...c, ...action.category } : c);
    const conceptsByReportId = { ...state.conceptsByReportId, [action.reportId]: sortedCategories }
    return { ...state, conceptsByReportId }
  }
  if('DELETE_REPORT_CONCEPT' === action.type) {
    const categories = (state.conceptsByReportId?.[action.reportId]) || [];
    const conceptsByReportId = {
      ...state.conceptsByReportId,
      [action.reportId]: categories.filter((c: any) => c._id !== action.categoryId)
    }
    return { ...state, conceptsByReportId }
  }
  if ('DELETE_REPORT_CONCEPTS' === action.type) {
    const categories = (state.conceptsByReportId?.[action.reportId]) || [];
    const conceptsByReportId = {
      ...state.conceptsByReportId,
      [action.reportId]: categories.filter((c: any) => action.categoryIds.indexOf(c._id) < 0)
    }
    return { ...state, conceptsByReportId }
  }
  if ('UPDATE_WORD_TO_CONCEPT' === action.type) {
    const categories = (state.conceptsByReportId?.[action.reportId]) || [];
    const sortedCategories = categories.map((c: any) => (c._id === action.category._id) ? { ...c, ...action.category } : c);
    const conceptsByReportId = { ...state.conceptsByReportId, [action.reportId]: sortedCategories }
    return { ...state, conceptsByReportId }
  }
  if ('FETCH_SIMILAR_TERMS' === action.type) {
    const similarWordsByReport = { ...state.similarWordsByReport, [`${action.reportId}_${action.categoryId}`]: action.data }
    return { ...state, similarWordsByReport }
  }
  if ('FETCH_REPORT_SUGGEST_CONCEPTS' === action.type) {
    const suggestConceptsByReport = { ...state.suggestConceptsByReport, [action.reportId]: action.data }
    return { ...state, suggestConceptsByReport }
  }
  if('RESET' === action.type) {
    return initialState
  }
  return state
}