export class PaginatedArray {
  length: number
  perPage: number
  firstPage: any
  lastPage: any
  loading: boolean
  hasNext: boolean
  dependencies: any
  groups: {[key: string]: any}

  constructor({ perPage, loading }: any) {
    this.groups = {};
    this.length = 0;
    this.perPage = perPage || 0;
    this.firstPage = null;
    this.lastPage = null;
    this.loading = loading || false;
    this.hasNext = null;
    this.dependencies = null;
  }

  setLoading = (loading: boolean, dependencies: any) => {
    if(isDifferent(dependencies, this.dependencies)) {
      this.loading = loading;
      this.dependencies = dependencies;
    }
  }

  addAt = (data: any[], index: number, dependencies: any) => {
    if(isDifferent({...dependencies, page: undefined}, {...this.dependencies, page: undefined})) {
      this.groups = {};
    }

    this.groups[index] = data.map(r => r._id);
    this.length = Object.values(this.groups).reduce((prev, current) => prev + current.length, 0) || 0;
    if (this.firstPage == null) this.firstPage = index;
    else this.firstPage = Math.min(this.firstPage, index);

    if (this.lastPage == null) this.lastPage = index;
    else this.lastPage = Math.max(this.lastPage, index);

    if (data.length === 0 || data.length < this.perPage) this.hasNext = false;
    else this.hasNext = true;

    this.dependencies = dependencies;
    this.loading = false;
  }

  isDifferent = (newDependencies: any) => isDifferent(newDependencies, this.dependencies)

  first = () => {
    const keys = this.groups && Object.keys(this.groups).sort();
    const firstGroup = this.groups[keys?.[0]];
    return firstGroup?.[0];
  }
}

const isDifferent = (newDependencies: any, prevDependencies: any) => {
  if (newDependencies == null && prevDependencies == null) return false;
  if (newDependencies == null) return true;
  if (prevDependencies == null) return true;
  if (Object.keys(newDependencies).length !== Object.keys(prevDependencies).length) return true;
  
  for(let key in newDependencies) {
    if(prevDependencies[key] !== newDependencies[key]) return true;
  }

  return false;
}