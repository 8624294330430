import { Router } from '@reach/router';
import { useLocalStorage } from '@rehooks/local-storage';
import React from 'react';
import DatePicker from '../../components/datePicker/datePicker';
import { WarningIcon } from '../../components/icons/icons';
import { ReportLeftNav } from '../../components/leftNav/leftNav';
import ReportAdmin from '../../components/reportAdmin/reportAdmin';
import ReportApp from '../../components/reportApp/reportApp';
import ReportBrand from '../../components/reportBrand/reportBrand';
import ReportBrands from '../../components/reportBrands/reportBrands';
import ReportComments from '../../components/reportComments/reportComments';
import ReportExecSummary from '../../components/reportExecSummary/reportExecSummary';
import ReportFooter from '../../components/reportFooter/reportFooter';
import { useAuthContext, useConceptsContext, useReportsContext } from '../../contexts';
import { useSearch } from '../../hooks';
import Drift from '../../libs/drift/drift';
import { uniqueBy } from '../../utils';
import styles from './report.module.scss';

interface ReportProps {
  reportId: string,
  sharedReportId?: string,
  demoReportId?: string,
  uri?: string
}

const Report: React.FC<ReportProps> = ({reportId, sharedReportId, demoReportId, uri}) => {
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const [filtersMinimized] = useLocalStorage('filtersMinimized', false);
  const [search, setSearch] = useSearch();
  const [{ permission }] = useAuthContext();
  const [{conceptsByReportId}, {fetchConceptsForReport, fetchConceptsLibrary, fetchReportSuggestConcepts}] = useConceptsContext();
  const [{reportsById, brandsById}, {fetchReport, fetchSharedReport, fetchDemoReport, reportFetchPhrases, fetchStatus}] = useReportsContext();

  const report = React.useMemo(() => {
    if(reportsById == null) return undefined;
    if(demoReportId) return reportsById[demoReportId];
    else if(sharedReportId) return reportsById[sharedReportId];
    else return reportsById[reportId];
  }, [reportsById, reportId, demoReportId, sharedReportId]);

  const dateType = search.dateType
  const dateValue = search.dateValue
  const reportIdFromReport = report?.data?._id;
  const reportConcepts = conceptsByReportId?.[reportIdFromReport];

  let shouldFetchReport = false;
  if(reportId != null && report == null) shouldFetchReport = true;
  else if(reportId != null && report != null && report.status !== 'DETAIL_FETCHED') shouldFetchReport = true;
  else if(reportId != null && report != null) {
    shouldFetchReport = dateType !== report.intervalType || dateValue !== report.date
  }
  
  const shouldFetchDemoReport = demoReportId != null && (report == null || (report != null && report.status !== 'DETAIL_FETCHED'))
  const shouldFetchSharedReport = sharedReportId != null && (report == null || (report != null && report.status !== 'DETAIL_FETCHED'))

  const conceptsById = React.useMemo(() => uniqueBy(reportConcepts, (c: any) => c._id), [reportConcepts]);
  React.useEffect(() => {
    if(shouldFetchReport) { fetchReport(reportId, dateType, dateValue) }
  }, [reportId, shouldFetchReport, fetchReport, dateType, dateValue])
  React.useEffect(() => {
    if(shouldFetchDemoReport) { fetchDemoReport(demoReportId); }
  }, [demoReportId, shouldFetchDemoReport, fetchDemoReport]);
  React.useEffect(() => {
    if (shouldFetchSharedReport) { fetchSharedReport(sharedReportId); }
  }, [sharedReportId, shouldFetchSharedReport, fetchSharedReport]);
  React.useEffect(() => {
    if(fetchStatus && reportId) { fetchStatus(reportId); }
  }, [fetchStatus, reportId])

  React.useEffect(() => {
    reportFetchPhrases(reportId, {sortBy: 'phraziness'});
  }, [reportId, reportFetchPhrases]);
  
  React.useEffect(() => { fetchConceptsForReport(reportIdFromReport); }, [reportIdFromReport, fetchConceptsForReport]);
  React.useEffect(() => { fetchReportSuggestConcepts(reportId); }, [reportId, fetchReportSuggestConcepts]);
  // React.useEffect(() => {if( permission.isSuperAdmin() ) fetchConceptsLibrary(); }, [permission, fetchConceptsLibrary]);
  React.useEffect(() => { fetchConceptsLibrary(); }, [fetchConceptsLibrary]);
  
  const versionHistoryIntervalType = 'month';
  const dateGroupBy = 'quarter';

  const closed = search.hideDetail !== undefined;

  const style: any = {
    '--nav-width': navMinimized ? 'var(--nav-closed-width)' : 'var(--nav-opened-width)',
    '--header-height' : report?.isProcessing ? 'var(--header-opened-height)' : 'var(--header-closed-height)',
    '--footer-height': '50px',
    '--filters-width': filtersMinimized ? 'var(--filters-closed-width)' : 'var(--filters-opened-width)',
    '--detail-width': closed ? 0 : '300px',
    '--detail-header-width': closed ? '300px' : '300px',
  }

  return <div style={style} className={styles.containerWrap}>
    {report?.isProcessing && <div className={styles.header}>
      <ProcessingHeader report={report} />
    </div>}
    <ReportLeftNav report={report} sharedReportId={sharedReportId} demoReportId={demoReportId} />

    <Router className={styles.router}>
      <ReportAdmin path="/admin" report={report} brandsById={brandsById} />


      <ReportExecSummary path="/" report={report} sharedReportId={sharedReportId} demoReportId={demoReportId} />
      {permission.canComment() && <ReportComments path="comments" report={report} conceptsById={conceptsById} />}

      <ReportBrands path="brands" report={report} sharedReportId={sharedReportId} conceptsById={conceptsById} concepts={reportConcepts} demoReportId={demoReportId} />

      <ReportBrandContainer path="brands/:brandId" report={report} sharedReportId={sharedReportId} demoReportId={demoReportId} conceptsById={conceptsById} reportConcepts={reportConcepts} versionHistoryIntervalType={versionHistoryIntervalType} dateType={dateType} dateValue={dateValue} />
      
      <ReportBrandAppContainer path="brands/:brandId/:metaId" report={report} sharedReportId={sharedReportId} demoReportId={demoReportId} conceptsById={conceptsById} reportConcepts={reportConcepts} versionHistoryIntervalType={versionHistoryIntervalType} dateType={dateType} dateValue={dateValue} />
    </Router>
    {(demoReportId || sharedReportId) && <Drift id={process.env.REACT_APP_DRIFT_ID} />}

    <ReportFooter report={report} brandsById={brandsById} uri={uri}>
      {reportId && <div className={styles.dateContainer}>
        <DatePicker groupBy={dateGroupBy} search={search} setSearch={setSearch} />
      </div>}
    </ReportFooter>
  </div>
}

export default Report;

interface ReportBrandContainerProps {
  report: any,
  sharedReportId?: string,
  uri?: any,
  brandId?: string,
  demoReportId?: string,
  versionHistoryIntervalType: any,
  dateType: any,
  dateValue: any,
  path?: string,
  conceptsById?: any,
  reportConcepts?: any,
}

const ReportBrandContainer: React.FC<ReportBrandContainerProps> = ({report, sharedReportId, uri, brandId, demoReportId, versionHistoryIntervalType, dateType, dateValue, ...props}) => {
  const [{brandsById},{fetchBrand, fetchBrandVoice, fetchBrandHistory, fetchBrandTfIdf, fetchDemoBrand, fetchDemoBrandVoice, fetchDemoBrandHistory, fetchPublicBrand, fetchPublicBrandVoice, fetchPublicBrandHistory}] = useReportsContext();
  const reportId = report?.data?._id;

  React.useEffect(() => {
    if (demoReportId) {
      fetchDemoBrand(demoReportId, brandId);
      fetchDemoBrandVoice(demoReportId, brandId);
    }
  }, [demoReportId, brandId, fetchDemoBrand, fetchDemoBrandVoice]);
  React.useEffect(() => {
    if (demoReportId) { fetchDemoBrandHistory(demoReportId, brandId, {intervalType: versionHistoryIntervalType}); }
  }, [demoReportId, brandId, fetchDemoBrandHistory, versionHistoryIntervalType]);
  React.useEffect(() => {
    if (!demoReportId && sharedReportId) {
      fetchPublicBrand(sharedReportId, brandId);
      fetchPublicBrandVoice(sharedReportId, brandId);
    }
  }, [demoReportId, sharedReportId, brandId, fetchPublicBrand, fetchPublicBrandVoice]);
  React.useEffect(() => {
    if (!demoReportId && sharedReportId) { fetchPublicBrandHistory(sharedReportId, brandId, {intervalType: versionHistoryIntervalType}); }
  }, [demoReportId, sharedReportId, brandId, fetchPublicBrandHistory, versionHistoryIntervalType]);

  React.useEffect(() => {
    if (!demoReportId && !sharedReportId) {
      fetchBrand(reportId, brandId);
      fetchBrandVoice(reportId, brandId, dateType, dateValue);
    }
  }, [reportId, demoReportId, sharedReportId, brandId, fetchBrand, fetchBrandVoice, dateType, dateValue]);
  React.useEffect(() => {
    if (!demoReportId && !sharedReportId) {
      fetchBrandTfIdf(reportId, brandId, dateType, dateValue);
    }
  }, [reportId, demoReportId, sharedReportId, brandId, fetchBrandTfIdf, dateType, dateValue]);
  React.useEffect(() => {
    if (!demoReportId && !sharedReportId) {
      fetchBrandHistory(reportId, brandId, {intervalType: versionHistoryIntervalType});
    }
  }, [reportId, demoReportId, sharedReportId, brandId, fetchBrandHistory, versionHistoryIntervalType]);

  const brand = brandsById?.[brandId];
  return <ReportBrand brand={brand} report={report} shared={sharedReportId != null} demoReportId={demoReportId} versionHistoryIntervalType={versionHistoryIntervalType} {...props} />
}

interface ReportBrandAppContainerProps {
  report: any,
  sharedReportId?: string,
  uri?: any,
  metaId?: string,
  brandId?: string,
  demoReportId?: string,
  versionHistoryIntervalType: any,
  dateType: any,
  dateValue: any,
  path?: string,
  conceptsById?: any,
  reportConcepts?: any,
}

const ReportBrandAppContainer: React.FC<ReportBrandAppContainerProps> = ({report, sharedReportId, uri, metaId, brandId, demoReportId, versionHistoryIntervalType, dateType, dateValue, ...props}) => {
  const [{appsById}, {fetchApp, fetchDemoApp, fetchPublicApp, fetchAppTfIdf, fetchAppVoice, fetchPublicAppVoice, fetchDemoAppVoice, fetchAppHistory, fetchPublicAppHistory, fetchDemoAppHistory}] = useReportsContext();
  const reportId = report?.data?._id;

  React.useEffect(() => {
    if (demoReportId) {
      fetchDemoApp(demoReportId, metaId);
      fetchDemoAppVoice(demoReportId, brandId, metaId)
    }
  }, [metaId, brandId, demoReportId, fetchDemoApp, fetchDemoAppVoice])
  React.useEffect(() => {
    if(demoReportId) {
      fetchDemoAppHistory(demoReportId, brandId, metaId, {intervalType: versionHistoryIntervalType});
    }
  }, [demoReportId, metaId, brandId, fetchDemoAppHistory, versionHistoryIntervalType])

  React.useEffect(() => {
    if (!demoReportId && sharedReportId) {
      fetchPublicApp(sharedReportId, metaId);
      fetchPublicAppVoice(sharedReportId, brandId, metaId)
    }
  }, [metaId, sharedReportId, demoReportId, fetchPublicApp, brandId, fetchPublicAppVoice])
  React.useEffect(() => {
    if(!demoReportId && sharedReportId) {
      fetchPublicAppHistory(sharedReportId, brandId, metaId, {intervalType: versionHistoryIntervalType});
    }
  }, [reportId, metaId, sharedReportId, demoReportId, brandId, fetchPublicAppHistory, versionHistoryIntervalType])
  React.useEffect(() => {
    if(!demoReportId && !sharedReportId) {
      fetchApp(reportId, metaId)
      fetchAppVoice(reportId, brandId, metaId, dateType, dateValue)
    }
  }, [reportId, metaId, sharedReportId, demoReportId, brandId, fetchApp, fetchAppVoice, dateType, dateValue])
  React.useEffect(() => {
    if (!demoReportId && !sharedReportId) {
      fetchAppTfIdf(reportId, brandId, metaId, dateType, dateValue);
    }
  }, [reportId, demoReportId, sharedReportId, brandId, metaId, fetchAppTfIdf, dateType, dateValue]);
  React.useEffect(() => {
    if(!demoReportId && !sharedReportId) {
      fetchAppHistory(reportId, brandId, metaId, {intervalType: versionHistoryIntervalType});
    }
  }, [reportId, metaId, sharedReportId, demoReportId, brandId, fetchAppHistory, versionHistoryIntervalType])
  
  const app = appsById && appsById[metaId];
  return <ReportApp app={app} brandId={brandId} report={report} shared={sharedReportId != null} demoReportId={demoReportId} versionHistoryIntervalType={versionHistoryIntervalType} {...props} />
}


interface ProcessingHeaderProps {
  report: any,
}

const ProcessingHeader: React.FC<ProcessingHeaderProps> = ({report}) => {
  const jobsLength = report?.jobs?.length;
  const [activeIndex, setActiveIndex] = React.useState(0);
  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      setActiveIndex((activeIndex+1) % jobsLength);
    }, 2000)
    return () => window.clearTimeout(timeout);
  }, [activeIndex, jobsLength])
  return <div className={styles.jobs}>
    <WarningIcon className={styles.warningIconLeft} />
    <WarningIcon className={styles.warningIconRight} />
    {report?.jobs?.map((job: any, index: number) => {
      let top: any = 0;
      if(activeIndex < index) top = '100%';
      else if(activeIndex > index) top = '-100%';

      const style = {
        zIndex: index,
        top,
      }

      return <div key={index} style={style} className={[styles.job].join(' ')}>{job.prettyAction}</div>
    })}
  </div>
}