import React from 'react';

import styles from './spinner.module.scss'

export const Spinner: React.FC<React.HTMLProps<HTMLDivElement>> = React.memo(({className}) => {
  const tone1 = "rgba(77.3160958190034%,67.83090450357619%,82.05479452054794%,0.179)";
  const tone2 = "rgba(55.68627450980394%,37.64705882352941%,57.25490196078431%,0.471)";
  const tone3 = "rgba(84.3137254901961%,76.47058823529412%,87.05882352941177%,0.792)";
  const tone4 = "rgba(15.68627450980393%,3.1372549019607843%,26.27450980392157%,0.863)"

  return <svg viewBox="0 0 100 20" fillRule="evenodd" className={[className, styles.container].join(' ')}>
    <circle cx="84" cy="10" r="0" fill={tone1}>
      <animate attributeName="r" values="10;0;0;0;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
      <animate attributeName="cx" values="84;84;84;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
    </circle>

    <circle cx="41.5416" cy="10" r="10" fill={tone2}>
      <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="-0.7s" />
      <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="-0.7s" />
    </circle>

    <circle cx="16" cy="10" r="7.51225" fill={tone3}>
      <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="-0.35s" />
      <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="-0.35s" />
    </circle>
    
    <circle cx="84" cy="10" r="2.48775" fill={tone4}>
      <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
      <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
    </circle>
    
    <circle cx="75.5416" cy="10" r="10" fill={tone1}>
      <animate attributeName="r" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
      <animate attributeName="cx" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
    </circle>
  </svg>
})