import React from 'react';
import {Button} from '../button/button'
import Input  from '../input/input'

import styles from './sourceUrlUpload.module.scss'

interface SourceUrlUploadProps {
  brand: any,
  provider: any,
  closeFn: any,
  createSource: any
}

export const SourceUrlUpload: React.FC<SourceUrlUploadProps> = ({brand, provider, closeFn, createSource}) => {
  const [url, setUrl] = React.useState('');

  const _createSource = () => {
    if(createSource) {
      createSource({url, appProvider:provider}, brand.data._id);
    }
  }

  return <div className={styles.container}>
    <Input placeholder="Url for source" value={url || ""} onChange={(e: any) => setUrl(e.target.value)} className={styles.input} />
   
    <div className={styles.buttons}>
      <Button title="Create source" disabled={url.length === 0} onClick={_createSource} />
      <Button title="Cancel" onClick={closeFn} />
    </div>
  </div>
}

export default SourceUrlUpload