import {groupBy, mapValues} from 'lodash'
import * as Concepts from './components/icons/concepts'

export const categories = [
  { key: 'quality', name: 'Quality', icon: Concepts.Quality },
  { key: 'design', name: 'Design', icon: Concepts.Design },
  { key: 'featureRequest', name: 'Feature Request', icon: Concepts.Features },
  { key: 'pricing', name: 'Pricing', icon: Concepts.Pricing },
  { key: 'service', name: 'Service', icon: Concepts.Service },
]

export const conceptIconByName = mapValues(groupBy(categories, c => c.name), cc => cc[0].icon)

export const getRangeString = (value: number | undefined, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return null;
  const val = value / outOf;
  if (val <= 0.2) return 'poor';
  else if (val <= 0.4) return 'bad';
  else if (val <= 0.6) return 'ok';
  else if (val <= 0.8) return 'good';
  else return 'great';
}

export const getScore = (avg: number) => {
  if (avg == null) return null;
  return Math.round(((avg + 1) * 50));
}

export const sentimentWords: {[id: number]: string} = {
  1: 'terrible',
  2: 'bad',
  3: 'ok',
  4: 'good',
  5: 'great',
}