import React from 'react'
import { Link } from '@reach/router'

import styles from './previewCard.module.scss'

export const PreviewCard = () => <div className={styles.previewCardWrap}>
  <div className={styles.previewCard}>
    <div className={styles.previewCardLabel}>PREVIEW</div>
    <div className={styles.header}>Welcome to your Harmonize Preview</div>
    <div className={styles.text}>Sign up to connect more data sources and instantly make more customer centric, data driven product and experience decisions.</div>
    <Link className={styles.button} to="/demo">Learn more</Link>
  </div>
</div>

export default PreviewCard