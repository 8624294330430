import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useSearch } from '../../hooks';
import { Search as SearchIcon } from '../../toneIcons';
import styles from './paginator.module.scss';

const Chevron = () => <svg width="32px" height="60px" viewBox="0 0 32 60">
  <path d="M7.0320353,1.30758381 C5.5292802,-0.326180066 2.97257894,-0.445171582 1.3214832,1.04180882 C-0.274576009,2.47922321 -0.440148518,4.89115353 0.908019832,6.52612462 L1.05288873,6.69241619 L22.491121,30 L1.05288873,53.3075838 C-0.39977454,54.8868889 -0.335826113,57.3035489 1.16147985,58.8060887 L1.3214832,58.9581912 C2.91754241,60.3956056 5.35983968,60.3323284 6.87831921,58.85074 L7.0320353,58.6924162 L30.9471391,32.6924162 C32.3008174,31.2207255 32.3491631,28.9993558 31.0921761,27.4741166 L30.9471391,27.3075838 L7.0320353,1.30758381 Z" />
</svg>


interface CloseIconProps extends React.HTMLProps<HTMLOrSVGElement> {
}

const CloseIcon: React.FC<CloseIconProps> = ({ className, onClick}) => <svg className={className} width="18px" height="18px" viewBox="0 0 18 18" fillRule="nonzero" onClick={onClick}>
  <path
    d="M17.3108918,17.9637349 C17.4680569,17.9274661 17.6070875,17.8488836 17.7279836,17.7279874 C17.8488798,17.6070912 17.9294772,17.4660457 17.969776,17.3048508 C18.0100747,17.1436559 18.0100747,16.984476 17.969776,16.827311 C17.9294772,16.670146 17.8488798,16.5351452 17.7279836,16.4223088 L10.3104466,9.00001241 L17.7279836,1.57625952 C17.8488798,1.4634231 17.9294772,1.32640745 17.969776,1.16521256 C18.0100747,1.00401767 18.0100747,0.844837715 17.969776,0.687672698 C17.9294772,0.530507681 17.8488798,0.39147709 17.7279836,0.270580923 C17.6070875,0.1577445 17.4660419,0.0811769281 17.304847,0.0408782058 C17.1436522,0.000579483506 16.9864871,0.000579483506 16.833352,0.0408782058 C16.6802168,0.0811769281 16.5432012,0.1577445 16.422305,0.270580923 L9.00494386,7.69450966 L1.58834535,0.270580923 C1.45938944,0.1577445 1.31834391,0.0811769281 1.16520877,0.0408782058 C1.01207363,0.000579483506 0.854908608,-0.00143545261 0.693713719,0.0348333974 C0.53251883,0.0711022475 0.395503174,0.149684756 0.282666752,0.270580923 C0.161770585,0.39147709 0.0811731406,0.530507681 0.0408744183,0.687672698 C0.000575696032,0.844837715 0.000575696032,1.00401767 0.0408744183,1.16521256 C0.0811731406,1.32640745 0.161770585,1.4634231 0.282666752,1.57625952 L7.69944111,9.00001241 L0.282666752,16.4223088 C0.161770585,16.5351452 0.0791582045,16.6721609 0.03482961,16.8333558 C-0.00949898454,16.9945507 -0.0115139206,17.1557456 0.0287848016,17.3169404 C0.0690835239,17.4781353 0.153710841,17.615151 0.282666752,17.7279874 C0.395503174,17.8488836 0.53251883,17.9274661 0.693713719,17.9637349 C0.854908608,18.0000038 1.01207363,18.0000038 1.16520877,17.9637349 C1.31834391,17.9274661 1.45938944,17.8488836 1.58834535,17.7279874 L9.00494386,10.3055152 L16.422305,17.7279874 C16.5432012,17.8488836 16.6822318,17.9274661 16.8393968,17.9637349 C16.9965618,18.0000038 17.1537268,18.0000038 17.3108918,17.9637349 Z"
  fill="currentColor"/>
  </svg>;


interface SearchProps {
  onChange: (any: any) => void,
  value: any
}

const Search: React.FC<SearchProps> = ({onChange, value}) => {
  const [internalValue, setInternalValue] = React.useState(value);
  const debounced = useDebouncedCallback(onChange, 1000)

  const _onChange = (v?: string) => {
    setInternalValue(v);
    debounced.callback(v);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    _onChange(e.target.value);
  }

  return <form className={styles.search} onSubmit={onSubmit}>
    <input onChange={(e) => _onChange(e.target.value)} value={internalValue || ''} autoFocus />
    <SearchIcon className={styles.searchIcon} />
    {internalValue && <button className={styles.close} onClick={() => onChange(null)}>
      <CloseIcon />
    </button>}
  </form>
}

interface PaginatorProps {
  results?: any,
  onCreate?: any,
  perPageDefault?: any
}

export const Paginator: React.FC<PaginatorProps> = ({results, onCreate, perPageDefault}) => {
  const [search, setSearch] = useSearch();
  const page = parseInt(search.page) || 0;
  const perPage = parseInt(search.perPage) || perPageDefault;
  const query = search.query || '';

  const hasPrev = page > 0;
  let hasNext = true;
  if(results?.ids && (results.ids.length < perPage || results.ids.length === 0)) {
    hasNext = false;
  }

  const changePage = (page: any) => {
    if(parseInt(page) === 0) setSearch((s: any) => ({...s, page: undefined}))
    else setSearch((s: any) => ({...s, page}))
  }

  const changePerPage = (perPage: any) => {
    if(parseInt(perPage) === perPageDefault) setSearch((s: any) => ({...s, perPage: undefined}))
    else setSearch((s: any) => ({...s, perPage}))
  }

  const changeQuery = (query: string) => {
    if(query === '' || query == null) setSearch((s:any) => ({...s, query: undefined, page: undefined}))
    else setSearch((s: any) => ({...s, query, page: undefined}))
  }

  return <div className={styles.container}>
    {onCreate && <div className={styles.addRow} onClick={onCreate}>+ Create Report</div>}
    <Search value={query} onChange={changeQuery} />
    <div className={styles.label}>
      <span>SHOW:</span>
      <div className={styles.select}>
        <select onChange={(e) => changePerPage(e.target.value)} value={perPage}>
          {Array.from({length: 3}, (_, i) => <option key={i} value={perPageDefault*(i+1)}>{perPageDefault*(i+1)}</option>)}
        </select>
      </div>
    </div>

    <div className={styles.pager}>
      <button className={styles.prev} disabled={!hasPrev} onClick={() => changePage(page - 1)}>
        <Chevron />
        <span>PREV</span>
      </button>
      <button className={styles.next} disabled={!hasNext} onClick={() => changePage(page + 1)}>
        <span>NEXT</span>
        <Chevron />
      </button>
    </div>
  </div>
}

export default Paginator