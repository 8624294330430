import React from 'react'

import { useAuthContext } from './auth.context'
import { AmplitudeContext } from '../libs/react-amplitude'
import { fetchUrl, headersWithXToken } from './utils'
import { initialState, reducer, ContextPropsState } from './apps.reducer'

type ContextPropsActions = {
  searchApps: (reportId: string, query: string, appProvider: string, brandId: string) => void,
  searchSuggestedApps: (reportId: string, query: string, appProvider: string, brandId: string) => void,
  reset: () => void,
}

const initialActions: ContextPropsActions = {
  searchApps: () => { throw new Error("searchApps not implemented") },
  searchSuggestedApps: () => { throw new Error("searchSuggestedApps not implemented") },
  reset: () => { throw new Error("reset not implemented") }
}

type ContextProps = [ContextPropsState, ContextPropsActions]

export const AppsContext = React.createContext<ContextProps>([initialState, initialActions]);

export const AppsProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({children}) => {
  const [{user}] = useAuthContext();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {amplitudeInstance} = React.useContext(AmplitudeContext);

  const searchApps = React.useCallback( async (reportId, query, appProvider, brandId) => {
    if(user == null) return;
    try {
      dispatch({type: 'SEARCHED_START', appProvider, query, reportId, brandId})
      if(query == null || query === '') {
        dispatch({type: 'SEARCHED', searchedApps: null, appProvider, query, reportId, brandId})
        return {results: []}
      }
      const encodedQuery = encodeURIComponent(query);

      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/${appProvider}/search/${encodedQuery}`;
      const data = await fetchUrl('GET', url, { headers });
      const searchedApps = data.results.map((app: any) => ({ ...app, appProvider }));
      dispatch({type: 'SEARCHED', searchedApps, appProvider, query: encodedQuery, reportId, brandId})
      return data;
    } catch(error) {
      console.error(error);
    }
  }, [amplitudeInstance, user])

  const searchSuggestedApps = React.useCallback( async (reportId, query, appProvider, brandId) => {
    if(user == null) return;
    try {
      dispatch({type: 'SUGGESTED_START', appProvider, query, reportId, brandId})
      if(query == null || query === '') {
        dispatch({type: 'SUGGESTED', suggestedApps: null, appProvider, query, reportId, brandId})
        return {results: []}
      }
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/${appProvider}/search/${query}`;
      const data = await fetchUrl('GET', url, { headers });
      const suggestedApps = data.results.map((app: any) => ({ ...app, appProvider }));
      dispatch({type: 'SUGGESTED', suggestedApps, appProvider, query, reportId, brandId})
      return suggestedApps;
    } catch(error) {
      console.error(error);
    }
  }, [amplitudeInstance, user])

  const reset = React.useCallback( () => dispatch({ type: 'RESET' }), [])

  const actions = { searchApps, searchSuggestedApps, reset }

  const value: ContextProps = [state, actions]

  return <AppsContext.Provider value={value}>
    {children}
  </AppsContext.Provider>
}


export const useAppsContext = () => React.useContext(AppsContext)