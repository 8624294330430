import React from 'react'

import { useKey } from "../../hooks/useKey";
import { findNextPrevReview } from '../../utils'

import { Chevron3Icon } from '../icons/icons'

import styles from './machineHeader.module.scss'
import { useSearch } from '../../hooks';

interface MachineHeaderProps {
  closed: any,
  reviews: any
}

export const MachineHeader: React.FC<MachineHeaderProps> = ({closed, reviews}) => {
  const [search, setSearch] = useSearch();
  useKey(['Enter'], (event: any) => {
    if((event.ctrlKey || event.metaKey) && (event.keyCode === 13 || event.keyCode === 10)) {
      const {nextReview, prevReview} = findNextPrevReview(reviews, search);
      if(event.shiftKey && prevReview) {
        setSearch((s: any) => ({ ...s, machine: prevReview }));
      } else if(!event.shiftKey && nextReview) {
        setSearch((s:any) => ({ ...s, machine: nextReview }));
      }
    }
  })

  const toggleOpened = () => setSearch((s: any) => ({ ...s, hideDetail: closed ? undefined : null }));

  return <div className={[styles.header, !closed ? styles.opened : null].join(' ')}>
    <div className={styles.title}>Classify Feedback</div>
    <div className={styles.chevron} onClick={toggleOpened}>
      <Chevron3Icon />
    </div>
  </div>
}

export default MachineHeader;