import React from 'react'

import Tooltip from '../../libs/context-tooltip/tooltip'
import { useMedia, useSearch } from '../../hooks'
import { VoiceIcon, HistoryIcon, BotIcon } from '../icons/icons'

import styles from './reviewsHeader.module.scss'

interface ButtonProps {
  text: any,
  icon?: any,
  tag?: any,
  search?: any,
  setSearch?: any,
  stacked?: any
}

const Button: React.FC<ButtonProps> = ({text, icon, tag, search, setSearch, stacked}) => {
  const onClick = () => { setSearch((s: any) => ({ ...s, section: tag })); }

  const isSelected = search?.section === tag;
  return <div className={[styles.button, isSelected ? styles.selected : null, stacked ? styles.stacked : null].join(' ')} onClick={!isSelected ? onClick : undefined}>
    {icon}
    <div className={styles.buttonText}>{text}</div>
  </div>
}

interface ReviewsHeaderProps {
  report: any,
  permission: any,
  firstReview: any,
}

export const ReviewsHeader: React.FC<ReviewsHeaderProps> = ({ report, permission, firstReview}) => {
  const [search, setSearch] = useSearch();
  const isScreenLarge = useMedia('(min-width: 1200px)');
  const toggleBot = () => setSearch((s: any) => ({ ...s, machine: (search.machine === undefined) ? firstReview : undefined }));

  const machineLearningTooltip = search.machine ? "Stop classifying" : "Classify feedback mode"
  return <div className={styles.reviewsHeader}>
    <Button text="Voice" icon={<VoiceIcon />} search={search} setSearch={setSearch} stacked={!isScreenLarge} />
    {permission.isSuperAdmin() && search.category != null && <Button text="Sentences" tag="sentences" search={search} setSearch={setSearch} stacked={!isScreenLarge} />}
    <Button text="History" icon={<HistoryIcon />} tag="versionHistory" search={search} setSearch={setSearch} stacked={!isScreenLarge} />
    
    <div className={styles.spacer} />
    {permission.canMachineLearn(report) && firstReview != null && <Tooltip position="bottom" content={machineLearningTooltip} className={styles.bot} onClick={toggleBot}>
      <BotIcon animated={search.machine !== undefined} />
    </Tooltip>}
  </div>
}

export default ReviewsHeader