import React from 'react'
import BrandSource from '../brandSource/brandSource'
import { useSearch } from '../../hooks'
import { sources } from '../../utils'
import Description from '../description/description'
import DetailInfoQuadrant from '../detailInfoQuadrant/detailInfoQuadrant'
import Images from '../images/images'
import styles from './detail.module.scss'

interface BrandDetailProps {
  brand: any,
  reportCounts: any,
  permission: any,
}

export const BrandDetail: React.FC<BrandDetailProps> = React.memo(({brand, reportCounts }) => {
  const [search, setSearch] = useSearch();
  const firstApp = brand?.data?.apps?.[0];
  const brandId = brand?.data?._id;
  const brandCounts = reportCounts?.brands?.[brandId];
  const isLoaded = brand?.status === 'DETAIL_FETCHED'

  return <div className={styles.detail}>
    <DetailInfoQuadrant stringType="Brands" data={brand} counts={brandCounts} />
    <Images urls={firstApp?.screenshotUrls} search={search} setSearch={setSearch} loaded={isLoaded} />
    <Description description={firstApp?.description} />
  </div>
})

interface AppDetailProps {
  app: any,
  permission: any,
  updateApp: any,
  reportCounts: any
}

export const AppDetail: React.FC<AppDetailProps> = React.memo(({app, permission, updateApp, reportCounts }) => {
  const [search, setSearch] = useSearch();
  const appId = app?.data?.metaId;
  const appCounts = reportCounts?.apps?.[appId];
  
  const isLoaded = app?.status === 'DETAIL_FETCHED'
  return <div className={styles.detail}>
    <DetailInfoQuadrant data={app} counts={appCounts} />
    <Images urls={app?.data?.screenshotUrls} search={search} setSearch={setSearch} loaded={isLoaded} />
    <Description description={app?.data?.description} />
    {permission.isSuperAdmin() && app?.data?.appProvider?.startsWith('csv') && <div className={styles.brands}>
      {sources.map(source => {
        if(source?.provider?.startsWith('csv')) {
          const className = [
            styles.brand,
            (app.data.appProvider === source.provider) ? styles.selected : null,
          ].join(' ');
          const style:any = source.style
          return <div className={className} key={source.provider} onClick={() => updateApp({appProvider: source.provider})}>
            <BrandSource name={source.name} icon={source.icon} style={style} />
          </div>
        } else return null;
      })}
    </div>}
  </div>
})

export default BrandDetail;