import React from 'react';
import { useLocation, useNavigate } from '@reach/router'
import { parse as queryParse, stringify as queryStringify } from 'query-string'


export const useSearch = (): any[] => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationPathname = location?.pathname;
  const locationSearch = location?.search;

  const search = React.useMemo(() => {
    if(locationSearch) return queryParse(locationSearch);
    else return {}
  }, [locationSearch])

  const setSearch = React.useCallback((dataOrFn: any) => {
    if(navigate == null) return;

    const data = (typeof dataOrFn === 'function') ? dataOrFn(search) : dataOrFn;
    const newSearch = queryStringify(data)
    if(newSearch) navigate(`${locationPathname}?${newSearch}`)
    else navigate(locationPathname)
  }, [locationPathname, search, navigate])

  return [search, setSearch];
}

export default useSearch