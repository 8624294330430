import {Me} from './models/me.model'
export class Permission {
  me: Me

  constructor(me: Me) {
    this.me = me;
  }

  isSuperAdmin = () => {
    if(this.me == null) return false;
    if(this.me.isAdmin) return true;
    else return false;
  }
  canCreateReport = () => {
    // HACK
    return true;
    // if(this.me == null) return false;
    // if(this.me.isAdmin) return true;
    // if(this.me.permissions?.canCreateReport) return true;
    // return false;
  }
  canDeleteReport = (report?: any) => {
    // HACK
    return true;
    // if(this.me == null) return false;
    // if(this.me.isAdmin) return true;
    // if(this.me.permissions?.canCreateReport) return true;
    // if(report?.userId === this.me._id) return true;
    // return false;
  }
  canModifyExecSummary = () => {
    if(this.me == null) return false;
    if(this.me.isAdmin) return true;
    else return false;
  }
  canModifyLibraryConcepts = () => {
    if(this.me == null) return false;
    if(this.me.isAdmin) return true;
    else return false;
  }
  canModifyConcepts = (report?: any) => {
    // HACK
    return true;
    // if(this.me == null) return false;
    // if(this.me.isAdmin) return true;
    // if(report == null) return false;
    // if(report.userId === this.me._id) return true;

    // const colab = report?.collaborators?.find((c: any) => c.userId === this.me._id);
    // if(colab?.permission === 'a' || colab?.permission === 'w') return true;

    // return false;
  }
  canMachineLearn = (report?: any) => {
    // HACK
    return true;
    // if(this.me == null) return false;
    // if(this.me.isAdmin) return true;
    // if(report == null) return false;
    // if(report.userId === this.me._id) return true;

    // const colab = report?.collaborators?.find((c: any) => c.userId === this.me._id);
    // if(colab?.permission === 'a' || colab?.permission === 'w') return true;

    // return false;
  }
  canModifyBrand = (report?: any) => {
    // HACK
    return true;
    // if(this.me == null) return false;
    // if(this.me.isAdmin) return true;
    // if(report == null) return false;
    // if(report.userId === this.me._id) return true;

    // const colab = report?.collaborators?.find((c: any) => c.userId === this.me._id);
    // if(colab?.permission === 'a' || colab?.permission === 'w') return true;

    // return false;
  }

  canModifyUsers = (report?: any) => {
    if(this.me == null) return false;
    if(this.me.isAdmin) return true;
    if(report == null) return false;
    if(report.userId === this.me._id) return true;

    const colab = report?.collaborators?.find((c: any) => c.userId === this.me._id);
    if(colab?.permission === 'a' ) return true;

    return false;
  }

  canComment = (report?: any) => {
    // HACK
    return true;
    // if(this.me == null) return false;
    // if(this.me.isAdmin) return true;
    // if(report == null) return false;
    // if(report.userId === this.me._id) return true;

    // const colab = report?.collaborators?.find((c: any) => c.userId === this.me._id);
    // if(colab?.permission === 'a' || colab?.permission === 'w') return true;

    // return false;
  }
}

export default Permission