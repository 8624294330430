import { Link } from "@reach/router";
import React from 'react';
import { Loading } from '../loading/loading';
import { useConceptsContext, useReportsContext } from '../../contexts';
import { useMedia, useSearch } from '../../hooks';
import { useReportLogEffect } from '../../libs/react-amplitude';
import { getSortedBrands, splitOutBadBrands } from '../../utils';
import { conceptIconByName } from '../../utils2';
import ConceptCell from '../cells/conceptCell';
import ConceptHeaderCell from '../cells/conceptHeaderCell';
import HeaderCell from '../cells/headerCell';
import NameCell from '../cells/nameCell';
import ReviewCountCell from '../cells/reviewCountCell';
import ScoreCell from '../cells/scoreCell';
import styles from './reportBrands.module.scss';

interface BrandCellProps {
  brand: any,
  dataCounts: any,
  uri: any,
  demoReportId: any,
  demoMapping: any,
  concepts: any[]
}

const BrandCell: React.FC<BrandCellProps> = ({brand, dataCounts, uri, demoReportId, demoMapping, concepts }) => {
  if(brand == null) return null;
  if(brand.data == null) return null;
  const brandData = brand.data;
  const isDemo = demoMapping?.brandId === brandData._id;

  const counts = dataCounts?.brands?.[brandData._id];
  return <div className={styles.row}>
    <NameCell name={brandData.name} imageUrl={brandData.imageUrl} url={`${uri}/${brandData._id}`} demoId={demoReportId} isDemo={isDemo} dominantColor={brandData.dominantColor} />
    <ScoreCell score={counts?.categoryScore} demoId={demoReportId} isDemo={isDemo} concepts={concepts} />
    <ReviewCountCell count={counts?.counts?.processed} demoId={demoReportId} isDemo={isDemo} />

    {concepts?.map(cat => {
      const categoryCounts = dataCounts?.brandsByCategory?.[cat._id];
      const brandCount = categoryCounts?.[brandData._id];
      const brands = cat?.brands || [];
      return <ConceptCell key={cat._id} data={brandCount} total={brands.length} demoId={demoReportId} isDemo={isDemo} concept={cat} />
    })}
  </div>
}

interface ReportBrandsProps {
  report?: any,
  location?: any,
  uri?: any,
  concepts?: any[],
  demoReportId?: string,
  sharedReportId?: string,
  path?: string,
  conceptsById?: any,
}

const ReportBrands: React.FC<ReportBrandsProps> = ({report, location, uri, demoReportId, sharedReportId, concepts}) => {
  useReportLogEffect({eventType: 'viewTLDR', report, location, demoReportId, shared: sharedReportId != null}, [report]);
  const [{brandsById}] = useReportsContext();
  const [{fetchedConcepts}] = useConceptsContext();
  const [search, setSearch] = useSearch();
  const reportBrandIds = report?.data?.brandIds;
  const dataCounts = report?.dataCounts;

  const [brands, lowRatingBrands] = React.useMemo(() => {
    const sortedBrandIds = getSortedBrands(reportBrandIds, brandsById, dataCounts, search.sort, search.sortOrder);
    const sortedBrands = sortedBrandIds?.map((id: any) => brandsById?.[id]);
    return splitOutBadBrands(sortedBrands, dataCounts);
  }, [search.sort, search.sortOrder, reportBrandIds, brandsById, dataCounts])

  const clickHeader = (sortKey: any) => {
    if(sortKey === search.sort && search.sortOrder === '1') setSearch({sort: sortKey, sortOrder: -1});
    else if(sortKey === search.sort && search.sortOrder === '-1') setSearch({});
    else setSearch({sort: sortKey, sortOrder: 1});
  }
  const getSortOrder = (k: any) => (search.sort === k) ? Number.parseInt(search.sortOrder) : null;

  const conceptsToScore = concepts?.filter(c => c.countInScore);
  const columnCount = 3 + (conceptsToScore?.length || 0);
  const demoMapping = report?.demoMapping;

  const style: any = {'--column-count': columnCount}

  return <div className={styles.container} style={style}>
    {!fetchedConcepts && <Loading />}
    {fetchedConcepts && <div className={styles.grid}>
      <ReportBrandsHeader concepts={conceptsToScore} clickHeader={clickHeader} getSortOrder={getSortOrder} />
        {brands.map(brand => <BrandCell key={brand?.data?._id}
          brand={brand}
          dataCounts={dataCounts}
          uri={uri}
          demoReportId={demoReportId}
          demoMapping={demoMapping}
          concepts={conceptsToScore} />)}

        {/* {!demoReportId && lowRatingBrands.length > 0 && <div className={styles.labelRow}>Brands below have too few number of reviews to be ranked properly (but we did our best)</div>} */}

        {lowRatingBrands.map(brand => <BrandCell key={brand?.data?._id}
          brand={brand} 
          uri={uri}
          dataCounts={dataCounts}
          demoReportId={demoReportId}
          demoMapping={demoMapping}
          concepts={conceptsToScore} />)}
        {demoReportId && <Link to='/demo' className={styles.demoButton}>Schedule a demo to unlock more</Link>}
    </div>}
  </div>
}

export default ReportBrands;

interface ReportBrandsHeaderProps {
  clickHeader: any,
  concepts: any[],
  getSortOrder: any
}
const ReportBrandsHeader: React.FC<ReportBrandsHeaderProps> = ({clickHeader, concepts, getSortOrder}) => {
  const isScreenLarge = useMedia('(min-width: 1200px)');

  return <div className={styles.headerRow}>
    <HeaderCell sortOrder={getSortOrder('name')}
      name='name'
      title='Brand Name'
      onClick={clickHeader} />
    <HeaderCell sortOrder={getSortOrder('score')}
      name='score'
      title='Score' onClick={clickHeader}
      tooltip={!isScreenLarge ? "filter by score" : null} />
    <HeaderCell sortOrder={getSortOrder('reviews')}
      name='reviews'
      title={isScreenLarge ? '# reviews' : "#"}
      onClick={clickHeader}
      tooltip={!isScreenLarge ? 'filter by # of reviews' : null} />

    {concepts?.map(c => <ConceptHeaderCell sortOrder={getSortOrder(c._id)}
      key={c._id}
      id={c._id}
      title={c.name}
      icon={conceptIconByName[c.name]}
      onClick={clickHeader}
      tooltip={!isScreenLarge ? `filter by ${c.name}` : null} />
    )}
  </div>
}