import React from 'react'
import { initialState, reducer, ContextPropsState } from './enhancedUsers.reducer'

// '${name} twitter OR facebook OR linkedin OR email OR snapchat OR @"${name}"'

type ContextPropsActions = {
  reset: () => void,
}

const initialActions: ContextPropsActions = {
  reset: () => { throw new Error("reset not implemented") }
}

type ContextProps = [ContextPropsState, ContextPropsActions]

export const EnhancedUsersContext = React.createContext<ContextProps>([initialState, initialActions]);

export const EnhancedUsersProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const reset = React.useCallback( () => dispatch({ type: 'RESET' }), [])

  const value:ContextProps = [
    state,
    {
      reset,
    }
  ]

  return <EnhancedUsersContext.Provider value={value}>
    {children}
  </EnhancedUsersContext.Provider>
}

export const useEnhancedUsersContext = () => React.useContext(EnhancedUsersContext)