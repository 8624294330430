import React, { createContext } from 'react'

import { useAuthContext } from './auth.context'
import { AmplitudeContext } from '../libs/react-amplitude'
import { fetchUrl, headersWithXToken } from './utils'
import { reducer, initialState, ContextPropsState } from './concepts.reducer'


type ContextPropsActions = {
  fetchConceptsForReport: (reportId: string) => void,
  fetchConceptsLibrary: () => void,

  addConceptsToLibrary: (body: any) => void,
  updateConceptsToLibrary: (conceptId: string, body: any) => void,
  deleteConceptFromLibrary: (conceptId: string) => void,
  updateConceptsOrder: (reportId: string, ids: string[]) => void,

  addConceptGroup: (body: any) => void,
  deleteConceptGroup: (conceptGroupId: string, body?: any) => void,
  updateConceptGroup: (conceptGroupId: string, body?: any) => void,

  createConcept: (reportId: string, body: any) => void,
  updateConcepts: (reportId: string, categoryId: string, body: any) => void,
  deleteConcept: (reportId: string, categoryId: string) => void,
  deleteConcepts: (reportId: string, categoryIds: string[]) => void,

  addWordToConcepts: (reportId: string, categoryId: string, word: string, aspect: string) => void,
  deleteWordFromConcepts: (reportId: string, categoryId: string, word: string, aspect: string) => void,

  fetchSimilarWordsConcepts: (reportId: string, categoryId: string) => void,
  fetchSimilarTerms: (reportId: string, categoryId: string, word: string, useGlobal: boolean) => void,

  fetchReportSuggestConcepts: (reportId: string) => void,
  reset: () => void,
}

const initialActions: ContextPropsActions = {
  fetchConceptsForReport: () => { throw new Error("fetchConceptsForReport not implemented") },
  fetchConceptsLibrary: () => { throw new Error("fetchConceptsLibrary not implemented") },

  addConceptsToLibrary: () => { throw new Error("addConceptsToLibrary not implemented") },
  updateConceptsToLibrary: () => { throw new Error("updateConceptsToLibrary not implemented") },
  deleteConceptFromLibrary: () => { throw new Error("deleteConceptFromLibrary not implemented") },
  updateConceptsOrder: () => { throw new Error("updateConceptsOrder not implemented") },

  addConceptGroup: () => { throw new Error("addConceptGroup not implemented") },
  deleteConceptGroup: () => { throw new Error("deleteConceptGroup not implemented") },
  updateConceptGroup: () => { throw new Error("updateConceptGroup not implemented") },

  createConcept: () => { throw new Error("createConcept not implemented") },
  updateConcepts: () => { throw new Error("updateConcepts not implemented") },
  deleteConcept: () => { throw new Error("deleteConcept not implemented") },
  deleteConcepts: () => { throw new Error("deleteConcepts not implemented") },

  addWordToConcepts: () => { throw new Error("addWordToConcepts not implemented") },
  deleteWordFromConcepts: () => { throw new Error("deleteWordFromConcepts not implemented") },

  fetchSimilarWordsConcepts: () => { throw new Error("fetchSimilarWordsConcepts not implemented") },
  fetchSimilarTerms: () => { throw new Error("fetchSimilarTerms not implemented") },

  fetchReportSuggestConcepts: () => { throw new Error("fetchReportSuggestConcepts not implemented") },

  reset: () => { throw new Error("reset not implemented") }
}

type ContextProps = [ContextPropsState, ContextPropsActions]

export const ConceptsContext = createContext<ContextProps>([initialState, initialActions]);

export const ConceptsProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [{ user }] = useAuthContext();
  const { amplitudeInstance } = React.useContext(AmplitudeContext);
  
  const fetchConceptsForReport = React.useCallback( async (reportId) => {
    if (reportId == null) return null;
    try {
      dispatch({ type: 'FETCH_REPORT_CONCEPTS_START'});
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/categories`;
      const data = await fetchUrl('GET', url, { headers });
      dispatch({ type: 'FETCH_REPORT_CONCEPTS', reportId, ...data })
    } catch (error) { console.error('fetchConceptsForReport', error); }
  }, [amplitudeInstance, user])

  const fetchConceptsLibrary = React.useCallback( async () => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/admin/concepts`
      const data = await fetchUrl('GET', url, { headers });
      dispatch({ type: 'FETCH_LIBRARY_CONCEPTS', data })
      return data;
    } catch (error) { console.error('fetchConceptsLibrary', error); }
  }, [amplitudeInstance, user])
  
  const addConceptsToLibrary = React.useCallback( async (body) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/admin/concepts`
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({ type: 'ADD_LIBRARY_CONCEPT', data: data.concept })
      return data;
    } catch (error) { console.error('addConceptsToLibrary', error); }
  }, [amplitudeInstance, user])

  const updateConceptsToLibrary = React.useCallback( async (conceptId, body) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/admin/concepts/${conceptId}`;
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({ type: 'UPDATE_LIBRARY_CONCEPT', conceptId, ...data })
      return data;
    } catch (error) { console.error('updateConceptsToLibrary', error); }
  }, [amplitudeInstance, user])
  const deleteConceptFromLibrary = React.useCallback( async (conceptId) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/admin/concepts/${conceptId}`;
      const data = await fetchUrl('DELETE', url, { headers });
      dispatch({ type: 'DELETE_LIBRARY_CONCEPT', conceptId })
      return data;
    } catch (error) { console.error('deleteConceptFromLibrary', error); }
  }, [amplitudeInstance, user])
  const updateConceptsOrder = React.useCallback( async (reportId, ids) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/categories/reorder`;
      const data = await fetchUrl('POST', url, { headers, body: {ids} });
      console.log(data?.categories?.map((c: any) => ({id: c._id, name: c.name})));
      console.log(ids);
      dispatch({ type: 'FETCH_REPORT_CONCEPTS', reportId, ...data })
      return data;
    } catch (error) { console.error('updateConceptsOrder', error); }
  }, [amplitudeInstance, user])

  const addConceptGroup = React.useCallback( async (body) => {
    if(body == null || user == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/admin/conceptGroups`;
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({type: 'UPDATE_LIBRARY_GROUP', data: data.concept});
      return data;
    } catch (error) { console.error('addConceptGroup', error); }
  }, [amplitudeInstance, user])

  const deleteConceptGroup = React.useCallback( async (conceptGroupId) => {
    if(user == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/admin/conceptGroups/${conceptGroupId}`;
      const data = await fetchUrl('DELETE', url, { headers });
      dispatch({type: 'DELETE_LIBRARY_GROUP', conceptGroupId});
      return data;
    } catch (error) { console.error('addConceptGroup', error); }
  }, [amplitudeInstance, user])

  const updateConceptGroup = React.useCallback( async (conceptGroupId, body) => {
    if(body == null || user == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/admin/conceptGroups/${conceptGroupId}`;
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({type: 'UPDATE_LIBRARY_GROUP', data: data.concept});
      return data;
    } catch (error) { console.error('updateConceptGroup', error); }
  }, [amplitudeInstance, user])

  const createConcept = React.useCallback( async (reportId, body) => {
    if(reportId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/categories`;
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({ type: 'ADD_REPORT_CONCEPTS', reportId, data })
      return data;
    } catch (error) {
      console.error('createConcept', error)
    }
  }, [amplitudeInstance, user])
  const updateConcepts = React.useCallback( async (reportId, categoryId, body) => {
    if(reportId == null || categoryId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/categories/${categoryId}`;
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({ type: 'UPDATE_REPORT_CONCEPTS', reportId, categoryId, category: data })
    } catch (error) {
      console.error('updateConcepts', error)
    }
  }, [amplitudeInstance, user])
  const deleteConcept = React.useCallback( async (reportId, categoryId) => {
    if(reportId == null || categoryId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/categories/${categoryId}`;
      const data = await fetchUrl('DELETE', url, { headers });
      dispatch({ type: 'DELETE_REPORT_CONCEPT', reportId, categoryId });
      return data;
    } catch (error) {
      console.error('deleteConcept', error)
    }
  }, [amplitudeInstance, user])
  const deleteConcepts = React.useCallback( async (reportId, categoryIds) => {
    if(reportId == null || categoryIds == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/categories`;
      const data = await fetchUrl('DELETE', url, { headers, body: {categoryIds} });
      dispatch({ type: 'DELETE_REPORT_CONCEPTS', reportId, categoryIds });
      return data;
    } catch (error) {
      console.error('deleteConcept', error)
    }
  }, [amplitudeInstance, user])

  const addWordToConcepts = React.useCallback( async (reportId, categoryId, word, aspect) => {
    if(reportId == null || categoryId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      let url = `/api/reports/${reportId}/categories/${categoryId}/word`;
      if(aspect) {
        url = `/api/reports/${reportId}/categories/${categoryId}/aspect/${aspect}/word`;
      }
      const data = await fetchUrl('POST', url, { headers, body: { word } });
      dispatch({ type: 'UPDATE_WORD_TO_CONCEPT', reportId, category: data })
    } catch (error) {
      console.error('addWordToConcepts', error)
    }
  }, [amplitudeInstance, user])
  const deleteWordFromConcepts = React.useCallback( async (reportId, categoryId, word, aspect) => {
    if(reportId == null || categoryId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      let url = `/api/reports/${reportId}/categories/${categoryId}/word`;
      if(aspect) {
        url = `/api/reports/${reportId}/categories/${categoryId}/aspect/${aspect}/word`;
      }
      const data = await fetchUrl('DELETE', url, { headers, body: { word } });
      dispatch({ type: 'UPDATE_WORD_TO_CONCEPT', reportId, category: data })
    } catch (error) {
      console.error('deleteWordFromConcepts', error)
    }
  }, [amplitudeInstance, user])

  const fetchSimilarWordsConcepts = React.useCallback( async (reportId, categoryId) => {
    if(reportId == null || categoryId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/categories/${categoryId}/suggest`;
      const data = await fetchUrl('GET', url, { headers });
      dispatch({ type: 'FETCH_SIMILAR_TERMS', reportId, categoryId, data })
    } catch (error) {
      console.error('fetchSimilarWordsConcepts', error)
    }
  }, [amplitudeInstance, user])

  const fetchSimilarTerms = React.useCallback( async (reportId, categoryId, word, useGlobal) => {
    if(reportId == null || categoryId == null) return;
    try {
      if (word?.length > 0) {
        const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
        const url = `/api/reports/${reportId}/terms/${word}/similar`;
        const data = await fetchUrl('GET', url, { headers, body: {useGlobal} });
        dispatch({ type: 'FETCH_SIMILAR_TERMS', reportId, categoryId, data })
      } else {
        dispatch({ type: 'FETCH_SIMILAR_TERMS', reportId, categoryId, data: [] })
      }
    } catch (error) {
      console.error('fetchSimilarTerms', error)
    }
  }, [amplitudeInstance, user])

  const fetchReportSuggestConcepts = React.useCallback( async (reportId) => {
    if(reportId == null || user == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/categories/suggest`;
      const data = await fetchUrl('GET', url, { headers });
      dispatch({ type: 'FETCH_REPORT_SUGGEST_CONCEPTS', reportId, data: data.categories })
    } catch (error) {
      console.error('fetchReportSuggestConcepts', error)
    }
  }, [amplitudeInstance, user])

  const reset = React.useCallback( () => dispatch({ type: 'RESET' }), [])

  const value: ContextProps = [
    state,
    {
      fetchConceptsForReport,
      fetchConceptsLibrary,

      addConceptsToLibrary,
      updateConceptsToLibrary,
      deleteConceptFromLibrary,
      updateConceptsOrder,

      addConceptGroup,
      deleteConceptGroup,
      updateConceptGroup,

      createConcept,
      updateConcepts,
      deleteConcept,
      deleteConcepts,

      addWordToConcepts,
      deleteWordFromConcepts,

      fetchSimilarWordsConcepts,
      fetchSimilarTerms,

      fetchReportSuggestConcepts,

      reset,
    }
  ]

  return <ConceptsContext.Provider value={value}>
    {children}
  </ConceptsContext.Provider>
}

export const useConceptsContext = () => React.useContext(ConceptsContext)