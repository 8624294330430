import React from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import {Link} from '@reach/router'
import firebase from 'firebase/compat/app';

import { useAuthContext } from '../../contexts'
import Input from '../../components/input/input'
import { Button } from '../../components/button/button'
import {ReportsLeftNav} from '../../components/leftNav/leftNav'


import styles from './profile.module.scss'

const Profile = React.memo(() => {
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const [state] = useAuthContext();

  const handleSubmit = async (e: any) => {
    if(e) e.preventDefault();
    try {
      await firebase.auth().sendPasswordResetEmail(state?.user?.email)
      
    } catch(error) {
    }
  }

  const style: any = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)'
  }

  return <div className={styles.containerWrap} style={style}>
    <ReportsLeftNav />
    <div className={styles.container}>
      <form className={styles.content} onSubmit={handleSubmit}>
      <h1 className={styles.heading}>Profile and Settings</h1>
        <Input placeholder="Please enter your email" title="Email" value={state.user.email || ''} disabled onChange={() => { }} />
        <Button title="Reset password" tagName="button" />
      </form>
      
      <div className={styles.content}>
        <Link className={styles.logoutButton} to="/logout">Logout</Link>
      </div>
    </div>
  </div>
})

export default Profile