import React from 'react'

import { getDetailRangeColor, getScore } from '../../utils'
import Icon from '../../components/icon/icon'
import Tooltip from '../../libs/context-tooltip/tooltip'
import Loading from '../../components/loading/loading'
import SelectBox from '../../components/selectBox/selectBox'
import Card from '../card/card'

import styles from './heatmapCard.module.scss'

interface HeatmapCardProps {
  report: any,
  concepts: any,
  filteredBrandIds: any,
  demoReportId: any,
  filter: any,
  setFilter: any,
  brandsById: any,
}

export const HeatmapCard: React.FC<HeatmapCardProps> = ({report, concepts, filteredBrandIds, demoReportId, filter, setFilter, brandsById}) => {
  const selectFiltes = report?.data?.filters;

  if(report == null || report.data == null) return <LoadingCard />
  if(concepts == null) return <LoadingCard />
  if(report.dataCounts == null) return <LoadingCard />

  let filters = null;
  if(selectFiltes?.length > 1) {
    filters = <SelectBox options={selectFiltes} value={filter} onChange={(e: any) => setFilter(e.target.value)} borderless />
  }

  return <Card auto full header="Sentiment Heatmap"
    description="Displays relative distribution of verbatim sentiment across consumer opinion"
    fileName={`${report?.data?.name}_heatmap.png`}
    filters={filters}>
      <HeatmapChart report={report} concepts={concepts} filteredBrandIds={filteredBrandIds} demoReportId={demoReportId} brandsById={brandsById} />
  </Card>
}

const LoadingCard = () => <Card full header="Sentiment Heatmap" description="Displays relative distribution of verbatim sentiment across consumer opinion">
  <Loading />
</Card>

export default HeatmapCard;


interface HeatmapChartProps {
  report: any,
  concepts: any[],
  filteredBrandIds: any,
  demoReportId: any,
  brandsById: any
}

const HeatmapChart: React.FC<HeatmapChartProps> = React.memo(({report, concepts, filteredBrandIds, demoReportId, brandsById}) => {
  const reportBrandIds: any[] = report?.data?.brandIds;
  const brandsByCategory = report?.dataCounts?.brandsByCategory;
  const filteredBrands = React.useMemo(() => {
    return reportBrandIds.filter(brandId => {
      if(filteredBrandIds != null && filteredBrandIds.indexOf(brandId) < 0) return false;
      else return true;
    })
  }, [reportBrandIds, filteredBrandIds]);
  
  return <div className={styles.container}>
    {concepts?.map(concept => {
      const brandsScores = brandsByCategory?.[concept._id];
      filteredBrands && filteredBrands.sort( (a, b) => {
        const aBrandScore = brandsScores?.[a];
        const bBrandScore = brandsScores?.[b];
        const aRankValue = aBrandScore?.ratingRank?.value;
        const bRankValue = bBrandScore?.ratingRank?.value;
        return bRankValue - aRankValue;
      })
      return <React.Fragment key={concept._id}>
        <div className={styles.name}>{concept.name}</div>
        <div className={styles.row}>
          {filteredBrands?.map((brandId, i) => {
            const brand = brandsById?.[brandId];
            const brandData = brand?.data;
            const brandScore = brandsScores?.[brandId];
            const rankValue = brandScore?.ratingRank?.value;
            const rankOutOf = brandScore?.ratingRank?.outOf;
            const score = brandScore && getScore(brandScore.categoryScore);
            const color = getDetailRangeColor(score);
            if(score == null) return null;
            const isDemo = report?.demoMapping?.brandId === brandId;
            const visible = (rankValue === 1) || (rankValue === rankOutOf) || isDemo || (filteredBrandIds?.length === 1) || ( filteredBrandIds != null && (i === 0 || (filteredBrandIds.length - 1) === i) )
            const showIcon = demoReportId == null || isDemo;

            const style = {'--color': color, left: `${score}%`}

            return <React.Fragment key={brandId}>
              <div className={styles.circle} style={style} />
              {showIcon && <Tooltip content={<CircleTooltip score={score} brand={brandData} />}
                tagName={Icon}
                className={[styles.icon, visible ? styles.visible : null].join(' ')}
                style={style}
                src={brandData?.imageUrl} rounded />}
            </React.Fragment>
          })}
        </div>
      </React.Fragment>
    })}
  </div>
})


interface CircleTooltipProps {
  brand: any,
  score: number,
}

const CircleTooltip: React.FC<CircleTooltipProps> = ({brand, score}) => <div className={styles.circleTooltipRow}>
  <Icon src={brand?.imageUrl} rounded className={styles.circleTooltipRowIcon} background={brand.dominantColor} />
  <div className={styles.circleTooltipRowTitle}>{brand?.name}</div>
  <div className={styles.circleTooltipRowScore}>{score}%</div>
</div>