import React from 'react'

import styles from './footerBar.module.scss'

interface FooterBarProps {
  showIntro: boolean,
  showResults: boolean,
  index: number,
  length: number
}

export const FooterBar: React.FC<FooterBarProps> = ({showIntro, showResults, index, length}) => {
  return <footer className={[styles.footerBar, (showResults && index === (length - 1)) ? styles.hidden : null].join(' ')}>
    <div style={{width: `${100*(index/(length + (showIntro ? -1 : 0)))}%`}} />
  </footer>
}

export default FooterBar