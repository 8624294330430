import React from 'react';
import { Router, Redirect } from '@reach/router'
import amplitude from "amplitude-js";

import { AmplitudeProvider } from "./libs/react-amplitude";

import { Providers } from './contexts'
import { Login, Signup, ForgotPassword, Logout } from './containers/auth'
import Profile from './containers/profile/profile'
import Report from './containers/report/report'
import DraftReport from './containers/draftReport/draftReport'
import DraftReport2 from './containers/draftReport2/draftReport'
import Concepts from './containers/concepts/concepts'
import ConceptGroups from './containers/conceptGroups/conceptGroups'
import { ToastProvider } from './libs/context-toast/toast'

import {namespaceStyle} from './utils'

import Reports from './containers/reports/reports'
import Users from './containers/users/users'
import ScheduleDemo from './containers/scheduleDemo/scheduleDemo'
import DemoSignup from './containers/demoSignup/demoSignup'
import Presentation from './containers/presentation/presentation'
import {SuperadminRoute, OnlyUnauthed, AuthedRoute} from './components/auth/routes'

import WeUseCookies from './components/weUseCookies/weUseCookies'

import styles from './App.module.scss';

const App = () => {
  return <AmplitudeProvider amplitudeInstance={amplitude.getInstance()} apiKey={process.env.REACT_APP_AMPLITUDE_KEY}>
    <ToastProvider>
      <Providers>
        <Router className={styles.container} style={namespaceStyle()}>
          {/* <Marketing path="/marketing" /> */}
          <AuthedRoute as={Logout} path="/logout" redirectTo="/login" />
          <OnlyUnauthed as={Login} path="/login" redirectTo="/reports" />
          <OnlyUnauthed as={Signup} path="/signup" redirectTo="/reports" />
          <OnlyUnauthed as={ForgotPassword} path="/forgotPassword" redirectTo="/reports" />

          <AuthedRoute as={Profile} path="/profile" redirectTo="/login" />
          <AuthedRoute as={Reports} path="/drafts" drafts redirectTo="/login" />
          <AuthedRoute as={DraftReport2} path="/drafts/:reportId/*" redirectTo="/login" />
          <AuthedRoute as={Reports} path="/reports" redirectTo="/login" />
          <AuthedRoute as={Report} path="/reports/:reportId/*" redirectTo="/login" />
          <AuthedRoute as={DraftReport} path="/draftReport/:reportId/*" redirectTo="/login" />

          <SuperadminRoute as={Concepts} path="/concepts" redirectTo="/login" />
          <SuperadminRoute as={ConceptGroups} path="/conceptGroups" redirectTo="/login" />
          <SuperadminRoute as={Users} path="/users/*" redirectTo="/login" />

          <Report path="/sharedReport/:sharedReportId/*" />
          <Report path="/sample/*" sharedReportId="sample" />
          <Report path="/demo/:demoReportId/*" />

          <DemoSignup path="/demoSignup" />
          <ScheduleDemo path="/demo" />

          <Presentation path="/p/:demoId" />
          <Redirect default from="*" to="/login" noThrow />
        </Router>
        <WeUseCookies />
      </Providers>
    </ToastProvider>
  </AmplitudeProvider>
}

export default App