import React from 'react'
import { useAuthContext, useReportsContext } from '../../contexts'
import Tooltip from '../../libs/context-tooltip/tooltip'
import Icon from '../icon/icon'
import { TrashIcon } from '../icons/icons'
import styles from './users.module.scss'
interface UsersProps extends React.HTMLProps<HTMLDivElement> {
  report: any
}

export const Users: React.FC<UsersProps> = React.memo(({report, className}) => {
  const [{ me, permission }] = useAuthContext();
  const [, {addCollaborators, deleteCollaborator}] = useReportsContext();

  const amICollaborator = report?.collaborators?.find((c: any) => c.userId === me._id);

  const onSelectChange = (e: any) => {
    addCollaborators(report?.data?._id, {permission: e.target.value})
  }

  return <div className={[styles.container, className].join(' ')}>
    <div className={styles.scrollable}>
      <div className={styles.content}>
        <Icon fakeText={me.email[0]} rounded className={styles.icon} />
        <div className={styles.email}>{me.email}</div>
        {!amICollaborator && <div className={styles.userType}>me</div>}
        {amICollaborator && <div className={styles.userType}>{amICollaborator?.permission}</div>}
        <div />

        {report?.data?.collaborators?.map((collaborator: any) => {
          const user = report?.usersById?.[collaborator?.userId];
          if(collaborator.userId === me._id) return null;

          const userEmail = user?.email || user._id;
          return <React.Fragment key={collaborator.userId}>
            <Icon fakeText={userEmail?.[0]} rounded className={styles.icon} />
            <div className={styles.email}>{userEmail}</div>
            {!permission.canModifyUsers(report) && <div className={styles.userType}>{collaborator.permission}</div>}
            {permission.canModifyUsers(report) && <>
              <div className={styles.userType}>
                <select value={collaborator.permission} onChange={onSelectChange} disabled>
                  <option value="a">admin</option>
                  <option value="w">write</option>
                  <option value="r">read</option>
                </select>
              </div>
              <div className={styles.trash} onClick={() => deleteCollaborator(report.data._id, collaborator.userId)}>
                <Tooltip tagName={TrashIcon} content="remove member" />
              </div>
            </>}
            {!permission.canModifyUsers(report) && <div />}
          </React.Fragment>
        })}
        {permission.canModifyUsers(report) && <AddUser onSubmit={(data: any) => addCollaborators(report.data._id, data)} />}
      </div>
    </div>
  </div>
})

export default Users;

interface AddUserProps {
  onSubmit: any
}

const AddUser: React.FC<AddUserProps> = ({onSubmit}) => {
  const [data, setData] = React.useState({email: '', permission: 'r'});

  const submit = (e: any) => {
    if(e) e.preventDefault();
    if(onSubmit) onSubmit(data);
  }
  const onMemberChange = (e: any) => {
    const email = e.target.value;
    setData((data) => ({...data, email}))
  }
  const onSelectChange = (e: any) => {
    const permission = e.target.value;
    setData((data) => ({...data, permission}))
  }

  return <form onSubmit={submit} className={styles.addMember}>
    <div />
    <input placeholder="add member" className={styles.input} value={data.email || ""} onChange={onMemberChange} />
    <div>
      <select value={data.permission} onChange={onSelectChange}>
        <option value="a">admin</option>
        <option value="w">write</option>
        <option value="r">read</option>
      </select>
    </div>
    <input type="submit" value="add" className={styles.button} />
  </form>
}