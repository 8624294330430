import React from 'react'
import {useDebouncedCallback} from 'use-debounce';

import { Chevron3Icon, PinIcon } from '../icons/icons'
import { conceptIconByName } from '../../utils2'

import styles from './conceptHeader.module.scss'
import { useSearch } from '../../hooks';

interface ConceptHeaderProps {
  data: any,
  closed: boolean,
  onUpdateConcept: any,
  autoFocus?: boolean
}

export const ConceptHeader: React.FC<ConceptHeaderProps> = ({data, closed, onUpdateConcept, autoFocus}) => {
  const [search, setSearch] = useSearch();
  const input = React.useRef<HTMLInputElement>();
  const dataName = data?.name;
  const [internalValue, setInternalValue] = React.useState(dataName);

  const debounced = useDebouncedCallback(value => onUpdateConcept(data?._id, {name: value}), 600)
  React.useEffect(() => { setInternalValue(dataName); }, [dataName]);


  const togglePin = (cId: any) => {
    setSearch((s: any) => ({
      ...s,
      pinnedConcept: (s.pinnedConcept === cId) ? undefined : cId,
      addConcept: (s.pinnedConcept === cId) ? s.addConcept : undefined,
    }));
  }
  const toggleOpened = () => setSearch((s: any) => ({ ...s, hideDetail: closed ? undefined : null }))

  const onInputChange = (e: any) => {
    debounced.callback(e.target.value);
    setInternalValue(e.target.value);
  }

  const formSubmit = (e: any) => {
    e.preventDefault();
    input?.current?.blur();
  }

  const icon = conceptIconByName[data?.name];
  const isPinned = (data?._id != null) && data?._id === search?.pinnedConcept;
  
  return <div className={[styles.header, !closed ? styles.opened : null, togglePin ? styles.togglePin : undefined].join(' ')}>
    {data != null && <>
      {icon && <div className={styles.icon}>
        {React.createElement(icon)}
      </div>}
      {!onUpdateConcept && <div className={[styles.title, styles.hoverable].join(' ')}>{data?.name}</div>}
      {onUpdateConcept && <form className={[styles.title, styles.hoverable].join(' ')} onSubmit={formSubmit}>
        <input placeholder="Concept title" ref={input} value={internalValue || ""} autoFocus={autoFocus} onChange={onInputChange} />
      </form>}
      {togglePin && <div className={[styles.pin, isPinned ? styles.selected : null].join(' ')} onClick={() => togglePin(data?._id)}>
        <PinIcon />
      </div>}
      <div className={styles.chevron} onClick={toggleOpened}>
        <Chevron3Icon />
      </div>
    </>}
  </div>
}

export default ConceptHeader;