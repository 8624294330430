import React from 'react'
import {Link, Match} from '@reach/router'

import {useKey} from '../../hooks/useKey'
import { useAuthContext } from '../../contexts'

import { ChevronIcon } from '../icons/icons'
import { Admin, Madden, Comment, Search, Dashboard } from '../../toneIcons'
import Icon from '../icon/icon'

import styles from './reportFooter.module.scss'

interface ReportFooterProps {
  report: any,
  brandsById: any,
  uri: any,
  children: any
}

export const ReportFooter: React.FC<ReportFooterProps> = ({report, brandsById, uri, children}) => {
  const [{permission}] = useAuthContext();

  const isSuperAdmin = permission.isSuperAdmin();
  const canComment = permission.canComment();

  const reportBrandIds = report?.data?.brandIds;

  let steps = [
    isSuperAdmin ? { icon: <Admin />, text: 'Admin', to: 'admin' } : null,
    { icon: <Madden />, text: 'Insights', to: './' },
    canComment ? { icon: <Comment />, text: 'Comments', to: 'comments' } : null,
    isSuperAdmin ? { icon: <Search />, text: 'Semantic Search', to: 'semanticSearch' } : null,
    isSuperAdmin ? { icon: <Dashboard />, text: 'Compare: Matrix', to: 'brands' } : null,
  ].filter(a => a);

  if(reportBrandIds) {
    const brandSteps = reportBrandIds.map((brandId: any) => {
      const brand = brandsById?.[brandId];
      if(brand == null) return null;

      return {
        imageUrl: brand.data.imageUrl,
        text: brand.data.name,
        to: `brands/${brand.data._id}`
      }
    }).filter((a: any) => a);
    steps = steps.concat(brandSteps);
  }

  return <div className={styles.container}>
    {steps?.map((step, index) => <Match path={step.to} key={index}>
      {props => {
        return props.match ? <PrevStep step={steps[index-1]} uri={uri} navigate={props.navigate} location={props.location} /> : null}
      }
    </Match>)}
    {children}
    {steps?.map((step, index) => <Match path={step.to} key={index}>
      {props => props.match ? <NextStep step={steps[index+1]} uri={uri} navigate={props.navigate} location={props.location} /> : null}
    </Match>)}
  </div>
}

interface NextStepProps {
  step: any,
  navigate: any,
  uri: any,
  location: any,
}

const NextStep: React.FC<NextStepProps> = ({step, navigate, uri, location}) => {
  const toStep = step ? [uri, (step.to && !step.to.startsWith('.') ? (step.to + location.search) : null) ].filter(a=>a).join('/') : null;
  useKey(['ArrowRight'], (e: any) => {
    if(e.target.tagName === "INPUT") return null;
    if(e.target.tagName === "TEXTAREA") return null;
    if(e.metaKey || e.shiftKey || e.ctrlKey) return null
    if(toStep && navigate) navigate(toStep);
  }, [toStep, navigate]);
  if(step == null) return <div />;

  return <Link to={`${step.to}${location.search}`} className={styles.footerButton}>
    {step.imageUrl && <Icon className={styles.footerIcon} src={step.imageUrl} />}
    {step.icon && React.cloneElement(step.icon, {className: styles.footerIcon})}
    {step.text && <div className={styles.footerText}>{step.text}</div>}
    <ChevronIcon className={styles.footerNextChevron} />
  </Link>;
}

interface PrevStepProps {
  step: any,
  navigate: any,
  uri: any,
  location: any,
}

const PrevStep: React.FC<PrevStepProps> = ({step, navigate, uri, location}) => {
  const toStep = step ? [uri, (step.to && !step.to.startsWith('.') ? (step.to + location.search) : null) ].filter(a=>a).join('/') : null;
  useKey(['ArrowLeft'], (e: any) => {
    if(e.target.tagName === "INPUT") return null;
    if(e.target.tagName === "TEXTAREA") return null;
    if(e.metaKey || e.shiftKey || e.ctrlKey) return null
    if(toStep && navigate) navigate(toStep);
  }, [toStep, navigate]);
  if(step == null) return <div />;

  return <Link to={`${step.to}${location.search}`} className={styles.footerButton}>
    <ChevronIcon className={styles.footerPrevChevron} />
    {step.imageUrl && <Icon className={styles.footerIcon} src={step.imageUrl} />}
    {step.icon && React.cloneElement(step.icon, {className: styles.footerIcon})}
    {step.text && <div className={styles.footerText}>{step.text}</div>}
  </Link>;
}

export default ReportFooter