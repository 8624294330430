import React, { createContext } from 'react'

import { useAuthContext } from './auth.context'
import { AmplitudeContext } from '../libs/react-amplitude'
import { fetchUrl, headersWithXToken } from './utils'
import { reducer, initialState, ContextPropsState } from './reports.reducer'


type ContextPropsActions = {
  fetchReports: (body: any) => void,
  createDraftReport: () => void,
  fetchReport: (reportId: string, intervalType?: any, date?: any) => void,
  fetchSharedReport: (publicReportId: string, body?: any) => void,
  fetchDemoReport: (demoReportId: string, body?: any) => void,
  fetchBrand: (reportId: string, brandId: string, body?: any) => void,
  fetchBrandVoice: (reportId: string, brandId: string, intervalType: any, date: any) => void,
  fetchBrandHistory: (reportId: string, brandId: string, body?: any) => void,
  fetchBrandTfIdf: (reportId: string, brandId: string, intervalType?: any, date?: any) => void,
  fetchPublicBrand: (reportId: string, brandId: string, body?: any) => void,
  fetchPublicBrandVoice: (reportId: string, brandId: string, body?: any) => void,
  fetchPublicBrandHistory: (reportId: string, brandId: string, body?: any) => void,
  fetchDemoBrand: (demoReportId: string, brandId: string, body?: any) => void,
  fetchDemoBrandVoice: (demoReportId: string, brandId: string, body?: any) => void,
  fetchDemoBrandHistory: (demoReportId: string, brandId: string, body?: any) => void,
  fetchApp: (reportId: string, metaId: string, body?: any) => void,
  fetchAppVoice: (reportId: string, brandId: string, metaId: string, intervalType: any, date: any) => void,
  fetchAppHistory: (reportId: string, brandId: string, metaId: string, body: any) => void,
  fetchAppTfIdf: (reportId: string, brandId: string, metaId: string, intervalType: any, date: any) => void,
  fetchPublicApp: (reportId: string, metaId: string, body?: any) => void,
  fetchPublicAppVoice: (reportId: string, brandId: string, metaId: string, body?: any) => void,
  fetchPublicAppHistory: (reportId: string, brandId: string, metaId: string, body?: any) => void,
  fetchDemoApp: (demoReportId: string, metaId: string, body?: any) => void,
  fetchDemoAppVoice: (demoReportId: string, brandId: string, metaId: string, body?: any) => void,
  fetchDemoAppHistory: (demoReportId: string, brandId: string, metaId: string, body?: any) => void,
  requestReport: (reportId: string, apps: any, cardId: string) => void,
  updateReportDataLocally: (reportData: any) => void,
  updateReportData: (reportData: any, doNotUpdateCache?: any) => void,
  deleteReport: (reportId: string) => void,
  publishReport: (reportId: string) => void,
  unPublishReport: (reportId: string) => void,
  shareReport: (reportId: string) => void,
  unshareReport: (reportId: string) => void,
  resendReportPublished: (reportId: string) => void,
  createDemoLink: (reportId: string, body: any) => void,
  deleteDemoLink: (demoId: string) => void,
  reportAddBrand: (reportId: string, brand: any) => void,
  reportDeleteBrand: (reportId: string, brandId: string) => void,
  reportUpdateBrand: (reportId: string, brandId: string, body: any) => void,
  reportAddApp: (reportId: string, app: any) => void,
  reportDeleteApp: (reportId: string, app: any) => void,
  reportUpdateApp: (reportId: string, metaId: string, body: any) => void,
  createSourceFromFile: (reportId: string, brandId: string, title: string, file: any, appProvider: string) => void,
  reportFetchPhrases: (reportId: string, body: any) => void,
  processReport: (reportId: string) => void,
  analyzeReport: (reportId: string) => void,
  addCollaborators: (reportId: string, body: any) => void,
  deleteCollaborator: (reportId: string, collaboratorId: string) => void,
  sendFeedback: (reportId: string, body: any) => void,
  sendDemoFeedback: (demoReportId: string, body: any) => void,
  fetchStatus: (reportId: string) => void,
  toggleReportActive: (reportId: string, shouldScrape: boolean) => void,

  reset: () => void,
}

const initialActions: ContextPropsActions = {
  fetchReports: () => { throw new Error("fetchReports not implemented") },
  createDraftReport: () => { throw new Error("createDraftReport not implemented") },
  fetchReport: () => { throw new Error("fetchReport not implemented") },
  fetchSharedReport: () => { throw new Error("fetchSharedReport not implemented") },
  fetchDemoReport: () => { throw new Error("fetchDemoReport not implemented") },
  fetchBrand: () => { throw new Error("fetchBrand not implemented") },
  fetchBrandVoice: () => { throw new Error("fetchBrandVoice not implemented") },
  fetchBrandHistory: () => { throw new Error("fetchBrandHistory not implemented") },
  fetchBrandTfIdf: () => { throw new Error("fetchBrandTfIdf not implemented") },
  fetchPublicBrand: () => { throw new Error("fetchPublicBrand not implemented") },
  fetchPublicBrandVoice: () => { throw new Error("fetchPublicBrandVoice not implemented") },
  fetchPublicBrandHistory: () => { throw new Error("fetchPublicBrandHistory not implemented") },
  fetchDemoBrand: () => { throw new Error("fetchDemoBrand not implemented") },
  fetchDemoBrandVoice: () => { throw new Error("fetchDemoBrandVoice not implemented") },
  fetchDemoBrandHistory: () => { throw new Error("fetchDemoBrandHistory not implemented") },
  fetchApp: () => { throw new Error("fetchApp not implemented") },
  fetchAppVoice: () => { throw new Error("fetchAppVoice not implemented") },
  fetchAppHistory: () => { throw new Error("fetchAppHistory not implemented") },
  fetchAppTfIdf: () => { throw new Error("fetchAppTfIdf not implemented") },
  fetchPublicApp: () => { throw new Error("fetchPublicApp not implemented") },
  fetchPublicAppVoice: () => { throw new Error("fetchPublicAppVoice not implemented") },
  fetchPublicAppHistory: () => { throw new Error("fetchPublicAppHistory not implemented") },
  fetchDemoApp: () => { throw new Error("fetchDemoApp not implemented") },
  fetchDemoAppVoice: () => { throw new Error("fetchDemoAppVoice not implemented") },
  fetchDemoAppHistory: () => { throw new Error("fetchDemoAppHistory not implemented") },
  requestReport: () => { throw new Error("requestReport not implemented") },
  updateReportDataLocally: () => { throw new Error("updateReportDataLocally not implemented") },
  updateReportData: () => { throw new Error("updateReportData not implemented") },
  deleteReport: () => { throw new Error("deleteReport not implemented") },
  publishReport: () => { throw new Error("publishReport not implemented") },
  unPublishReport: () => { throw new Error("unPublishReport not implemented") },
  shareReport: () => { throw new Error("shareReport not implemented") },
  unshareReport: () => { throw new Error("unshareReport not implemented") },
  resendReportPublished: () => { throw new Error("resendReportPublished not implemented") },
  createDemoLink: () => { throw new Error("createDemoLink not implemented") },
  deleteDemoLink: () => { throw new Error("deleteDemoLink not implemented") },
  reportAddBrand: () => { throw new Error("reportAddBrand not implemented") },
  reportDeleteBrand: () => { throw new Error("reportDeleteBrand not implemented") },
  reportUpdateBrand: () => { throw new Error("reportUpdateBrand not implemented") },
  reportAddApp: () => { throw new Error("reportAddApp not implemented") },
  reportDeleteApp: () => { throw new Error("reportDeleteApp not implemented") },
  reportUpdateApp: () => { throw new Error("reportUpdateApp not implemented") },
  createSourceFromFile: () => { throw new Error("createSourceFromFile not implemented") },
  reportFetchPhrases: () => { throw new Error("reportFetchPhrases not implemented") },
  processReport: () => { throw new Error("processReport not implemented") },
  analyzeReport: () => { throw new Error("analyzeReport not implemented") },
  addCollaborators: () => { throw new Error("addCollaborators not implemented") },
  deleteCollaborator: () => { throw new Error("deleteCollaborator not implemented") },
  sendFeedback: () => { throw new Error("sendFeedback not implemented") },
  sendDemoFeedback: () => { throw new Error("sendDemoFeedback not implemented") },
  fetchStatus: () => { throw new Error("fetchStatus not implemented") },
  toggleReportActive: () => { throw new Error("toggleReportActive not implemented") },
  reset: () => { throw new Error("reset not implemented") },  
}

type ContextProps = [ContextPropsState, ContextPropsActions]

export const ReportsContext = createContext<ContextProps>([initialState, initialActions]);

export const ReportsProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [{ me, user }] = useAuthContext();
  const { amplitudeInstance } = React.useContext(AmplitudeContext);
  const isMeAdmin = me?.isAdmin;

  const fetchReports = React.useCallback( async (body) => {
    try {
      dispatch({ type: 'FETCH_REPORTS_START', ...body })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = isMeAdmin ? `/api/v2/admin/reports` : `/api/v2/reports`;
      const {reports, users} = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_REPORTS', reports: reports, users: users, ...body })
    } catch (error) { console.error('fetchReports', error); }
  }, [amplitudeInstance, user, isMeAdmin])

  const createDraftReport = React.useCallback( async () => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/draft`;
      const data = await fetchUrl('POST', url, {  headers });
      return data;
    } catch (error) { console.error('createDraftReport', error); }
  }, [amplitudeInstance, user])

  const fetchReport = React.useCallback( async (reportId, intervalType, date) => {
    if (reportId == null) return null;
    try {
      dispatch({ type: 'FETCH_REPORT_START', reportId, date, intervalType })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}`;
      const data = await fetchUrl('GET', url, { headers, body: {intervalType, date} });
      dispatch({ type: 'FETCH_REPORT', ...data, reportId, intervalType, date })
    } catch (error) {
      dispatch({ type: 'FETCH_REPORT_ERROR', error, reportId })
    }
  }, [amplitudeInstance, user])
  const fetchSharedReport = React.useCallback( async (publicReportId, body) => {
    if (publicReportId == null) return null;
    try {
      dispatch({ type: 'FETCH_REPORT_START', publicReportId })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/publicReports/${publicReportId}`
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_REPORT', ...data, publicReportId })
    } catch (error) {
      dispatch({ type: 'FETCH_REPORT_ERROR', error, publicReportId })
    }
  }, [amplitudeInstance, user])

  const fetchDemoReport = React.useCallback( async (demoReportId, body) => {
    if (demoReportId == null) return null;
    try {
      dispatch({ type: 'FETCH_REPORT_START', demoReportId })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${demoReportId}`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_REPORT', ...data, demoReportId });
    } catch (error) {
      dispatch({ type: 'FETCH_REPORT_ERROR', error, demoReportId })
    }
  }, [amplitudeInstance, user])

  const fetchBrand = React.useCallback( async (reportId, brandId, body) => {
    if (reportId == null || brandId == null) return;
    try {
      dispatch({ type: 'FETCH_BRAND_START', brandId })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brand/${brandId}`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_BRAND', data, brandId })
    } catch (error) { console.error('fetchBrand', error); }
  }, [amplitudeInstance, user])
  const fetchBrandVoice = React.useCallback( async (reportId, brandId, intervalType, date) => {
    if (reportId == null || brandId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brand/${brandId}/voice`
      const data = await fetchUrl('GET', url, { headers, body: {intervalType, date} });
      dispatch({ type: 'FETCH_BRAND_VOICE', ...data, brandId, intervalType, date })
    } catch (error) { console.error('fetchBrandVoice', error); }
  }, [amplitudeInstance, user])

  const fetchBrandHistory = React.useCallback( async (reportId, brandId, body) => {
    if (reportId == null || brandId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brand/${brandId}/history`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_BRAND_HISTORY', brandId, ...data })
    } catch (error) { console.error('fetchBrandHistory', error) }
  }, [amplitudeInstance, user])
  const fetchBrandTfIdf = React.useCallback( async (reportId, brandId, intervalType, date) => {
    if (reportId == null || brandId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brand/${brandId}/tfidf`;
      const data = await fetchUrl('GET', url, { headers, body: {intervalType, date} });
      dispatch({ type: 'FETCH_BRAND_TFIDF', brandId, ...data, intervalType, date })
    } catch (error) {
      dispatch({ type: 'FETCH_BRAND_TFIDF_ERROR', brandId, error, intervalType, date })
    }
  }, [amplitudeInstance, user])

  const fetchPublicBrand = React.useCallback( async (reportId, brandId, body) => {
    if (reportId == null || brandId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/publicReports/${reportId}/brand/${brandId}`;

      dispatch({ type: 'FETCH_BRAND_START', brandId })
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_BRAND', data, brandId })
    } catch (error) { console.error('fetchPublicBrand', error) }
  }, [amplitudeInstance, user])

  const fetchPublicBrandVoice = React.useCallback( async (reportId, brandId, body) => {
    if (reportId == null || brandId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/publicReports/${reportId}/brand/${brandId}/voice`
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_BRAND_VOICE', ...data, brandId })
    } catch (error) { console.error('fetchPublicBrandVoice', error); }
  }, [amplitudeInstance, user])

  const fetchPublicBrandHistory = React.useCallback( async (reportId, brandId, body) => {
    if (reportId == null || brandId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/publicReports/${reportId}/brand/${brandId}/history`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_BRAND_HISTORY', reportId, brandId, ...data })
    } catch (error) { console.error('fetchPublicBrandHistory', error); }
  }, [amplitudeInstance, user])

  const fetchDemoBrand = React.useCallback( async (demoReportId, brandId, body) => {
    if (demoReportId == null || brandId == null) return;
    try {
      dispatch({ type: 'FETCH_BRAND_START', brandId })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${demoReportId}/brand/${brandId}`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_BRAND', data, brandId })
    } catch (error) { console.error('fetchDemoBrand', error)
    }
  }, [amplitudeInstance, user])
  const fetchDemoBrandVoice = React.useCallback( async (demoReportId, brandId, body) => {
    if (demoReportId == null || brandId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${demoReportId}/brand/${brandId}/voice`
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_BRAND_VOICE', ...data, brandId })
    } catch (error) { console.error('fetchDemoBrandVoice', error) }
  }, [amplitudeInstance, user])
  
  const fetchDemoBrandHistory = React.useCallback( async (demoReportId, brandId, body) => {
    if (demoReportId == null || brandId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${demoReportId}/brand/${brandId}/history`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_BRAND_HISTORY', brandId, ...data })
    } catch (error) { console.error('fetchDemoBrandHistory', error); }
  }, [amplitudeInstance, user])

  const fetchApp = React.useCallback( async (reportId, metaId, body) => {
    if (reportId == null || metaId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/app/${metaId}`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_APP', reportId, metaId, data })
    } catch (error) { console.error('fetchApp', error) }
  }, [amplitudeInstance, user])
  const fetchAppVoice = React.useCallback( async (reportId, brandId, metaId, intervalType, date) => {
    if (reportId == null || metaId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brand/${brandId}/app/${metaId}/voice`;
      const data = await fetchUrl('GET', url, { headers, body: {intervalType, date} });
      dispatch({ type: 'FETCH_APP_VOICE', metaId, ...data, intervalType, date })
    } catch (error) { console.error('fetchApp', error); }
  }, [amplitudeInstance, user])
  const fetchAppHistory = React.useCallback( async (reportId, brandId, metaId, body) => {
    if (reportId == null || brandId == null || metaId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brand/${brandId}/app/${metaId}/history`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_APP_HISTORY', reportId, brandId, metaId, ...data })
    } catch (error) { console.error('fetchAppHistory', error); }
  }, [amplitudeInstance, user])
  const fetchAppTfIdf = React.useCallback( async (reportId, brandId, metaId, intervalType, date) => {
    if (reportId == null || brandId == null || metaId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/app/${metaId}/tfidf`;
      const data = await fetchUrl('GET', url, { headers, body: {intervalType, date} });
      dispatch({ type: 'FETCH_APP_TFIDF', reportId, brandId, metaId, ...data, intervalType, date })
    } catch (error) {
      dispatch({ type: 'FETCH_APP_TFIDF_ERROR', brandId, metaId, error, intervalType, date })
    }
  }, [amplitudeInstance, user])

  const fetchPublicApp = React.useCallback( async (reportId, metaId, body) => {
    if (reportId == null || metaId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/publicReports/${reportId}/app/${metaId}`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_APP', reportId, metaId, data })
    } catch (error) {
      console.error('fetchApp', error)
    }
  }, [amplitudeInstance, user])
  const fetchPublicAppVoice = React.useCallback( async (reportId, brandId, metaId, body) => {
    if (reportId == null || brandId == null || metaId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/publicReports/${reportId}/brand/${brandId}/app/${metaId}/voice`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_APP_VOICE', metaId, ...data })
    } catch (error) { console.error('fetchApp', error); }
  }, [amplitudeInstance, user])
  const fetchPublicAppHistory = React.useCallback( async (reportId, brandId, metaId, body) => {
    if (reportId == null || brandId == null || metaId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/publicReports/${reportId}/brand/${brandId}/app/${metaId}/history`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_APP_HISTORY', reportId, brandId, metaId, ...data })
    } catch (error) { console.error('fetchAppHistory', error); }
  }, [amplitudeInstance, user])
  const fetchDemoApp = React.useCallback( async (demoReportId, metaId, body) => {
    if (demoReportId == null || metaId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${demoReportId}/app/${metaId}`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_APP', demoReportId, metaId, data })
    } catch (error) { console.error('fetchDemoApp', error); }
  }, [amplitudeInstance, user])
  const fetchDemoAppVoice = React.useCallback( async (demoReportId, brandId, metaId, body) => {
    if (demoReportId == null || metaId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${demoReportId}/brand/${brandId}/app/${metaId}/voice`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_APP_VOICE', metaId, ...data })
    } catch (error) { console.error('fetchApp', error); }
  }, [amplitudeInstance, user])
  const fetchDemoAppHistory = React.useCallback( async (demoReportId, brandId, metaId, body) => {
    if (demoReportId == null || brandId == null || metaId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${demoReportId}/brand/${brandId}/app/${metaId}/history`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCH_APP_HISTORY', reportId: demoReportId, demoReportId, brandId, metaId, ...data })
    } catch (error) { console.error('fetchDemoAppHistory', error); }
  }, [amplitudeInstance, user])

  const requestReport = React.useCallback( async (reportId, apps, cardId) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/request`
      const data = await fetchUrl('POST', url, { body: { apps, cardId }, headers });

      dispatch({ type: 'UPDATE_REPORT', ...data })
      return data;
    } catch (error) { console.error('requestReport', error); }
  }, [amplitudeInstance, user])

  const updateReportDataLocally = React.useCallback( async (reportData) => {
    if(reportData == null || reportData._id == null) return null;
    dispatch({ type: 'UPDATE_REPORT', report: reportData });
  }, [])

  const updateReportData = React.useCallback( async (reportData, doNotUpdateCache) => {
    if(reportData == null || reportData._id == null) return null;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportData._id}`
      const data = await fetchUrl('PUT', url, { body: reportData, headers });
      if(!doNotUpdateCache) {
        dispatch({ type: 'UPDATE_REPORT', ...data });
      }
    } catch (error) { console.error('updateReportData', error); }
  }, [amplitudeInstance, user])

  const deleteReport = React.useCallback( async (reportId) => {
    try {
      dispatch({ type: 'DELETE_REPORT_START', reportId })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}`
      const data = await fetchUrl('DELETE', url, { headers });
      dispatch({ type: 'DELETE_REPORT', reportId })
      return data;
    } catch (error) { console.error('deleteReport', error); }
  }, [amplitudeInstance, user])

  const toggleReportActive = React.useCallback( async (reportId, isActive) => {
    try {
      dispatch({ type: 'TOGGLE_REPORT_ACTIVE_START', reportId, isActive })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/admin/reports/${reportId}/toggleActive`
      const data = await fetchUrl('POST', url, { body: {active: isActive }, headers });

      dispatch({ type: 'TOGGLE_REPORT_ACTIVE', reportId, active: data?.report?.active })
      return data;
    } catch (error) { console.error('toggleReportActive', error); }
  }, [amplitudeInstance, user])

  const publishReport = React.useCallback( async (reportId) => {
    if (reportId == null || !isMeAdmin) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/admin/reports/${reportId}/publish`;
      const data = await fetchUrl('POST', url, { headers });
      dispatch({ type: 'UPDATE_REPORT', ...data })
      return data;
    } catch (error) { console.error('publishReport', error); }
  }, [amplitudeInstance, user, isMeAdmin])
  const unPublishReport = React.useCallback( async (reportId) => {
    if (reportId === null || !isMeAdmin) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/admin/reports/${reportId}/unpublish`;
      const data = await fetchUrl('POST', url, { headers });
      dispatch({ type: 'UPDATE_REPORT', ...data })
      return data;
    } catch (error) { console.error('unPublishReport', error); }
  }, [amplitudeInstance, user, isMeAdmin])

  const shareReport = React.useCallback( async (reportId) => {
    if(reportId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/public`;
      const data = await fetchUrl('POST', url, { headers });
      dispatch({ type: 'UPDATE_REPORT', ...data })
      return data;
    } catch (error) { console.error('shareReport', error) }
  }, [amplitudeInstance, user])
  const unshareReport = React.useCallback( async (reportId) => {
    if(reportId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/private`;
      const data = await fetchUrl('POST', url, { headers });
      dispatch({ type: 'UPDATE_REPORT', report: {...data.report, publicId: undefined} })
      return data;
    } catch (error) { console.error('unshareReport', error) }
  }, [amplitudeInstance, user])

  const resendReportPublished = React.useCallback( async (reportId) => {
    if (reportId == null || !isMeAdmin) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/admin/reports/${reportId}/resendPublished`;
      const data = await fetchUrl('POST', url, { headers });
      return data;
    } catch (error) {
      console.error('resendReportPublished', error)
    }
  }, [amplitudeInstance, user, isMeAdmin])

  const createDemoLink = React.useCallback( async (reportId, body) => {
    if(reportId == null || body == null || Object.keys(body).length === 0) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/admin/demo`;
      const data = await fetchUrl('POST', url, { body: {...body, reportId}, headers });
      dispatch({ type: 'CREATE_DEMO_LINK', data, reportId });
      return data;
    } catch (error) { console.error('createDemoLink', error); }
  }, [amplitudeInstance, user])

  const deleteDemoLink = React.useCallback( async (demoId) => {
    if(demoId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/admin/demo/${demoId}`
      const data = await fetchUrl('DELETE', url, { headers });
      dispatch({ type: 'DELETE_DEMO_LINK', data });
      return data;
    } catch (error) { console.error('deleteDemoLink', error); }
  }, [amplitudeInstance, user])

  const reportAddBrand = React.useCallback( async (reportId, brand) => {
    if (reportId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brands`;
      const data = await fetchUrl('POST', url, { headers, body: brand });
      dispatch({ type: 'REPORT_ADD_BRAND', reportId, ...data })
      return data;
    } catch (error) { console.error('reportAddBrand', error) }
  }, [amplitudeInstance, user])
  const reportDeleteBrand = React.useCallback( async (reportId, brandId) => {
    if (reportId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brands/${brandId}`;
      const data = await fetchUrl('DELETE', url, { headers });
      
      dispatch({ type: 'UPDATE_REPORT', reportId, brandId, ...data })
    } catch (error) { console.error('reportDeleteBrand', error) }
  }, [amplitudeInstance, user])
  const reportUpdateBrand = React.useCallback( async (reportId, brandId, body) => {
    if (reportId == null || brandId == null) return;
    try {
      dispatch({ type: 'REPORT_UPDATE_BRAND', reportId, brand: { _id: brandId, ...body} })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brands/${brandId}`;
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({ type: 'REPORT_UPDATE_BRAND', reportId, ...data })
      return data;
    } catch (error) { console.error('reportUpdateBrand', error) }
  }, [amplitudeInstance, user])

  const reportAddApp = React.useCallback( async (reportId, app) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/app`;
      const data = await fetchUrl('POST', url, { body: app, headers });
      dispatch({ type: 'REPORT_ADD_APP', reportId, ...data })
      return data;
    } catch (error) { console.error('reportAddApp', error); }
  }, [amplitudeInstance, user])

  const reportDeleteApp = React.useCallback( async (reportId, app) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/apps/${app.metaId}`;
      const data = await fetchUrl('DELETE', url, { headers });
      dispatch({ type: 'REPORT_DELETE_APP', reportId, ...data })
      return data;
    } catch (error) { console.error('reportDeleteApp', error); }
  }, [amplitudeInstance, user])

  const reportUpdateApp = React.useCallback( async (reportId, metaId, body) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/apps/${metaId}`
      const data = await fetchUrl('POST', url, { body, headers });
      dispatch({ type: 'REPORT_UPDATE_APP', reportId, ...data })
      return data;
    } catch (error) { console.error('reportUpdateApp', error); }
  }, [amplitudeInstance, user])

  const createSourceFromFile = React.useCallback( async (reportId, brandId, title, file, appProvider) => {
    if (reportId == null) return;
    const formData = new FormData();
    formData.append('title', title);
    formData.append('appProvider', appProvider);
    formData.append('reviewsCSV', file);
    
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = brandId ? `/api/v2/reports/${reportId}/brands/${brandId}/csvSource` : `/api/v2/reports/${reportId}/csvSource`
      const data = await fetchUrl('POST', url, { headers, body: formData });
      dispatch({ type: 'REPORT_ADD_APP', reportId, brandId, ...data })
    } catch (error) { console.error('createSourceFromFile', error); }
  }, [amplitudeInstance, user])

  const reportFetchPhrases = React.useCallback( async (reportId, body) => {
    if(reportId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/phrases`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({type: 'FETCH_REPORT_PHRASES', reportId, data});
      return data;
    } catch (error) { console.error('reportFetchPhrases', error) }
  }, [amplitudeInstance, user])


  const processReport = React.useCallback( async (reportId) => {
    if (reportId == null || !isMeAdmin) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/process`;
      await fetchUrl('POST', url, { headers });
    } catch (error) { console.error('processReport', error); }
  }, [amplitudeInstance, user, isMeAdmin])

  const analyzeReport = React.useCallback( async (reportId) => {
    if (reportId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/analyze`;
      await fetchUrl('POST', url, { headers });
    } catch (error) { console.error('analyzeReport', error); }
  }, [amplitudeInstance, user])

  const addCollaborators = React.useCallback( async (reportId, body) => {
    if(reportId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/collaborators`;
      
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({ type: 'UPDATE_REPORT', ...data })
    } catch (error) { console.error('addCollaborators', error) }
  }, [amplitudeInstance, user])
  const deleteCollaborator = React.useCallback( async (reportId, collaboratorId) => {
    if(reportId == null || collaboratorId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/collaborators/${collaboratorId}`;
      const data = await fetchUrl('DELETE', url, {headers});
      dispatch({ type: 'UPDATE_REPORT', ...data })
    } catch (error) { console.error('deleteCollaborator', error) }
  }, [amplitudeInstance, user])

  const sendFeedback = React.useCallback( async (reportId, body) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/feedback`;
      const href = window.location.href;
      const data = await fetchUrl('POST', url, { headers, body: {...body, href} });
      return data;
    } catch (error) { console.error('sendFeedback', error) }
  }, [amplitudeInstance, user])
  const sendDemoFeedback = React.useCallback( async (demoReportId, body) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${demoReportId}/feedback`;
      const href = window.location.href;
      const data = await fetchUrl('POST', url, { headers, body: {...body, href} });
      return data;
    } catch (error) { console.error('sendDemoFeedback', error) }
  }, [amplitudeInstance, user])
  const fetchStatus = React.useCallback( async (reportId) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/status`;
      const data = await fetchUrl('GET', url, { headers });
      dispatch({ type: 'FETCH_REPORT_STATUS', ...data, reportId })
      return data;
    } catch (error) { console.error('fetchStatus', error) }
  }, [amplitudeInstance, user])

  const reset = React.useCallback( () => dispatch({ type: 'RESET' }), [])

  const value: ContextProps = [
    state,
    {
      fetchReports,
      createDraftReport,

      fetchReport,
      fetchSharedReport,
      fetchDemoReport,

      fetchBrand,
      fetchBrandVoice,
      fetchBrandHistory,
      fetchBrandTfIdf,

      fetchPublicBrand,
      fetchPublicBrandVoice,
      fetchPublicBrandHistory,

      fetchDemoBrand,
      fetchDemoBrandVoice,
      fetchDemoBrandHistory,

      fetchApp,
      fetchAppVoice,
      fetchAppHistory,
      fetchAppTfIdf,

      fetchPublicApp,
      fetchPublicAppVoice,
      fetchPublicAppHistory,

      fetchDemoApp,
      fetchDemoAppVoice,
      fetchDemoAppHistory,

      requestReport,
      updateReportDataLocally,
      updateReportData,
      deleteReport,
      publishReport,
      unPublishReport,
      shareReport,
      unshareReport,
      resendReportPublished,
      
      createDemoLink,
      deleteDemoLink,

      reportAddBrand,
      reportDeleteBrand,
      reportUpdateBrand,

      reportAddApp,
      reportDeleteApp,
      reportUpdateApp,

      createSourceFromFile,

      reportFetchPhrases,

      processReport,
      analyzeReport,

      addCollaborators,
      deleteCollaborator,

      sendFeedback,
      sendDemoFeedback,

      fetchStatus,
      toggleReportActive,

      reset,
    }
  ]

  return <ReportsContext.Provider value={value}>
    {children}
  </ReportsContext.Provider>
}

export const useReportsContext = () => React.useContext(ReportsContext)