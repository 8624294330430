import React from 'react';

import { Spinner } from '../spinner/spinner'

import styles from './loading.module.scss'

export const Loading: React.FC<React.HTMLProps<HTMLDivElement>> = React.memo(({ className }) => <div className={[className, styles.loading].join(' ')}>
  <Spinner />
</div>)

export const LoadingContainer = () => {
  return <div className={styles.container}>
    <Spinner />
  </div>
}

export default Loading