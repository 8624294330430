import React from 'react';

import {getScore, getRangeString} from '../../utils'

import styles from './sentimentTooltip.module.scss'

interface SentimentTooltipProps {
  cats: any[],
  conceptsById: any
}

export const SentimentTooltip: React.FC<SentimentTooltipProps> = ({cats, conceptsById}) => {
  const conceptNames = cats?.map(cat => conceptsById?.[cat.c]?.name).filter(a => a);

  const sentiment = Math.max(...cats.map(c => c.s));

  const rangeString = getRangeString(getScore(sentiment));
  if(conceptNames != null && conceptNames.length > 0)
    return <div className={styles.container}>{conceptNames.join(', ')} {conceptNames.length === 1 ? 'score is' : 'scores are'} <strong>{rangeString}</strong></div>
  else
    return <div className={styles.container}>Sentence sentiment is <strong>{rangeString}</strong></div>
}

export default SentimentTooltip