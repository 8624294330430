import 'firebase/compat/auth'; //v9

export type ContextPropsState = {
  users: any,
  cards: any,
  customer: any,
}

export type OrdersAction = 
  | ({ type: "FETCHED_USERS", data: any} )
  | { type: "UPDATED_USER_PERMISSIONS", data: any}
  | { type: "FETCH_CARDS", data: any }
  | { type: "DELETE_CARD", data: any }
  | { type: "ADD_CARD", data: any }
  
  | { type: "RESET" }


export const initialState: ContextPropsState = {
  users: undefined,
  cards: undefined,
  customer: undefined,
};

export const reducer = (state: ContextPropsState, action: OrdersAction) => {
  if('FETCHED_USERS' === action.type) {
    return { ...state, users: action.data }
  } else if('UPDATED_USER_PERMISSIONS' === action.type) {
    const users = {...state.users};
    const newData = users.users.map((user: any) => {
      if(user.user._id === action.data._id) return {...user, permissions: action.data}
      else return user;
    })
    const newUsers = {...users, users: newData};
    return { ...state, users: newUsers }
  } else if (action.type === 'FETCH_CARDS') {
    return { ...state, cards: action.data.cards, customer: action.data.customer };
  } else if (action.type === 'DELETE_CARD') {
    return { ...state, cards: (state.cards || []).filter((c: any) => c.id !== action.data.id) }
  } else if (action.type === 'ADD_CARD') {
    return { ...state, cards: [...state.cards, action.data.card] }
  } else if('RESET' === action.type) {
    return initialState
  } else {
    throw new Error();
  }
}