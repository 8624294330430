import React from 'react'

import Icon from '../icon/icon'

import styles from './selectBox.module.scss'

interface OptionProps {
  value: string,
  label: string,
  src: string
}

const Option: React.FC<OptionProps> = ({value, label, src}) => {
  const style = {
    backgroundImage: src != null ?`url(${src})` : null
  }
  return <option value={value} className={styles.option} style={style}>{label}</option>
}

interface OptionGroupProps {
  label: string,
  options: any[]
}

const OptionGroup: React.FC<OptionGroupProps> = ({label, options}) => {
  return <optgroup label={label}>
    {options.map(option => {
      if(Array.isArray(option))
        return option.map(option => <Option key={option.value} {...option} />)
      else return <Option key={option.value} {...option} />
    })}
  </optgroup>
}

const getIcon = (options: any, value: string) => {
  if(options == null || value == null) return null;
  for(var i = 0; i< options.length; i++) {
    const option = options[i];
    if(option.options) {
      for(var j = 0; j < option.options.length; j++) {
        const nestedOption = option.options[j];
        if(nestedOption.value === value && nestedOption.icon) {
          return nestedOption.icon;
        }
      }
    }
    if(option.value === value && option.icon) {
      return option.icon;
    }
  }
}

interface SelectBoxProps {
  options: any[],
  value: any,
  onChange: any,
  borderless: boolean
}

export const SelectBox: React.FC<SelectBoxProps> = ({options, value, onChange, borderless}) => {
  if(options == null) return null;
  const icon = getIcon(options, value);

  return <div className={[styles.select, borderless ? styles.borderless : null].join(' ')}>
    <div className={styles.content}>
      {icon != null && <Icon src={icon} rounded className={styles.brandIcon} />}
      <select onChange={onChange} value={value}>
        {options.map(option => {
          if(option.options) {
            return <OptionGroup label={option.label} options={option.options} key={option.label} />
          }
          return <Option key={option.value} {...option} />
        })}
      </select>
    </div>
  </div>
}

export default SelectBox;
