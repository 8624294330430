import React from 'react'
import DemoViz from '../../components/demoViz/demoViz'

import styles from './scheduleDemo.module.scss'

export const ScheduleDemo = () => {
  React.useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }, [])
  return <div className={styles.container}>
    <DemoViz />

    <div className={styles.content}>
      <div className={[styles.calendly, 'calendly-inline-widget'].join(' ')} data-url={process.env.REACT_APP_CALENDLY_URL} />
    </div>
  </div>
}

export default ScheduleDemo;