import React from 'react';
import {Link} from '@reach/router'

import Tooltip from '../../libs/context-tooltip/tooltip'
import { getRangeColor, getRangeIcon, getScore, getRangeString } from '../../utils'

import styles from './cell.module.scss'

interface ConceptCellProps extends React.HTMLProps<HTMLDivElement> {
  data: any,
  demoId: string,
  isDemo: boolean,
  concept?: any,
  total?: any,
}

const ConceptCell: React.FC<ConceptCellProps> = React.memo(({ data, demoId, isDemo, className, concept }) => {
  const value = (demoId != null && !isDemo) ? null : getScore(data?.categoryScore);
  const style = {
    opacity: (demoId != null && !isDemo) ? 0.1 : 1,
    '--circle': getRangeColor(value),
  }

  if (data == null || Number.isNaN(value) || value == null) {
    return <div className={[className, styles.nan].join(' ')} style={style}>
      {demoId == null && <span>N/A</span>}
      {demoId != null && <div className={styles.circle} />}
    </div>
  }

  const Icon = getRangeIcon(value);

  const tooltipContent = <TooltipItem value={value}
    rank={data?.categoryScoreRank?.value}
    outOf={data?.categoryScoreRank?.outOf}
    catType={concept.name}
    rangeText={getRangeString(value)} />

  return <Link className={[className, styles.centeredCell].join(' ')} style={style} to={`${data.brandId}?category=${concept._id}`}>
    <Tooltip background="#fff" arrowOffset={5} content={tooltipContent}>
      <div className={styles.circle}>
        {Icon != null && <Icon />}
      </div>
    </Tooltip>
  </Link>
})

export default ConceptCell

interface TooltipItemProps extends React.HTMLProps<HTMLDivElement> {
  value: number,
  rank: number,
  outOf: number,
  catType: string,
  rangeText: string,
}

const TooltipItem: React.FC<TooltipItemProps> = ({ value, rank, outOf, catType, rangeText }) => <>
  <div className={styles.tooltipSentimentScore}>Sentiment score: {(value).toFixed(0)}/100</div>
  <div className={styles.tooltipSentimentText}>Rank: {rank}/{outOf}</div>
  <div className={styles.tooltipSentimentText}>
    Reviews mentioning <strong>{catType}</strong> had <strong className={styles.tooltipSentimentValue} style={{ color: getRangeColor(value) }}>{rangeText}</strong> ratings
  </div>
</>