import React from 'react';

const useIsomorphicLayoutEffect = (typeof window !== 'undefined') ? React.useLayoutEffect : React.useEffect;

const getSize = () => ({
  innerHeight: window.innerHeight || 0,
  innerWidth: window.innerWidth || 0,
  outerHeight: window.outerHeight || 0,
  outerWidth: window.outerWidth || 0,
})

export const useWindowSize = () => {
  const [size, setSize] = React.useState(getSize());

  useIsomorphicLayoutEffect(() => {
    let requestRunning: number | null = null;
    const docChange = () => {
      if ((typeof window !== 'undefined') && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setSize(getSize());
          requestRunning = null;
        });
      }
    }

    if ((typeof window !== 'undefined')) {
      window.addEventListener('resize', docChange);
      window.addEventListener('orientationchange', docChange);
      docChange()

      return () => {
        window.removeEventListener('resize', docChange);
        window.removeEventListener('orientationchange', docChange);
      }
    }
  }, []);

  return size
}