import React from 'react'

import {useDataContext} from '../../contexts'
import Input from '../../components/input/input'
import DemoViz from '../../components/demoViz/demoViz'

import styles from './demoSignup.module.scss'

export const DemoSignup = () => {
  const [, {askForDemo}] = useDataContext();
  const [data, setData] = React.useState<any>({});
  const [error, setError] = React.useState<any>({});

  const onSubmit = (e: any) => {
    e.preventDefault();
    const error: any = {};
    if(data.name == null || data.name.length === 0) error.name = "Please enter your name";
    if(data.email == null || data.email.length === 0) {
      error.email = "Please enter your email";
    } else if(data.email.indexOf('@') <= 0) {
      error.email = "Your email seems to be misformatted";
    } else if(data.email.indexOf('@gmail') > 0) {
      error.email = "Please provide a WORK email";
    }
    if(data.phone == null || data.phone.length === 0) error.phone = "Please enter your name";
    if(Object.keys(error).length > 0) {
      setError(error);
    } else {
      askForDemo(data);
    }
  }

  return <div className={styles.container}>
    <DemoViz />
    <div className={styles.content} >
      <form onSubmit={onSubmit}>
        <h1>SIGN UP</h1>
        <Input className={styles.input} title="NAME" value={data.name || ""} onChange={(e: any) => setData({...data, name: e.target.value})} error={error['name']} />
        <Input className={styles.input} title="WORK EMAIL" value={data.email || ""} onChange={(e: any) => setData({...data, email: e.target.value})} error={error['email']} />
        <Input className={styles.input} title="PHONE" value={data.phone || ""}  onChange={(e: any) => setData({...data, phone: e.target.value})} error={error['phone']} />
        <input type="submit" value="sign up" className={styles.button} />
      </form>
    </div>
  </div>
}

export default DemoSignup
