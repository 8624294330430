import React, { createContext } from 'react'

import { useAuthContext } from './auth.context'
import { AmplitudeContext } from '../libs/react-amplitude'
import { fetchUrl, headersWithXToken } from './utils'
import { reducer, initialState, ContextPropsState } from './data.reducer'

type ContextPropsActions = {
  askForDemo: (body: any) => void,
  sentimentSearch: (body: any) => void,
  reset: () => void,
}

const initialActions: ContextPropsActions = {
  askForDemo: () => { throw new Error("askForDemo not implemented") },
  sentimentSearch: () => { throw new Error("sentimentSearch not implemented") },
  reset: () => { throw new Error("reset not implemented") }
}

type ContextProps = [ContextPropsState, ContextPropsActions]

export const DataContext = createContext<ContextProps>([initialState, initialActions]);

export const DataProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [{ user }] = useAuthContext();
  const { amplitudeInstance } = React.useContext(AmplitudeContext);
  
  const askForDemo = React.useCallback( async (body) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/askForDemo`;
      await fetchUrl('POST', url, { headers, body });
    } catch (error) { console.error('askForDemo', error); }
  }, [amplitudeInstance, user])

  const sentimentSearch = React.useCallback( async (body) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `https://semantic-search-dot-appvalidate-dev.appspot.com/search`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({type: 'FETCH_SENTIMENT_SEARCH', ...body, data});
      return data;
    } catch (error) { console.error('sentimentSearch', error); }
  }, [amplitudeInstance, user])

  const reset = React.useCallback( () => dispatch({ type: 'RESET' }), [])

  const value: ContextProps = [
    state,
    {
      askForDemo,
      sentimentSearch,
      reset,
    }
  ]

  return <DataContext.Provider value={value}>
    {children}
  </DataContext.Provider>
}

export const useDataContext = () => React.useContext(DataContext)