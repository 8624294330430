import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; //v9

import { Me } from '../models/me.model';
import Permission from '../permission';


export type ContextPropsState = {
  me?: Me,
  fetching: boolean,
  initialising?: boolean,
  user?: firebase.User,
  permission?: Permission
}

export type OrdersAction = 
  | ({ type: "FETCHED_ME_START" } )
  | ({ type: "FETCHED_ME", data: any } )
  | ({ type: "FETCHED_ME_ERROR" } )
  | { type: "RESET" }

const firebaseConfig = {
  apiKey: "AIzaSyDSaNFieLh9Tb_mg5JB6fs5wowdYOLVeTQ",
  authDomain: "appvalidate-dev.firebaseapp.com",
  databaseURL: "https://appvalidate-dev.firebaseio.com",
  projectId: "appvalidate-dev",
  storageBucket: "appvalidate-dev.appspot.com",
  messagingSenderId: "16565769248"
};

firebase.initializeApp(firebaseConfig);

export const initialState: ContextPropsState = {
  me: undefined,
  fetching: true,
};

export const reducer = (state: ContextPropsState, action: OrdersAction) => {
  if(action.type === 'FETCHED_ME_START') {
    return {...state, fetching: true}
  }
  if(action.type === 'FETCHED_ME') {
    return {...state, fetching: false, me: action.data}
  }
  if(action.type === 'FETCHED_ME_ERROR') {
    return {...state, fetching: false}
  }
  if(action.type === 'RESET') {
    return initialState
  }
}
