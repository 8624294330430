import React from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; //v9
import { useAuthState } from 'react-firebase-hooks/auth';

import { fetchUrl, headersWithXToken } from './utils'
import Permission from '../permission'
import { useSetUserId } from '../libs/react-amplitude'
import { reducer, initialState, ContextPropsState } from './auth.reducer'


type ContextPropsActions = {
  fetchMe: (namespaceId?: string) => void,
  reset: () => void,
}

const initialActions: ContextPropsActions = {
  fetchMe: () => { return new Error("fetchMe not implemented") },
  reset: () => { return new Error("reset not implemented") },
}

type ContextProps = [ContextPropsState, ContextPropsActions]

export const AuthContext = React.createContext<ContextProps>([initialState, initialActions]);

export const AuthProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [user, initialising] = useAuthState(firebase.auth());
  useSetUserId(user?.uid, user);

  const permission = new Permission(state.me);

  const fetchMe = React.useCallback( async(namespaceId) => {
    if(user == null) return;
    try {
      dispatch({type: 'FETCHED_ME_START'})
      const headers = await headersWithXToken(user);
      const data = await fetchUrl('GET', `/api/me`, {headers, body: {namespaceId}});
      dispatch({type: 'FETCHED_ME', data})
    } catch(error) {
      dispatch({type: 'FETCHED_ME_ERROR'})
    }
  }, [user]);

  const reset = React.useCallback( async () => {
    await firebase.auth().signOut();
    dispatch({ type: 'RESET' })
  }, [])

  const newState = Object.assign({}, state, {initialising, user, permission})
  const value: ContextProps = [newState,
    {
      fetchMe,
      reset
    }
  ]

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>
}


export const useAuthContext = () => React.useContext(AuthContext)