import React from 'react'

import { Loading } from '../../components/loading/loading'
import Review, {PlaceholderReview} from '../../components/review/review'
import SelectBox from '../../components/selectBox/selectBox'
import SentimentQuadrant from '../../components/sentimentQuadrant/sentimentQuadrant'
import Card from '../card/card'
import { useReviewsContext } from '../../contexts'

import styles from './loversHatersCard.module.scss'

interface LoversHatersCardProps {
  report: any,
  concepts: any[],
  demoReportId: any,
  conceptsById: any,
  allReviews: any,
  editing?: any,
  topicId?: any,
  setTopicId?: any,
  filter?: any,
  setFilter?: any,
  sharedReportId?: any,
  onDelete?: any,
  brandsById?: any
}

export const LoversHatersCard: React.FC<LoversHatersCardProps> = ({report, concepts, demoReportId, conceptsById, allReviews, editing, topicId, setTopicId, filter, setFilter, sharedReportId, onDelete}) => {
  const [{reviewsById}, { fetchReview, fetchDemoReview, fetchPublicReview }] = useReviewsContext();
  const [hoveredReview, setHoveredReview] = React.useState(null);
  const [selectedReview, setSelectedReview] = React.useState(null);
  const selectFiltes = report?.data?.filters;

  // const conceptsOptions = concepts?.filter(c => c.countInScore).map(concept => ({
  //   value: concept._id,
  //   label: concept.name
  // }))

  const conceptsOptions = concepts?.map(concept => ({
    value: concept._id,
    label: concept.name
  }))

  let hoveredReviewObj: any = null;
  if(hoveredReview?._id && reviewsById) {
    hoveredReviewObj = reviewsById[hoveredReview._id];
  }
  let selectedReviewObj: any = null;
  if(selectedReview?._id && reviewsById) {
    selectedReviewObj = reviewsById[selectedReview._id];
  }

  const selectReview = (review: any) => {
    if(review) {
      if(reviewsById == null || reviewsById[review._id] == null) {
        if(demoReportId && fetchDemoReview) {
          fetchDemoReview(demoReportId, review._id);
        } else if(sharedReportId && fetchPublicReview) {
          fetchPublicReview(report?.data?._id, sharedReportId, review._id);
        } else if(!sharedReportId && fetchReview) {
          fetchReview(report?.data?._id, review._id);
        }
      }

      const app = report?.data?.apps?.find((app: any) => app.metaId === review.metaId);
      setHoveredReview({ _id: review._id, app });
    } else {
      setHoveredReview(null);
    }
  }

  const clickReview = (review: any) => {
    if(review) {
      if(reviewsById == null || reviewsById[review._id] == null) {
        if(demoReportId && fetchDemoReview) {
          fetchDemoReview(demoReportId, review._id);
        } else if(sharedReportId && fetchPublicReview) {
          fetchPublicReview(report?.data?._id, sharedReportId, review._id);
        } else if(!sharedReportId && fetchReview) {  
          fetchReview(report?.data?._id, review._id);
        }
      }

      const app = report?.data?.apps?.find((app: any) => app.metaId === review.metaId);
      setSelectedReview({ _id: review._id, app });
    } else {
      setSelectedReview(null);
    }
  }

  const clickTag = (id: string) => {
    setTopicId(id)
    setSelectedReview(null);
  }

  let filters = [];
  if(topicId) {
    console.log("conceptsOptions", conceptsOptions)
    filters.push(<SelectBox key="conceptPicker" options={conceptsOptions} value={topicId} onChange={(e: any) => clickTag(e.target.value)} borderless />);
  }
  if(selectFiltes?.length > 1) {
    filters.push(<SelectBox key="filters" options={selectFiltes} value={filter} onChange={(e: any) => setFilter(e.target.value)} borderless />);
  }

  if(allReviews == null) return <LoadingCard filters={filters} />
  if(report == null || report.data == null) return <LoadingCard filters={filters} />
  if(report.dataCounts == null) return <LoadingCard filters={filters} />

  return <Card full header="Fans &amp; Haters"
      description="Grid based review display. Up and to the right is 'better'"
      fileName={`${report?.data?.name}_lovers_and_haters.png`}
      filters={filters} onDelete={onDelete} editing={editing}>
      <div className={styles.horizontal}>
        <SentimentQuadrant allReviews={allReviews} clickReview={clickReview} selectReview={selectReview} selected={selectedReview?._id} />
        <div className={styles.review}>
          {hoveredReviewObj && <>
            {!hoveredReviewObj.loading && <Review report={report} review={hoveredReviewObj} app={hoveredReview?.app} conceptsById={conceptsById} noWrap />}
            {hoveredReviewObj.loading && <Loading className={styles.loading} />}
          </>}
          {!hoveredReviewObj && selectedReviewObj && <>
            {!selectedReviewObj.loading && <Review report={report} review={selectedReviewObj} app={selectedReview && selectedReview.app} conceptsById={conceptsById} noWrap />}
            {selectedReviewObj.loading && <Loading className={styles.loading} />}
          </>}
          {!hoveredReviewObj && !selectedReviewObj && <div className={styles.clickCorrespondingVerbatimsWrap}>
            <PlaceholderReview opacity={1} noWrap />
            <div className={styles.clickCorrespondingVerbatims}>Select point to see the corresponding verbatim</div>
          </div>}
        </div>
      </div>
    </Card>
}

const LoadingCard = ({filters}: any) => {
  return <Card full header="Fans &amp; Haters" description="Hover over points on the grid below to see the verbatim feedback.  To learn more about how feedback is mapped, read our documentation. Up and to the right is 'better'" filters={filters}>
    <Loading />
  </Card>
}

export default LoversHatersCard;