import { uniqueBy } from '../utils'
import { groupBy, mapValues, merge } from 'lodash'

export type ContextPropsState = {
  reportsById?: {[reportId: string]: any},
  reportIdsByStatus?: {[status: string]: any},

  brandsById?: {[brandId: string]: any},
  appsById?: {[appId: string]: any},
  appIdsByBrandId?: {[brandId: string]: any},
}

export type OrdersAction = 
  | ({ type: "FETCH_REPORTS_START", page: any, perPage: any, status: any } )
  | ({ type: "FETCH_REPORTS", reports: any[], status: any, users: any, page: any, perPage: any } )
  | ({ type: "FETCH_REPORTS_ERROR" })
  
  | ({ type: "FETCH_REPORT_START", reportId?: string, publicReportId?: string, demoReportId?: string, date?: any, intervalType?: any })
  | ({ type: "FETCH_REPORT", report: any, demoMappings: any, demoMapping: any, usersById: any, date: any, intervalType: any, dataCounts: any, publicReportId?: string, demoReportId?: string })
  | ({ type: "FETCH_REPORT_ERROR", reportId?: string, publicReportId?: string, demoReportId?: string, error: any })

  | ({ type: "UPDATE_REPORT", report: any, jobs?: any, usersById?: any, isProcessing?: boolean})
  | ({ type: "DELETE_REPORT_START", reportId: string})
  | ({ type: "DELETE_REPORT", reportId: string})

  | ({ type: "TOGGLE_REPORT_ACTIVE_START", reportId: string, isActive: boolean})
  | ({ type: "TOGGLE_REPORT_ACTIVE", reportId: string, active: boolean})

  | ({ type: "FETCH_BRAND_START", brandId: string})
  | ({ type: "FETCH_BRAND", brandId: string, data: any})
  | ({ type: "FETCH_BRAND_VOICE", brandId: string, data: any, dataV2: any, categories: any, date: any, intervalType: any})
  | ({ type: "FETCH_BRAND_HISTORY", brandId: string, data: any, dataV2: any})
  | ({ type: "FETCH_BRAND_TFIDF", brandId: string, data: string})
  | ({ type: "FETCH_BRAND_TFIDF_ERROR", brandId: string, error: any, intervalType?: any, date?: any})
  | ({ type: "FETCH_APP", metaId: string, data: any, reportId?: string, demoReportId?: string })
  | ({ type: "FETCH_APP_VOICE", metaId: string, data: any, dataV2: any, intervalType: any, categories: any, date: any})
  | ({ type: "FETCH_APP_HISTORY", metaId: string, data: any, dataV2: any})
  | ({ type: "FETCH_APP_TFIDF", metaId: string, data: any})
  | ({ type: "FETCH_APP_TFIDF_ERROR", metaId: string, brandId: string, intervalType: any, date: any, error: any })
  | ({ type: "CREATE_DEMO_LINK", data: any, reportId: string })
  | ({ type: "DELETE_DEMO_LINK", data: any })
  | ({ type: "REPORT_ADD_BRAND", data: any, reportId: string, brand: any })
  | ({ type: "REPORT_UPDATE_BRAND", reportId: string, app?: any, brand: any })
  | ({ type: "REPORT_ADD_APP", reportId: string, app: any, brand: any})
  | ({ type: "REPORT_UPDATE_APP", reportId: string, app: any})
  | ({ type: "REPORT_DELETE_APP", reportId: string, app: any})
  | ({ type: "FETCH_REPORT_PHRASES", reportId: string, data: any})
  | ({ type: "FETCH_REPORT_STATUS", reportId: string, isProcessing: boolean, jobs: any, appsById: {[key:string]: any} })
  | { type: "RESET" }


export const initialState: ContextPropsState = {
  reportsById: undefined,
  reportIdsByStatus: undefined,

  brandsById: undefined,
  appsById: undefined,
  appIdsByBrandId: undefined,
};

export const reducer = (state: ContextPropsState, action: OrdersAction) => {
  if('FETCH_REPORTS_START' === action.type) {
    const currentReportIdsByStatus = (action.page > 0) ? state.reportIdsByStatus?.[action.status] : [];
    const reportIdsByStatus = {
      ...state.reportIdsByStatus,
      [action.status] : {
        ...currentReportIdsByStatus,
        fetching: true,
        page: action.page,
        perPage: action.perPage,
      }
    }
    return { ...state, reportIdsByStatus }
  }
  if('FETCH_REPORTS' === action.type) {
    const reportIds = action.reports?.map(r => r._id);
    const reportsById = {
      ...state.reportsById,
      ...uniqueBy(action.reports, (r: any) => r._id, (r: any) => {
        const newReport = r;
        newReport.appIds = r?.apps?.map((a: any) => a.metaId);
        newReport.brandIds = r?.brands?.map((b: any) => b._id);
        const oldReport = state.reportsById?.[r._id];
        if(oldReport?.status === 'DETAIL_FETCHED') return oldReport;
        else return { data: newReport, status: 'FETCHED' }
       })
    }

    const currentReportIdsByStatus = state.reportIdsByStatus?.[action.status];
    
    const reportIdsByStatus = {
      ...state.reportIdsByStatus,
      [action.status] : {
        ...currentReportIdsByStatus,
        ids: reportIds,
        users: action.users,
        fetching: false,
        page: action.page,
        perPage: action.perPage,
      }
    }

    const brandsById = { ...state.brandsById }
    action.reports.forEach(report => {
      report.brands && report.brands.forEach((brand: any) => {
        const oldBrand = state.brandsById?.[brand._id];
        if(!oldBrand || oldBrand.status !== 'DETAIL_FETCHED') {
          brandsById[brand._id] = { ...oldBrand, data: brand, status: 'FETCHED' }
        }
      })
    })
    return { ...state, reportsById, reportIdsByStatus, brandsById }
  }

  if('FETCH_REPORT_START' === action.type) {
    const reportId = action.reportId || action.publicReportId || action.demoReportId;
    const oldReport = state.reportsById?.[reportId];
    const report = {
      ...oldReport,
      status: 'FETCHING',
      date: action.date,
      intervalType: action.intervalType,
      fetching: true,
    }
    const reportsById = {
      ...state.reportsById,
      [reportId]: report
    }

    return { ...state, reportsById }
  } else if('FETCH_REPORT' === action.type) {
    const reportId: string = action.report._id;
    const oldReport = state.reportsById?.[reportId];
    const newReportData = {
      ...oldReport?.data,
      ...createFilters(action.report),
      brandIds: action?.report?.brands?.map((b: any) => b._id),
      appIds: action?.report?.apps?.map((b: any) => b.metaId)
    } 
    const report = {
      ...oldReport,
      status: 'DETAIL_FETCHED',
      fetching: false,
      date: action.date,
      intervalType: action.intervalType,
      dataCounts: action.dataCounts,
      data: newReportData
    }
    
    if(action.demoMappings) { report.demoMappings = { ...report.demoMappings, ...action.demoMappings } }
    if(action.demoMapping) { report.demoMapping = action.demoMapping }
    if(action.usersById) { report.usersById = action.usersById }

    const reportsById = { ...state.reportsById, [reportId]: report }
    if(action.publicReportId) { reportsById[action.publicReportId] = report; }
    if(action.demoReportId) { reportsById[action.demoReportId] = report; }

    const newBrandsById = mapValues(groupBy(action.report.brands, b => b._id), bb => {
      const b = bb[0];
      const oldBrand = state.brandsById?.[b._id];
      if(oldBrand?.status === 'DETAIL_FETCHED') return oldBrand
      else return { ...oldBrand, data: b, status: 'FETCHED' }
    })

    const brandsById = {
      ...state.brandsById,
      ...newBrandsById
    }
    const appsById = {
      ...state.appsById,
      ...uniqueBy(action.report.apps, (a: any) => a.metaId, (a: any) => {
        const oldApp = state.appsById?.[a.metaId];
        if(oldApp?.status === 'DETAIL_FETCHED') return oldApp
        return { data: a, status: 'FETCHED' }
      })
    }

    const newAppIdsByBrandId = mapValues(
      groupBy(action.report.apps, a => a.brandId),
      apps => apps?.sort((a,b) => {
        if(a?.appProvider && b?.appProvider) return a.appProvider.localeCompare(b.appProvider);
        else if(a?.appProvider) return -1;
        else if(b?.appProvider) return 1;
        else return 0;
      }).map(a => a.metaId)
    )


    const appIdsByBrandId = {
      ...state.appIdsByBrandId,
      ...newAppIdsByBrandId
    }

    return { ...state, reportsById, brandsById, appsById, appIdsByBrandId }
  } else if('FETCH_REPORT_ERROR' === action.type) {
    const reportId = action.reportId || action.publicReportId || action.demoReportId;
    const oldReport = state.reportsById?.[reportId];
    const report = {
      ...oldReport,
      status: 'ERROR_FETCHING',
      fetching: false,
      error: true,
    }
    const reportsById = {
      ...state.reportsById,
      [reportId]: report
    }
    return { ...state, reportsById }
  } else if ('UPDATE_REPORT' === action.type) {
    const reportId = action.report._id;
    const oldReport = (state.reportsById || {})[reportId];
    const report = { ...oldReport }
    report.data = { ...report.data, ...action.report }
    report.isProcessing = action.isProcessing;
    report.jobs = action.jobs;

    if(action.usersById) { report.usersById = action.usersById }
    const reportsById = { ...state.reportsById, [reportId]: report }
    return { ...state, reportsById }
  } else if (action.type === 'DELETE_REPORT_START') {
    const currentReport = state.reportsById?.[action.reportId];

    const reportsById = {
      ...state.reportsById,
      [action.reportId]: {
        ...currentReport,
        deleting: true,
      }
    }

    return { ...state, reportsById }
  } else if (action.type === 'DELETE_REPORT') {
    const currentReport = state.reportsById?.[action.reportId];
    const reportsById = { ...state.reportsById }
    delete reportsById[action.reportId];

    const status = currentReport.data.status === 'draft' ? 'draft' : null;
    const currentReportIds = state.reportIdsByStatus?.[status]
    const reportIdsByStatusIdsSet = new Set( currentReportIds?.ids || []);
    reportIdsByStatusIdsSet.delete(action.reportId);

    const reportIds = {
      ...currentReportIds,
      ids: Array.from(reportIdsByStatusIdsSet)
    }

    const reportIdsByStatus = {
      ...state.reportIdsByStatus,
      [status]: reportIds
    }

    return { ...state, reportsById, reportIdsByStatus }
  } else if (action.type === 'TOGGLE_REPORT_ACTIVE_START') {
    const currentReport = state.reportsById?.[action.reportId];

    const reportsById = {
      ...state.reportsById,
      [action.reportId]: {
        ...currentReport,
        changingActive: true,
      }
    }

    return { ...state, reportsById }
  } else if (action.type === 'TOGGLE_REPORT_ACTIVE') {
    const currentReport = state.reportsById?.[action.reportId];

    const reportsById = {
      ...state.reportsById,
      [action.reportId]: {
        ...currentReport,
        data: Object.assign( {}, currentReport.data, { active: action.active } ),
        changingActive: undefined,
      }
    }

    return { ...state, reportsById }
  } else if('FETCH_BRAND_START' === action.type) {
    const oldBrand = (state.brandsById || {})[action.brandId];
    const brand = { ...oldBrand, status: 'FETCHING' }

    const brandsById = { ...state.brandsById, [action.brandId]: brand }
    return { ...state, brandsById }
  } else if('FETCH_BRAND' === action.type) {
    const oldBrand = (state.brandsById || {})[action.data._id];
    const brand = { ...oldBrand, status: 'DETAIL_FETCHED' }
    brand.data = { ...brand.data, ...action.data }
    
    const brandsById = { ...state.brandsById, [action.data._id]: brand }
    return { ...state, brandsById }
  } else if ('FETCH_BRAND_VOICE' === action.type) {
    const oldBrand = (state.brandsById || {})[action.brandId];
    const brand = { ...oldBrand, intervalType: action.intervalType, date: action.date }
    // brand.categories = { ...brand.categories, ...action.categories }
    // brand.voice = { ...brand.voice, ...action.data }
    brand.voiceV2 = { ...brand.voiceV2, ...action.dataV2 }

    const brandsById = { ...state.brandsById, [action.brandId]: brand }
    return { ...state, brandsById }
  } else if( action.type === 'FETCH_BRAND_HISTORY') {
    const oldBrand = (state.brandsById || {})[action.brandId];
    const brand = { ...oldBrand }
    brand.history = action.dataV2

    const brandsById = { ...state.brandsById, [action.brandId]: brand }
    return { ...state , brandsById}
  } else if( action.type === 'FETCH_BRAND_TFIDF') {
    const oldBrand = (state.brandsById || {})[action.brandId];
    const brand = { ...oldBrand, tfidf: action.data }

    const brandsById = { ...state.brandsById, [action.brandId]: brand }
    return { ...state, brandsById }
  } else if( action.type === 'FETCH_BRAND_TFIDF_ERROR') {
    const oldBrand = (state.brandsById || {})[action.brandId];
    const brand = { ...oldBrand, tfidf: undefined }

    const brandsById = { ...state.brandsById, [action.brandId]: brand }
    return { ...state, brandsById }
  } else if('FETCH_APP' === action.type) {
    const oldApp = (state.appsById || {})[action.metaId];
    const app = { ...oldApp, status: 'DETAIL_FETCHED' }
    app.data = { ...app.data, ...action.data }
    
    const appsById = { ...state.appsById, [action.metaId]: app }
    return { ...state, appsById }
  } else if ('FETCH_APP_VOICE' === action.type) {
    const oldApp = (state.appsById || {})[action.metaId];
    const app = { ...oldApp, intervalType: action.intervalType, date: action.date }
    // app.categories = { ...app.categories, ...action.categories }
    // app.voice = { ...app.voice, ...action.data }
    app.voiceV2 = { ...app.voiceV2, ...action.dataV2 }

    const appsById = { ...state.appsById, [action.metaId]: app }
    return { ...state, appsById }
  } else if( action.type === 'FETCH_APP_HISTORY') {
    const oldApp = (state.appsById || {})[action.metaId];
    const app = { ...oldApp, history: action.dataV2 }
    
    const appsById = { ...state.appsById, [action.metaId]: app }
    return { ...state , appsById}
  } else if( action.type === 'FETCH_APP_TFIDF') {
    const oldApp = (state.appsById || {})[action.metaId];
    const app = { ...oldApp, tfidf: action.data }
    const appsById = { ...state.appsById, [action.metaId]: app }
    
    return { ...state, appsById }
  } else if( action.type === 'FETCH_APP_TFIDF_ERROR') {
    const oldApp = (state.appsById || {})[action.metaId];
    const app = { ...oldApp, tfidf: undefined }
    const appsById = { ...state.appsById, [action.metaId]: app }
    return { ...state, appsById }
  } else if (action.type === 'CREATE_DEMO_LINK') {
    const oldReport = (state.reportsById || {})[action.data.reportId];
    const report = { ...oldReport }
    const oldBrandDemoMapping = report?.demoMappings?.[action.data.brandId] || [];
    const brandDemoMapping = [...oldBrandDemoMapping, action.data ];
    report.demoMappings = { ...report.demoMappings, [action.data.brandId]: brandDemoMapping }
    
    const reportsById = { ...state.reportsById, [action.data.reportId]: report }

    return { ...state, reportsById }
  } else if (action.type === 'DELETE_DEMO_LINK') {
    const oldReport = (state.reportsById || {})[action.data.reportId];
    const report = { ...oldReport }
    const brandDemoMapping = report?.demoMappings?.[action.data.brandId].filter((dm: any) => dm._id !== action.data._id);
    report.demoMappings = { ...report.demoMappings, [action.data.brandId]: brandDemoMapping }
    
    const reportsById = { ...state.reportsById, [action.data.reportId]: report }
    return { ...state, reportsById }
  } else if(action.type === 'REPORT_ADD_BRAND') {
    const oldReport = (state.reportsById || {})[action.reportId];
    const report = { ...oldReport }

    const reportData = report.data || {};

    const brandsById = { ...state.brandsById }
    if(action.brand) {
      if(action.brand.imageUrl) { reportData.imageUrl = action.brand.imageUrl }
      if(!(reportData?.brands?.find((b: any) => b._id === action.brand._id))) {
        reportData.brands = [ ...(reportData?.brands || []), action.brand ];
      }
      brandsById[action.brand._id] = {data: action.brand, status: 'FETCHED'}
    }
    reportData.brandIds = reportData?.brands?.map((b: any) => b._id)
    report.data = reportData;

    const reportsById = { ...state.reportsById, [action.reportId]: report }

    return { ...state, reportsById, brandsById }
  } else if (action.type === 'REPORT_UPDATE_BRAND') {
    const oldReport = (state.reportsById || {})[action.reportId];
    const report = { ...oldReport }

    const reportData = report.data;
    reportData.apps = (reportData?.apps || [])

    const appsById = { ...state.appsById }
    if(action.app) {
      appsById[action.app.metaId] = {data: action.app, status: 'FETCHED'}
      reportData.apps.push(action.app);
    }

    const brandsById = { ...state.brandsById }
    if(action.brand) {
      if(action.brand.imageUrl) { reportData.imageUrl = action.brand.imageUrl }
      const brandData = brandsById?.[action.brand?._id]?.data;
      const newBrandData = { ...brandData, ...action.brand }
      brandsById[action.brand._id] = { ...brandsById[action.brand._id], data: newBrandData }
      if(!(reportData?.brands?.find( (b: any) => b._id === action.brand._id))) {
        reportData.brands = [ ...(reportData?.brands || []), action.brand ];
      }
      if(action.app) {
        brandsById[action.app.metaId] = {data: action.brand, status: 'FETCHED'}
      }
    }

    report.data = reportData;
    const reportsById = { ...state.reportsById, [action.reportId]: report }

    const appIdsByBrandId = {
      ...state.appIdsByBrandId,
      ...mapValues(
        groupBy(reportData.apps, a => a.brandId),
        apps => apps?.sort((a,b) => a.appProvider.localeCompare(b.appProvider)).map(a => a.metaId) )
    }

    return { ...state, reportsById, appsById, brandsById, appIdsByBrandId }
  } else if (action.type === 'REPORT_ADD_APP') {
    const oldReport = (state.reportsById || {})[action.reportId];
    const report = { ...oldReport }

    const reportData = report.data;
    reportData.apps = (reportData?.apps || [])

    const appsById = { ...state.appsById }
    if(action.app) {
      appsById[action.app.metaId] = {data: action.app, status: 'FETCHED'}
      reportData.apps.push(action.app);
    }

    const brandsById = { ...state.brandsById }
    if(action.brand) {
      if(action.brand.imageUrl) { reportData.imageUrl = action.brand.imageUrl }
      if(!(reportData?.brands?.find( (b: any) => b._id === action.brand._id))) {
        reportData.brands = [ ...(reportData?.brands || []), action.brand ];
      }
      if(action.app) {
        brandsById[action.brand._id] = {data: action.brand, status: 'FETCHED'}
      }
    }

    reportData.brandIds = reportData?.brands?.map((b: any) => b._id)
    reportData.appIds = reportData?.apps?.map((b: any) => b.metaId)
    report.data = reportData;
    const reportsById = { ...state.reportsById, [action.reportId]: report }

    const appIdsByBrandId = {
      ...state.appIdsByBrandId,
      ...mapValues(
        groupBy(reportData.apps, a => a.brandId),
        apps => apps?.sort((a,b) => a.appProvider.localeCompare(b.appProvider)).map(a => a.metaId)
      )
    }

    return { ...state, reportsById, appsById, brandsById, appIdsByBrandId }
  } else if (action.type === 'REPORT_UPDATE_APP') {
    const oldReport = (state.reportsById || {})[action.reportId];
    const report = { ...oldReport }

    const reportData = report.data;
    reportData.apps = (reportData?.apps || [])

    const appsById = { ...state.appsById }
    if(action.app) {
      appsById[action.app.metaId] = {data: action.app, status: 'FETCHED'}
      reportData.apps.map((app: any) => {
        if(app?.metaId === action.app.metaId) return action.app;
        else return app;
      })
    }

    report.data = reportData;
    const reportsById = { ...state.reportsById, [action.reportId]: report }

    const appIdsByBrandId = {
      ...state.appIdsByBrandId,
      ...mapValues(
        groupBy(reportData.apps, a => a.brandId),
        apps => apps?.sort((a,b) => a.appProvider.localeCompare(b.appProvider))?.map(a => a.metaId) )
    }

    return { ...state, reportsById, appsById, appIdsByBrandId }
  } else if (action.type === 'REPORT_DELETE_APP') {
    const oldReport = (state.reportsById || {})[action.reportId];
    const report = { ...oldReport }

    const reportData = report.data;
    reportData.apps = reportData?.apps.filter((a: any) => a.metaId !== action.app.metaId)
    report.data = reportData;
    const reportsById = { ...state.reportsById, [action.reportId]: report }

    const appsById = {
      ...state.appsById,
      [action.app.metaId]: action.app,
    }

    const appIds = state.appIdsByBrandId?.[action.app.brandId];

    const appIdsByBrandId = {
      ...state.appIdsByBrandId,
      [action.app.brandId]: appIds.filter((id: any) => id !== action.app.metaId),
    }

    return { ...state, appsById, reportsById, appIdsByBrandId }
  } else if('FETCH_REPORT_PHRASES' === action.type) {
    const reportId = action.reportId;
    const oldReport = (state.reportsById || {})[reportId];
    const report = { ...oldReport }
    report.phrases = action.data;
    const reportsById = { ...state.reportsById, [action.reportId]: report }
    return { ...state, reportsById };
  } else if('FETCH_REPORT_STATUS' === action.type) {
    const reportId = action.reportId;
    const oldReport = (state.reportsById || {})[reportId];
    const report = { ...oldReport }
    report.isProcessing = action.isProcessing;
    report.jobs = action.jobs;
    const reportsById = { ...state.reportsById, [action.reportId]: report }
    
    const currentAppsById = state.appsById

    // appsById in state have a wrapping data property
    // add such data property
    const appsByIdStatuses = mapValues(action.appsById, (v) => ( { data: v } ) )
    const appsById = merge(currentAppsById, appsByIdStatuses)

    return { ...state, reportsById, appsById }
  } else if ('RESET' === action.type) {
    return initialState
  }
  throw new Error();
}

const createFilters = (report: any) => {
  if(report.brandGroups && Object.keys(report.brandGroups).length > 0) {
    const brandsById = mapValues(groupBy(report.brands, b => b._id), b => b[0])
    const filters = [
      { value: '', label: 'All' },
      ...Object.keys(report.brandGroups).map(key => {
        return {
          label: key,
          options: [
            { value: key, label: `All ${key}` },
            ...(report.brandGroups[key].map((k: any) => {
              const brand = brandsById[k];
              if(brand == null) return null;
              return { value: `${key}_${brand._id}`, label: brand.name, icon: brand.imageUrl };
            }).filter((a: any)=>a) || [])
          ]
        }
      })
    ];
    return { ...report, filters }
  } else {
    let filters = report?.brands?.map((brand: any) => ({ value: `_${brand._id}`, label: brand.name, icon: brand.imageUrl }));
    if(filters?.length > 1) {
      filters.unshift({value: "", label: 'All'});
    }
    return { ...report, filters }
  }
}