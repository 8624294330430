import React from 'react'

import styles from './cell.module.scss'

interface CellProps extends React.HTMLProps<HTMLDirectoryElement> {
  tagName?: any,
  sticky?: Boolean,
  deleting?: Boolean,
  active?: Boolean,
  index: number,
  colIndex?: number,
  to?: string,
  state?: any,
  href?: string,
}


export const Cell: React.FC<CellProps> = ({tagName, index, deleting, sticky, className, active, children, ...props}) => {
  const Tag: any = tagName || 'div'

  const classList = [
    styles.cell,
    sticky ? styles.sticky : undefined,
    index % 2 === 0 ? styles.even : undefined,
    deleting ? styles.deleting : undefined,
    active ? styles.active : undefined,
    className,
  ].join(' ');

  return <Tag {...props} className={classList}>
    {children}
  </Tag>
}

export default Cell;