import React from 'react'

import styles from './bubbleFooterBar.module.scss'

interface BubbleFooterBarProps {
  showIntro: boolean,
  index: number,
  length: number
}

export const BubbleFooterBar: React.FC<BubbleFooterBarProps> = ({showIntro, index, length}) => {
  const style = {
    '--circle-spacer': '4px',
    '--circle-size': `calc(var(--bubble-progess-bar-height, 0) * 0.75)`,
    '--small-circle-size': `calc(var(--bubble-progess-bar-height, 0) * 0.25)`,
    transform: [
      `translate( calc(var(--circle-size, 0)*-0.5), 0)`,
      `translate( calc(var(--small-circle-size, 0)*${-index}), 0)`,
      `translate( calc(var(--circle-spacer, 0)*${-1}), 0)`,
      `translate( calc(var(--circle-spacer, 0)*${-index*2}), 0)`,
    ].join(' ')
  }
  return <footer className={[styles.bubbleFooterBar, (showIntro && index === 0) ? styles.hidden : null].join(' ')}>
    <div className={styles.bubbleFooterBarContent} style={style}>
      {Array.from({length: length - 2}, (_, k) => <div className={[styles.bubbleFooterBubble, (index === (k + (showIntro ? 1 : 0)) ) ? styles.selected : null].join(' ')} key={k}>
        Q{k+1}
      </div>)}
    </div>
  </footer>
}

export default BubbleFooterBar