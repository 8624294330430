import React, {useEffect, useState} from 'react';
import { isValidAmplitudeInstance } from './lib/validation';
import { Loading } from '../../components/loading/loading'


type ContextProps = {
  amplitudeUserId?: string,
  amplitudeInstance?: any
}

export const AmplitudeContext = React.createContext<ContextProps>({});

interface AmplitudeProviderProps extends React.HTMLProps<HTMLDivElement> {
  amplitudeInstance: any,
  apiKey: string,
  userId: string
}

const AmplitudeProvider: React.FC<AmplitudeProviderProps> = ({ amplitudeInstance, apiKey, userId, children }) => {
  const [amplitudeUserId, setAmplitudeUserId] = useState(null)
  useEffect(() => {
    if (isValidAmplitudeInstance(amplitudeInstance)) {
      if (apiKey) {
        amplitudeInstance.init(apiKey, null, null, (instance: any) => {
          setAmplitudeUserId(instance.options.deviceId);
        });
      }
      if (userId) amplitudeInstance.setUserId(userId);
    } else {
      console.error('AmplitudeProvider was not provided with a valid "amplitudeInstance" prop.');
    }
  }, [amplitudeInstance, apiKey, userId]);

  if(amplitudeUserId != null) {
    return <AmplitudeContext.Provider value={{amplitudeUserId, amplitudeInstance}}>
      {children}
    </AmplitudeContext.Provider>
  }
  
  return <AmplitudeContext.Provider value={{amplitudeInstance}}>
    <Loading />
  </AmplitudeContext.Provider>
}

export default AmplitudeProvider;