import React from 'react'
import { PlusIcon } from '../icons/icons'
import { AddIconPopup } from '../../libs/popup/popup'

import styles from './icon.module.css'

const isString = (test: any) => typeof test === 'string' || test instanceof String

interface IconProps extends React.HTMLProps<HTMLDivElement> {
  src?: any,
  tagName?: any,
  grayscale?: boolean,
  rounded?: boolean,
  onUpload?: any,
  fakeText?: string,
  background?: string
}

export const Icon: React.FC<IconProps> = ({src, style, tagName, className, grayscale, rounded, onUpload, fakeText, background, ...props}) => {
  const [showPopup, setShowPopup] = React.useState(false);
  let img = src;

  const onSubmit = (value: any) => {
    if(onUpload) onUpload(value);
    setShowPopup(false);
  }

  const Tag = tagName || 'div';

  const onClick =  (img == null && onUpload != null) ? () => setShowPopup(true) : undefined;
  const onDoubleClick =  (img != null && onUpload != null) ? () => setShowPopup(true) : undefined;

  return <Tag className={[className, grayscale ? styles.grayscale : null, rounded ? styles.rounded : null, styles.icon].join(' ')} style={{...style, '--background': background}} onClick={onClick} onDoubleClick={onDoubleClick} {...props}>
    {img != null && isString(img) && <div style={{backgroundImage: `url("${img}")`}} className={styles.img} />}
    {img != null && !isString(img) && img !== false && React.createElement(img)}
    {img == null && onUpload != null && <PlusIcon />}
    {img == null && onUpload == null && fakeText}

    {showPopup && <AddIconPopup onClose={() => setShowPopup(false)} onSubmit={onSubmit} />}
  </Tag>
}

export default Icon;