import React from 'react'
import { createPortal } from 'react-dom';
import usePortal from '../usePortal'

import styles from './toast.module.scss'

export const ToastPortal: React.FC<React.HTMLProps<HTMLDivElement>> = ({ id, children }) => {
  const target = usePortal(id || 'modal-toast');
  return createPortal(children, target);
}



type ContextProps = {
  showToast: (message: any, timeout?: number) => void,
  hideToast: () => void
}

const initialState: ContextProps = {
  showToast: () => { throw new Error("showToast not implemented")},
  hideToast: () => { throw new Error("hideToast not implemented")},
};


export const ToastContext = React.createContext<ContextProps>(initialState);
export const ToastProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({children}) => {
  const [showing, setShowing] = React.useState(false);
  const [showingTimeout, setShowingTimeout] = React.useState(2000);
  const [message, setMessage] = React.useState("");
  React.useEffect(() => {
    if(!showing && showingTimeout < 0) return;
    const ref = window.setTimeout(() => {
      setShowing(false);
    }, showingTimeout)

    return () => {
      window.clearTimeout(ref);
    }
  }, [showing, showingTimeout])

  const showToast = (message: any, timeout: number) => {
    setMessage(message);
    setShowing(true);
    if(timeout != null) setShowingTimeout(timeout);
  }
  const hideToast = () => {
    setShowing(false);
  }
  
  const value: ContextProps = {showToast, hideToast}

  return <ToastContext.Provider value={value}>
    {children}
    <ToastPortal>
      <div className={[styles.container, showing ? styles.showing : null].join(' ')}>
        <div className={styles.content}>{message}</div>
      </div>
    </ToastPortal>
  </ToastContext.Provider>
}