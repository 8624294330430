import React from 'react'
import classnames from 'classnames'

import styles from './table.module.scss'

interface TableProps extends React.HTMLProps<HTMLDivElement> {
  columns: string
}

export const Table: React.FC<TableProps> = ({children, className, columns, style}) => {
  const _style: any = {
    ...style,
    '--table-template-columns': columns
  }
  
  return <div className={classnames(styles.tableContainer, className)} style={_style}>
    <div className={styles.table}>
    {children}
    </div>
  </div>
}

export default Table