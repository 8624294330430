import React from 'react';

import { useUsersContext } from '../../contexts'
import { Loading } from '../../components/loading/loading'
import { OutlineButton } from '../../components/button/button'

import styles from './users.module.scss'

interface UserProps {
  data: any,
  updateUserPermissions: any,
  index: any
}

const User: React.FC<UserProps> = ({ data, updateUserPermissions, index }) => {
  const canCreateReport = data.permissions.canCreateReport;
  return <div className={styles.user}>
    <span className={styles.index}>{index}.)</span>
    {data.user.email}
    <div className={styles.spacer} />
    {!data.user.isAdmin && <>
      {!canCreateReport && <OutlineButton title="Set Write permissions" onClick={() => updateUserPermissions(data.user._id, {canCreateReport: true})} />}
      {canCreateReport && <OutlineButton title="unset Write permissions" onClick={() => updateUserPermissions(data.user._id, {canCreateReport: false})} />}
    </>}
    {data.user.isAdmin && <OutlineButton title="Admin" className={styles.admin} />}
  </div>
}


export const Users = () => {
  const [state, {fetchUsers, updateUserPermissions}] = useUsersContext();
  React.useEffect(() => {
    if(state.users == null) fetchUsers();
  }, [state.users, fetchUsers])

  const _updateUserPermissions = (userId: string, data: any) => updateUserPermissions(userId, data)

  
  const sortedUsers: any[] = state?.users?.users?.sort( (a: any, b: any) => {
    if(a.user.isAdmin && !b.user.isAdmin) return -1;
    else if(!a.user.isAdmin && b.user.isAdmin) return 1;
    else return 0;
  })

  return <div>
    <div className={styles.container}>
      {!sortedUsers && <Loading className={styles.loading} />}
      {sortedUsers && <>
        <div className={styles.gridHeader}>USERS</div>
        {sortedUsers.map((user, i) =>
          <User key={user.user._id} data={user} index={i+1} updateUserPermissions={_updateUserPermissions} />
        )}
      </>}
    </div>
  </div>
}

export default Users;