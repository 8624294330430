import React, { createContext, useReducer, useContext } from 'react'
import 'firebase/compat/auth'; //v9


import { useAuthContext } from './auth.context'
import { fetchUrl, headersWithXToken } from './utils'
import { AmplitudeContext } from '../libs/react-amplitude'
import { reducer, initialState, ContextPropsState } from './users.reducer'


type ContextPropsActions = {
  fetchUsers: () => void,
  updateUserPermissions: (userId: string, permissions: any) => void,
  fetchCards: () => void,
  deleteCard: (cardId: string) => void,
  addCard: (stripeToken: string) => void,
  reset: () => void,
}

const initialActions: ContextPropsActions = {
  fetchUsers: () => { throw new Error("fetchUsers not implemented") },
  updateUserPermissions: () => { throw new Error("updateUserPermissions not implemented") },
  fetchCards: () => { throw new Error("fetchCards not implemented") },
  deleteCard: () => { throw new Error("deleteCard not implemented") },
  addCard: () => { throw new Error("addCard not implemented") },
  reset: () => { throw new Error("reset not implemented") },
}

type ContextProps = [ContextPropsState, ContextPropsActions]


export const UsersContext = createContext<ContextProps>([initialState, initialActions]);

export const UsersProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [{ user }] = useAuthContext();
  const {amplitudeInstance} = useContext(AmplitudeContext);

  const fetchUsers = React.useCallback( async () => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = '/api/admin/users';
      const data = await fetchUrl('GET', url, { headers });
      dispatch({ type: 'FETCHED_USERS', data })
      return data;
    } catch (error) {
      console.error('fetchUsers', error);
    }
  }, [amplitudeInstance, user])

  const updateUserPermissions = React.useCallback( async (userId, permissions) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/admin/users/${userId}/permissions`;
      const data = await fetchUrl('POST', url, {body: permissions, headers });
      dispatch({ type: 'UPDATED_USER_PERMISSIONS', data })
      return data;
    } catch (error) {
      console.error('updateUserPermissions', error);
    }
  }, [amplitudeInstance, user])


  const fetchCards = React.useCallback( async () => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = '/api/cards'
      const data = await fetchUrl('GET', url, { headers });
      dispatch({ type: 'FETCH_CARDS', data })
      return data;
    } catch (error) {
      console.error('fetchCards', error)
    }
  }, [amplitudeInstance, user])
  const deleteCard = React.useCallback( async (cardId) => {
    if(cardId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/cards/${cardId}`;
      const data = await fetchUrl('DELETE', url, { headers });
      dispatch({ type: 'DELETE_CARD', data })
      return data;
    } catch (error) {
      console.error('deleteCard', error)
    }
  }, [amplitudeInstance, user])
  const addCard = React.useCallback( async (stripeToken) => {
    if(stripeToken == null) return;
    const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
    const url = '/api/cards'
    const data = await fetchUrl('POST', url, { body: { stripeToken }, headers });
    dispatch({ type: 'ADD_CARD', data })
    return data;
  }, [amplitudeInstance, user])

  const reset = React.useCallback( () => dispatch({ type: 'RESET' }), [])

  const actions = {
    fetchUsers,
    updateUserPermissions,
    reset,
    addCard,
    deleteCard,
    fetchCards
  }

  const value: ContextProps = [state, actions]

  return <UsersContext.Provider value={value}>
    {children}
  </UsersContext.Provider>
}


export const useUsersContext = () => React.useContext(UsersContext)