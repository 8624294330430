import React from 'react'
import Tooltip from '../../libs/context-tooltip/tooltip'
import { getColor, getScore } from '../../utils'
import { conceptIconByName } from '../../utils2'
import Icon from '../icon/icon'
import { Market } from '../icons/icons'
import styles from './insightPolarChart.module.scss'


const getAngle = (i: number, length: number) => (i * 2 * Math.PI / length) - Math.PI / 2;

interface InsightPolarChartProps extends React.HTMLProps<HTMLDivElement> {
  width: number,
  height: number,
  concepts: any,
  report: any,
  brands: any[],
}

export const InsightPolarChart: React.FC<InsightPolarChartProps> = ({width = 500, height = 500, concepts, report, brands, className, onClick}) => {
  if(concepts == null || brands == null) return null;

  const inset = 50;
  const sections = [20, 40, 60, 80, 100];
  const radius = Math.min(width, height)/2 - inset;

  const paths = brands.map((brand, i) => {
    if(brand == null) return {};
    const brandData = brand?.data;
    const color = getColor(i);
    const points = concepts.map( (cat: any, pointIndex: number) => {
      const brandsByCategory = report?.dataCounts?.brandsByCategory;
      const categoryScore = brandsByCategory?.[cat._id];
      const brandScore = categoryScore?.[brandData?._id];
      
      let score = 0;
      let count: number = 0;
      if(brandScore == null) {
        const categoryScoreValues: any[] = categoryScore && Object.values(categoryScore);
        const categoryScores = categoryScoreValues?.map((v: any) => (getScore(v.categoryScore) || 0) );
        score = (categoryScores?.reduce((a, b) => a + b, 0)/categoryScores?.length) || 0
        count = (categoryScoreValues?.reduce( (p: number, c: {count: number}) => p + c.count, 0)) || 0
      } else {
        score = getScore(brandScore?.categoryScore) || 0;
        count = brandScore.count;
      }
      
      const angle = getAngle(pointIndex, concepts.length);

      const x = radius * (1 + Math.cos(angle) * score / 100) + inset;
      const y = radius * (1 + Math.sin(angle) * score / 100) + inset;

      if(brandData == null) {
        return { x, y, score, color, name: "Market Average", imageUrl: Market, count, conceptName: cat.name }
      } else {
        return { x, y, score, color, name: brandData?.name, imageUrl: brandData?.imageUrl, count, conceptName: cat.name, brandId: brandData?._id, conceptId: cat._id }
      }
    })
    if(points == null || points.length === 0) return null;
    return {points, color};
  });

  return <div className={[styles.polarContent, className].join(" ")} style={{width, height}}>
    <div className={styles.concepts}>
      {concepts?.map((c: any, i: number) => {
        const angle = getAngle(i, concepts.length);

        const x = (1 + Math.cos(angle)) * radius + inset;
        const y = (1 + Math.sin(angle)) * radius + inset;
        const angleLegibility = (angle > 0 && angle < Math.PI) ? '-180deg' : '0';
        const Icon = conceptIconByName[c.name];
        return <div key={c._id} className={styles.label} style={{ transform: `translate(${x}px, ${y}px) translate(-50%, -50%) rotate(${angle * 180 / Math.PI}deg) rotate(90deg) translate(0, -50%) translate(0, -14px) rotate(${angleLegibility})` }}>
          {Icon && React.createElement(Icon, { className: styles.conceptIcon })}
          <div>
            {c?.name?.split(' ')?.map((l: any, i: number) => <div key={i}>{l}</div>)}
          </div>
        </div>
      })}
    </div>
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} className={styles.containerChart}>
      <SpiderAxis inset={inset} concepts={concepts} axis={sections} radius={radius} />
      <ScoreAxis height={height} width={width} concepts={concepts} radius={radius} inset={inset} />
      <ScoreAxisLabels height={height} width={width} axis={sections} concepts={concepts} radius={radius} />

      <BrandArea paths={paths} />
      <BrandPoints paths={paths} onClick={onClick} />
    </svg>
  </div>
}

export default InsightPolarChart

interface ScoreAxisLabelsProps {
  concepts: any,
  axis: any,
  radius: number,
  height: number,
  width: number
}

const ScoreAxisLabels: React.FC<ScoreAxisLabelsProps> = ({concepts, axis, radius, height, width}) => {
  if(concepts == null || concepts.length === 0) return null;
  if(axis == null || axis.length === 0) return null;

  return axis.map( (s: any) => {
    const rectWidth = 20 + (s === 100 ? 8 : 0);
    const rectHeight = 16;
    const x = width/2 - rectWidth/2;
    const y = height/2  - (radius * s / 100) - rectHeight/2
    return <React.Fragment key={s} >
      <rect x={x} y={y} width={rectWidth} height={rectHeight} rx={4} className={styles.labelRect} />
      <text x={width/2} y={height/2  - (radius * s / 100) + 1} className={styles.axisText} alignmentBaseline="middle" textAnchor="middle" dominantBaseline="middle">{s}</text>
    </React.Fragment>;
  })
}

interface AxisProps extends React.HTMLProps<HTMLDivElement> {
  concepts: any[],
  width?: number,
  height?: number,
  radius: number,
  inset: any,
  axis?: any[]
}

const ScoreAxis: React.FC<AxisProps> = ({concepts, width, height, radius, inset}) => {
  if(concepts == null || concepts.length === 0) return null;

  return <>
    {concepts.map((_, i) => {
      const angle = getAngle(i, concepts.length);
      const x = radius * (1 + Math.cos(angle)) + inset;
      const y = radius * (1 + Math.sin(angle)) + inset;
      const rectSize = 10;

      return <React.Fragment key={i}>
        <line x1={x} y1={y} x2={width/2} y2={height/2} className={styles.axis} />
        <rect x={x - rectSize/2} y={y - rectSize/2} width={rectSize} height={rectSize} rx={4} className={styles.labelRect} />
      </React.Fragment>
    })}
  </>
}

const SpiderAxis: React.FC<AxisProps> = ({concepts, axis, radius, inset}) => {
  if(concepts == null || concepts.length === 0) return null;
  if(axis == null || axis.length === 0) return null;

  return <>
    {axis.map( (s, axisIndex) => {
      const path = concepts.map( (_, i) => {
        const angle = getAngle(i, concepts.length);
        const r = radius * (axisIndex + 1) / axis.length

        const x = r * Math.cos(angle) + inset + radius;
        const y = r * Math.sin(angle) + inset + radius;
        return `${(i===0) ? 'M' : 'L'} ${x} ${y}`
      }).join(' ')

      if(!path) return null;

      return <path key={s} d={path + 'Z'} className={styles.axis} />
    })}
  </>
}

interface BrandAreaProps extends React.HTMLProps<HTMLDivElement> {
  paths?: any[]
}

const BrandArea: React.FC<BrandAreaProps> = ({paths}) => {
  if(paths == null) return null;
  return <>
    {paths.map((p, i) => {
      if(p == null) return null;
      const path = p.points && (p.points.map((p: any, i: number) => `${i === 0 ? 'M' : 'L'} ${p.x} ${p.y}`).join(' ') + 'Z');
      const style: any = {'--color': p.color}
      return <path d={path} style={style} key={i} className={styles.path} />
    })}
  </>
}

const BrandPoints: React.FC<BrandAreaProps> = React.memo(({paths, onClick}) => {
  return <>
    {paths?.map(pObj => {
      if(pObj == null) return null;

      return pObj?.points?.map( (point: any, i: number) => {
        const style: any = {'--color': pObj.color}
        return <React.Fragment key={i}>
          <Tooltip tagName="circle" cx={point.x} cy={point.y} r={5} style={style} className={styles.point} background="#fff" tooltipClassName={styles.circleTooltipContainer} content={<CircleTooltip data={point} />} onClick={onClick ? () => {onClick(point)} : undefined} />
        </React.Fragment>
      })
    })}
  </>
})

interface CircleTooltipProps {
  data: any
}

const CircleTooltip: React.FC<CircleTooltipProps> = ({data}) => {
  let isPosString = null;
  if(data.score > 50) isPosString = <strong className={styles.positive}>positive</strong>
  else isPosString = <strong className={styles.negative}>positive</strong>

  const style: any = {'--color': data.color}
  return <div className={styles.circleTooltip} style={style}>
    <Icon src={data.imageUrl} rounded />
    <div className={styles.tooltipText}>
      <div className={styles.tooltipTextHeader}>{data.name}</div>
      <p><strong>{data.count}</strong> reviews contain keywords that likely indicate {data.conceptName}.</p>
      <p><strong>{data.score}%</strong> of these are {isPosString}.</p>
    </div>
  </div>
}