import React from 'react';
import { useReportsContext } from '../../contexts';
import Tooltip from '../../libs/context-tooltip/tooltip';
import DraftSection from '../draftSection/draftSection';
import Icon from '../icon/icon';
import { DeleteIcon } from '../icons/icons';
import Input from '../input/input';
import styles from './draftBrandGroupsSection.module.scss';

interface DraftBrandGroupsSectionPRops {
  opened: any,
  to?: string,
  report: any,
  brandsById: any,
}

export const DraftBrandGroupsSection: React.FC<DraftBrandGroupsSectionPRops> = ({ opened, to, report, brandsById }) => {
  const [, {updateReportData, updateReportDataLocally}] = useReportsContext();
  const [addingGroupName, setAddingGroupName] = React.useState<any>();
  const brandIds = React.useMemo(() => {
    const brandGroups: any = report?.data?.brandGroups && Object.values(report.data.brandGroups).reduce( (p: any, c: any) => [...c, ...p], []);
    return report?.data?.brandIds?.filter((brandId: string) => (brandGroups?.indexOf(brandId)) < 0);
  }, [report])

  if(report == null) return null;
  if(!opened) return <DraftSection title="Group Brands" to={to} icon="G" />

  const submitGroup = (e: any) => {
    e.preventDefault();

    const brandGroups = report?.data?.brandGroups;
    const newBrandGroups = { ...brandGroups, [addingGroupName]: [] };
    const _id = report?.data?._id;
    const newReport = { _id, brandGroups: newBrandGroups };
    updateReportDataLocally(newReport);
    updateReportData(newReport)

    setAddingGroupName(null);
  }

  const deleteGroup = (group: any) => {
    const brandGroups = report?.data?.brandGroups;
    const newBrandGroups = { ...brandGroups };
    delete newBrandGroups[group];

    const _id = report?.data?._id;
    const newReport = { _id, brandGroups: newBrandGroups };
    updateReportDataLocally(newReport);
    updateReportData(newReport)
  }


  const onDragStart = (e: any, data: any, group?: any) => {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('_id', data._id);
    e.dataTransfer.setData('group', group);
    document.body.classList.add('dragging');
  }
  const onDragEnd = () => document.body.classList.remove('dragging');
  const onDrop = async (group: any, e: any) => {
    const id = e.dataTransfer.getData('_id');
    const previousGroup = e.dataTransfer.getData('group');
    const brandGroups = report?.data?.brandGroups;
    let groupBrands = brandGroups?.[group];
    if(groupBrands.indexOf(id) < 0) groupBrands.push(id);
    
    let prevGroupBrands = previousGroup && brandGroups?.[previousGroup];
    if(prevGroupBrands) {
      const prevGroupIndex = prevGroupBrands.indexOf(id);
      if(prevGroupIndex >= 0) prevGroupBrands.splice(prevGroupIndex, 1);
    }

    const newBrandGroups = { ...brandGroups, [group]: groupBrands.filter((a: any)=>a) };
    if(previousGroup && prevGroupBrands) {
      newBrandGroups[previousGroup] = prevGroupBrands.filter((a: any)=>a)
    }

    const _id = report?.data?._id;
    const newReport = { _id, brandGroups: newBrandGroups };
    updateReportDataLocally(newReport);
    updateReportData(newReport)
  }
  const onDragOver = (event: any) => event.preventDefault();

  const deleteBrand = (group: any, id: any) => () => {
    const brandGroups = report?.data?.brandGroups;
    let groupBrands = brandGroups && brandGroups[group];
    const idIndex = groupBrands.indexOf(id);
    if(idIndex >= 0) {
      groupBrands.splice(idIndex, 1);
    }

    const newBrandGroups = { ...brandGroups, [group]: groupBrands };

    const _id = report?.data?._id;
    const newReport = { _id, brandGroups: newBrandGroups };
    updateReportDataLocally(newReport);
    updateReportData(newReport)
  }

  const brandGroups = report?.data?.brandGroups && Object.keys(report.data.brandGroups).sort();
  return <DraftSection title="Group brands" icon="G">
    <div className={styles.groups}>
      {brandGroups?.map(group => {
        const ids = report.data.brandGroups[group];
        return <React.Fragment key={group}>
          <div className={styles.groupName}>{group}</div>
          <div className={styles.groupValues} onDrop={(e) => onDrop(group, e)} onDragOver={onDragOver}>
            {ids?.map((id: string) => {
              const brand = brandsById?.[id];
              if(brand == null) return null;
              const brandData = brand.data;
              return <Tooltip key={brandData._id} content={brandData.name}>
                <Icon key={brandData._id} src={brandData.imageUrl} rounded className={styles.icon} onDragStart={(e) => onDragStart(e, brandData, group)} onDragEnd={onDragEnd} draggable onClick={deleteBrand(group, brandData._id)} />
              </Tooltip>
            })}
          </div>
          <div className={styles.deleteIcon} onClick={() => deleteGroup(group)}>
            <DeleteIcon />
          </div>
        </React.Fragment>
      })}
    </div>
    
    <form onSubmit={submitGroup}>
      <Input placeholder="+ Add Group" onChange={(e: any) => setAddingGroupName(e.target.value)} value={addingGroupName || ""} />
    </form>

    {brandIds?.length > 0 && <div>Ungrouped brands</div>}
    <div className={styles.brands}>
      {brandIds?.filter((brandId: string) => brandsById?.[brandId]?.data?.imageUrl)?.map((brandId: string) => {
        const brand = brandsById?.[brandId];
        return <Tooltip className={styles.icon} key={brandId} content={brand.data.name}>
          <Icon key={brandId} src={brand.data.imageUrl} rounded className={styles.icon} onDragStart={(e) => onDragStart(e, brand.data)} onDragEnd={onDragEnd} draggable />
        </Tooltip>
      })}
    </div>
  </DraftSection>
}

export default DraftBrandGroupsSection