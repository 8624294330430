import React from 'react'
import { getRangeColor2 } from '../../utils'

import styles from './versionHistory.module.scss'

const getWeekNumber = (d: Date) => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil(( ( (d.valueOf() - yearStart.valueOf()) / 86400000) + 1)/7);
}

interface VersionHistoryProps {
  data: any,
  intervalType: any
}

export const VersionHistory: React.FC<VersionHistoryProps> = ({data, intervalType}) => {
  const NUM = 50;

  return <div className={styles.container}>
    <div className={styles.container2}>
      {data != null && <div className={styles.header}>Each rectangle is at most {NUM} reviews</div>}
      {data?.map((d: any, key: number) => {
        const counts = d.counts;
        const sentimentValues: any[] = counts.byCategoryScore;
        // firebase format
        // const date = new Date(d.intervalBucket.date._seconds*1000);
        // string date format
        const date = new Date(d.intervalBucket?.date);
        const monthString = date.toLocaleString('en-us', { month: 'short' });
        const yearString = date.getUTCFullYear();
        const monthNumber = date.getMonth();
        const weekNumber = getWeekNumber(date);
        const quarter = Math.floor(monthNumber/3)
        
        let label = ''
        if(intervalType === 'year') label = `${yearString}`;
        else if(intervalType === 'quarter') label = `Q${4 - (monthNumber+1)%4} ${yearString}`;
        else if(intervalType === 'month') label = `${monthString} ${yearString}`;
        else if(intervalType === 'week') { label = `week ${weekNumber}, ${yearString}`; }

        return <React.Fragment key={key}>
          {monthNumber === 11 && key !== 0 && <div className={styles.yearSpacer} />}
          <div className={[styles.row, styles[`quarter${quarter}`]].join(' ')}>
            <div className={styles.label}>{label}</div>
            <div className={styles.scoreRow}>
              {sentimentValues?.map((value, i) => {
                if(value === 0) return null;
                const width = 100 * value / counts.categorized;
                return <React.Fragment key={i}>
                  <div style={{width: `calc(${width}% - 1px)`}} className={styles.bucket}>
                    {Array.from({length: Math.ceil(value/NUM)}, (_, j) => {
                      return <div key={j} style={{background: getRangeColor2((i+1)*20)}} />
                    })}
                  </div>
                </React.Fragment>
              })}
            </div>
            <div className={styles.labelRight}>{counts.categorized}</div>
          </div>
        </React.Fragment>
      })}
    </div>
  </div>
}

export default VersionHistory