import React, { CSSProperties } from 'react'
import Color from 'color'

import {useComponentSize} from '../../hooks/index'
import Loading from '../../components/loading/loading'
import { Popup } from '../../libs/popup/popup'
import InsightPolarChart from '../../components/insightPolarChart/insightPolarChart'
import Icon from '../../components/icon/icon'
import {Market} from '../../components/icons/icons'
import Card from '../card/card'
import {getColor} from '../../utils'

import styles from './polarCard.module.scss'

interface PolarCardProps {
  demoReportId: string,
  report: any,
  concepts: any[],
  sortedBrands: any[],
  navigate: any,
  brandsById?: any,
}

export const PolarCard: React.FC<PolarCardProps> = React.memo(({demoReportId, report, concepts, sortedBrands, navigate }) => {
  const ref = React.useRef();
  const {width, height} = useComponentSize(ref);
  const [showingPopupIndex, setShowingPopupIndex] = React.useState(null);
  const [selectedBrands, setSelectedBrands] = React.useState(sortedBrands?.slice(0, 2));
  const reportId = report?.data?._id;
  const demoMapping = report?.demoMapping;
  const demoMappingBrandId = demoMapping?.brandId;
  
  React.useEffect(() => {
    if(sortedBrands == null) return;
    
    if(demoMappingBrandId) {
      const brand = sortedBrands?.find((b: any) => b.data._id === demoMappingBrandId);
      setSelectedBrands([brand, {}]);
    } else {
      setSelectedBrands(sortedBrands?.slice(0, 2));
    }
  }, [sortedBrands, demoMappingBrandId]);

  if(report == null || report.data == null) return <LoadingCard />
  if(selectedBrands == null) return <LoadingCard />
  if(report.dataCounts == null) return <LoadingCard />

  const size = Math.min(width, height);
  const filters = <BrandPicker selectedBrands={selectedBrands} setShowingPopupIndex={!demoReportId && setShowingPopupIndex} brandCounts={report?.dataCounts?.brands} />;

  const clickCircle = (point: any) => {
    if(point?.conceptId && point?.brandId) {
      if (navigate) navigate(`/reports/${reportId}/brands/${point.brandId}?category=${point.conceptId}`)
    }
  }

  return <Card header="Polar Sentiment"
    description="We capture sentiment across key concepts mentioned across customer feedback"
    fileName={`${report.data.name}_polar.png`}
    filters={filters}>
    <div className={styles.container} ref={ref}>
      {size !== 0 && <InsightPolarChart report={report}
        brands={selectedBrands}
        width={size}
        height={size}
        concepts={concepts?.filter(c => c.countInScore)}
        onClick={clickCircle} />}
    </div>
    {showingPopupIndex != null && <BrandPopup selectedBrands={selectedBrands}
      setSelectedBrands={setSelectedBrands}
      showingPopupIndex={showingPopupIndex}
      setShowingPopupIndex={setShowingPopupIndex}
      brands={sortedBrands} />}
  </Card>
})

const LoadingCard = () => <Card header="Polar Sentiment" description="We capture sentiment across key concepts mentioned across customer feedback">
  <Loading />
</Card>

export default PolarCard;

interface BrandPickerProps {
  selectedBrands: any[],
  setShowingPopupIndex: any,
  brandCounts?: any,
}

const BrandPicker: React.FC<BrandPickerProps> = ({selectedBrands, setShowingPopupIndex, brandCounts}) => <div className={styles.brandPicker}>
  {selectedBrands.map( (brand, i) => {
    const color = getColor(i);
    const style: any = {'--color': color};
    const onClick = setShowingPopupIndex ? (() => setShowingPopupIndex(i)) : undefined;

    if(brand == null) return <div key={i} className={styles.iconBucketWrap} style={style} onClick={onClick}>
      <div className={styles.brandPlaceholderIconBucket} />
    </div>
    if(Object.keys(brand).length === 0) {
      return <div key={i} className={styles.iconBucketWrap} style={style} onClick={onClick}>
        <div className={styles.iconBucket}>
          <Icon src={Market} rounded />
        </div>
        <div className={styles.iconBucketContent}>
          <div className={styles.iconBucketContentHeader}>Market benchmark</div>
        </div>
      </div>
    }

    const brandData = brand?.data;
    const brandCount = brandCounts?.[brandData?._id];

    const rankValue = brandCount?.ratingRank?.value;
    const outOf = brandCount?.ratingRank?.outOf;
    return <div key={i} className={styles.iconBucketWrap} style={style} onClick={onClick}>
      <div className={styles.iconBucket}>
        <Icon src={brandData?.imageUrl} rounded background={brandData?.dominantColor} />
      </div>
      <div className={styles.iconBucketContent}>
        <div className={styles.iconBucketContentHeader}>{brandData?.name}</div>
        {rankValue > 1 && <div className={styles.iconBucketContentSubHeader}>#{rankValue} out of {outOf}</div>}
        {rankValue === 1 && <div className={styles.iconBucketContentSubHeader}>Market Leader</div>}
      </div>
    </div>
  })}
</div>

interface BrandPopupProps {
  selectedBrands: any,
  setSelectedBrands: any,
  showingPopupIndex: any,
  setShowingPopupIndex: any,
  brands: any[]
}

const BrandPopup: React.FC<BrandPopupProps> = ({selectedBrands, setSelectedBrands, showingPopupIndex, setShowingPopupIndex, brands}) => {
  const updateSelectedBrandsAtIndex = (index: number, value: any) => {
    if(index >= 0 && index < selectedBrands.length) {
      const newSelectedBrands = selectedBrands.slice(0);
      newSelectedBrands[index] = value;
      setSelectedBrands(newSelectedBrands);
    }
    setShowingPopupIndex(null);
  }

  const backgroundColor = Color(getColor(showingPopupIndex)).lighten(0.1).toString();

  const style: any = {
    '--color': getColor(showingPopupIndex),
    '--background': backgroundColor
  }

  return <Popup onClose={() => setShowingPopupIndex(null)}>
    <div className={styles.popup}>
      <div className={styles.brandRow} style={style} onClick={() => updateSelectedBrandsAtIndex(showingPopupIndex, null)}>
        <div className={styles.brandPlaceholderIcon} />
        <div className={styles.brandName}>none</div>
      </div>
      <div className={styles.brandRow} style={style} onClick={() => updateSelectedBrandsAtIndex(showingPopupIndex, {})}>
        <Icon src={Market} rounded />
        <div className={styles.brandName}>Market Average</div>
      </div>

      {brands?.map(brand =>
        <div className={styles.brandRow} key={brand.data._id} style={style} onClick={() => updateSelectedBrandsAtIndex(showingPopupIndex, brand)}>
          <Icon src={brand?.data?.imageUrl} rounded background={brand?.dominantColor}  />
          <div className={styles.brandName}>{brand?.data?.name}</div>
        </div>
      )}
    </div>
  </Popup>
}