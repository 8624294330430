import React from 'react'
import {Link} from '@reach/router'

import styles from './button.module.scss'

const Spinner =  React.memo(() => {
  const tone1 = "rgba(80%,80%,80%,0.179)";
  const tone2 = "rgba(50%,50%,50%,0.471)";
  const tone3 = "rgba(100%,100%,100%,0.792)";
  const tone4 = "rgba(30%,30%,30%,0.863)";

  return <svg viewBox="0 0 79 20" fillRule="evenodd">
    <circle fill={tone1} cx="76.05" cy="10.05" r="1">
      <animate attributeName="r" values="10;0;0;0;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
      <animate attributeName="cx" values="76.05;76.05;76.05;76.05;76.05" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
    </circle>
    <circle fill={tone2} cx="76" cy="10" r="2.51957">
      <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="-0.7s" />
      <animate attributeName="cx" values="12.64;12.64;39.5;66.36;66.36" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="-0.7s" />
    </circle>
    <circle fill={tone3} cx="67.4335" cy="10" r="10">
      <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="-0.35s" />
      <animate attributeName="cx" values="12.64;12.64;39.5;66.36;66.36" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="-0.35s" />
    </circle>
    <circle fill={tone4} cx="33.4335" cy="10" r="10">
      <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
      <animate attributeName="cx" values="12.64;12.64;39.5;66.36;66.36" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
    </circle>
    <circle fill={tone1} cx="8" cy="10" r="7.48043">
      <animate attributeName="r" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
      <animate attributeName="cx" values="12.64;12.64;12.64;39.5;66.36" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="1.4s" repeatCount="indefinite" begin="0s" />
    </circle>
  </svg>
})

interface ButtonProps extends React.HTMLProps<HTMLDivElement> {
  tagName?: any,
  disabled?: boolean,
  loading?: boolean,
  to?: string
}

export const Button: React.FC<ButtonProps> = React.memo(({title, tagName, disabled, loading, className: _className, style, ...props}) => {
  const className = [
    styles.container,
    _className,
    disabled ? styles.disabled : null,
    isInteractive({tagName, ...props}) ? styles.hoverable : null,
  ].join(" ");

  const TagName = tagName || "div"

  if(disabled) return <div className={className} style={style} >{title}</div>
  else if(loading) return <div className={className} style={style} ><Spinner /></div>
  else return <TagName className={className} style={style} {...props}>{title}</TagName>
})

interface IconButtonProps extends React.HTMLProps<HTMLDivElement> {
  tagName?: any,
  disabled?: boolean,
  loading?: boolean,
  icon?: any,
}

export const OutlineButton: React.FC<IconButtonProps> =  React.memo(({title, tagName, disabled, loading, className: _className, icon, ...props}) => {
  const className = [
    styles.outlineContainer,
    _className,
    disabled ? styles.disabled : null,
    isInteractive({tagName, ...props}) ? styles.hoverable : null,
  ].join(" ");

  const TagName = tagName || "div"

  if(disabled) return <div className={className}>{title}</div>
  else if(loading) return <div className={className}><Spinner /></div>
  else return <TagName className={className} type='submit' {...props}>
    {icon != null && React.cloneElement(icon, {className: styles.icon})}
    <span>{title}</span>
  </TagName>
})

const isInteractive = (props: any) => {
  if(props.onClick != null) return true;
  else if(props.onMouseEnter != null) return true;
  else if(props.onMouseLeave != null) return true;
  else if(props.onMouseMove != null) return true;
  else if(props.onMouseOut != null) return true;
  else if(props.onMouseOver != null) return true;
  else if(props.onMouseUp != null) return true;
  else if(props.tagName === 'button') return true;
  else if(props.tagName === Link && props.to != null) return true;
  else if(props.tagName === 'a' && props.href != null) return true;
  else return false;
}