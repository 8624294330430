import { Link, useNavigate } from '@reach/router';
import { useLocalStorage } from '@rehooks/local-storage';
import moment from 'moment';
import React from 'react';
import Cell from '../../components/cell/cell';
import CellHeader from '../../components/cellHeader/cellHeader';
import DeletePopup from '../../components/deletePopup/deletePopup';
import Icon from '../../components/icon/icon';
import { ReportsLeftNav } from '../../components/leftNav/leftNav';
import { Loading } from '../../components/loading/loading';
import Paginator from '../../components/paginator/paginator';
import { DeletePill, EditPill, TogglePill } from '../../components/pills/pills';
import Table from '../../components/table/table';
import { useAuthContext, useReportsContext } from '../../contexts';
import { useSearch } from '../../hooks';
import Tooltip from '../../libs/context-tooltip/tooltip';
import styles from './reports.module.scss';


const PER_PAGE = 25;
const MAX_BRAD_ICONS = 10;

interface CellsProps {
  report: any,
  index: number,
  brandsById:any,
  usersById: any,
  onDelete: any,
  permission: any,
  onToggleActive: any
}

const Cells: React.FC<CellsProps> = ({report, index, brandsById, usersById, onDelete, permission, onToggleActive}) => {
  const data = report?.data;
  const props = { index, deleting: report.deleting }
  const isSuperAdmin = permission.isSuperAdmin();

  const link = (data?.status === 'draft') ? `/draftReport/${data?._id}` : `/reports/${data?._id}`;
  const date = data?.createdOn && moment(data?.createdOn);
  const user = usersById && usersById[data?.userId];

  const maxSlice = (data && data?.brandIds && (data?.brandIds.length > MAX_BRAD_ICONS)) ? (MAX_BRAD_ICONS - 1) : MAX_BRAD_ICONS;
  
  const isReportActive = report?.data?.active === true
  const canEdit = data && (data?.status !== 'draft') && isSuperAdmin;
  return <>
    <Cell sticky tagName={Link} to={link} {...props}>{data?.name || `Report ${index+1}`}</Cell>
    <Cell {...props}>
      {data?.brandIds && data?.brandIds.slice(0, maxSlice).map((id: any) => {
        const brand = brandsById && brandsById[id];
        if(brand == null || brand.data == null) return null;
        const imageUrl = brand.data?.imageUrl;
        return <Tooltip key={id} className={styles.icon} content={brand.data?.name}>
          <Icon src={imageUrl} rounded />
        </Tooltip>}
      )}
      {maxSlice < MAX_BRAD_ICONS && <Tooltip className={styles.fakeIcon} content={`${data?.brandIds.length - maxSlice} more brands`}>
        <span>+{data?.brandIds.length - maxSlice}</span>
      </Tooltip>}
    </Cell>
    <Cell {...props}>{date && date.format('LLL')}</Cell>
    <Cell {...props}>{(user && user.email) || data?.userId}</Cell>
    <Cell {...props}>
      {canEdit && <EditPill tagName={Link} to={`/reports/${data?._id}/admin`} />}
      <TogglePill isOn={isReportActive} onText="active" onHoverText="deactivate" offText="inactive" offHoverText="activate" onClick={() => onToggleActive(data?._id, !isReportActive ) } /> 
      <DeletePill onClick={() => onDelete(data?._id)} />
    </Cell>
  </>
}


interface ReportsProps {
  drafts: any
}

const Reports: React.FC<ReportsProps> = ({drafts}) => {
  const navigate = useNavigate();
  const [{ permission }] = useAuthContext();
  const [showDeletePopupId, setShowDeletePopupId] = React.useState<any>(null);
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const [search, setSearch] = useSearch();
  const [{reportsById, brandsById, reportIdsByStatus}, {fetchReports, deleteReport, createDraftReport, toggleReportActive}] = useReportsContext();

  const reportStatus = drafts ? 'draft' : null;
  const searchQuery = search.query;
  const searchPage = parseInt(search.page) || 0;
  const searchPerPage = parseInt(search.perPage) || PER_PAGE;
  const reportIdsObj = reportIdsByStatus?.[reportStatus];

  let shouldFetch = false;
  if(reportIdsObj == null) shouldFetch = true;
  else if(reportIdsObj.page !== searchPage) shouldFetch = true;
  else if(reportIdsObj.query !== searchQuery) shouldFetch = true;
  else if(reportIdsObj.perPage !== searchPerPage) shouldFetch = true;

  React.useEffect(() => {
    if(fetchReports && shouldFetch) fetchReports({ perPage: searchPerPage, search: searchQuery, page: searchPage, status: reportStatus })
  }, [fetchReports, reportStatus, searchPage, searchQuery, searchPerPage, shouldFetch])

  const onChange = (query?: any) => {
    setSearch((s: any) => ({...s, page: undefined, query: (query?.length > 0) ? query : undefined }));
  }

  const onCreate = async () => {
    const reportRes: any = await createDraftReport();
    if(reportRes) navigate(`/draftReport/${reportRes.report._id}`);
  }

  const closeDeletePopup = (deleteId?: string) => {
    if(deleteId) deleteReport(deleteId);
    setShowDeletePopupId(null)
  }
  
  const reportIds = reportIdsObj?.ids;

  const style: any = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)'
  }
  
  const isFetching = reportIdsObj?.fetching;

  const canCreateReports = true //permission.canCreateReport();

  return <div style={style}>
    <ReportsLeftNav />
    <div className={styles.container}>
      <Paginator onCreate={canCreateReports && onCreate} perPageDefault={PER_PAGE} results={reportIdsObj} />

      <Table columns="max-content minmax(min-content, 1fr) max-content max-content max-content">
        <CellHeader sticky>NAME</CellHeader>
        <CellHeader>BRANDS</CellHeader>
        <CellHeader>ORIGINATED</CellHeader>
        <CellHeader>ADMIN</CellHeader>
        <CellHeader>ACTIONS</CellHeader>

        {isFetching && <div className={styles.loadingRow}>
          <Loading />
        </div>}
        {!isFetching && reportIds?.map((id: any, index: number) => {
          const report = reportsById?.[id];

          if(report) return <Cells key={id} report={report} index={index} brandsById={brandsById} onDelete={setShowDeletePopupId} usersById={reportIdsObj?.users} permission={permission} onToggleActive={ () => toggleReportActive(id, !(report?.data?.active == true) ) } />
          else return null
        })}
      </Table>
    </div>

    {showDeletePopupId != null && <DeletePopup onClose={() => closeDeletePopup()} onSubmit={() => closeDeletePopup(showDeletePopupId)} />}
  </div>
}

export default Reports;