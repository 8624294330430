import React from 'react'

import styles from './slide.module.scss'

const Centered = ({children}: any) => <div className={styles.centered}>
  <div className={styles.centeredContent}>
    {children}
  </div>
</div>


export const Slide = ({style, report}: any) => {
  const reportName = report?.data?.name;
  return <div className={styles.container} style={{...style, '--top-width': '740px', '--bottom-width': '740px'}}>
    <Centered>
      {reportName}
    </Centered>
  </div>
}