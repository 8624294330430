import React from 'react'

import Icon from '../icon/icon'
import { Chevron3Icon, CloseIcon } from '../icons/icons'

import styles from './userHeader.module.scss'
import { useSearch } from '../../hooks'

interface UserHeaderProps {
  closed: any,
  data?: any,
  setSearch?: any,
}

export const UserHeader: React.FC<UserHeaderProps> = ({closed, data}) => {
  const [, setSearch] = useSearch()
  const toggleOpened = () => setSearch((s: any) => ({ ...s, hideDetail: closed ? undefined : null }))
  const closeUser = () => setSearch((s: any) => ({ ...s, user: undefined, userTab: undefined }))

  if(data == null) return null;
  const icon = data?.icon;
  const name = data?.name;
  return <div className={[styles.detailHeader, !closed ? styles.opened : null].join(' ')}>
    <Icon src={icon} className={styles.icon} rounded />
    <div className={[styles.title, styles.hoverable].join(' ')}>{name}</div>
    <div className={styles.close} onClick={closeUser}>
      <CloseIcon />
    </div>
    {toggleOpened && <div className={styles.chevron} onClick={toggleOpened}>
      <Chevron3Icon />
    </div>}
  </div>
}

export default UserHeader;