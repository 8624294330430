import React, { useCallback, useEffect, useRef } from 'react'

import styles from './expandingTextarea.module.scss'

export const getHeight = (rows: number, el: HTMLElement) => {
  const {
    borderBottomWidth,
    borderTopWidth,
    lineHeight,
    paddingBottom,
    paddingTop
  } = window.getComputedStyle(el)

  const rowHeight = rows == null ? 0 : parseFloat(lineHeight) *
    Math.floor(rows) +
    parseFloat(borderBottomWidth) +
    parseFloat(borderTopWidth) +
    parseFloat(paddingBottom) +
    parseFloat(paddingTop)

  const scrollHeight = el.scrollHeight +
    parseFloat(borderBottomWidth) +
    parseFloat(borderTopWidth)

  return Math.max(rowHeight, scrollHeight)
}

export const resize = (rows: number, el: HTMLElement) => {
  if (el) {
    el.style.height = '0'
    el.style.height = `${getHeight(rows, el)}px`
  }
}

interface ExpandingTextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  rows?: number,
}

const ExpandingTextarea: React.FC<ExpandingTextareaProps> = ({rows = 0, value, onChange, onInput, ...props}) => {
  const ref = useRef(null)
  useEffect(() => { resize(rows, ref.current) }, [ rows, value ]);
  const handleInput = useCallback(
    e => {
      if(onChange) onChange(e);
      if(onInput) onInput(e);
      resize(rows, ref.current);
    },
    [ rows, onChange, onInput ]
  )

  return <textarea rows={rows} value={value} onChange={onChange} {...props} className={[styles.textarea, props.className].join(' ')} onInput={handleInput} ref={ref} />
}

export default ExpandingTextarea