import React from 'react'
import Color from 'color'

import {getRangeColor2} from '../../utils'
import styles from './topicTag.module.scss'

interface TopicTagProps extends React.HTMLProps<HTMLDivElement> {
  value: any,
  count?: number,
  selected?: boolean,
  onClick?: any,
  onDelete?: any,
  onAdd?: any,
}

export const TopicTag: React.FC<TopicTagProps> = ({value, count, selected, onClick, onDelete, onAdd, style, className}) => {
  const _className = [
    className,
    styles.topic,
    selected ? styles.selected : null,
    onClick ? styles.hoverable : null,
  ].join(' ')

  return <div className={_className} style={style} onClick={onClick ? (() => onClick(value)) : null}>
    {onAdd && <div className={styles.clearContainer} onClick={(e) => {e.stopPropagation(); onAdd(value)}}>
      <Plus />
    </div>}
    <span>{value}</span>
    {count != null && <span className={styles.count}>{count}</span>}
    {onDelete && <div className={styles.clearContainer} onClick={(e) => {e.stopPropagation(); onDelete(value)}}>
      <Clear />
    </div>}
  </div>
}

const Clear: React.FC<React.SVGProps<SVGElement>> = ({onClick}) => <svg className={styles.clear} viewBox="0 0 11 11" onClick={onClick}>
  <path d="M6.55624874,5.5 L10.7812437,9.72499498 C11.0729188,10.01667 11.0729188,10.4895687 10.7812437,10.7812437 C10.4895687,11.0729188 10.01667,11.0729188 9.72499498,10.7812437 L5.5,6.55624874 L1.27500502,10.7812437 C0.983329985,11.0729188 0.510431314,11.0729188 0.218756278,10.7812437 C-0.0729187592,10.4895687 -0.0729187592,10.01667 0.218756278,9.72499498 L4.44375126,5.5 L0.218756278,1.27500502 C-0.0729187592,0.983329985 -0.0729187592,0.510431314 0.218756278,0.218756278 C0.510431314,-0.0729187592 0.983329985,-0.0729187592 1.27500502,0.218756278 L5.5,4.44375126 L9.72499498,0.218756278 C10.01667,-0.0729187592 10.4895687,-0.0729187592 10.7812437,0.218756278 C11.0729188,0.510431314 11.0729188,0.983329985 10.7812437,1.27500502 L6.55624874,5.5 Z" />
</svg>

const Plus: React.FC<React.SVGProps<SVGElement>> = ({onClick}) => <svg className={styles.plus} viewBox="0 0 11 11" onClick={onClick}>
  <path d="M6.55624874,5.5 L10.7812437,9.72499498 C11.0729188,10.01667 11.0729188,10.4895687 10.7812437,10.7812437 C10.4895687,11.0729188 10.01667,11.0729188 9.72499498,10.7812437 L5.5,6.55624874 L1.27500502,10.7812437 C0.983329985,11.0729188 0.510431314,11.0729188 0.218756278,10.7812437 C-0.0729187592,10.4895687 -0.0729187592,10.01667 0.218756278,9.72499498 L4.44375126,5.5 L0.218756278,1.27500502 C-0.0729187592,0.983329985 -0.0729187592,0.510431314 0.218756278,0.218756278 C0.510431314,-0.0729187592 0.983329985,-0.0729187592 1.27500502,0.218756278 L5.5,4.44375126 L9.72499498,0.218756278 C10.01667,-0.0729187592 10.4895687,-0.0729187592 10.7812437,0.218756278 C11.0729188,0.510431314 11.0729188,0.983329985 10.7812437,1.27500502 L6.55624874,5.5 Z" />
</svg>


export default TopicTag


interface TopicAddTagProps {
  value: any,
  onClick: any
}

export const sentimentWords: {[key: string]: string} = {
  1: 'terrible',
  2: 'bad',
  3: 'ok',
  4: 'good',
  5: 'great',
}

export const TopicAddTag: React.FC<TopicAddTagProps> = ({value, onClick}) => <div className={styles.addTopic} onClick={onClick}>+ {value}</div>

export const TopicAddSentimentTag: React.FC<TopicAddTagProps> = ({value, onClick}) => {
  const [showSentiment, setShowSentiment] = React.useState(false);
  return <div className={styles.addTopic} onClick={() => setShowSentiment(true)}>
    <div>+ {value}</div>
    {showSentiment && <div className={styles.sentimentRow}>
      {Object.keys(sentimentWords).map((sentimentKey: string) => {
        const sentimentKeyNumber = Number.parseInt(sentimentKey)
        const score = sentimentKeyNumber*20;
        const color = Color(getRangeColor2(score)).fade(0.8).toString();
        const hoverColor = Color(getRangeColor2(score)).fade(0.2).toString();
        const style: any = {
          '--color': color,
          '--hover-color': hoverColor
        }
        const sentimentText = sentimentWords[sentimentKey]
        return <div key={sentimentKey} style={style} className={styles.sentimentItem} onClick={onClick ? () => onClick((sentimentKeyNumber-3)/2) : undefined }>{sentimentText}</div>
      })}
    </div>}
  </div>
}