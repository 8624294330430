import React from 'react'
import { createPortal } from 'react-dom';
import { isMobile } from 'react-device-detect'
import usePortal from '../usePortal'

import styles from './tooltip.module.scss'

const initialState: any = {
  top: 0,
  left: 0,
  height: 0,
  width: 0,
  visible: false,
  position: null,
  tooltipPosition: {
    top: 0,
    left: 0,
  },
  arrowPosition: {}
};

const reducer = (state: any, action: any) => {
  if('SET_POSITION' === action.type) {
    return {
      ...state,
      top: action.top,
      left: action.left,
      width: action.width,
      height: action.height,
      visible: action.visible
    }
  } else if('SET_TOOLTIP' === action.type) {
    return {
      ...state,
      position: action.position,
      tooltipPosition: {
        top: action.top,
        left: action.left
      },
      arrowPosition: {
        top: action.arrowTop,
        left: action.arrowLeft
      }
    }
  } else if('RESET' === action.type) {
    return initialState
  } else {
    throw new Error();
  }
}

export const TooltipPortal: React.FC<React.HTMLProps<HTMLDivElement>> = ({ id, children }) => {
  const target = usePortal(id || 'modal-tooltip');

  return createPortal(children, target);
}

const check_top = ({ state, arrowSize, offsetWidth, offsetHeight, offset, padding, scrollX, scrollY, maxLeft }: any) => {
  if ((state.top - offsetHeight - offset - padding) > 0) {
    const top = state.top - offsetHeight - offset + scrollY;
    const left = Math.max(scrollX + padding, Math.min(state.left + (state.width - offsetWidth) / 2 + scrollX, maxLeft));
    const correction = state.left - left - (offsetWidth - state.width) / 2;
    const position = 'top';
    const arrowTop = offsetHeight;
    const arrowLeft = offsetWidth / 2 - arrowSize + scrollX + correction;
    return { top, left, position, arrowTop, arrowLeft }
  }
  return null
}

const check_right = ({ state, arrowSize, offsetWidth, offsetHeight, innerWidth, offset, padding, scrollX, scrollY, maxTop }: any) => {
  if ((state.left + state.width + offsetWidth + offset + padding) < innerWidth) {
    const top = Math.max(scrollY + padding, Math.min(state.top + (state.height - offsetHeight) / 2 + scrollY, maxTop))
    const left = state.left + state.width + offset + scrollX;
    const position = 'right';
    const correction = state.top - top - (offsetHeight - state.height) / 2;
    const arrowTop = offsetHeight / 2 - arrowSize + scrollY + correction;
    const arrowLeft = -arrowSize
    return { top, left, position, arrowTop, arrowLeft }
  }
  return null;
}

const check_left = ({ state, arrowSize, offsetWidth, offsetHeight, offset, padding, scrollX, scrollY, maxTop }: any) => {
  if ((state.left - state.width - offset - padding) > 0) {
    const top = Math.max(scrollY + padding, Math.min(state.top + (state.height - offsetHeight) / 2 + scrollY, maxTop))
    const left = state.left - offsetWidth - offset + scrollX;
    const position = 'left';
    const correction = state.top - top - (offsetHeight - state.height) / 2;
    const arrowTop = offsetHeight / 2 - arrowSize + scrollY + correction;
    const arrowLeft = offsetWidth
    return { top, left, position, arrowTop, arrowLeft }
  }
  return null;
}
const check_bottom = ({ state, arrowSize, offsetWidth, offsetHeight, innerHeight, offset, padding, scrollX, scrollY, maxLeft }: any) => {
  if ((state.top + state.height + offsetHeight + offset + padding) < innerHeight) {
    const top = state.top + state.height + offset + scrollY;
    const left = Math.max(scrollX + padding, Math.min(state.left + (state.width - offsetWidth) / 2 + scrollX, maxLeft))
    const correction = state.left - left - (offsetWidth - state.width) / 2;
    const position = 'bottom';
    const arrowTop = -arrowSize;
    const arrowLeft = offsetWidth / 2 - arrowSize + scrollX + correction;
    return { top, left, position, arrowTop, arrowLeft }
  }
  return null;
}

interface TooltipProps extends React.HTMLProps<HTMLDivElement> {
  manual?: boolean,
  tagName?: any,
  visible?: boolean,
  content?: any,
  background?: string,
  arrowOffset?: number,
  tooltipClassName?: string,
  cx?: number,
  cy?: number,
  r?: number,
  offset?: number,
  rounded?: boolean,
  position?: any
}

const Tooltip: React.FC<TooltipProps> = ({ manual, visible, ...props }) => {
  const Tag = props.tagName || "div";
  if(isMobile) return <Tag {...props} />

  if(!manual) return <AutoTooltip {...props} />
  else return <ManualTooltip visible={visible} {...props} />;
}


interface ManualTooltipProps {
  manual?: boolean,
  tagName?: any,
  visible: boolean,
  background?: string,
  shadow?: any,
  arrowSize?: number,
  arrowOffset?: number,
  padding?: number,
  content?: any,
  position?: any,
  tooltipClassName?: string,
  color?: string
}

const ManualTooltip: React.FC<ManualTooltipProps> = ({tagName, background, shadow, arrowSize = 6, arrowOffset = 0, padding = 6, content, position, tooltipClassName, color, visible, ...props}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const el = React.useRef(null);
  const tagEl = React.useRef(null);
  const Tag = tagName || "div";

  const { top, left, width, height } = state;

  React.useLayoutEffect(() => {
      if(tagEl == null || tagEl.current == null) return;
      
      if(visible) {
        const { top, left, width, height } = tagEl.current.getBoundingClientRect();
        dispatch({ type: 'SET_POSITION', top, left, width, height, visible: true })
      } else if(!visible) {
        dispatch({ type: 'RESET' })
      }
    }, [visible])

  React.useLayoutEffect(() => {
    if(state.visible && el?.current) {
      const offset = arrowSize + arrowOffset;
      const { innerWidth, innerHeight, scrollX, scrollY } = window;
      const { offsetWidth, offsetHeight } = el.current;
      const maxLeft = innerWidth - offsetWidth + scrollX - padding;
      const maxTop = innerHeight - offsetHeight + scrollY - padding;
      let heuristic = ['top', 'right', 'left', 'bottom'];
      
      if(position != null) {
        if(Array.isArray(position)) {
          heuristic = position.concat(heuristic).slice(0,4)
        } else {
          heuristic = [position].concat(heuristic).slice(0,4)
        }
      }

      const data = {
        state: {
          top,
          left,
          width,
          height,
        },
        arrowSize,
        offsetWidth,
        offsetHeight,
        offset,
        padding,
        scrollX,
        scrollY,
        maxLeft,
        maxTop,
        innerWidth,
        innerHeight
      };
      
      let returnData = null;
      for (var i = 0; i < heuristic.length; i++) {
        switch (heuristic[i]) {
          case 'top':
            returnData = check_top(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData })
              return;
            }
            break;
          case 'left':
            returnData = check_left(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData })
              return;
            }
            break;
          case 'right':
            returnData = check_right(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData })
              return;
            }
            break;
          case 'bottom':
            returnData = check_bottom(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData })
              return;
            }
            break;
          default: break;
        }
      }
    }
  }, [state.visible, el, arrowSize, arrowOffset, padding, position, top, left, width, height])

  const tooltipStyle = {
    ...state.tooltipPosition,
    '--arrow-size': `${arrowSize}px`,
    '--background-color': background || "rgba(0, 0, 0, 0.85)",
    '--shadow-color': shadow || "rgba(0,0,0,.5)",
  }

  return <>
     <Tag {...props} ref={tagEl} />
     {visible && <TooltipPortal>
       <div className={[styles.container, tooltipClassName].join(' ')} ref={el} style={tooltipStyle}>
         {content}
         <div className={[styles.arrow, styles[state.position]].join(' ')} style={state.arrowPosition} />
       </div>
     </TooltipPortal>}
   </>
}


interface AutoTooltipProps extends React.HTMLProps<HTMLDivElement> {
  manual?: boolean,
  tagName?: any,
  background?: string,
  shadow?: any,
  arrowSize?: number,
  arrowOffset?: number,
  padding?: number,
  content?: any,
  position?: any,
  tooltipClassName?: string,
  color?: string
}
const AutoTooltip: React.FC<AutoTooltipProps> = ({ tagName, background, shadow, arrowSize = 6, arrowOffset = 0, padding = 6, content, position, tooltipClassName, color, ...props }) => {
  const el = React.useRef(null);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const isVisible = state.visible;
  const { top, left, width, height } = state;

  const onMouseEnter = (e: any) => {
    if (props.onMouseEnter) props.onMouseEnter(e);
    const { top, left, width, height } = e.currentTarget.getBoundingClientRect();
    dispatch({ type: 'SET_POSITION', top, left, width, height, visible: true })
  }
  const onMouseLeave = (e: any) => {
    if (props.onMouseEnter) props.onMouseLeave(e);
    dispatch({ type: 'RESET' })
  }

  React.useEffect(() => {
    if(state.visible && el?.current) {
      const offset = arrowSize + arrowOffset;
      const { innerWidth, innerHeight, scrollX, scrollY } = window;
      const { offsetWidth, offsetHeight } = el.current;
      const maxLeft = innerWidth - offsetWidth + scrollX - padding;
      const maxTop = innerHeight - offsetHeight + scrollY - padding;
      let heuristic = ['top', 'right', 'left', 'bottom'];
      
      if(position != null) {
        if(Array.isArray(position)) {
          heuristic = position.concat(heuristic).slice(0,4)
        } else {
          heuristic = [position].concat(heuristic).slice(0,4)
        }
      }

      const data = {
        state: {
          top,
          left,
          width,
          height,
        },
        arrowSize,
        offsetWidth,
        offsetHeight,
        offset,
        padding,
        scrollX,
        scrollY,
        maxLeft,
        maxTop,
        innerWidth,
        innerHeight
      };
      
      let returnData = null;
      for (var i = 0; i < heuristic.length; i++) {
        switch (heuristic[i]) {
          case 'top':
            returnData = check_top(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData })
              return;
            }
            break;
          case 'left':
            returnData = check_left(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData })
              return;
            }
            break;
          case 'right':
            returnData = check_right(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData })
              return;
            }
            break;
          case 'bottom':
            returnData = check_bottom(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData })
              return;
            }
            break;
          default: break;
        }
      }
    }
  }, [state.visible, el, arrowSize, arrowOffset, padding, position, top, left, width, height])

  const tooltipStyle = {
    ...state.tooltipPosition,
    '--arrow-size': `${arrowSize}px`,
    '--background-color': background || "rgba(0, 0, 0, 0.85)",
    '--shadow-color': shadow || "rgba(0,0,0,.5)",
  }

  const Tag = tagName || "div";
  return <>
    <Tag {...props} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} />
    {isVisible && <TooltipPortal>
      <div className={[styles.container, tooltipClassName].join(' ')} ref={el} style={tooltipStyle}>
        {content}
        <div className={[styles.arrow, styles[state.position]].join(' ')} style={state.arrowPosition} />
      </div>
    </TooltipPortal>}
  </>
}

export default Tooltip


interface TooltipPopupProps extends React.HTMLProps<HTMLDivElement> {
  arrowPosition?: any,
  position?: string,
  tooltipClassName: string,
  arrowSize?: number,
  background?: string,
  arrowOffset?: number,
  padding?: number,
  shadow?: string,
  containerSize?: any,
  offset?: any
}
export const TooltipPopup: React.FC<TooltipPopupProps> = ({arrowPosition, position = "top", tooltipClassName, children, arrowSize = 6, background, arrowOffset = 0, padding = 6, shadow, containerSize={}, offset={} }) => {
  const d: any = React.useMemo(() => {
    let heuristic = ['top', 'right', 'left', 'bottom'];
    
    if(position != null) {
      if(Array.isArray(position)) {
        heuristic = position.concat(heuristic).slice(0,4)
      } else {
        heuristic = [position].concat(heuristic).slice(0,4)
      }
    }

    const data = {
      state: {
        top: offset.top - containerSize.bbox.top,
        left: offset.left - containerSize.bbox.left,
        width: offset.width,
        height: offset.height,
      },
      arrowSize,
      offsetWidth: offset.width,
      offsetHeight: 18, // offset.height,
      offset: arrowSize + arrowOffset,
      padding,
      scrollX: offset.scrollLeft || 0,
      scrollY: offset.scrollTop || 0,
      maxLeft: containerSize.bbox.width,
      maxTop: containerSize.bbox.height,
      innerWidth: containerSize.bbox.width,
      innerHeight: containerSize.bbox.height,
    };

    let returnData = null;
    for (var i = 0; i < heuristic.length; i++) {
      switch (heuristic[i]) {
        case 'top':
          returnData = check_top(data);
          if (returnData != null) {
            return returnData;
          }
          break;
        case 'left':
          returnData = check_left(data);
          if (returnData != null) {
            return returnData;
          }
          break;
        case 'right':
          returnData = check_right(data);
          if (returnData != null) {
            return returnData;
          }
          break;
        case 'bottom':
          returnData = check_bottom(data);
          if (returnData != null) {
            return returnData;
          }
          break;
        default: return {};
      }
    }
    return {}
  }, [arrowSize, arrowOffset, padding, position, containerSize.bbox, offset])

  const tooltipStyle = {
    top: d.top,
    left: d.left,
    '--arrow-size': `${arrowSize}px`,
    '--background-color': background || "rgba(0, 0, 0, 0.85)",
    '--shadow-color': shadow || "rgba(0,0,0,.5)",
  }

  return <div className={[styles.container, tooltipClassName].join(' ')} style={tooltipStyle}>
    {children}
    <div className={[styles.arrow, styles[d.position]].join(' ')} style={arrowPosition} />
  </div>
}