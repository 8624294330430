import React from 'react'
import DeletePopup from '../../components/deletePopup/deletePopup'
import { useLocalStorage } from '@rehooks/local-storage';

import EditConceptPopup from '../../components/editConceptPopup/editConceptPopup'
import SuggestConcept from '../../components/suggestConcept/suggestConcept'
import { useConceptsContext } from '../../contexts'

import { ReportsLeftNav } from '../../components/leftNav/leftNav'

import styles from './concepts.module.scss'

export const Concepts = () => {
  const [showDeletePopupId, setShowDeletePopupId] = React.useState<any>(null);
  const [showEditConceptPopupId, setShowEditConceptPopupId] = React.useState(null);
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const [{libraryConcepts}, {fetchConceptsLibrary, deleteConceptFromLibrary}] = useConceptsContext();
  React.useEffect(() => { fetchConceptsLibrary()}, [fetchConceptsLibrary])

  const judgementConcepts:any[] = libraryConcepts?.filter((c:any) => c.catType === 'judgement');
  const domainConcepts:any[] = libraryConcepts?.filter((c:any) => c.catType === 'domain');

  judgementConcepts?.sort((a, b) => a.name.localeCompare(b.name))
  domainConcepts?.sort((a, b) => a.name.localeCompare(b.name))

  const style: any = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  }

  const closeDeletePopup = (deleteId?: string) => {
    if(deleteId) deleteConceptFromLibrary(deleteId);
    setShowDeletePopupId(null)
  }
  const closeEditPopup = (conceptId?: string) => {
    if(conceptId) {
      console.log(conceptId)
    }
    setShowEditConceptPopupId(null)
  }

  return <div style={style} className={styles.containerWrap}>
    <ReportsLeftNav />
    <div className={styles.container}>
      <div className={styles.rowHeader}>DOMAIN CONCEPTS</div>
      {domainConcepts?.map(concept => <SuggestConcept key={concept._id} data={concept} onDeleteConcept={setShowDeletePopupId} onEdit={setShowEditConceptPopupId} />)}

      <div className={styles.rowHeader}>JUDGEMENT CONCEPTS</div>
      {judgementConcepts?.map(concept => <SuggestConcept key={concept._id} data={concept} onDeleteConcept={setShowDeletePopupId} onEdit={setShowEditConceptPopupId} />)}
    </div>

    {showDeletePopupId != null && <DeletePopup onClose={() => closeDeletePopup()} onSubmit={() => closeDeletePopup(showDeletePopupId)} />}
    {showEditConceptPopupId != null && <EditConceptPopup conceptId={showEditConceptPopupId} onClose={() => closeEditPopup()} /> }
  </div>
}

export default Concepts;