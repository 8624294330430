import React from 'react'

import { useReviewsContext } from '../../contexts'
import { uniqueBy, getRangeColor2, getHighlightRangeColor, getHoverHighlightRangeColor, sentimentWords, getScore } from '../../utils'
import { conceptIconByName } from '../../utils2'
import { ToastContext } from '../../libs/context-toast/toast'
import { useKey } from "../../hooks/useKey";

import { TrashIcon } from '../icons/icons'

import styles from './machineDetail.module.scss'
import { useSearch } from '../../hooks';

interface MachineDetailProps {
  reportId: string,
  brandId: string,
  concepts?: any,
  reviewsById?: any,
  conceptsById?: any,
  reviews?: any,
  data?: any,
}

export const MachineDetail: React.FC<MachineDetailProps> = React.memo(({reportId, brandId, concepts, reviewsById, conceptsById }) => {
  const [search, setSearch] = useSearch();
  const machineSplit = search?.machine?.split('_');


  if(reviewsById && machineSplit?.length >= 3) {
    const review = reviewsById[machineSplit[0]];
    return <MachineDetailActive reportId={reportId} brandId={brandId} concepts={concepts} review={review} setSearch={setSearch} search={search} />
  }

  return <div className={styles.detail}>
    <div className={styles.concepts}>
      <ol className={styles.orderedList}>
      <li>Select review to classify.</li>
      <li>Highlight a phrase, sentence, or multiple sentences.</li>
      
      </ol>
    </div>
    {reviewsById && machineSplit && <ClassOverrides reportId={reportId} review={reviewsById[machineSplit[0]]} brandId={brandId} conceptsById={conceptsById} />}
  </div>
})

export default MachineDetail;

interface ClassOverridesProps {
  reportId: string,
  review: any,
  brandId: string,
  conceptsById: any,
}

const ClassOverrides: React.FC<ClassOverridesProps> = ({reportId, review, brandId, conceptsById}) => {
  const [, {deleteClassification}] = useReviewsContext();
  const {showToast} = React.useContext(ToastContext);
  const classOverrides = review?.classOverrides
  
  const onDelete = async (id: string) => {
    await deleteClassification(reportId, brandId, review._id, id);
    showToast('updated classification');
  }

  const reviewText = (review.title ? review.title + ". " : ". ") + review.text;

  return <div className={styles.classOverrides}>
    {classOverrides?.map((range: any) => {
      const score = getScore(range.score);
      const style: any = {
        '--background': getHighlightRangeColor(score),
        '--hover-background': getHoverHighlightRangeColor(score),
        '--color': getRangeColor2(score),
      }
      const substr = reviewText?.substr(range.b, range.e - range.b);
      const words = substr?.split(' ');
      const startWord = words?.[0];
      const endWord = words?.length > 0 && words?.[words?.length - 1];

      const concepts = range?.catIds?.map((id: string) => conceptsById?.[id]?.name).filter((a: any) => a);
      
      return <div key={range._id} className={styles.row}>
        <div className={styles.range} style={style}>
          {endWord != null && <span>{startWord}...{endWord}</span>}
          {endWord == null && <span>{startWord}</span>}
          <span>&nbsp; ({concepts.join(', ')})</span>
        </div>
        <TrashIcon className={styles.trashIcon} onClick={() => onDelete(range._id)} />
      </div>
    })}
  </div>;
}

interface MachineDetailActiveProps {
  reportId: string,
  brandId: string,
  concepts: any,
  search: any,
  review: any,
  setSearch: any
}

const MachineDetailActive: React.FC<MachineDetailActiveProps> = React.memo(({reportId, brandId, concepts, search, review, setSearch }) => {
  const [, {updateClassification, addClassification, deleteClassification}] = useReviewsContext();
  const {showToast} = React.useContext(ToastContext);
  const reviewRanges = review?.ranges || [];
  const reviewClassOverrides = review?.classOverrides;
  const joinedRanges = React.useMemo(() => {
    if(reviewClassOverrides?.length > 0) {
      let joinedRanges = [];
      const filteredRanges = reviewRanges.filter((r: any) => {
        for(var i = 0; i< reviewClassOverrides.length; i++) {
          const classOverride = reviewClassOverrides[i];
          if(classOverride?.b >= r.b && classOverride?.b < r.e) {
            return false;
          } else if(classOverride?.b < r.e && classOverride?.e >= r.b) {
            return false;
          }
        }
        return true;
      })

      let classOverridesIndex = 0;
      for(var i = 0; i< filteredRanges.length; i++) {
        const range = filteredRanges[i];
        for(var j = classOverridesIndex; j<reviewClassOverrides.length; j++) {
          const classOverride = reviewClassOverrides[j];
          if(classOverride.b > range.e) {
            break;
          } else if(classOverride.e < range.b){
            const correctedOverride = {
              cats:classOverride.catIds.map((c:any) => ({c, s: classOverride.score, id: classOverride._id})),
              b: classOverride.b,
              e: classOverride.e
            };
            joinedRanges.push(correctedOverride);
            classOverridesIndex+=1;
          }
        }
        joinedRanges.push(range);
      }
      for(var k = classOverridesIndex; k<reviewClassOverrides.length; k++) {
        const classOverride = reviewClassOverrides[k];
        const correctedOverride = {
          cats:classOverride.catIds.map((c: any) => ({c, s: classOverride.score, id: classOverride._id})),
          b: classOverride.b,
          e: classOverride.e
        };
        joinedRanges.push(correctedOverride);
      }
      return joinedRanges;
    }
    return reviewRanges;
  }, [reviewRanges, reviewClassOverrides])
  const [activeConcept, setActiveConcept] = React.useState<any>();

  const machineSplit = search?.machine?.split('_')
  const selectedRangeCats = React.useMemo(() => {
    if(machineSplit == null || review == null) return null;
    const start = Number.parseInt(machineSplit[1]);
    const end = Number.parseInt(machineSplit[2]);
    const range = joinedRanges?.find((range: any) => range.b === start && range.e === end);
    if(range == null) return null;
    return range.cats && uniqueBy(range.cats.filter((cat: any) => cat.c), (cat: any) => cat.c)
  }, [machineSplit, review, joinedRanges])

  const setRating = async (rating: any) => {
    if(rating == null) return;
    const activeRange = activeConcept && selectedRangeCats?.[activeConcept._id];
    if(activeRange?.id != null) {
      
      showToast('updating classification');
      await updateClassification(reportId, brandId, review._id, activeRange.id, {
        begin: machineSplit[1],
        end: machineSplit[2],
        score: rating,
      })

      setSearch((s: any) => ({ ...s, machine: review._id}))
    } else {

      showToast('Classifying verbatim');
      await addClassification(reportId, brandId, review._id, {
        catIds: [activeConcept._id],
        begin: machineSplit[1],
        end: machineSplit[2],
        score: rating,
      })

      setSearch((s: any) => ({ ...s, machine: review._id}))
    }
  }

  const onDeleteConcept = async (id: string) => {
    await deleteClassification(reportId, brandId, review._id, id);
    showToast('updated classification');
  }

  return <div className={styles.detail}>
    {activeConcept == null && concepts && review != null && <Concepts concepts={concepts} selectedRangeCats={selectedRangeCats} setActiveConcept={setActiveConcept} machineSplit={machineSplit} onDeleteConcept={onDeleteConcept} />}
 
    {activeConcept != null && <>
      <Concept concept={activeConcept} selectedRangeCats={selectedRangeCats} />
      <Ratings ratings={[0,1,2,3,4]} setRating={setRating} />
    </>}
  </div>
})

interface RatingsProps {
  ratings: any[],
  setRating: any,
}

const Ratings: React.FC<RatingsProps> = ({ratings, setRating}) => {
  useKey(['`', '~', '1', '2', '3', '4', '5'], (e: any) => {
    if(e.keyCode === 192) {
      // setRating && setRating(null)
    } else {
      const value = Number.parseInt(String.fromCharCode(e.keyCode));
      setRating && setRating((value - 3)/2)
    }
  });
  const removeStyle = {
    '--background': "#F1F1F1",
    '--hover-background': "#ACACAC",
    '--color': "#444",
  }
  return <div className={styles.ratings}>
    <Rating shortcut='~' style={removeStyle} word={'remove'} onClick={setRating ? () => setRating(null) : undefined} />
    {ratings?.map(rating => {
      const score = rating * 100/(ratings.length - 1);
      const style = {
        '--background': getHighlightRangeColor(score),
        '--hover-background': getHoverHighlightRangeColor(score),
        '--color': getRangeColor2(score),
      }
      const sentimentAny: any = sentimentWords
      const word = sentimentAny?.[rating + 1]
      return <Rating key={rating} style={style} shortcut={rating+1} word={word} onClick={setRating ? () => setRating((rating - 2)/2) : undefined} />
    })}
  </div>
}

interface RatingProps {
  word?: string,
  style: any,
  shortcut: any,
  onClick: any
}

const Rating: React.FC<RatingProps> = ({word, style, shortcut, onClick}) => {
  return <div className={styles.rating} style={style} onClick={onClick}>
    <div className={styles.ratingButton}>{shortcut}</div>
    <div>{word}</div>
  </div>
}

interface ConceptsProps {
  concepts: any,
  selectedRangeCats: any,
  setActiveConcept: any,
  machineSplit: any,
  onDeleteConcept: any
}

const Concepts: React.FC<ConceptsProps> = React.memo(({concepts, selectedRangeCats, setActiveConcept, machineSplit, onDeleteConcept}) => {
  const [inputValue, setInputValue] = React.useState(null);
  const ref = React.useRef<any>();
  const [highlightIndex, setHighlightIndex] = React.useState(0);
  React.useEffect(() => {
    ref.current.focus();
  }, [machineSplit])

  const lowerCaseInputValue = inputValue?.toLowerCase();
  const filteredConcepts = concepts?.filter((c: any) => {
    if(lowerCaseInputValue == null || lowerCaseInputValue.length === 0) return true;
    const lowerCaseName = c.name.toLowerCase();
    return lowerCaseName.indexOf(lowerCaseInputValue) >= 0;
  })

  const onKeyDown = (e: any) => {
    if(e.key === 'ArrowDown') {
      const hasInputValue = inputValue?.length > 0
      const index = Math.min(filteredConcepts.length + (hasInputValue ? 0 : -1), highlightIndex + 1);
      setHighlightIndex(index);
    } else if(e.key === 'ArrowUp') {
      setHighlightIndex( Math.max(highlightIndex - 1, 0));
    } else if(e.key === 'Enter') {
      if(highlightIndex < filteredConcepts.length) {
        const concept = filteredConcepts[highlightIndex];
        setActiveConcept(concept);
      } else if(highlightIndex === filteredConcepts.length) {
      }
    }
  }

  return <>
    <input autoFocus className={styles.input} placeholder="Search Concepts..." onChange={(e) => setInputValue(e.target.value || '')} value={inputValue || ''} onKeyDown={onKeyDown} ref={ref} />
      
    <div className={styles.concepts}>
      {filteredConcepts?.map((concept: any, i: number) =>
        <Concept key={concept._id} concept={concept} selectedRangeCats={selectedRangeCats} isHighlighted={highlightIndex === i} onClick={() => setActiveConcept(concept)} onDelete={onDeleteConcept} />
      )}
      {inputValue?.length > 0 && <div className={[styles.row, (highlightIndex === filteredConcepts.length ? styles.highlighted : null)].join(' ')}>
        <div className={styles.createConcept}>Create <strong>&lsquo;{inputValue}&rsquo;</strong> concept...</div>
      </div>}
    </div>
  </>
})

interface ConceptProps {
  concept: any,
  isHighlighted?: boolean,
  selectedRangeCats?: any,
  onClick?: any,
  onDelete?: any
}

const Concept: React.FC<ConceptProps> = ({concept, isHighlighted, selectedRangeCats, onClick, onDelete}) => {
  let style: any = {}
  const selectedRangeCat = selectedRangeCats?.[concept._id];
  if(selectedRangeCat) {
    const score = getScore(selectedRangeCat?.s);
    style['--background'] = getHighlightRangeColor(score);
    style['--highlight-background'] = getHoverHighlightRangeColor(score);
    style['--color'] = getRangeColor2(score);
  }
  const className = [
    styles.row,
    onClick != null ? styles.hoverable : null,
    selectedRangeCat ? styles.selected : null,
    isHighlighted ? styles.highlighted : null
  ].join(" ")
  const ConceptIcon = conceptIconByName[concept.name];
  return <div className={className} style={style}>
    <div className={styles.concept} onClick={onClick}>
      <span>{concept.name}</span>
      {ConceptIcon != null && <ConceptIcon className={styles.conceptIcon} />}
    </div>
    {selectedRangeCat?.id && onDelete && <TrashIcon className={styles.trashIcon} onClick={() => onDelete(selectedRangeCat.id)} />}
  </div>
}