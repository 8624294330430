import { stringify as queryStringify } from 'query-string'
import firebase from 'firebase/compat/app';

export const getUserToken = async (user?: firebase.User) => {
  if (user == null) return null;
  const token = await user.getIdToken(true);
  return token;
}

type MethodType = "GET" | "POST" | "DELETE" | "PUT"

export const fetchUrl = async (method: MethodType, url: string, options: any) => {
  const body = options?.body;
  let newUrl = null;
  if(method === 'GET') {
    newUrl = [url, body && queryStringify(body)].filter(a => a).join('?');
  } else {
    newUrl = url;
  }
  const contentType = (body instanceof FormData) ? undefined : "application/json"
  let headers: any = {};
  if(options?.headers) headers = { ...options.headers }
  if(contentType) headers['Content-Type'] = contentType;
  
  const res = await fetch(newUrl, {
    method,
    headers,
    body: (body == null || method === 'GET') ? undefined : ( (body instanceof FormData) ? body : JSON.stringify(body))
  });
  if (res.ok) {
    return await res.json();
  }
  throw new Error(`${method} with token failed ${url} ${JSON.stringify(body, null, 2)}`);
}

export const headersWithXToken = async (user: firebase.User | undefined, headers: any = {}) => {
  const userIdToken = user != null && await user.getIdToken();
  if(userIdToken) headers['X-TOKEN'] = userIdToken;
  return headers;
}