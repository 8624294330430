import React from 'react'
import Popup from '../popup/popup'
import Users from '../users/users'


interface EditTeamsPopupProps {
  report: any,
  onClose: any,
}

export const EditTeamsPopup: React.FC<EditTeamsPopupProps> = ({report, onClose}) => {
  return <Popup onClose={onClose}>
    <h4>Edit teams</h4>

    <Users report={report} />
  </Popup>
}

export default EditTeamsPopup;