import React from 'react'
import styles from './keyButtons.module.scss'


interface KeyButtonsProps {
  keys: string[]
}
export const KeyButtons: React.FC<KeyButtonsProps> = ({keys}) => {
  if(keys == null) return null;
  return <div className={styles.buttons}>
    {keys.map((key, i) => <React.Fragment key={i}>
      {i > 0 && <span>+</span>}
      <KeyButton value={key} />
    </React.Fragment>)}
  </div>
}


interface KeyButtonProps {
  value: string
}

export const KeyButton: React.FC<KeyButtonProps> = ({value}) => {
  return <div className={styles.button}>{value}</div>
}

export default KeyButtons