import React from 'react'
import { Trash, Pencil } from '../../toneIcons'
import useForm from '../../hooks/useForm'
import Tooltip from '../../libs/context-tooltip/tooltip'
import Icon from '../icon/icon'

import styles from './draftBrandHeader.module.scss'

interface DraftBrandHeaderProps {
  reportId: string,
  brand: any,
  reportUpdateBrand: any,
  reportDeleteBrand: any,
}

export const DraftBrandHeader: React.FC<DraftBrandHeaderProps> = ({reportId, brand, reportUpdateBrand, reportDeleteBrand}) => {
  const { values, handleChange, registerSubmit } = useForm({name: brand?.data?.name})
  const [editingTitle, setEditingTitle] = React.useState(false);

  const brandId = brand?.data?._id;

  const onSubmit = async ({values}: any) => {
    await reportUpdateBrand(reportId, brand.data._id, values);
    setEditingTitle(false);
  }

  if(editingTitle) {
    return <form className={styles.container} onSubmit={registerSubmit(onSubmit)} >
      <input placeholder="your brand title" value={values.name} name="name" onChange={handleChange} autoFocus />
      <button type="submit">submit</button>
    </form>
  } else {
    const content = <div>
      Delete <strong>{brand?.data?.name}</strong> and associated sources
    </div>;

    return <div className={styles.container}>
      <Icon src={brand?.data?.imageUrl} rounded className={styles.icon} />
      <div className={styles.text}>{brand?.data?.name}</div>
      <button onClick={ () => setEditingTitle(true)} className={styles.editButton}>
        <Pencil />
      </button>
      <Tooltip tagName="button" content={content} onClick={() => reportDeleteBrand(reportId, brandId)} className={styles.deleteButton}>
        <Trash />
      </Tooltip>
    </div>
  }
}

export default DraftBrandHeader;