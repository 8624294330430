import React from 'react'

import { Warning } from '../../toneIcons'
import Popup from '../popup/popup'

import styles from './deletePopup.module.scss'

interface DeletePopupProps extends React.HTMLProps<HTMLDivElement> {
  onClose: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  onSubmit: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const DeletePopup: React.FC<DeletePopupProps> = ({onClose, onSubmit}) => {
  return <Popup icon={<Warning className={styles.warning} />} onClose={onClose}>
    <h4>Permanent Delete</h4>
    <p>This is CAN NOT be undone. Hope you know what you're doing</p>
    {onSubmit != null && <div className={styles.button} onClick={onSubmit}>Yes, delete it</div>}
  </Popup>
}

export default DeletePopup;