export type ContextPropsState = {
  groupedSentiments: {[key: string]: any }
}

export type OrdersAction = 
  | ({ type: "FETCH_SENTIMENT_SEARCH", data: any, reportId: string } )
  | { type: "RESET" }


export const initialState: ContextPropsState = {
  groupedSentiments: {},
};

export const reducer = (state: ContextPropsState, action: OrdersAction) => {
  if('FETCH_SENTIMENT_SEARCH' === action.type) {
    if(Array.isArray(action.data)) {
      const groupedSentiments = { ...state.groupedSentiments, [action.reportId]: action.data };    
      return { ...state, groupedSentiments }
    } else {
      const groupedSentiments = Object.assign({}, state.groupedSentiments, {[action.reportId]: []})
      return { ...state, groupedSentiments }
    }
  } else if ('RESET' === action.type) {
    return initialState
  }

  return state
}
