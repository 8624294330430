import React from 'react'
import {stringify as queryStringify} from 'query-string'
import {Link} from '@reach/router'

import styles from './userDetail.module.scss'

interface UserDetailProps {
  search: any
}

export const UserDetail: React.FC<UserDetailProps> = React.memo(({search}) => {
  return <div className={styles.detail}>
    <div className={styles.tabs}>
      <Link className={[styles.tab, (search.userTab == null) ? styles.selected : null].join(' ')} to={`?${queryStringify({...search, userTab: undefined })}`}>HISTORY</Link>
      <Link className={[styles.tab, (search.userTab === 'purchase') ? styles.selected : null].join(' ')} to={`?${queryStringify({...search, userTab: 'purchase'})}`}>PURCHASE</Link>
      {/* <Link className={[styles.tab, (search.userTab === 'segments') ? styles.selected : null].join(' ')} to={`?${queryStringify({...search, userTab: 'segments'})}`}>SEGMENTS</Link> */}
    </div>
    <div className={styles.scrollable}>
      {search.userTab == null && <UserHistory />}
      {search.userTab === 'purchase' && <UserPurchase />}
      {/* {search.userTab === 'segments' && <>
      
      </>} */}
    </div>
  </div>
})

export default UserDetail;

interface UserHistoryRowProps {
  title: any,
  subtitle: any,
  date: any
}

const UserHistoryRow: React.FC<UserHistoryRowProps> = ({title, subtitle, date}) => {
  return <div className={styles.userRow}>
    <div className={styles.userRowCircle}>
      <div className={styles.circle} />
      <div className={styles.circleBar} />
    </div>
    <div className={styles.userRowTitle}>{title}</div>
    <div className={styles.userRowSubtitle}>{subtitle}</div>
    <div className={styles.userRowDate}>{date}</div>
  </div>
}

const UserHistory = () => {
  return <div>
    <UserHistoryRow title="Online Purchase" subtitle="Inbound: Mobile instagram" date="Jan 27, 2019" />
    <UserHistoryRow title="App Installation" subtitle="Register account, Email optin" date="Feb 1, 2019" />
    <UserHistoryRow title="Support Chat" subtitle="Confusion" date="Mar 28, 2019" />
    <UserHistoryRow title="Post Call Survey" subtitle="Positive" date="Mar 28, 2019" />
    <UserHistoryRow title="Referral Link used" subtitle="" date="Aug 17, 2019" />
  </div>
}

const UserPurchase = () => {
  return <div>
    <UserHistoryRow title="Vanilla Frapuccino" subtitle="Seattle SODO $4.97" date="Jan 27, 2019" />
    <UserHistoryRow title="Caramel Machiato" subtitle="Greenwood Safeway $4.97" date="Feb 1, 2019" />
    <UserHistoryRow title="Cafe Americano" subtitle="Location $4.97" date="Mar 28, 2019" />
    <UserHistoryRow title="Vanilla Frapuccino" subtitle="Location $4.97" date="Mar 28, 2019" />
    <UserHistoryRow title="Cafe Americano" subtitle="Location $4.97" date="Aug 17, 2019" />
  </div>
}