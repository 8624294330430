import React from 'react'
import { useReportsContext } from '../../contexts'
import useForm from '../../hooks/useForm'

import styles from './draftReportNameSection.module.scss'

export const DraftReportNameSection = ({active, seen, report, onNext, onClick}) => {
  const [, {updateReportData}] = useReportsContext();
  const reportName = report?.data?.name;
  const reportId = report?.data?._id;
  const { values, handleChange, registerSubmit } = useForm({_id: reportId, name: reportName})

  if(!active && !seen) return null;

  const onSubmit = ({values}) => {
    updateReportData(values);
    if(onNext) onNext();
  }

  const isSubmitDisabled = !(values?.name?.length > 0)

  const header = reportName ? "Ok, let's rename your report..." : "First, let's name your report...";

  return <form onSubmit={registerSubmit(onSubmit)} className={[styles.section, seen ? styles.seen : null].join(' ')} onClick={!active ? onClick : null}>
    <h1 className={styles.header}>{header}</h1>
    <input className={styles.input}
      disabled={!active}
      name="name"
      value={values.name || ""}
      onChange={handleChange}
      autoComplete="off"
      autoFocus />
    {!reportName && <button className={[styles.submit, styles.clickable].join(' ')} disabled={isSubmitDisabled} type="submit">NEXT</button>}
    {reportName && <button className={[styles.submit, styles.clickable].join(' ')} disabled={isSubmitDisabled} type="submit">SAVE</button>}
  </form>
}

export default DraftReportNameSection;