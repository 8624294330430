import React from 'react';

import { useKey } from "../../hooks/useKey";
import Popup from '../../libs/popup/popup'
import { PhotoIcon, Chevron2Icon } from '../icons/icons'
import styles from './images.module.scss'

interface ImageProps {
  src: string,
  onClick: any
}

const Image: React.FC<ImageProps> = React.memo(({ src, onClick }) => {
  const [isHorizontal, setIsHorizontal] = React.useState(false);

  const onLoad = ({ target: img }: any) => {
    if (img.offsetWidth > img.offsetHeight) setIsHorizontal(true);
    else setIsHorizontal(false);
  }

  return <div className={[styles.image, isHorizontal ? styles.horizontal : null].join(' ')} onClick={() => onClick && onClick(src)}>
    <img src={src} onLoad={onLoad} alt={src} />
  </div>
})

interface FakeImageProps {
  horizontal?: boolean
}

const FakeImage: React.FC<FakeImageProps> = ({horizontal}) => <div className={[styles.fakeImage, horizontal ? styles.horizontal : null].join(' ')}>
  <PhotoIcon />
</div>

interface ImagesProps {
  urls: string[],
  loaded: boolean,
  search: any,
  setSearch: any
}

export const Images: React.FC<ImagesProps> = React.memo(({ urls, loaded, search, setSearch }) => {
  const onChangePhoto = (inc: any) => {
    const photo = ((Number.parseInt(search.photo) || 0) + urls.length + (inc || 0)) % urls.length;
    setSearch((s: any) => ({ ...s, photo }));
  }

  if(!loaded && urls == null) return <div className={styles.images}>
    <FakeImage />
    <FakeImage />
    <FakeImage />
  </div>

  if(urls == null) return null;
  const showPopup = search.photo != null;

  return <div className={styles.images}>
    {urls?.map((src, i) => <Image key={src} src={src} onClick={() => onChangePhoto(i)} />)}
    {showPopup && <ImagesPopup screenshot={urls[Number.parseInt(search.photo)]} onChangePhoto={onChangePhoto} onClose={() => setSearch((s: any) => ({ ...s, photo: undefined }))} />}
  </div>
})

export default Images

interface ImagesPopupProps {
  screenshot: any,
  onChangePhoto: any,
  onClose: any
}

const ImagesPopup: React.FC<ImagesPopupProps> = ({screenshot, onChangePhoto, onClose}) => {
  useKey(['ArrowRight', 'ArrowLeft', 'Escape'], (event: any) => {
    if(event.key === 'ArrowLeft') onChangePhoto(-1);
    else if(event.key === 'ArrowRight') onChangePhoto(1)
    else if(event.key === 'Escape') onClose();
  })

  return <Popup onClose={() => onClose()}>
    <div className={styles.prevIcon} onClick={() => onChangePhoto(-1)}><Chevron2Icon /></div>
    <div className={styles.popupContent}>
      <img src={screenshot} alt="screenshot" />
    </div>
    <div className={styles.nextIcon} onClick={() => onChangePhoto(1)}><Chevron2Icon /></div>
  </Popup>
}