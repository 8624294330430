import React, {useState} from 'react';

import styles from './input.module.css'

const CloseIcon: React.FC<React.SVGProps<SVGElement>> = ({onClick}) => <svg viewBox="0 0 64 64" fillRule="evenodd" className={styles.closeIcon} onClick={onClick}>
  <path d="M32,64 C14.326888,64 0,49.673112 0,32 C0,14.326888 14.326888,0 32,0 C49.673112,0 64,14.326888 64,32 C64,49.673112 49.673112,64 32,64 Z M32,58 C46.3594035,58 58,46.3594035 58,32 C58,17.6405965 46.3594035,6 32,6 C17.6405965,6 6,17.6405965 6,32 C6,46.3594035 17.6405965,58 32,58 Z M36.2426407,32 L45.7885822,41.5459415 C46.9601551,42.7175144 46.9601551,44.6170094 45.7885822,45.7885822 C44.6170094,46.9601551 42.7175144,46.9601551 41.5459415,45.7885822 L32,36.2426407 L22.4540585,45.7885822 C21.2824856,46.9601551 19.3829906,46.9601551 18.2114178,45.7885822 C17.0398449,44.6170094 17.0398449,42.7175144 18.2114178,41.5459415 L27.7573593,32 L18.2114178,22.4540585 C17.0398449,21.2824856 17.0398449,19.3829906 18.2114178,18.2114178 C19.3829906,17.0398449 21.2824856,17.0398449 22.4540585,18.2114178 L32,27.7573593 L41.5459415,18.2114178 C42.7175144,17.0398449 44.6170094,17.0398449 45.7885822,18.2114178 C46.9601551,19.3829906 46.9601551,21.2824856 45.7885822,22.4540585 L36.2426407,32 Z" />
</svg>

const WarningIcon = () => <svg viewBox="0 0 90 85" fillRule="evenodd" className={styles.warningIcon}>
  <path d="M89.330978,76.561 L50.097978,2.984 C49.102978,1.16 47.196978,0 45.096978,0 C42.996978,0 41.093978,1.16 40.123978,2.984 L0.668978001,76.561 C-0.271021999,78.301 -0.215021999,80.428 0.802978001,82.114 C1.828978,83.827 3.682978,84.877 5.668978,84.877 L84.356978,84.877 C86.345978,84.877 88.169978,83.827 89.192978,82.114 C90.215978,80.428 90.270978,78.301 89.330978,76.561 Z M44.818978,75.621 C42.666978,75.621 40.922978,73.878 40.922978,71.725 C40.922978,69.575 42.666978,67.829 44.818978,67.829 C46.970978,67.829 48.715978,69.574 48.715978,71.725 C48.715978,73.877 46.970978,75.621 44.818978,75.621 Z M50.433978,31.533 L47.855978,60.765 C47.709978,62.64 46.564978,64.044 44.818978,64.044 C43.073978,64.044 41.928978,62.64 41.781978,60.765 L39.203978,31.533 C39.071978,29.841 39.183978,28.309 40.670978,26.916 C41.867978,25.793 43.241978,24.955 44.817978,24.955 C46.394978,24.955 47.768978,25.793 48.964978,26.916 C50.452978,28.309 50.565978,29.84 50.433978,31.533 Z" />
</svg>


interface InputPros extends React.HTMLProps<HTMLDivElement> {
  error?: any,
  titleClassName?: string,
  borderClassName?: string,
  preTextIcon?: any,
  hideCloseButton?: any,
  value?: any,
  onChange?: any,
}

export const Input: React.FC<InputPros> = ({title, value, placeholder, type, name, disabled, error, onFocus, onBlur, className: _className, onChange, autoFocus, titleClassName, borderClassName, preTextIcon, defaultValue, hideCloseButton, autoCapitalize, autoComplete, ...props}) => {
  const [focused, setFocused] = useState(false);

  const className = [
    styles.container,
    _className,
    (value?.length > 0) ? styles.hasValue : null,
    (disabled) ? styles.disabled : null,
    (focused) ? styles.focused : null,
    (error) ? styles.error : null,
  ].filter(a=>a).join(" ");

  const onInputFocus = (e: any) => {
    setFocused(true);
    if(onFocus) onFocus(e);
  }
  const onInputBlur = (e: any) => {
    setFocused(false);
    if(onBlur) onBlur(e);
  }

  const onDeleteText = () => {
    onChange({target: {value: ''}});
  }

  const showClose = !disabled && value?.length > 0 && !hideCloseButton
  return <div className={className} {...props}>
    {title != null && <div className={[styles.title, titleClassName].join(' ')}>
      <span>{title}</span>
      {error != null && <span>{error}</span>}
    </div>}
    <div className={[styles.border, borderClassName].join(' ')}>
      {preTextIcon != null && React.cloneElement(preTextIcon, {className: [preTextIcon.props.className, styles.preTextIcon].join(' ')})}
      <input className={styles.input}
        placeholder={placeholder}
        type={type}
        name={name}
        value={value}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onChange={onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete} />
      {error && <WarningIcon />}
      {showClose && <CloseIcon onClick={onDeleteText} />}
    </div>
  </div>
}

export default Input