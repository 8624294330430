import React from 'react';

import { conceptIconByName } from '../../utils2'

import styles from './reviewFooter.module.scss'


interface ReviewFooterProps extends React.HTMLProps<HTMLDivElement> {
  concepts?: any,
  appProvider?: string,
  sourcesById?: {[sourceId: string]: any},
  conceptsById?: {[conceptId: string]: any},
  href?: string
}

export const ReviewFooter: React.FC<ReviewFooterProps> = React.memo(({concepts, appProvider, sourcesById, conceptsById, href}) => {
  const source = sourcesById[appProvider];
  const conceptIds = concepts?.map((c: any) => c.c);
  const uniqueConceptIds = conceptIds != null ? Array.from(conceptIds && new Set(conceptIds)) : [];
  return <div className={styles.footer}>
    {source?.icon && <>
      {href && <a href={href} target="_blank" rel="noopener noreferrer" className={styles.linkIcon}>
        {React.createElement(source.icon)}
      </a>}
      {!href && <span className={styles.icon}>
        {React.createElement(source.icon)}
      </span>}
    </>}
    {conceptsById != null && uniqueConceptIds?.map((id: string) => {
      const Icon = conceptIconByName[conceptsById?.[id]?.name];

      if(Icon == null) return null;
      return <span className={styles.icon} key={id}>
        <Icon tone1="#B7B6B9" tone2="#B7B6B9" tone3="#B7B6B9" tone4="#B7B6B9" />
      </span>
    })}
  </div>
})


export default ReviewFooter