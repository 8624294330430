import React from 'react';
import { useGlobalKey } from "../../hooks/useKey";
import styles from './nextPrevArrows.module.scss';


const ArrowLeft: React.FC<React.SVGProps<SVGElement>> = ({className}) => {
  return <svg width="19px" height="15px" viewBox="0 0 19 15" className={className}>
    <path d="M0.32023833,8.25250007 L6.44063888,14.3729006 C6.86095891,14.7932207 7.54063898,14.7932207 7.94063901,14.3729006 C8.36095905,13.9525806 8.36095905,13.2729005 7.94063901,12.8729005 L3.63983863,8.55250009 L17.9398399,8.55250009 C18.51952,8.55250009 18.9999983,8.07282005 18.9999983,7.49234 C18.9999983,6.91265995 18.52032,6.4321799 17.9398399,6.4321799 L3.63983863,6.4321799 L7.93983901,2.13217952 C8.36015905,1.71185948 8.36015905,1.03217942 7.93983901,0.632179386 C7.73983899,0.432179368 7.46015897,0.311859357 7.17967894,0.311859357 C6.89999892,0.311859357 6.63983889,0.411859366 6.41951888,0.632179386 L0.32031833,6.73217993 C0.120318312,6.93217995 -1.69873238e-06,7.21185997 -1.69873238e-06,7.49234 C-1.69873238e-06,7.77202002 0.120310312,8.05250005 0.32031833,8.25250007 L0.32023833,8.25250007 Z" />
  </svg>
}

interface NextPrevArrowsProps {
  index: number,
  slides: any[],
  onPrev: (e:any | undefined, index: number) => void,
  onNext: (e:any | undefined, index: number) => void,
  answers?: any[],
}

const animationTimeMS = 100;
export const NextPrevArrows: React.FC<NextPrevArrowsProps> = ({index, answers, slides, onPrev, onNext}) => {
  const isPrevDisabled = index <= 0;
  let isNextDisabled = index >= ( (slides?.length || 0) - 1);

  if(slides?.[index]) {
    const slide = slides[index];

    if(slide?.required) {
      const answerValue = answers?.[slide.id];
      if(slide.type === 'number' && answerValue?.length > 0) {
        isNextDisabled = false;
      } else if(slide.type === 'nps' && answerValue != null) {
        isNextDisabled = false;
      } else {
        isNextDisabled = !answerValue;
      }
    }
  }
  

  const style: any = {
    '--animation-time': `${animationTimeMS}ms`,
  }
  
  return <div className={styles.container} style={style}>
    <LeftButton onButtonClick={isPrevDisabled ? undefined : onPrev} index={index} />
    <RightButton onButtonClick={isNextDisabled ? undefined : onNext} index={index} />
  </div>
}

interface ButtonProps extends React.HTMLProps<HTMLElement> {
  index: number,
  onButtonClick: (e:any | undefined, index: number) => void
}

const LeftButton: React.FC<ButtonProps> = ({onButtonClick, index}) => {
  const [selected, setSelected] = React.useState(false);
  const disabled = onButtonClick == null;

  const onClickButton = React.useCallback(() => {
    setSelected(true);

    setTimeout(() => {
      onButtonClick?.(undefined, index);
      setSelected(false);
    }, animationTimeMS + 200);
  }, [onButtonClick, index])
  
  const arrowPress = React.useCallback(() => {
    if(!disabled) onClickButton();
  }, [disabled, onClickButton])

  useGlobalKey('ArrowLeft', arrowPress);

  const className = [selected ? styles.selected : null].join(" ")
  return <button disabled={disabled} className={className} onClick={onClickButton}>
    <ArrowLeft />
  </button>
}

const RightButton: React.FC<ButtonProps> = ({onButtonClick, index}) => {
  const [selected, setSelected] = React.useState(false);
  const disabled = onButtonClick == null;

  const onClickButton = React.useCallback(() => {
    setSelected(true);

    setTimeout(() => {
      onButtonClick?.(undefined, index);
      setSelected(false);
    }, animationTimeMS + 200);
  }, [onButtonClick, index])
  
  const arrowPress = React.useCallback(() => {
    if(!disabled) onClickButton();
  }, [disabled, onClickButton])

  useGlobalKey('ArrowRight', arrowPress);

  const className = [selected ? styles.selected : null].join(" ")
  return <button disabled={disabled} className={className} onClick={onClickButton}>
    <ArrowLeft className={styles.right} />
  </button>
}

export default NextPrevArrows