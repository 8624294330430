import React from 'react'

import useForm from '../../hooks/useForm'
import { SmileBox } from '../../toneIcons'
import Popup from '../popup/popup'
import Loading from '../loading/loading'
import {namespaceStyle} from '../../utils'

import {useReportsContext} from '../../contexts'

import styles from './feedbackPopup.module.scss'

interface FeedbackPopupProps {
  demoReportId: string,
  report: any,
  onClose: any
}

export const FeedbackPopup: React.FC<FeedbackPopupProps> = ({demoReportId, report, onClose}) => {
  const [showThanks, setShowThanks] = React.useState(false);
  const { values, errors, isSubmitting, handleChange, registerSubmit, reset }: any = useForm({rating: null, text: ''})
  // const [, {sendFeedback, sendDemoFeedback}] = useReportsContext();

  const sendFeedback = (test: any, body: any) => {}
  const sendDemoFeedback = (test: any, body: any) => {}

  const disabled = values.rating == null || !values.text;
  const onSubmit = async ({values, setErrors, setIsSubmitting}: any) => {
    try {
      setIsSubmitting(true);
      const body = { ...values };
      if(demoReportId) {
        body.description = report?.demoMapping?.description;
        await sendDemoFeedback(demoReportId, body);
      } else {
        await sendFeedback(report?.data?._id, body);
      }
      setIsSubmitting(false);
      setShowThanks(true);
    } catch(error) {
      setErrors({global: 'Sorry, there was an error sending your feedback, try again later'})
      setIsSubmitting(false);
      setShowThanks(false);
    }
  }

  const messages = [
    <p>Sorry we missed the mark - How can we improve?</p>,
    <p>Sorry we missed the mark - How can we improve?</p>,
    <p>What can we do to make this "perfect"?</p>,
    <p>What can we do to make this "perfect"?</p>,
    <p>Great! Thanks for your support.  Anything you'd improve? </p>,
  ];
  
  return <Popup onClose={onClose} popupContentClassName={styles.content} style={namespaceStyle()}>
    {isSubmitting && <Loading className={styles.loading} />}
    {!isSubmitting && !showThanks && <form onSubmit={registerSubmit(onSubmit)} className={styles.form}>
      <h4>How are we doing?</h4>
      {errors?.global && <div className={styles.error}>{errors.global}</div>}
      
      <div className={styles.smiles}>
        {Array.from({length: 5}).map( (_, index, arr) => {
          const score = (2*index)/(arr.length - 1) - 1;
          return <label className={[styles.smile, values.rating != null ? styles.hasValue : null].join(' ')} key={index}>
            <input type="radio" name="rating" checked={values.rating === `${index}`} value={index} onChange={handleChange} />
            <SmileBox score={score} />
          </label>
        })}
      </div>
      <div className={styles.hr} />

      { values.rating != null && messages[values.rating] }
      { values.rating == null && <p>What <strong>one thing</strong> would make this dashboard better?</p> }
      <textarea className={styles.textarea} onChange={handleChange} value={values.text} name="text" />

      <button className={[styles.submit, disabled ? styles.disabled : null].join(' ')} disabled={disabled} type="submit">Submit</button>
    </form>}
    {!isSubmitting && showThanks && <div className={styles.thankyou}>
      <div className={styles.thankyouMessage}>
        <strong>Thank you</strong> for your feedback <span role="img" aria-label="Raised Fist">✊</span>
      </div>
      <button className={styles.submit} onClick={() => {reset(); setShowThanks(false)}}>I have more to say...</button>
    </div>}
  </Popup>
}

export default FeedbackPopup;