import { uniqueBy } from '../utils'
import { PaginatedArray } from '../paginatedArray'
import { getScore, getDetailRangeColor } from '../utils'
import { mapValues } from 'lodash'

const sigmoid = (t: number) => 1 / (1 + Math.pow(Math.E, -t));

export type ContextPropsState = {
  groupedReviews?: any,
  reviewsById?: {[id: string]: any[]},
  commentsByReviewId?: any,
  reviewsWithCommentsByReportId?: {[id: string]: any},
  reviewsByTags?: {[tag: string]: any},
}

export type OrdersAction = 
  | ({ type: "FETCH_REVIEWS_START", reportId: string, id: string, search: any } )
  | ({ type: "FETCH_REVIEWS", reportId: string, id: string, search: any, data: any, perPage?: number } )
  | ({ type: "FETCH_REVIEWS_ERROR", reportId: string, id: string})
  
  | ({ type: "FETCH_REVIEW_START", reportId?: string, reviewId: string, demoReportId?: string})
  | ({ type: "FETCH_REVIEW", review: any})
  | ({ type: "FETCH_REVIEW_ERROR", reportId?: string, demoReportId?: string, reviewId: string})

  | ({ type: "ADD_COMMENT", data: any, reviewId: string, reportId: string})
  | ({ type: "DELETE_COMMENT", data: any, reviewId: string, reportId: string})
  | ({ type: "FETCH_COMMENTS", data: any, reportId: string})

  | ({type: "FETCHED_TAGGED_REVIEWS", reviews: any[], reportId: string, tag: string})
  | { type: "UPDATE_CLASSIFICATION", reportId: string, brandId: string, reviewId: string, reportAppReview: any}

  | { type: "RESET" }


export const initialState: ContextPropsState = {
  groupedReviews: undefined,
  reviewsById: undefined,
  commentsByReviewId: undefined,
  reviewsWithCommentsByReportId: undefined,
  reviewsByTags: undefined
};

export const reducer = (state: ContextPropsState, action: OrdersAction) => {
  if('FETCH_REVIEWS_START' === action.type) {
    const key = `${action.reportId}_${action.id}`;
    const groupedReviews = { ...state.groupedReviews };

    if (groupedReviews[key] == null) {
      groupedReviews[key] = new PaginatedArray({ perPage: 50, loading: true })
    }
    groupedReviews[key].setLoading(true, { ...action.search });

    return { ...state, groupedReviews }
  }
  if('FETCH_REVIEWS' === action.type) {
    const key = `${action.reportId}_${action.id}`
    const groupedReviews = { ...state.groupedReviews };

    const commentsByReviewId = { ...state.commentsByReviewId, ...uniqueBy(action.data.comments, (c:any) => c.reviewId, (v:any) => v.comments)}

    if (groupedReviews[key] == null) {
      groupedReviews[key] = new PaginatedArray({ perPage: 50 })
    }
    const dependencies = { ...action.search };
    delete dependencies['page'];
    groupedReviews[key].addAt(action.data.reviews, action.data.page, dependencies);
    

    const reviewsById = {
      ...state.reviewsById,
      ...action.data.reviews && uniqueBy(action.data.reviews, (r: any) => r._id, (r: any) => correctReviewRanges(r))
    }
    return { ...state, groupedReviews, commentsByReviewId, reviewsById }
  }
  if ('FETCH_REVIEW_START' === action.type) {
    // const key = `${action.reportId}_${action.id}`
    // const groupedReviews = { ...state.groupedReviews };
    // groupedReviews[key] = new PaginatedArray({ perPage: 50 });
    return { ...state }
  }
  if ('FETCH_REVIEW' === action.type) {
    const reviewsById = {
      ...state.reviewsById,
      [action.review._id]: correctReviewRanges(action.review)
    }
    return { ...state, reviewsById }
  }
  if ('FETCH_REVIEW_ERROR' === action.type) {
    // const key = `${action.reportId}_${action.id}`
    // const groupedReviews = { ...state.groupedReviews };
    // groupedReviews[key] = new PaginatedArray({ perPage: 50 });
    return { ...state }
  }
  if ('FETCH_REVIEWS_ERROR' === action.type) {
    const key = `${action.reportId}_${action.id}`
    const groupedReviews = { ...state.groupedReviews };
    groupedReviews[key] = new PaginatedArray({ perPage: 50 });
    return { ...state, groupedReviews }
  }
  if('ADD_COMMENT' === action.type) {
    const commentsByReviewId = { ...state.commentsByReviewId, [action.data.reviewId] : action.data.comments }
    return { ...state, commentsByReviewId }
  }
  if('DELETE_COMMENT' === action.type) {
    const commentsByReviewId = { ...state.commentsByReviewId, [action.data.reviewId] : action.data.comments }
    return { ...state, commentsByReviewId }
  }
  if('FETCH_COMMENTS' === action.type) {
    const commentsByReviewId = { ...state.commentsByReviewId, ...uniqueBy(action.data.comments, (a: any) => a.reviewId, (v: any) => v.comments)}
    const reviewsWithCommentsByReportId = { ...state.reviewsWithCommentsByReportId, [action.reportId]: action.data?.comments?.map((c: any) => c.reviewId) }
    const reviewsById = {
      ...state.reviewsById,
      ...mapValues(action.data.reviewsById, (r: any) => correctReviewRanges(r)),
     }
    return { ...state, reviewsById, reviewsWithCommentsByReportId, commentsByReviewId }
  }
  if ('FETCHED_TAGGED_REVIEWS' === action.type) {
    const filteredReviews = action.reviews.filter(r => r.ss != null && r.sm != null)
    const magAvg = filteredReviews.reduce((p, r) => {
      if (r.sm == null) { return p }
      return p + (r.sm);
    }, 0) / filteredReviews.length;

    const reviews = filteredReviews.map(r => {
      return {
        ...r,
        sigSm: sigmoid(r.sm - magAvg) * 2 - 1,
        time: new Date(r.date).getTime(),
        color: getDetailRangeColor(getScore(r.ss)),
      }
    });
    const reviewsByTags = { ...state.reviewsByTags, [`${action.reportId}_${action.tag}`]: reviews }
    return { ...state, reviewsByTags }
  } else if('UPDATE_CLASSIFICATION' === action.type) {
    const review = state.reviewsById?.[action.reviewId];
    const newReview = { ...review, classOverrides: action.reportAppReview.classOverrides};

    const reviewsById = { ...state.reviewsById, [action.reviewId]: correctReviewRanges(newReview) }
    return { ...state, reviewsById }
  } else if('RESET' === action.type) {
    return initialState
  }

  throw new Error();
}

const correctReviewRanges = (review: any) => {
  if(review == null) return review;

  if(review.classOverrides != null && review.classOverrides.length > 0) {
    let joinedRanges = [];
    const filteredRanges = (review.ranges && review.ranges.filter((r:any) => {
      for(var i = 0; i< review.classOverrides.length; i++) {
        const classOverride = review.classOverrides[i];
        if(classOverride != null && classOverride.b >= r.b && classOverride.b < r.e) {
          return false;
        } else if(classOverride != null && classOverride.b < r.e && classOverride.e >= r.b) {
          return false;
        }
      }
      return true;
    })) || []

    
    let classOverridesIndex = 0;
    for(var i = 0; i< filteredRanges.length; i++) {
      const range = filteredRanges[i];
      for(var j = classOverridesIndex; j<review.classOverrides.length; j++) {
        const classOverride = review.classOverrides[j];
        if(classOverride.b > range.e) {
          break;
        } else if(classOverride.e < range.b){
          const correctedOverride = {cats:classOverride.catIds.map((c:any) => ({c, s: classOverride.score})), b: classOverride.b, e: classOverride.e};
          joinedRanges.push(correctedOverride);
          classOverridesIndex+=1;
        }
      }
      joinedRanges.push(range);
    }

    for(var k = classOverridesIndex; k<review.classOverrides.length; k++) {
      const classOverride = review.classOverrides[k];
      const correctedOverride = {cats:classOverride.catIds.map((c: any) => ({c, s: classOverride.score})), b: classOverride.b, e: classOverride.e};
      joinedRanges.push(correctedOverride);
    }
    review.joinedRanges = joinedRanges;
  } else {
    review.joinedRanges = review.ranges;
  }
  return review;
}