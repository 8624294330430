import React from 'react'
import { useLocalStorage } from '@rehooks/local-storage';

import DeletePopup from '../../components/deletePopup/deletePopup'
import { useConceptsContext } from '../../contexts'
import Input from '../../components/input/input'
import TopicTag, {TopicAddTag} from '../../components/topicTag/topicTag'
import { DeleteIcon } from '../../components/icons/icons'

import { ReportsLeftNav } from '../../components/leftNav/leftNav'
import { sources} from '../../utils'

import styles from './conceptGroups.module.scss'

export const ConceptGroups = () => {
  const [showDeletePopupId, setShowDeletePopupId] = React.useState(null);
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const [state, {fetchConceptsLibrary, addConceptGroup, updateConceptGroup, deleteConceptGroup}] = useConceptsContext();
  const [isAdding, setIsAdding] = React.useState<boolean>();
  const [addingData, setAddingData] = React.useState<any>({});
  const [addingConceptId, setAddingConceptId] = React.useState();
  const [addingProviderId, setAddingProviderId] = React.useState();
  React.useEffect(() => { fetchConceptsLibrary()}, [fetchConceptsLibrary])

  const judgementConcepts = state?.libraryConcepts?.filter((c: any) => c.catType === 'judgement');
  const domainConcepts = state?.libraryConcepts?.filter((c: any) => c.catType === 'domain');
  const conceptGroups = state.libraryConceptGroups;

  const addConceptGroupSubmit = (e: any) => {
    if(e) e.preventDefault();
    setAddingData({});
    addConceptGroup(addingData);
    setIsAdding(false);
  }

  const style: any = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  }

  const closeDeletePopup = (deleteId?: string) => {
    if(deleteId) deleteConceptGroup(deleteId);
    setShowDeletePopupId(null)
  }

  return <div style={style} className={styles.containerWrap}>
    <ReportsLeftNav />
    <div className={styles.container}>
      {!isAdding && <div className={styles.addGroup} onClick={() => setIsAdding(true)}>Add Group</div>}
      {isAdding && <form className={styles.addGroup} onSubmit={addConceptGroupSubmit}>
        <Input placeholder="Group Name" onChange={(e: any) => setAddingData({...addingData, name: e.target.value})} value={addingData.name || ''} autoFocus />
      </form>}
      {conceptGroups?.map((c:any) => <ConceptGroup key={c._id} data={c} deleteConceptGroup={setShowDeletePopupId} judgementConcepts={judgementConcepts} domainConcepts={domainConcepts} addingConceptId={addingConceptId} setAddingConceptId={setAddingConceptId} addingProviderId={addingProviderId} setAddingProviderId={setAddingProviderId} libraryConceptsById = {state.libraryConceptsById} updateConceptGroup={updateConceptGroup} />)}
    </div>

    {showDeletePopupId != null && <DeletePopup onClose={() => closeDeletePopup()} onSubmit={() => closeDeletePopup(showDeletePopupId)} />}
  </div>
}

interface ConceptGroupProps {
  data: any,
  deleteConceptGroup: any,
  judgementConcepts: any,
  domainConcepts: any,
  addingConceptId: any,
  setAddingConceptId: any,
  addingProviderId: any,
  setAddingProviderId: any,
  libraryConceptsById: any,
  updateConceptGroup: any
}

const ConceptGroup: React.FC<ConceptGroupProps> = ({data, deleteConceptGroup, judgementConcepts, domainConcepts, addingConceptId, setAddingConceptId, addingProviderId, setAddingProviderId, libraryConceptsById, updateConceptGroup}) => {

  const addConcept = (group: any, concept: any) => {
    const conceptIds = group.conceptIds || [];
    if(conceptIds.indexOf(concept._id) < 0) conceptIds.push(concept._id);
    updateConceptGroup(group._id, {conceptIds})
  }
  const deleteConcept = (group: any, id: string) => {
    const conceptIds = (group.conceptIds || []).filter((cId: string) => cId !== id);
    updateConceptGroup(group._id, {conceptIds})
  }

  const addProvider = (group: any, source: any) => {
    const triggerProviders = group.triggerProviders || [];
    if(triggerProviders.indexOf(source.provider) < 0) triggerProviders.push(source.provider);
    updateConceptGroup(group._id, {triggerProviders})
  }
  const deleteProvider = (group: any, provider: string) => {
    const triggerProviders = (group.triggerProviders || []).filter((tProvider: any) => tProvider !== provider);
    updateConceptGroup(group._id, {triggerProviders})
  }
  
  return <div className={styles.card}>
    <div className={styles.header}>
      <span>{data.name}</span>
      <DeleteIcon className={styles.deleteIcon} onClick={() => deleteConceptGroup(data._id)} />
    </div>
    {data?.conceptIds?.map((id: string) => {
      const concept = libraryConceptsById?.[id];
      return <TopicTag key={id} value={concept.name} onClick={() => deleteConcept(data, id)} />
    })}
    
    {addingConceptId === data._id && <>
      <div className={styles.conceptsHeader}>domain concepts</div>
      {domainConcepts?.map((concept: any) => {
        if(data.conceptIds && data.conceptIds.indexOf(concept._id) >= 0) return null;
        return <TopicAddTag key={concept._id} value={concept.name} onClick={() => addConcept(data, concept)} />
      })}
      <div className={styles.conceptsHeader}>judgement concepts</div>
      {judgementConcepts?.map((concept: any) => {
        if(data?.conceptIds?.indexOf(concept._id) >= 0) return null;
        return <TopicAddTag key={concept._id} value={concept.name} onClick={() => addConcept(data, concept)} />
      })}
    </>}

    <button className={styles.addConceptsButton} onClick={() => setAddingConceptId(addingConceptId === data._id ? undefined : data._id)}>
      {addingConceptId === data._id && <span>Cancel</span>}
      {addingConceptId !== data._id && <span>Add concepts</span>}
    </button>

    {data?.triggerProviders?.map((provider: any) => {
      return <TopicTag key={provider} value={provider} onClick={() => deleteProvider(data, provider)} />
    })}
    {addingProviderId === data._id && <>
      {sources?.map(source => {
        if(data?.triggerProviders?.indexOf(source.provider) >= 0) return null;
        return <TopicAddTag key={source.provider} value={source.provider} onClick={() => addProvider(data, source)} />
      })}
    </>}
    
    <button className={styles.addConceptsButton} onClick={() => setAddingProviderId(addingProviderId === data._id ? undefined : data._id)}>
      {addingProviderId === data._id && <span>Cancel</span>}
      {addingProviderId !== data._id && <span>Add providers</span>}
    </button>
  </div>
}

export default ConceptGroups;