import React from 'react'
import download from 'downloadjs'
import domtoimage from 'dom-to-image-more'
import { isMobile } from 'react-device-detect'

import { DownloadIcon } from '../../components/icons/icons'

import styles from './card.module.scss'

interface CardProps extends React.HTMLProps<HTMLDivElement> {
  header: string,
  description: string,
  filters?: any,
  full?: boolean,
  onDelete?: any,
  inset?: any,
  editing?: boolean,
  fileName?: string,
  auto?: any,
}

export const Card: React.FC<CardProps> = (props) => {
  if(isMobile) return <MobileCard {...props} />
  else return <DesktopCard {...props} />
}

const MobileCard: React.FC<CardProps> = ({header, description, filters, children, full, onDelete, inset, onClick, editing}) => {
  return <div className={[styles.container, full ? styles.full : null].join(' ')} onClick={onClick}>
    {(header != null || filters != null) && <div className={styles.header}>
      <div className={styles.title}>{header}</div>
      <div className={styles.filters}>{filters}</div>
    </div>}
    <div className={styles.content}>
      {description != null && <div className={styles.description} dangerouslySetInnerHTML={{__html: description}} />}
      <div className={[styles.contentData, inset ? styles.inset : null].join(' ')}>{children}</div>
    </div>
    {editing === null && <div className={styles.editingContainer}>
      <button className={styles.delete} onClick={onDelete}>Delete</button>
    </div>}
  </div>
}

const DesktopCard: React.FC<CardProps> = ({header, fileName, description, filters, children, auto, full, onDelete, inset, onClick, editing}) => {
  const exportableRef = React.useRef(null);
  const exportChart = async () => {
    const dataUrl = await domtoimage.toPng(exportableRef.current, {scale: 2})
    download(dataUrl, fileName);
  }

  return <div className={[styles.section, full ? styles.full : null, auto ? styles.auto : null].join(' ')} onClick={onClick} >
    <div className={styles.container} ref={exportableRef}>
      {(header != null || filters != null) && <div className={styles.header}>
        <div className={styles.title}>{header}</div>
        <div className={styles.filters}>{filters}</div>
      </div>}
      <div className={styles.content}>
        {description != null && <div className={styles.description} dangerouslySetInnerHTML={{__html: description}} />}
        <div className={[styles.contentData, inset ? styles.inset : null].join(' ')}>{children}</div>
      </div>
      {editing === null && <div className={styles.editingContainer}>
        <button className={styles.delete} onClick={onDelete}>Delete</button>
      </div>}
    </div>
    {fileName && <div onClick={exportChart} className={styles.exportButton}>
      <DownloadIcon />
    </div>}
    {fileName && <div className={styles.highlight} />}
  </div>
}

export default Card