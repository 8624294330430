import React, { RefObject } from 'react'
import download from 'downloadjs'
import domtoimage from 'dom-to-image-more'

import Tooltip from '../../libs/context-tooltip/tooltip'
import { DownloadIcon } from '../icons/icons'

import styles from './downloadButton.module.scss'

interface DownloadButtonProps extends React.HTMLProps<HTMLDivElement> {
  fileName: string,
  exportableRef: RefObject<any>,
  textDirection?: "left" | "right"
}

export const DownloadButton: React.FC<DownloadButtonProps> = React.memo(({className, fileName, exportableRef, textDirection='left'}) => {
  const exportChart = async () => {
    const dataUrl = await domtoimage.toPng(exportableRef.current, {scale: 2})
    download(dataUrl, fileName);
  }

  return <>
    <Tooltip content="Save as .png" onClick={exportChart} className={[styles.button, styles[textDirection], className].join(' ')}>
      <DownloadIcon />
    </Tooltip>
    <div className={styles.highlight} />
  </>
})

export default DownloadButton