import React from 'react';
import {Link} from '@reach/router'

import Icon from '../icon/icon'

import styles from './cell.module.scss'

interface NameCellProps extends React.HTMLProps<HTMLDivElement> {
  isDemo: boolean,
  demoId: string,
  name: string,
  imageUrl: string,
  url: string,
  dominantColor: string,
}

export const NameCell: React.FC<NameCellProps> = React.memo(({isDemo, demoId, name, imageUrl, url, dominantColor, className}) => {
  const style = {
    opacity: (demoId != null && !isDemo) ? 0.1 : 1
  }

  return <Link className={[className, styles.cell].join(' ')} style={style} to={url}>
    <Icon className={styles.appIcon} src={imageUrl} rounded background={dominantColor}  />
    <span className={styles.appTitle}>{name}</span>
  </Link>
})

export default NameCell;