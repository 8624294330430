import React from 'react'
import TopicTag, {TopicAddTag} from '../topicTag/topicTag'
import Tooltip from '../../libs/context-tooltip/tooltip'
import { getRangeColor2, getScore, getHighlightRangeColor, getHoverHighlightRangeColor } from '../../utils'

import styles from './topicSection.module.scss'
import { useSearch } from '../../hooks'
import Permission from '../../permission'

interface TopicSectionProps {
  report: any,
  permission: Permission,
  tags: any[],
  updateTopic?: any,
  onDeleteTopic?: any,
}

export const TopicSection: React.FC<TopicSectionProps> = React.memo(({report, permission, tags, updateTopic, onDeleteTopic}) => {
  const [search, setSearch] = useSearch()
  const addReportFilterFn = () => updateTopic([...(tags || []), search.query]);
  const onDelete = (topic: any) => {
    if(onDeleteTopic) onDeleteTopic(topic)
    else if(updateTopic) updateTopic((tags || []).filter(t => t !== topic))
  }

  const toggleSearch = (v: any) => {
    setSearch( { ...search, query: ((search.query === v) ? undefined : v), page: undefined } );
  }

  const hasQuery = search?.query?.length > 0;
  const hasSearch = hasQuery && tags?.indexOf(search.query) >= 0;

  const canModify = permission?.canModifyBrand(report)
  return <div className={styles.topics}>
    {tags?.map(s => <TopicTag key={s}
        value={s}
        selected={(search && s === search.query)}
        onClick={toggleSearch}
        onDelete={canModify ? onDelete : undefined} />
    )}
    {hasQuery && !hasSearch && updateTopic != null && <TopicAddTag onClick={updateTopic ? addReportFilterFn : undefined} value={search.query} />}
  </div>
})

interface TopicWithCountSectionProps extends React.HTMLProps<HTMLDivElement> {
  report?: any,
  permission?: Permission,
  tags: any[],
  updateTopic?: any,
  onDeleteTopic?: any,
  showingMore?: any
}

export const TopicWithCountSection: React.FC<TopicWithCountSectionProps> = React.memo(({report, permission, tags, updateTopic, onDeleteTopic, showingMore}) => {
  const [search, setSearch] = useSearch()
  const addReportFilterFn = () => updateTopic([...(tags || []), search.query]);
  const onDelete = (topic: any) => {
    if(onDeleteTopic) onDeleteTopic(topic)
    else if(updateTopic) updateTopic((tags || []).filter(t => t !== topic))
  }

  const toggleSearch = (v: any) => {
    setSearch( { ...search, query: ((search.query === v) ? undefined : v), page: undefined } );
  }

  const hasQuery = search?.query?.length > 0;
  const hasSearch = hasQuery && tags?.find(v => v?.value === search.query);

  const canModify = permission?.canModifyBrand(report)
  const filteredTags: any[] = showingMore ? tags : tags?.slice(0, 10);
  return <div className={styles.topics}>
    {filteredTags?.map((s, index) => {
      if(s.phrases) {
        const content = <div>
          {s?.phrases?.map((p: any, i: number) => <div key={i}>{p}</div>)}
        </div>
        
        let style: any = {}
        let tooltipColor;
        if(s.sentiment != null) {
          const score = getScore(s.sentiment);
          tooltipColor = getRangeColor2(score);
          const background = getHighlightRangeColor(score);
          const hoverBackground = getHoverHighlightRangeColor(score);

          style['--color'] = '#505050';
          style['--hover-color'] = '#505050';
          style['--background'] = background;
          style['--hover-background'] = hoverBackground;
        }

        return <Tooltip key={index} content={content} background={tooltipColor} tooltipClassName={styles.tooltipContent}>
          <TopicTag
            value={s.value}
            count={s.count}
            style={style}
            selected={(search && s.value === search.query)}
            onClick={toggleSearch}
            onDelete={canModify ? onDelete : undefined} />
        </Tooltip>
      } else {
        return <TopicTag key={index}
          value={s.value}
          count={s.count}
          selected={(search && s.value === search.query)}
          onClick={toggleSearch}
          onDelete={canModify ? onDelete : undefined} />
      }
    })}
    {hasQuery && !hasSearch && updateTopic != null && <TopicAddTag onClick={updateTopic ? addReportFilterFn : undefined} value={search.query} />}
  </div>
})

export default TopicSection