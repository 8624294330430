import React from 'react'
import styles from './brandSource.module.scss'

interface BrandSourceProps extends React.HTMLProps<HTMLDivElement> {
  name?: string,
  icon: any
}

export const BrandSource: React.FC<BrandSourceProps> = ({ className, name, icon, style }) => {
  return <div key={name} className={[className, styles.brandSource].join(' ')} style={style}>
    <div className={styles.brandSourceIcon}>{React.createElement(icon)}</div>
    {name != null && <div className={styles.brandSourceName}>{name}</div>}
  </div>
}

export default BrandSource