import { Link } from '@reach/router'
import React from 'react'
import { MinimizeIcon } from '../icons/icons'
import styles from './draftSection.module.scss'

interface DraftSectionProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  icon?: any,
  to?: any,
  minimizeable?: string,
  onClick?: any
}

export const DraftSection: React.FC<DraftSectionProps> = ({ title, icon, to, style, minimizeable, children, onClick }) => {
  const TagName = (to !== undefined) ? Link : "section";
  let isHoverable = false;
  if(to || onClick) isHoverable = true;

  return <TagName className={[styles.section, isHoverable ? styles.hoverable : null].join(' ')} to={to} style={style} onClick={onClick}>
    <div className={styles.sectionIcon}>{icon}</div>
    <div className={styles.sectionHeader}>{title}</div>
    {minimizeable && <Link className={styles.minimizeIcon} to={minimizeable}>
      <MinimizeIcon />
    </Link>}
    {children != null && <div className={styles.sectionContent}>
      {children}
    </div>}
  </TagName>
}

export default DraftSection