import React from 'react';
import { Redirect } from '@reach/router'
import { useLocalStorage } from '@rehooks/local-storage';

import { useReportsContext, useConceptsContext } from '../../contexts'
import {ReportLeftNav} from '../../components/leftNav/leftNav'
import { LoadingContainer } from '../../components/loading/loading'


import DraftReportNameSection from './draftReportNameSection'
import DraftReportBrandsSection from './draftReportBrandsSection'
import DraftReportConceptsSection from './draftReportConceptsSection'

import styles from './draftReport.module.scss'

interface DraftReportProps {
  reportId: string,
  shared?: any
}

export const DraftReport: React.FC<DraftReportProps> = ({reportId, shared}) => {
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const [{reportsById}, {fetchReport}] = useReportsContext();
  const [, {fetchConceptsLibrary, fetchConceptsForReport}] = useConceptsContext()
  React.useEffect(() => { fetchReport(reportId); }, [reportId, fetchReport]);
  React.useEffect(() => { fetchConceptsLibrary()}, [fetchConceptsLibrary]);
  React.useEffect(() => { fetchConceptsForReport(reportId); }, [reportId, fetchConceptsForReport]);

  const report = reportsById?.[reportId];
  if (report === null || report?.error) return <Redirect to='/reports' noThrow />
  if (report === undefined) return <LoadingContainer />
  if (report?.fetching) return <LoadingContainer />

  

  const style: any = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)'
  }

  let startStep: any = null;
  if(report?.data?.name) startStep = 'brands';

  const steps = [
    {path: 'name', Component: DraftReportNameSection},
    {path: 'brands', Component: DraftReportBrandsSection},
    {path: 'concepts', Component: DraftReportConceptsSection},
  ]

  const startStepIndex = steps.findIndex(s => s.path === startStep) || 0

  return <div style={style}>
    <ReportLeftNav report={report} />
    <DraftReportContent report={report} startStepIndex={startStepIndex} steps={steps} />
  </div>
}

export default DraftReport;

interface DraftReportContentProps {
  report: any,
  steps: any[],
  startStepIndex: any
}

const DraftReportContent: React.FC<DraftReportContentProps> = ({report, steps, startStepIndex}) => {
  const [stepIndex, setStepIndex] = React.useState(startStepIndex);

  return <div className={styles.container}>
    {steps?.map( ({path, Component}, index) => {
      const onNext = () => setStepIndex((s: any) => s + 1);
      const onClick = () => setStepIndex(index);
      return <Component report={report} active={index === stepIndex} seen={index < stepIndex} key={path} onNext={onNext} onClick={onClick} />
    })}
  </div>
}