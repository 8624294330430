import React from 'react';
import Icon from '../icon/icon';

import styles from './brandsMinimizedSubSection.module.scss';

interface BrandsMinimizedSubSectionProps {
  brandIds: string[],
  brandsById: {[brandId: string]: any}
}

export const BrandsMinimizedSubSection: React.FC<BrandsMinimizedSubSectionProps> = ({brandIds, brandsById}) => {
  if(brandsById == null) return null;
  if(brandIds == null) return null;
  return <div className={styles.brands}>
    {brandIds?.map(id => {
      const brand = brandsById[id];
      const brandImage = brand?.data?.imageUrl;
      const brandColor = brand?.data?.dominantColor;
      return <Icon src={brandImage} key={id} className={styles.icon} rounded background={brandColor} />
    })}
  </div>
}

export default BrandsMinimizedSubSection