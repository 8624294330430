import React from 'react';
import { Redirect } from '@reach/router'
import { useLocalStorage } from '@rehooks/local-storage';

import { useReportsContext, useConceptsContext } from '../../contexts'
import {ReportLeftNav} from '../../components/leftNav/leftNav'
import { Loading } from '../../components/loading/loading'
import { useSearch } from '../../hooks'

import DeletePopup from '../../components/deletePopup/deletePopup'
import { ReportNameSection, ReportBrandsSection, ReportConceptsSection } from '../../components/draftReportSections/draftReportSections'

import styles from './draftReport.module.scss'

interface DraftReportProps {
  navigate: any,
  reportId: string,
  shared: any
}

const DraftReport: React.FC<DraftReportProps> = ({ navigate, reportId, shared }) => {
  const [showDeletePopupId, setShowDeletePopupId] = React.useState<any>(null);
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const [search] = useSearch();
  const [{reportsById}, {fetchReport, deleteReport, requestReport}] = useReportsContext();
  const [, {fetchConceptsLibrary, fetchConceptsForReport}] = useConceptsContext()
  React.useEffect(() => { fetchReport(reportId); }, [reportId, fetchReport]);
  React.useEffect(() => { fetchConceptsLibrary()}, [fetchConceptsLibrary]);
  React.useEffect(() => { fetchConceptsForReport(reportId); }, [reportId, fetchConceptsForReport]);

  const report = reportsById?.[reportId];
  if (report === null) return <Redirect to='/reports' noThrow />
  if (report === undefined) return <Loading />

  const data = [
    {step: undefined, Component: ReportNameSection },
    {step: 'brands', Component: ReportBrandsSection},
    {step: 'concepts', Component: ReportConceptsSection},
  ]

  const style: any = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)'
  }

  const closeDeletePopup = async (deleteId?: string) => {
    if(deleteId) {
      await deleteReport(deleteId);
      setShowDeletePopupId(null);
      navigate('/reports')
    } else {
      setShowDeletePopupId(null);
    }
  }

  return <div style={style}>
    <ReportLeftNav report={report} />
    <div className={styles.container} >
      <div>
        {data?.map(({Component, step}, i) => {
          const opened = search.step === step;
          const to = (step == null) ? '' : `?step=${step}`;
          const next = ((i+1) < data.length) ? `?step=${data[i+1].step}` : undefined;
          return <Component key={i} report={report} navigate={navigate} opened={opened} to={to} next={next} />
        })}
        <SubmitButton report={report} navigate={navigate} requestReport={requestReport} />

        <div className={styles.delete} onClick={() => setShowDeletePopupId(reportId)}>Delete Report?</div>
      </div>
      <div className={styles.fileUpload}>
        <div className={styles.fileUploadContent}>
          Drop .CSV to upload
        </div>
      </div>
    </div>

    {showDeletePopupId != null && <DeletePopup onClose={() => closeDeletePopup()} onSubmit={() => closeDeletePopup(showDeletePopupId)} />}
  </div>
}

interface SubmitButtonProps {
  report: any,
  navigate: any,
  requestReport: any
}

const SubmitButton: React.FC<SubmitButtonProps> = ({report, navigate, requestReport}) => {
  const _requestReport = async (cardId: string) => {
    await requestReport(report.data._id, report.data.apps, cardId);
    if (navigate) navigate(`/reports/${report.data._id}`)
  }

  let isDisabled = false;
  if(report == null || report.data == null || report.data.apps == null) isDisabled = true;
  else if(report.data.apps.length < 1) isDisabled = true;
  else if(report.data.name == null || report.data.name.length <= 0) isDisabled = true;
  
  return <button disabled={isDisabled} className={styles.submitButton} onClick={() => _requestReport('admin')}>Submit</button>
}

export default DraftReport