import React from 'react'
import { useSearch } from '../../hooks'
import Icon from '../icon/icon'
import { Chevron3Icon } from '../icons/icons'
import styles from './detailHeader.module.scss'

interface DetailHeaderProps {
  data?: any,
  closed?: boolean,
  onUploadIcon?: any,
  rounded?: boolean,
  setSearch?: any,
}

export const DetailHeader: React.FC<DetailHeaderProps> = ({data, closed, onUploadIcon, rounded}) => {
  const [, setSearch] = useSearch();
  const iconUrl = data?.data?.imageUrl || data?.data?.iconUrl;
  
  const toggleOpened = () => {
    setSearch((s: any) => ({ ...s, hideDetail: closed ? undefined : null }));
  }

  const color = data?.data?.dominantColor
  const className = [
    styles.header,
    !closed ? styles.opened : null
  ].join(' ')

  const style: any = {
    '--color': color
  }

  return <div className={className} style={style}>
    <Icon src={iconUrl} className={styles.icon} onUpload={onUploadIcon} rounded={rounded} background={color} />
    <div className={styles.title}>{(data?.data?.name || data?.data?.title)}</div>
    <div className={styles.chevron} onClick={toggleOpened}>
      <Chevron3Icon />
    </div>
  </div>
}

export default DetailHeader;