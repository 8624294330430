import React from 'react';

import { DropdownChevronIcon } from '../icons/currentColorIcons'
import Tooltip from '../../libs/context-tooltip/tooltip'

import styles from './cell.module.scss'

interface ConceptHeaderCellProps extends React.HTMLProps<HTMLDivElement> {
  sortOrder: any,
  icon: any,
  tooltip: any,
  onClick: any
}

export const ConceptHeaderCell: React.FC<ConceptHeaderCellProps> = React.memo(({sortOrder, icon: Icon, id, className, title, onClick, tooltip}) => {
  let props: any = {
    className: [
      className,
      styles.headerCell,
      sortOrder === -1 ? styles.desc : null,
      sortOrder === 1 ? styles.asc : null
    ].join(' '),
  }
  let Tag: any = 'div'
  if(tooltip) {
    Tag = Tooltip;
    props.arrowOffset = -5;
    props.content = tooltip;
    props.position = 'bottom';
    props.tooltipClassName = styles.tooltip;
  }
  if(onClick) props.onClick = () => onClick(id);
  
  return <Tag {...props}>
    <div className={styles.headerCellContent}>
      {Icon != null && <Icon className={styles.headerIcon} />}
      <div className={styles.headerText}>{title}</div>
      <DropdownChevronIcon className={styles.headerChevron} />
    </div>
  </Tag>
})

export default ConceptHeaderCell