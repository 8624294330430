import React from 'react'
import { Link } from '@reach/router'
import UtfString from 'utfstring'

import { StarIcon, CloseIcon } from '../icons/icons'
import { OutlineButton } from '../button/button'
import Review, {PlaceholderReview} from '../review/review'
import { PreviewCard } from '../previewCard/previewCard'
import { Loading } from '../loading/loading'
import Search from '../search/search'
import {TooltipPopup} from '../../libs/context-tooltip/tooltip'
import {sentimentWords, findAncestor} from '../../utils'
import { conceptIconByName } from '../../utils2'
import {useComponentSize, useSearch} from '../../hooks'
import {BotIcon} from '../icons/icons'

import styles from './reviews.module.scss'

interface ReviewsProps {
  report?: any,
  id?: any,
  permission?: any,
  reviews?: any,
  app?: any,
  demoId?: any,
  conceptsById?: any,
  commentsByReviewId?: any,
  reviewsById?: any,
  groupedCatTypes?: any,
  search?: any,
  setSearch?: any,
}

export const Reviews: React.FC<ReviewsProps> = ({report, id, permission, reviews, app, demoId, conceptsById, commentsByReviewId, reviewsById, groupedCatTypes }) => {
  const [search, setSearch] = useSearch();
  const [tooltip, setTooltip] = React.useState<any>();
  const [scrollOffset, setScrollOffset] = React.useState({});
  const scrollableRef = React.useRef();
  const scrollableSize = useComponentSize(scrollableRef);
  React.useEffect(() => {
    const handleMouseDown = () => {
      onMouseUp(reviewsById, setTooltip, scrollOffset);
    }
    window.addEventListener("mouseup", handleMouseDown, { passive: true });
    return () => {
      window.removeEventListener("mouseup", handleMouseDown);
    }
  }, [reviewsById, scrollOffset]);
  const onChange = (query: any) => {
    setSearch((s: any) => ({ ...s, query: query?.length > 0 ? query : undefined, page: undefined }));
  }

  if(id == null) return null;
  if(reviews === null) return <Search onChange={onChange} value={search.query} className={styles.search} />
  
  if(reviews != null && reviews?.loading === false && reviews?.length === 0 && reviews?.firstPage === 0) return <>
    <Search onChange={onChange} value={search.query} className={styles.search}  />
    <EmptyState search={search} setSearch={setSearch} conceptsById={conceptsById} />
  </>
  if(reviews != null && reviews?.loading === true && reviews?.length === 0) return <>
    <Search onChange={onChange} value={search.query} className={styles.search}  />
    <Loading />
  </>;
  if(conceptsById == null || reviews === undefined || (reviews?.length === 0 && reviews?.firstPage === 0)) return <>
    <Search onChange={onChange} value={search.query} className={styles.search}  />
    <Loading />
  </>;

  const onScroll = (scrollTop: any, scrollLeft: any) => {
    window.requestAnimationFrame(() => {
      setScrollOffset({scrollTop, scrollLeft});
    })
  }

  const fetchNewPage = () => {
    if(reviews.hasNext && reviews.lastPage != null && setSearch) setSearch({...search, page: reviews.lastPage + 1});
  }

  const loadPrevious = () => {
    if(reviews.firstPage != null && reviews.firstPage > 0 && setSearch) setSearch({...search, page: reviews.firstPage - 1});
  }

  const reviewsKeys = reviews && reviews.groups && Object.keys(reviews.groups).sort();

  const resetScrollDeps = [id, search.query, search.category, search.rating, search.sRating].join('_');
  const machineSplit = search.machine && search.machine.split('_');

  const categorizeStart = () => {
    if(tooltip) {
      const machine = [tooltip.reviewId, tooltip.startOffset, tooltip.endOffset].join('_');
      setSearch((s: any) => ({...s, machine }))
    }
  }

  return <>
    <div className={styles.searchContainer}>
      <Search onChange={onChange} value={search.query} className={styles.search}  />
    </div>
    <Tags search={search} setSearch={setSearch} conceptsById={conceptsById} />
    <div className={styles.scrollable} ref={scrollableRef}>
      <List className={styles.scrollableContent} resetScrollDeps={resetScrollDeps} callback={fetchNewPage} canLoad={reviews?.hasNext} onScroll={onScroll}>
        {tooltip?.startOffset != null && tooltip?.endOffset != null && <TooltipPopup offset={tooltip} containerSize={scrollableSize} tooltipClassName={styles.tooltipContainer}>
          <div className={styles.categorizeButton} onClick={categorizeStart}>
            <BotIcon />
            <span>train</span>
          </div>
        </TooltipPopup>}
        {reviews?.loading && <div className={styles.listLoading}>
          <Loading />
        </div>}
        {reviewsKeys?.map(p => <React.Fragment key={p}>
          {reviews.firstPage === Number.parseInt(p) && reviews.firstPage > 0 && <OutlineButton title="Load previous page" className={styles.prevLoad} onClick={loadPrevious} />}
          {!demoId && reviews?.groups?.[p]?.map((id: any, i: number) => {
            let prevReviewId = null;
            let nextReviewId = null;
            const numP = Number.parseInt(p);
            if(machineSplit != null && machineSplit[0] != null) {
              if(i > 0) {
                prevReviewId = reviews.groups[p][i-1];
              } else if(i === 0 && numP > 0 && reviews.groups[numP-1] != null) {
                prevReviewId = reviews.groups[numP-1][reviews.groups[numP-1].length-1];
              }
              if(i < (reviews.groups[numP].length - 1)) {
                nextReviewId = reviews.groups[numP][i+1];
              } else if(i === (reviews.groups[numP].length - 1) && numP < (reviewsKeys.length - 1) && reviews.groups[numP+1] != null ) {
                nextReviewId = reviews.groups[numP+1][0];
              }
            }

            const comments = commentsByReviewId?.[id];
            return <Review key={id}
              report={report}
              app={app}
              review={reviewsById?.[id]}
              search={search}
              setSearch={setSearch}
              permission={permission}
              conceptsById={conceptsById}
              showPrevMachineButton={machineSplit?.[0] && (nextReviewId === machineSplit[0])}
              showNextMachineButton={machineSplit?.[0] && (prevReviewId === machineSplit[0])}
              comments={comments}
              groupedCatTypes={groupedCatTypes}
              tooltip={tooltip} />
          })}
          {demoId && reviews?.groups?.[p]?.length >= 1 && <Review
            app={app}
            review={reviewsById?.[reviews.groups[p][0]]}
            search={search}
            setSearch={setSearch}
            permission={permission}
            conceptsById={conceptsById}
            comments={commentsByReviewId?.[reviews?.groups?.[p]?.[0]]}
            groupedCatTypes={groupedCatTypes}
            tooltip={tooltip} />}
          {demoId && <PreviewCard />}
          {demoId && reviews?.groups?.[p]?.length > 1 && reviews.groups[p].slice(1).map((id: string) =>
            <Review key={id}
              report={report}
              app={app}
              review={reviewsById?.[id]}
              search={search}
              setSearch={setSearch}
              permission={permission}
              conceptsById={conceptsById}
              comments={commentsByReviewId?.[id]}
              groupedCatTypes={groupedCatTypes}
              tooltip={tooltip} />
          )}
        </React.Fragment>)}
        {demoId && <div className={styles.placeholderReviewHolder}>
          {[0,1,2].map(i => <PlaceholderReview key={i} opacity={1 - i*0.4} />)}
          <Link className={styles.placeholderReviewHolderButton} to="/demo">Sign up to see more valuable customer feedback nuggets</Link>
        </div>}
      </List>
    </div>
  </>
}

export default Reviews;

interface ListProps extends React.HTMLProps<HTMLDivElement> {
  callback: any,
  canLoad: any,
  resetScrollDeps: any,
  onScroll: any
}

const List: React.FC<ListProps> = ({children, callback, canLoad, resetScrollDeps, onScroll, ...props}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const scrollRef = React.useRef(null);
  const contentRef = React.useRef(null);
  
  const scrollRefCurrent = scrollRef?.current
  const handleScroll = React.useCallback((e) => {
    const target = e.target;
    const scrollTop = target.scrollTop;
    const scrollLeft = target.scrollLeft;
    const scrollBottom = target.scrollTop + target.offsetHeight;
    const contentOffsetHeight = contentRef.current.offsetHeight;
    const bottomTrigger = contentOffsetHeight * 0.9;

    if(onScroll) {
      onScroll(scrollTop, scrollLeft);
    }
    
    if(canLoad) {
      window.requestAnimationFrame(() => {
        if(scrollBottom > bottomTrigger && !isFetching && canLoad) {
          setIsFetching(true);
        }
      })
    }
  }, [canLoad, onScroll])

  React.useEffect(() => {
    scrollRef && scrollRef.current && scrollRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [resetScrollDeps, scrollRef])
  React.useEffect(() => {
    if(scrollRefCurrent == null) return;
    scrollRefCurrent.addEventListener('scroll', handleScroll);
    return () => scrollRefCurrent.removeEventListener('scroll', handleScroll);
  }, [scrollRefCurrent, handleScroll])

  React.useEffect(() => {
    if (!isFetching) return;
    callback(setIsFetching);
  }, [isFetching]);

  const childrenCount = React.Children.count(children);
  React.useEffect(() => {
    setIsFetching(false);
  }, [childrenCount]);

  React.useEffect(() => {
    if(!canLoad) setIsFetching(false);
  }, [canLoad])

  return <div ref={scrollRef} {...props}>
    <div className={styles.listContainer} ref={contentRef}>
      {children}
      {(canLoad && isFetching) && <div className={styles.listLoading}>
        <Loading />
      </div>}
    </div>
  </div>
}

interface EmptyStateProps {
  setSearch: any,
  search: any,
  conceptsById: any
}

const EmptyState: React.FC<EmptyStateProps> = ({setSearch, search, conceptsById}) => {
  const hasFilters = [search.category, search.rating, search.sRating].filter(a => a).length > 0;
  const selectedConcept = search.category && conceptsById?.[search.category];
  const sRating: any = search.sRating
  const anySentimentWords: any = sentimentWords
  return <div className={styles.noReviews}>
    {search.query && <span><strong>0 results</strong> found for '<strong>{search.query}</strong>'</span>}
    {!search.query && <strong>0 results</strong>}
    {hasFilters && <div className={styles.selectedFilters}>
      <div>You have the following filters selected:</div>
      {search.rating != null && <div className={styles.filter}>
        {Array.from({length: Number.parseInt(search.rating)}, (_, i) => <StarIcon key={i} />)}
        <div className={styles.delete} onClick={() => setSearch({...search, rating: undefined }) }><CloseIcon /></div>
      </div>}
      {search.sRating != null && <div className={styles.filter}>
        {anySentimentWords?.[sRating]}
        <div className={styles.delete} onClick={() => setSearch({...search, sRating: undefined }) }><CloseIcon /></div>
      </div>}
      {selectedConcept != null && <div className={styles.filter}>
        {conceptIconByName[selectedConcept.name] != null && React.createElement(conceptIconByName[selectedConcept.name])}
        <span>{selectedConcept.name}</span>
        <div className={styles.delete} onClick={() => setSearch({...search, category: undefined }) }><CloseIcon /></div>
      </div>}
      <div className={styles.clearFilters} onClick={() => setSearch({...search, category: undefined, rating: undefined, sRating: undefined})}>Clear all filters?</div>
    </div>}
  </div>
}

interface TagsProps {
  setSearch: any,
  search: any,
  conceptsById: any
}

const Tags: React.FC<TagsProps> = ({search, setSearch, conceptsById}) => {
  const hasFilters = [search.category, search.rating, search.sRating].filter(a => a).length > 0;
  const selectedConcept = search.category && conceptsById?.[search.category];
  if(!hasFilters) return null;

  const anySentiment: any = sentimentWords
  return <div className={styles.tags}>
    {search.rating != null && <div className={styles.filter}>
      {Array.from({length: Number.parseInt(search.rating)}, (_, i) => <StarIcon key={i} />)}
      <div className={styles.delete} onClick={() => setSearch((s: any) => ({...s, rating: undefined })) }><CloseIcon /></div>
    </div>}
    {search.sRating != null && <div className={styles.filter}>
      {anySentiment?.[search.sRating]}
      <div className={styles.delete} onClick={() => setSearch((s: any) => ({...s, sRating: undefined })) }><CloseIcon /></div>
    </div>}
    {selectedConcept != null && <div className={styles.filter}>
      {conceptIconByName[selectedConcept.name] != null && React.createElement(conceptIconByName[selectedConcept.name])}
      <span>{selectedConcept.name}</span>
      <div className={styles.delete} onClick={() => setSearch((s: any) => ({...s, category: undefined })) }><CloseIcon /></div>
    </div>}
  </div>
}

const onMouseUp = (reviewsById: any, setTooltip: any, scrollOffset: any) => {
  try {
    const selection = window.getSelection();
    const selectedText = selection.toString();
    
    if (selection.getRangeAt && selectedText !== '') {
      const range = selection.getRangeAt(0);
      const ancestor = findAncestor(range.startContainer, 'textContainer');
      const box = range.getBoundingClientRect();

      if(ancestor) {
        const commonAncestor = range.commonAncestorContainer;
        if(commonAncestor === ancestor || !commonAncestor.contains(ancestor)) {
          const textContainer = findAncestor(commonAncestor, 'textContainer');
          const reviewWrap = findAncestor(commonAncestor, 'reviewWrap');
          const startContainer = range.startContainer.parentNode;
          const endContainer = range.endContainer.parentNode;
          const reviewId = reviewWrap?.getAttribute('id');

          const review = reviewsById?.[reviewId];
          
          if(textContainer && startContainer && endContainer) {
            const lines = textContainer.children;
            let offset = review.title != null ? UtfString.length(review.title) + 2 : 0;
            let startOffset = 0;
            let endOffset = 0;

            for(var i=0; i<lines.length; i++) {
              const lineEl = lines[i];
              const lineChildren = lineEl?.firstChild?.children;

              for(var j=0; j<lineChildren.length; j++) {
                const el = lineChildren[j]
                if(el == null) {
                  continue;
                }
                if(el === startContainer) {
                  startOffset = offset + range.startOffset;
                }
                if (el === endContainer){
                  endOffset = offset + range.endOffset;
                  break;
                }
                offset += el.innerHTML.length;
              }

              offset += 1;
            }
            
            setTooltip({
              reviewId,
              startOffset,
              endOffset,
              top: box.top,
              bottom: box.bottom,
              left: box.left,
              right: box.right,
              width: box.width,
              height: box.height,
              ...scrollOffset
            })
          }
        } else {
        }
      }
    } else {
      window.requestAnimationFrame(() => {
        setTooltip(null)
      })
    }
    
  } catch(e) {
    console.error(e);
  }
}