import React from 'react'
import Popup from '../../libs/popup/popup'
import moment from 'moment'
import {namespaceStyle} from '../../utils'

import styles from './datePicker.module.scss'

const MAX_YEARS_BACK = 5;

interface DatePickerProps {
  search: any,
  setSearch: any,
  groupBy?: any
}

export const DatePicker: React.FC<DatePickerProps> = ({search, setSearch}) => {
  const [dateType, setDateType] = React.useState(search.dateType);
  const [isVisible, setIsVisible] = React.useState(false);

  const momentDateValue = search.dateValue && moment(Number(search.dateValue));
  let groupingLabel = "All";
  if('year' === search.dateType) {
    groupingLabel = `Year ${momentDateValue.format('YYYY')}`;
  }
  else if('quarter' === search.dateType) {
    groupingLabel = `Quarter ${momentDateValue.quarter()} - ${momentDateValue.format('YYYY')}`;
  }
  else if('month' === search.dateType) {
    groupingLabel = `${momentDateValue.format('MMMM YYYY')}`;
  }
  else if('week' === search.dateType) {
    const start = momentDateValue.startOf('isoWeek').format('L');
    const end = momentDateValue.endOf('isoWeek').format('L')
    groupingLabel = `${start} - ${end}`;
  }

  const onOpenPopup = () => {
    setIsVisible(true);
  }

  const onCancel = () => {
    setIsVisible(false);
    setDateType(search.dateType);
  }

  const selectAll = () => {
    setSearch((s: any) => ({ ...s, dateType: undefined, dateValue: undefined}))
    setDateType(undefined)
    setIsVisible(false);
  }

  const onSetDate = (dateType: any) => (dateValue: any) => {
    setSearch((s: any) => ({ ...s, dateType, dateValue }))
    setDateType(dateType)
    setIsVisible(false);
  }

  return <>
    <div className={styles.dateContainer} onClick={onOpenPopup} style={namespaceStyle()}>
      <div>{groupingLabel}</div>
    </div>
    {isVisible && <Popup onClose={() => onCancel()}>
      <div className={styles.popup} style={namespaceStyle()}>
        <div className={styles.popupHeader}>Date Picker</div>
        <div className={styles.pickerContainer}>
          <div className={styles.typePicker}>
            <div className={[styles.typePickerRow, dateType == null ? styles.selected : null].join(' ')} onClick={() => selectAll()}>All</div>
            <div className={[styles.typePickerRow, dateType === 'year' ? styles.selected : null].join(' ')} onClick={() => setDateType('year')}>Year</div>
            <div className={[styles.typePickerRow, dateType === 'quarter' ? styles.selected : null].join(' ')} onClick={() => setDateType('quarter')}>Quarter</div>
            <div className={[styles.typePickerRow, dateType === 'month' ? styles.selected : null].join(' ')} onClick={() => setDateType('month')}>Month</div>
            <div className={[styles.typePickerRow, dateType === 'week' ? styles.selected : null].join(' ')} onClick={() => setDateType('week')}>Week</div>
          </div>
          <div className={styles.valuePicker}>
            {dateType == null && <div className={styles.valuePickerRow}>Will Show all data</div>}
            {dateType === 'year' && <YearPicker onSetDate={onSetDate('year')} dateType={search.dateType} dateValue={momentDateValue} />}
            {dateType === 'quarter' && <QuarterPicker onSetDate={onSetDate('quarter')} dateType={search.dateType} dateValue={momentDateValue} />}
            {dateType === 'month' && <MonthPicker onSetDate={onSetDate('month')} dateType={search.dateType} dateValue={momentDateValue} />}
            {dateType === 'week' && <WeekPicker onSetDate={onSetDate('week')} dateType={search.dateType} dateValue={momentDateValue} />}
          </div>
        </div>
      </div>
    </Popup>}
  </>
}

export default DatePicker

interface YearPickerProps {
  onSetDate: any,
  dateType: any,
  dateValue: any
}

const YearPicker: React.FC<YearPickerProps> = ({onSetDate, dateType, dateValue}) => {
  const now: any = moment();

  const minYear = now.format('YYYY') - MAX_YEARS_BACK;
  
  const years = [];
  while(now.format('YYYY') > minYear) {
    const valueOf = now.valueOf();
    const year = now.format('YYYY');
    const isSelected = dateType === 'year' && dateValue?.format('YYYY') === year;
    years.push([year, valueOf, isSelected]);
    now.subtract(1, 'year')
  }

  return <>
    {years?.map(([year, valueOf, selected]) => <div className={[styles.valuePickerRow, selected ? styles.selected : null].join(' ')} key={valueOf} onClick={() => onSetDate(valueOf)}>{year}</div>)}
  </>
}


interface QuarterPickerProps {
  onSetDate: any,
  dateType: any,
  dateValue: any
}
const QuarterPicker: React.FC<QuarterPickerProps> = ({onSetDate, dateType, dateValue}) => {
  const now: any = moment();

  const minYear = now.format('YYYY') - MAX_YEARS_BACK;

  const yearQuarters = [];
  while(now.format('YYYY') > minYear) {
    const valueOf =  now.valueOf();
    const year = now.format('YYYY');
    const quarter = now.quarter();
    const isSelected = dateType === 'quarter' && dateValue?.format('YYYY') === year && dateValue?.quarter() === quarter;
    yearQuarters.push([year, quarter, valueOf, isSelected]);
    now.subtract(1, 'quarter')
  }

  return <>
    {yearQuarters?.map(([year, quarter, valueOf, selected]) =>
      <div className={[styles.valuePickerRow, selected ? styles.selected : null].join(' ')} key={valueOf} onClick={() => onSetDate(valueOf)}>Quarter {quarter} &ndash; {year}</div>
    )}
  </>
}


interface MonthPickerProps {
  onSetDate: any,
  dateType: any,
  dateValue: any
}

const MonthPicker: React.FC<MonthPickerProps> = ({onSetDate, dateType, dateValue}) => {
  const now: any = moment();

  const minYear = now.format('YYYY') - MAX_YEARS_BACK;

  const months = [];
  while(now.format('YYYY') > minYear) {
    const valueOf =  now.valueOf();
    const year = now.format('YYYY');
    const month = now.format('MMMM');
    const isSelected = dateType === 'month' && dateValue?.format('YYYY') === year && dateValue?.format('MMMM') === month;
    months.push([year, month, valueOf, isSelected]);
    now.subtract(1, 'month')
  }

  return <>
    {months?.map(([year, month, valueOf, selected]) =>
      <div className={[styles.valuePickerRow, selected ? styles.selected : null].join(' ')} key={valueOf} onClick={() => onSetDate(valueOf)}>{month} &ndash; {year}</div>
    )}
  </>
}

interface WeekPickerProps {
  onSetDate: any,
  dateType: any,
  dateValue: any
}

const WeekPicker: React.FC<WeekPickerProps> = ({onSetDate, dateType, dateValue}) => {
  const now: any = moment();

  const minYear = now.format('YYYY') - MAX_YEARS_BACK;

  const weeks = [];
  while(now.format('YYYY') > minYear) {
    const start = now.startOf('isoWeek').format('L');
    const end = now.endOf('isoWeek').format('L')
    const valueOf =  now.startOf('isoWeek').valueOf();
    const isSelected = dateType === 'week' && dateValue?.startOf('isoWeek').format('L') === start;
    weeks.push([start, end, valueOf, isSelected]);
    now.subtract(1, 'week')
  }

  return <>
    {weeks?.map(([start, end, valueOf, selected]) =>
      <div className={[styles.valuePickerRow, selected ? styles.selected : null].join(' ')} key={valueOf} onClick={() => onSetDate(valueOf)}>{start} &mdash; {end}</div>
    )}
  </>
}