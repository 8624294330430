import urlJoin from "url-join"
import Color from 'color'
import escapeStringRegexp from 'escape-string-regexp'
import { parse as queryParse, stringify as queryStringfy } from 'query-string'

import { Rank1Icon, Rank2Icon, Rank3Icon, Rank4Icon, Rank5Icon } from './components/icons/icons'
import * as Brands from './components/icons/brands'
import * as Concepts from './components/icons/concepts'

export const uniqueBy = (items, keyFn, valueFn) => {
  if (items == null) return items;
  return items.reduce((result, item) => {
    return {
      ...result,
      [keyFn(item)]: (valueFn ? valueFn(item) : item)
    }
  }, {})
}

export const groupMultipleBy = (items, keyFn, valueFn) => {
  if(items == null) return items;
  const grouped = {}
  items.forEach(itemArray => {
    itemArray && itemArray.forEach(item => {
      const key = keyFn(item);
      if(grouped[key] == null) grouped[key] = []
      grouped[key].push(valueFn ? valueFn(item) : item);
    })
  })
  return grouped;
}

export const categories = [
  { key: 'quality', name: 'Quality', icon: Concepts.Quality },
  { key: 'design', name: 'Design', icon: Concepts.Design },
  { key: 'featureRequest', name: 'Feature Request', icon: Concepts.Features },
  { key: 'pricing', name: 'Pricing', icon: Concepts.Pricing },
  { key: 'service', name: 'Service', icon: Concepts.Service },
]

export const conceptIconByName = uniqueBy(categories, c => c.name, v => v.icon);

export const starColor = (value) => {
  if (value === 5) return "#FFB135"
  else if (value === 4) return "#FFBC51"
  else if (value === 3) return "#FFC76C"
  else if (value === 2) return "#FFD188"
  else if (value === 1) return "#FFDCA4"
  else return "#FFF0D9"
}

export const getRangeColor = (value, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return '#C5C5C5';

  const val = value / outOf;
  if (val <= 0.2) return '#D71015'; // red
  else if (val <= 0.4) return '#FFAC3C';  // orange
  else if (val <= 0.6) return '#edc909';  // yellow
  else if (val <= 0.8) return '#b1d00b';  // green
  else return '#71CE0B'; // deep green
}

export const getRangeColor2 = (value, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef';

  const val = value / outOf;
  if (val <= 0.2) return '#EC3E23'; // red
  else if (val <= 0.4) return '#EA8F14';  // orange
  else if (val <= 0.6) return '#E7C601';  // yellow
  else if (val <= 0.8) return '#ACB601';  // green
  else return '#5C9E02'; // deep green
}

export const getHighlightRangeColor = (value, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef';

  const val = value / outOf;
  if (val <= 0.2) return '#FBD8D3'; // red
  else if (val <= 0.4) return '#FBE9D0';  // orange
  else if (val <= 0.6) return '#FAF4CC';  // yellow
  else if (val <= 0.8) return '#EEF0CC';  // green
  else return '#DEECCC'; // deep green
}
export const getFadedHighlightRangeColor = (value, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef';

  const val = value / outOf;
  if (val <= 0.2) return '#FEF5F4'; // red
  else if (val <= 0.4) return '#FEF9F3';  // orange
  else if (val <= 0.6) return '#FEFCF2';  // yellow
  else if (val <= 0.8) return '#FBFBF2';  // green
  else return '#F7FAF2'; // deep green
}

export const getHoverHighlightRangeColor = (value, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef';

  const val = value / outOf;
  if (val <= 0.2) return '#F59E91'; // red
  else if (val <= 0.4) return '#F4C789';  // orange
  else if (val <= 0.6) return '#F3E280';  // yellow
  else if (val <= 0.8) return '#D5DA80';  // green
  else return '#ADCE80'; // deep green
}

export const getBubbleColors = (value, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef';

  const val = value / outOf;
  if (val <= 0.2) return '#EC3E23'; // red
  else if (val <= 0.4) return '#EA8F14';  // orange
  else if (val <= 0.6) return '#d0d0d0';  // yellow
  else if (val <= 0.8) return '#ACB601';  // green
  else return '#5C9E02'; // deep green
}


export const getDetailRangeColor = (value, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return '#C5C5C5';

  const val = value / outOf;
  if (val <= 0.04*2) return '#F93B3B';
  else if (val <= 0.04*5) return '#EF761E';
  else if (val <= 0.04*7) return '#ff8329';
  else if (val <= 0.04*10) return '#ffdd00';
  else if (val <= 0.04*12) return '#ffdd00';
  else if (val <= 0.04*15) return '#ffdd00';
  else if (val <= 0.04*17) return '#C2F636';
  else if (val <= 0.04*20) return '#C2F636';
  else if (val <= 0.04*23) return '#7DB44B';
  else return '#519D0D';
}


export const getRangeIcon = (value, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return null;

  const val = value / outOf;
  if (val <= 0.2) return Rank1Icon;
  else if (val <= 0.4) return Rank2Icon;
  else if (val <= 0.6) return Rank3Icon;
  else if (val <= 0.8) return Rank4Icon;
  else return Rank5Icon;
}

export const getRangeString = (value, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return null;
  const val = value / outOf;
  if (val <= 0.2) return 'poor';
  else if (val <= 0.4) return 'bad';
  else if (val <= 0.6) return 'ok';
  else if (val <= 0.8) return 'good';
  else return 'great';
}

export const getScore = (avg) => {
  if (avg == null) return null;
  return Math.round(((avg + 1) * 50));
}

export const getNPSScore = (score) => {
  if(score <= 3) return 0;
  else if(score <= 6) return 1;
  else if(score <= 8) return 2;
  else if(score <= 10) return 3;
  else return 0;
}
export const getNPSColor = (score) => {
  if (score <= 0) return '#FF5F69';
  else if (score <= 1) return '#F98964';
  else if (score <= 2) return '#F7CC3F';
  else if (score <= 3) return '#4EA051';
}

export const getLetterGrade = (score, padded) => {
  if (score == null) return null;

  if(score >= 95) return 'A+';
  else if(score >= 85) return 'A';
  else if(score >= 80) return 'A-';
  else if(score >= 75) return 'B+';
  else if(score >= 65) return  'B';
  else if(score >= 60) return 'B-';
  else if(score >= 55) return 'C+';
  else if(score >= 45) return  'C';
  else if(score >= 40) return 'C-';
  else if(score >= 35) return 'D+';
  else if(score >= 25) return  'D';
  else if(score >= 20) return 'D-';
  else if(score >= 15) return 'F+';
  else if(score >= 5) return  'F';
  else return 'F-';
}




export const genreIcon = (genre) => {
  switch (genre) {
    case 'Books': return `https://devimages-cdn.apple.com/app-store/categories/images/books_2x.png`
    case 'Business': return `https://devimages-cdn.apple.com/app-store/categories/images/business_2x.png`
    case 'Developer Tools': return `https://devimages-cdn.apple.com/app-store/categories/images/developertools_2x.png`
    case 'Education': return `https://devimages-cdn.apple.com/app-store/categories/images/education_2x.png`
    case 'Entertainment': return `https://devimages-cdn.apple.com/app-store/categories/images/entertainment_2x.png`
    case 'Finance': return `https://devimages-cdn.apple.com/app-store/categories/images/finance_2x.png`
    case 'Food & Drink': return `https://devimages-cdn.apple.com/app-store/categories/images/foodanddrink_2x.png`
    case 'Games': return `https://devimages-cdn.apple.com/app-store/categories/images/games_2x.png`
    case 'Graphics & Design': return `https://devimages-cdn.apple.com/app-store/categories/images/graphicsanddesign_2x.png`
    case 'Health & Fitness': return `https://devimages-cdn.apple.com/app-store/categories/images/fitness_2x.png`
    case 'Lifestyle': return `https://devimages-cdn.apple.com/app-store/categories/images/lifestyle_2x.png`
    case 'Kids': return `https://devimages-cdn.apple.com/app-store/categories/images/kids_2x.png`
    case 'Magazines & Newspapers': return `https://devimages-cdn.apple.com/app-store/categories/images/magazinesandnewspapers_2x.png`
    case 'Medical': return `https://devimages-cdn.apple.com/app-store/categories/images/medical_2x.png`
    case 'Music': return `https://devimages-cdn.apple.com/app-store/categories/images/music_2x.png`
    case 'Navigation': return `https://devimages-cdn.apple.com/app-store/categories/images/navigation_2x.png`
    case 'News': return `https://devimages-cdn.apple.com/app-store/categories/images/news_2x.png`
    case 'Photo & Video': return `https://devimages-cdn.apple.com/app-store/categories/images/photoandvideo_2x.png`
    case 'Photography': return `https://devimages-cdn.apple.com/app-store/categories/images/photoandvideo_2x.png`
    case 'Productivity': return `https://devimages-cdn.apple.com/app-store/categories/images/productivity_2x.png`
    case 'Reference': return `https://devimages-cdn.apple.com/app-store/categories/images/reference_2x.png`
    case 'Shopping': return `https://devimages-cdn.apple.com/app-store/categories/images/shopping_2x.png`
    case 'Social Networking': return `https://devimages-cdn.apple.com/app-store/categories/images/socialnetworking_2x.png`
    case 'Sports': return `https://devimages-cdn.apple.com/app-store/categories/images/sports_2x.png`
    case 'Travel': return `https://devimages-cdn.apple.com/app-store/categories/images/travel_2x.png`
    case 'Utilities': return `https://devimages-cdn.apple.com/app-store/categories/images/utilities_2x.png`
    case 'Video': return `https://devimages-cdn.apple.com/app-store/categories/images/video_2x.png`
    case 'Weather': return `https://devimages-cdn.apple.com/app-store/categories/images/weather_2x.png`
    default: return null;
  }
}

export const getCountryCode = (url) => {
  if (url == null) return null;
  const regex = 'apple.com/([^/]+)/';
  const found = url.match(regex);
  if (found?.length > 1) {
    return found[1].toUpperCase();
  } else return null;
}

export const apiUrl = (url, isAdmin) => {
  if (isAdmin) return urlJoin('/api/admin', url);
  else return urlJoin('/api', url);
}

export const shouldQuery = (_params, _prevParams) => {
  if (_params == null && _prevParams == null) return false;
  if (_params == null || _prevParams == null) return true;

  const _filteredParams = filteredAppsSearch(_params);
  const _filteredPrevParams = filteredAppsSearch(_prevParams);

  const params = _filteredParams?.delete('page');
  const prevParams = _filteredPrevParams?.delete('page');
  if (params.count() !== prevParams.count()) return true;

  for (var key of prevParams.keys()) {
    if (params.get(key) !== prevParams.get(key)) return true;
  }
  return false;
}

export const filteredAppsSearch = (search) => {
  if (search == null) return null;

  const allowedKeys = ['category', 'rating', 'query', 'page'];
  return search.filter((_, k) => allowedKeys.indexOf(k) >= 0).filter(a => a);
}

export const sharedLocationSearch = (location) => {
  if (location == null) return location;
  const searchObj = queryParse(location.search);
  delete searchObj.page
  return queryStringfy(searchObj)
}

export const sources = [
  { name: 'Android',          icon: Brands.Android,      provider: 'google',          items: 'android apps', searchable: true },
  { name: 'Apple',            icon: Brands.Apple,        provider: 'apple',           items: 'apple apps',   searchable: true },
  { name: 'Amazon',           icon: Brands.Amazon,       provider: 'amazon',          items: 'products',       viaUrl: true, },
  { name: 'Amazon Questions', icon: Brands.Amazon,       provider: 'amazonQuestions', items: 'questions',      viaUrl: true, },
  { name: 'CSV',              icon: Brands.CSV,          provider: 'csv',             items: 'csv files' },

  { name: 'G2',               icon: Brands.G2,           provider: 'g2',              items: 'companies',    premium: true, searchable: true, hidden: true },
  { name: 'TrustPilot',       icon: Brands.TrustPilot,   provider: 'trustPilot',      items: 'companies',    premium: true, searchable: true, hidden: true },
  { name: 'Trust Radius',     icon: Brands.TrustRadius,  provider: 'trustRadius',     items: 'companies',    premium: true, searchable: true },
  { name: 'Slack',            icon: Brands.Slack,        provider: 'slack',           items: 'messages',     premium: true },
  { name: 'Zapier',           icon: Brands.Zapier,       provider: 'zapier',          items: 'zaps',         premium: true },
  { name: 'Zendesk',          icon: Brands.Zendesk,      provider: 'zendesk',         items: 'tickets',      premium: true },
  { name: 'Qualtrics',        icon: Brands.Qualtrics,    provider: 'qualtrics',       items: 'companies',    premium: true },
  { name: 'Alexa Skill',      icon: Brands.AmazonAlexa,  provider: 'amazonAlexa',     items: 'skills',       premium: true },
  { name: 'Google Sheets',    icon: Brands.GoogleSheets, provider: 'googleSheets',    items: 'sheets',       premium: true },
  { name: 'Google Maps',      icon: Brands.GoogleMaps,   provider: 'googleMaps',      items: 'places',       premium: true, viaUrl: true },

  { name: 'User Voice',  icon: Brands.UserVoice,   provider: 'userVoice',   items: 'companies', premium: true },
  { name: 'Twitter',     icon: Brands.Twitter,     provider: 'twitter',     items: 'tweets',    premium: true },
  { name: 'Delighted',   icon: Brands.Delighted,   provider: 'delighted',   items: 'companies', premium: true, hidden: true },
  { name: 'Glassdoor',   icon: Brands.Glassdoor,   provider: 'glassdoor',   items: 'companies', premium: true, hidden: true },
  { name: 'TripAdvisor', icon: Brands.Tripadvisor, provider: 'tripadvisor', items: 'companies', premium: true, hidden: true },
  { name: 'Yelp',        icon: Brands.Yelp,        provider: 'yelp',        items: 'companies', premium: true, style: { '--icon-scale': '100%' }, hidden: true },
  { name: 'Podcasts',    icon: Brands.Podcasts,    provider: 'podcasts',    items: 'podcasts',  premium: true, style: { '--icon-scale': '100%' } },

  { name: 'Amazon CSV',  icon: Brands.Amazon,      provider: 'csvAmazon',      items: 'products',  premium: true },
  { name: 'TripAdvisor', icon: Brands.Tripadvisor, provider: 'csvTripadvisor', items: 'companies', premium: true },
  { name: 'Yelp',        icon: Brands.Yelp,        provider: 'csvYelp',        items: 'companies', premium: true, style: { '--icon-scale': '100%' } },
  { name: 'Walmart',     icon: Brands.Walmart,     provider: 'csvWalmart',     items: 'companies', premium: true },
  { name: 'Target',      icon: Brands.Target,      provider: 'csvTarget',      items: 'companies', premium: true },
  { name: 'Youtube',     icon: Brands.Youtube,     provider: 'csvYoutube',     items: 'videos',    premium: true },
  { name: 'Influenster', icon: Brands.Influenster, provider: 'csvInfluenster', items: 'companies', premium: true },
  { name: 'G2',          icon: Brands.G2,          provider: 'csvG2',          items: 'companies', premium: true },
  { name: 'TrustPilot',  icon: Brands.TrustPilot,  provider: 'csvTrustPilot',  items: 'companies', premium: true },
  { name: 'Glassdoor',   icon: Brands.Glassdoor,   provider: 'csvGlassdoor',   items: 'companies', premium: true },
  { name: 'Indeed',      icon: Brands.Indeed,      provider: 'csvIndeed',      items: 'companies', premium: true },
  { name: 'Delighted',   icon: Brands.Delighted,   provider: 'csvDelighted',   items: 'companies', premium: true },
  { name: 'NPS',         icon: Brands.Delighted,   provider: 'csvNPS',         items: 'reviews',   premium: true },
]
export const sourcesById = uniqueBy(sources, b => b.provider);

export const defaultSources = sources.filter(s => !s.hidden && (s.searchable || s.viaUrl || s.provider.startsWith('csv')) ).sort( (a, b) => a.name.localeCompare(b.name));
export const premiumSources = sources.filter(s => !s.hidden && !(s.searchable || s.viaUrl || s.provider.startsWith('csv')) ).sort( (a, b) => a.name.localeCompare(b.name))

export const pluralize = (singularWord, value) => {
  if(value === 1) return singularWord;
  else return singularWord + "s";
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const LOW_REVIEW_COUNT_CUTOFF = 150

export const appKeyFn = (app, otherId) => [otherId, app?.appProvider, app?.appId].filter(a=>a).join('_');


export const getColor = (i) => {
  if (i === 0) return '#66c2a5';
  else if (i === 1) return '#fc8d62';
  else if (i === 2) return '#8da0cb';
  else return '#666'
}

export const getYYYYMM = (date) => {
  if(date) return `${date.getFullYear()}-${date.getMonth()}`;
  return null;
}

export const urlDoesMatch = (pathname, uri, search, to, matchType) => {
  if(to?.startsWith('?')) {
    return (uri === pathname) && (to === search);
  } else {
    if(matchType === 'startsWith')
      return pathname.startsWith([uri, to].filter(a => a).join('/'));
    else
      return pathname === [uri, to].filter(a => a).join('/');
  }
}

export const sentimentWords = {
  1: 'terrible',
  2: 'bad',
  3: 'ok',
  4: 'good',
  5: 'great',
}

export const locales = ["us", "gb", "au", "ca"];


export const findNextPrevReview = (reviews, search) => {
  if(reviews == null) return;
  
  const reviewsKeys = reviews?.groups && Object.keys(reviews.groups).sort();
  const machine = search?.machine;
  const machineSplit = machine?.split('_');
  const machineId = machineSplit?.[0];
  let prevReview = null;
  let nextReview = null;
  for(var i=0; i<reviewsKeys.length; i++) {
    const key = reviewsKeys[i];
    const groupedReviews = reviews?.groups[key];
    for(var j=0; j<groupedReviews.length; j++) {
      const review = groupedReviews[j];
      if(review === machineId) {
        if((j-1) >= 0) prevReview = groupedReviews[j-1];
        else if(i > 0) {
          const prevKey = reviewsKeys[i-1];
          const groupedReviews = reviews?.groups[prevKey];
          prevReview = groupedReviews[groupedReviews.length - 1]
        }

        if((j+1) < (groupedReviews.length - 1) ) nextReview = groupedReviews[j+1];
        else if(i < (reviewsKeys.length - 1)) {
          const nextKey = reviewsKeys[i+1];
          const groupedReviews = reviews?.groups[nextKey];
          nextReview = groupedReviews[0];
        }
       return {prevReview, nextReview}
      }
    }
  }
  const groupedReviews = reviews.groups[reviewsKeys[0]];
  if(groupedReviews) {
    return {
      prevReview: groupedReviews[0],
      nextReview: groupedReviews[0],
    }
  }
  return {};
}

export const getSortedBrands = (brandIds, brandsById, reportCounts, sort, sortOrder) => {
  if(brandIds == null) return brandIds;
  if(brandsById == null) return brandIds;

  let clonedBrandIds = brandIds.slice(0);

  if(sort != null && sortOrder != null) {
    clonedBrandIds.sort( (aId, bId) => {
      const a = brandsById?.[aId]?.data;
      const b = brandsById?.[bId]?.data;
      if(sort === 'name') {
        const aV = (a?.name) || null;
        const bV = (b?.name) || null;
        return bV && (bV.localeCompare(aV) * sortOrder);
      } else if(sort === 'score') {
        const aV = getScore(reportCounts?.brands?.[a._id]?.categoryScore);
        const bV = getScore(reportCounts?.brands?.[b._id]?.categoryScore);
        if(aV == null) return 1;
        else if(bV == null) return  -1;
        else return (aV - bV) * -sortOrder;
      } else if(sort === 'reviews') {
        const aV = reportCounts?.brands?.[a._id]?.count || null;
        const bV = reportCounts?.brands?.[b._id]?.count || null;
        if(aV == null) return  1;
        else if(bV == null) return  -1;
        else return (aV - bV) * sortOrder;
      } else {
        const categoryCounts = reportCounts?.brandsByCategory?.[sort]
        const aCount = categoryCounts?.[a._id];
        const bCount = categoryCounts?.[b._id];

        if(aCount == null || aCount.categoryScore == null) return 1;
        else if(bCount == null || bCount.categoryScore == null) return -1;
        else {
          return (bCount.categoryScore - aCount.categoryScore) * sortOrder;
        }
      }
    })
  } else {
    clonedBrandIds.sort( (a,b) => brandsById?.[a]?.idx - brandsById?.[b]?.idx);
  }
  
  return clonedBrandIds
}

export const splitOutBadBrands = (brands, reportCounts) => {
  if(brands == null) return [[], []];
  if(reportCounts == null) return [[], []];
  if(reportCounts.brandsByCategory == null) return [[], []]

  const validBrands = [];
  const invalidBrands = [];

  brands.forEach( (brand) => {
    const categoryIds = Object.keys(reportCounts.brandsByCategory);
    const insufficientReviewsIds = categoryIds.filter(cId => {
      return reportCounts?.brandsByCategory?.[cId]?.[brand._id]?.insufficientReviews;
    })

    if( insufficientReviewsIds.length >= categoryIds.length * 0.9 ) {
      validBrands.push(brand);
    } else {
      invalidBrands.push(brand);
    }
  })
  return [validBrands, invalidBrands];
}


export const feedbackColors = [
  '#FF5F69',
  '#F98964',
  '#F7CC3F',
  '#83C956',
  '#4EA051',
]

export const scoreToFeedbackColor = (score) => {
  if(score <= -0.6) return "#FF5F69";
  else if(score <= -0.2) return "#F98964"
  else if(score <= 0.2) return "#F7CC3F";
  else if(score <= 0.6) return "#83C956"
  else if(score > 0.6) return "#4EA051";
  else return null;
}

export const getSearch = (search, caseSensitive) => {
  if(search instanceof RegExp) return search;
  if(search == null || search.length === 0) return null;

  let flags = '';
  if (!caseSensitive) flags +='i';

  if (typeof search === 'string') return new RegExp(escapeStringRegexp(search), flags);
  else return new RegExp(search, flags);
}

export const isMac = () => navigator.userAgent.indexOf('Mac OS X') !== -1



export const filterConcepts = (concepts, conceptId, query) => {
  if(concepts == null) return [];

  const lowerQuery = query && query.toLowerCase();
  return concepts.filter(c => {
    if(c.catType === conceptId) {
      const lowerName = c.name && c.name.toLowerCase();
      if(lowerQuery && lowerQuery.length > 0) return lowerName.indexOf(lowerQuery) >= 0;
      else return true;
    }
    return false;
  })
}

export const findAncestor = (el, cls) => {
  if(el == null) return el;
  if(el.className?.indexOf(cls) >= 0) return el;

  const parentElement = el.parentElement;
  if(parentElement == null) return null;
  return findAncestor(parentElement, cls);
}

export const reportKey = (reportId, key) => reportId + key;


export const namespaceStyle = (namespace, initial = {}) => {
  // const primaryColor = (namespace?.data?.primaryColor) || 'green'
  // const secondaryColor = (namespace?.data?.secondaryColor) || 'orange'

  const primaryColor = (namespace?.data?.primaryColor) || '#524f9f'
  const secondaryColor = (namespace?.data?.secondaryColor) || '#634592'
  return {
    ...initial,
    ...colorStyle(primaryColor, 'primary'),
    ...colorStyle(secondaryColor, 'secondary'),
  }
}


export const colorStyle = (color, prefix, initial = {}) => {
  const style = initial;

  const onWhite = Color('#f9f9f9');
  
  style[`--${prefix}-color`] = color;
  try {
    style[`--${prefix}-w98-color`] = Color(color).mix(onWhite, 0.95).hex();
    style[`--${prefix}-w95-color`] = Color(color).mix(onWhite, 0.95).hex();
    style[`--${prefix}-w90-color`] = Color(color).mix(onWhite, 0.9).hex();
    style[`--${prefix}-w80-color`] = Color(color).mix(onWhite, 0.8).hex();
    style[`--${prefix}-w70-color`] = Color(color).mix(onWhite, 0.7).hex();
    style[`--${prefix}-w50-color`] = Color(color).mix(onWhite, 0.5).hex();
    style[`--${prefix}-w40-color`] = Color(color).mix(onWhite, 0.4).hex();
    style[`--${prefix}-w30-color`] = Color(color).mix(onWhite, 0.3).hex();
    style[`--${prefix}-w20-color`] = Color(color).mix(onWhite, 0.2).hex();
    style[`--${prefix}-w05-color`] = Color(color).mix(onWhite, 0.05).hex();
    style[`--${prefix}-a30-color`] = Color(color).alpha(0.3).toString();
    style[`--${prefix}-a00-color`] = Color(color).alpha(0).toString();
    // style[`--${prefix}-a70-color`] = Color(color).alpha(0.7).toString();
    
    style['--text-color'] = Color(color).isLight() ? '#333' : '#fefefe';
  } catch(error) {}
  return style;
}