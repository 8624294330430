import React from 'react'
import Color from 'color'
import {Link} from '@reach/router'
import {stringify as queryStringify} from 'query-string'

import { getScore, getRangeColor2 } from '../../utils'
import { conceptIconByName } from '../../utils2'
import RatingBar from '../ratingBar/ratingBar'
import Tooltip from '../../libs/context-tooltip/tooltip'
import { PinIcon, Dragger } from '../icons/icons'

import styles from './conceptsSection.module.scss'
import { useSearch } from '../../hooks'
import Permission from '../../permission'

interface ConceptsSectionProps extends React.HTMLProps<HTMLDivElement> {
  data?: any,
  report: any,
  concepts: any[],
  permission: Permission,
  catType: string,
  updateConceptsOrder: any
}

const ConceptsSection: React.FC<ConceptsSectionProps> = React.memo(({data, report, concepts, permission, catType, updateConceptsOrder}) => {
  const [search, setSearch] = useSearch()
  const [dragging, setDragging] = React.useState(null);
  
  React.useEffect(() => {
    if(dragging == null) return;

    const onMouseMove = (e: any) => {
      if(dragging == null) return;
      const el = document.elementFromPoint(e.clientX, e.clientY);
      if(el == null) return;
      const rect = el.getBoundingClientRect();
      const conceptId = el.getAttribute('id');
      const under = (e.clientY - rect.y) > (rect.height/2);
  
      if(dragging.under !== under || dragging.conceptId !== conceptId) {
        const newDragging = {...dragging, conceptId, under};
        window.requestAnimationFrame(() => {
          setDragging(newDragging);
        })
      }
    }
    const onMouseUp = (e: any) => {
      if(dragging == null) return;
      const el = document.elementFromPoint(e.clientX, e.clientY);
      if(el == null) return;
  
      const rect = el.getBoundingClientRect();
      const conceptId = el.getAttribute('id');
      const under = (e.clientY - rect.y) > (rect.height/2);
  
      let newConceptIds = []
      for(var i=0; i< concepts.length; i++) {
        const concept = concepts[i];
        if(conceptId === concept._id) {
          if(dragging.originConceptId === concept._id) {
            newConceptIds.push(concept._id);
          } else if(under) {
            newConceptIds.push(concept._id);
            newConceptIds.push(dragging.originConceptId);
          } else {
            newConceptIds.push(dragging.originConceptId);
            newConceptIds.push(concept._id);
          }
        }
        else if(dragging.originConceptId === concept._id) continue;
        else newConceptIds.push(concept._id);
      }
  
      document.body.classList.remove('dragging');
      setDragging(null)
      if(updateConceptsOrder) updateConceptsOrder(newConceptIds)
    }

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };

  }, [dragging, updateConceptsOrder, concepts])
  const addConceptSearch = queryStringify({...search, addConcept: catType, hideDetail: undefined });
  
  const onPin = (e: any, cId: string) => {
    if(e) e.preventDefault();
    if(e) e.stopPropagation();

    setSearch((s: any) => ({
      ...s,
      category: s.category === cId ? undefined : s.category,
      pinnedConcept: (s.pinnedConcept === cId) ? undefined : cId,
      addConcept: (s.pinnedConcept === cId) ? s.addConcept : undefined,
    }));
  }

  const onToggleAspect = (aspect: any) => () => {
    setSearch((s: any) => ({ ...s, conceptAspect: (parseInt(s.conceptAspect) === aspect) ? undefined : aspect }))
  }
  const onToggle = (v: any) => {
    const isPinned = search.pinnedConcept === v;
    setSearch( (s: any) => ({
      ...s,
      category: ((s.category === v) ? undefined : v),
      page: undefined,
      conceptAspect: undefined,
      addConcept: undefined,
      pinnedConcept: (isPinned && s.category !== v) ? undefined : s.pinnedConcept,
    } ));
  }

  const selectedConceptIndex = concepts?.findIndex(c => c._id === (search?.category));
  const selectedConceptType = concepts?.[selectedConceptIndex]?.catType;

  const startDragging = (updateConceptsOrder != null) ? (conceptId: any) => {
    document.body.classList.add('dragging');
    setDragging({originConceptId: conceptId})
  } : undefined

  return <div className={[styles.ratings, (permission.canModifyBrand(report)) ? styles.adminRatings : null].join(' ')}>
    {concepts?.map((c, i) => <Row concept={c} key={c._id} onPin={onPin}
      index={i}
      search={search}
      permission={permission}
      onToggle={onToggle}
      onToggleAspect={onToggleAspect}
      selectedConceptIndex={selectedConceptIndex}
      data={data?.[c._id]}
      selectedConceptType={selectedConceptType}
      // startDragging={(permission.canModifyBrand(report)) ? startDragging : undefined}
      startDragging={startDragging}
      dragging={dragging}
      report={report} />)}
    {/* {(permission.canModifyBrand(report)) && <Link className={styles.newRowName} to={`?${addConceptSearch}`}>+ Add Concept</Link>} */}
    <Link className={styles.newRowName} to={`?${addConceptSearch}`}>+ Add Concept</Link>
  </div>
})

const ConceptsTooltip = ({conceptName}: any) => <div>
  <div className={styles.tooltipRowFooter}>Click to see {conceptName} Reviews</div>
</div>

export default ConceptsSection;

interface RowProps extends React.HTMLProps<HTMLDivElement> {
  report: any,
  concept: any,
  onPin: any,
  index: any,
  search: any,
  permission: Permission,
  onToggle: any,
  onToggleAspect: any,
  selectedConceptIndex: any,
  selectedConceptType: any,
  startDragging: any,
  dragging: any,
  data: any
}

const Row: React.FC<RowProps> = ({report, concept, onPin, index, search, data, permission, onToggle, onToggleAspect, selectedConceptIndex, selectedConceptType, startDragging, dragging}) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const isSelected = (search?.category) === concept._id;
  const showAspectButtons = concept.catType === 'judgement' && isSelected;
  const style = {
    gridRowStart: index+1 + ((selectedConceptIndex != null && selectedConceptType === 'judgement' && index > selectedConceptIndex) ? 1 : 0),
    gridRowEnd: 'span 1',
  }

  const isDragingOver = dragging?.conceptId === concept._id && !dragging?.under;
  const isDragingUnder = dragging?.conceptId === concept._id && dragging?.under
  
  const props = {
    style,
    onClick: onToggle ? () => onToggle(concept._id) : undefined,
    onMouseOver: () => setIsHovering(true),
    onMouseOut: () => setIsHovering(false),
    isHovering,
    isSelected,
  }
  
  return <React.Fragment key={concept._id}>
    <div className={[styles.droppable, isDragingOver ? styles.draggingOver : null, isDragingUnder ? styles.draggingUnder : null].join(' ')} style={style} id={concept._id}>
      <div className={styles.topBar} />
      <div className={styles.bottomBar} />
    </div>
    {startDragging && <div className={styles.dragger} style={style} onMouseDown={() => startDragging(concept._id)}><Dragger /></div>}
    <NameCell name={concept.name} icon={conceptIconByName[concept.name]} {...props} />
    <RatingCell score={data?.categoryScore} {...props} />
    <CountCell count={data?.counts.processed} {...props} />
    {/* {(permission.canModifyBrand(report)) && <PinCell isPinned={search.pinnedConcept === concept._id} style={style} onPin={onPin} id={concept._id} />} */}
    <PinCell isPinned={search.pinnedConcept === concept._id} style={style} onPin={onPin} id={concept._id} />
    {data != null && <Tooltip content={<ConceptsTooltip conceptName={concept.name} />} manual visible={isHovering} className={styles.tooltipRow} style={style} />}
    {showAspectButtons && <AspectButtons conceptAspect={search.conceptAspect} concept={concept} onToggleAspect={onToggleAspect} />}
  </React.Fragment>
}

interface CellProps extends React.HTMLProps<HTMLDivElement> {
  name?: string,
  icon?: any,
  isSelected?: boolean,
  isHovering?: boolean,
  score?: number,
  count?: number
}

const NameCell: React.FC<CellProps> = ({style, name, icon, isSelected, isHovering, ...props}) => {
  const className = [
    styles.rowName,
    isSelected ? styles.selected : null,
    isHovering ? styles.hovering : null,
  ].join(' ')
  return <div className={className} style={style} {...props}>
    <span>{name}</span>
    {icon && React.createElement(icon, {className: styles.rowIcon})}
  </div>
}
const RatingCell: React.FC<CellProps> = ({style, score, isSelected, isHovering, ...props}) => {
  const className = [
    styles.ratingBar,
    isSelected ? styles.selected : null,
    isHovering ? styles.hovering : null,
  ].join(' ')
  const ratingScore = getScore(score);
  const color = getRangeColor2(ratingScore);
  const _style: any = {
    '--color':color,
    ...style
  }
  return <div className={className} style={_style} {...props}>
    <RatingBar value={ratingScore} />
  </div>
}
const CountCell: React.FC<CellProps> = ({style, count, isSelected, isHovering, ...props}) => {
  const className = [
    styles.rowCount,
    isSelected ? styles.selected : null,
    isHovering ? styles.hovering : null,
  ].join(' ')
  return <div className={className} style={style} {...props}>{count}</div>
}

interface PinCellProps extends React.HTMLProps<HTMLDivElement> {
  isPinned: boolean,
  onPin: any,
}

const PinCell: React.FC<PinCellProps> = ({isPinned, id, style, onPin}) => {
  const className = [styles.pin, isPinned ? styles.pinned : null].join(' ');
  return <Tooltip content="Pin" className={className} style={style} onClick={(e) => onPin(e, id)}>
    <PinIcon />
  </Tooltip>
}

interface AspectButtonsProps {
  conceptAspect: any,
  concept: any,
  onToggleAspect: any
}

const AspectButtons: React.FC<AspectButtonsProps> = ({conceptAspect, concept, onToggleAspect}) => {
  let negColor = null;
  let posColor = null;
  let negHoverColor = null;
  let posHoverColor = null;
  if(concept.catType === 'judgement') {
    negColor = Color(getRangeColor2(0)).fade(0.5).toString();
    posColor = Color(getRangeColor2(100)).fade(0.5).toString();
    negHoverColor = getRangeColor2(0);
    posHoverColor = getRangeColor2(100);
  }
  const negativeName = (concept?.negative?.name) || 'Negative';
  const positiveName = (concept?.positive?.name) || 'Positive';
  const negativeClassName = [styles.aspectRowButton, (conceptAspect === '-1') ? styles.selected : null].join(' ');
  const positiveClassName = [styles.aspectRowButton, (conceptAspect === '1') ? styles.selected : null].join(' ');
  const negativeStyle: any = {'--color': negColor, '--hover-color': negHoverColor};
  const positiveStyle: any = {'--color': posColor, '--hover-color': posHoverColor}

  return <div className={styles.aspectRow}>
    <div className={negativeClassName} style={negativeStyle} onClick={onToggleAspect(-1)}>{negativeName}</div>
    <div className={styles.aspectRowSpacer} />
    <div className={positiveClassName} style={positiveStyle} onClick={onToggleAspect(1)}>{positiveName}</div>
  </div>
}