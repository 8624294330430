import React from 'react'

import useForm from '../../hooks/useForm'
import { ShortStars } from '../../components/stars/stars';
import { sourcesById, defaultSources, premiumSources, uniqueBy } from '../../utils'
import { useReportsContext, useAppsContext } from '../../contexts'
import Tooltip from '../../libs/context-tooltip/tooltip'
import { Trash } from '../../toneIcons'
import Icon from '../../components/icon/icon'
import { Loading } from '../../components/loading/loading'

import DraftBrandHeader from '../../components/draftBrandHeader/draftBrandHeader'

import styles from './draftReportBrandsSection.module.scss'

interface DraftReportBrandsSectionProps extends React.HTMLProps<HTMLDivElement> {
  active: boolean,
  seen: any,
  report: any,
  onNext: any
}

export const DraftReportBrandsSection: React.FC<DraftReportBrandsSectionProps> = ({active, seen, report, onNext, onClick}) => {
  const [{brandsById, appsById}, {reportAddBrand}] = useReportsContext();
  const [addingBrand, setAddingBrand] = React.useState(false);
  const brandIds = report?.data?.brandIds;
  const reportId = report?.data?._id;
  const appIds = report?.data?.appIds;
  const { values, handleChange, registerSubmit, reset } = useForm({})
  
  const hasBrands = brandIds?.length > 0;

  if(seen) {
    return <div className={styles.seenSection} onClick={onClick}>
      <div className={styles.seenSectionTitle}>All Brands:</div>
      {brandIds?.map((id: string) => {
        return <Icon src={brandsById?.[id]?.data?.imageUrl} rounded key={id} />
      })}
    </div>
  }

  if(!active) return null

  const onSubmit = async ({values}: any) => {
    await reportAddBrand(reportId, values);
    reset();
    setAddingBrand(false);
  }

  const sources = Array.from(new Set(report?.data?.appIds?.map((id: string) => {
    const provider = appsById?.[id]?.data?.appProvider;
    if(sourcesById?.[provider]?.searchable) return appsById?.[id]?.data?.appProvider;
    else return null
  }).filter((a: any) => a)));

  if(!hasBrands) {
    return <form className={styles.section} onSubmit={registerSubmit(onSubmit)} >
      <h1 className={styles.header}>Let's add your first BRAND. Name it...</h1>
      <input className={styles.input}
          disabled={!active}
          name="name"
          value={values.name || ""}
          onChange={handleChange}
          autoComplete="off"
          placeholder={hasBrands ? "Type in to add more brands" : null}
          autoFocus />
      <button className={[styles.submit, styles.clickable].join(' ')} disabled={!(values?.name?.length > 0)} type="submit">Add</button>
    </form>
  }

  return <div className={styles.section}>
    {brandIds?.map((brandId: string) => <Brand report={report} key={brandId} brand={brandsById?.[brandId]} sources={sources} />)}

    {brandIds?.length > 0 && <>
      {!addingBrand && <div className={styles.addBrand} onClick={() => setAddingBrand(true)}>
        <div>+ Add brand</div>
      </div>}
      {addingBrand && <div className={styles.addingBrand}>
        <button onClick={() => setAddingBrand(false)}>cancel</button>
        
        <form onSubmit={registerSubmit(onSubmit)} className={[styles.section, styles.addBrandSection].join(' ')}>
          <input className={styles.input}
            name="name"
            value={values.name || ""}
            onChange={handleChange}
            autoComplete="off"
            placeholder={"Ok, let's add another BRAND. Name it..."}
            autoFocus />
          <button className={styles.submit} disabled={!(values?.name?.length > 0)} type="submit">Add</button>
        </form>

      </div>}
    </>}

    {brandIds?.length >= 1 && appIds?.length >= 1 && <button onClick={onNext}>All done adding brands</button>}
  </div>
}

export default DraftReportBrandsSection;



interface BrandProps {
  report: any,
  brand: any,
  sources: any
}

const Brand: React.FC<BrandProps> = ({report, brand, sources}) => {
  const [{appIdsByBrandId, appsById}, {reportDeleteBrand, reportUpdateBrand, reportAddApp, createSourceFromFile}] = useReportsContext();
  const [selectedSource, setSelectedSource] = React.useState(null);
  const [{searchedApps}, {searchApps}] = useAppsContext();
  if(brand == null || brand.data == null) return null;

  const brandId = brand?.data?._id;
  const brandName = brand?.data?.name;
  const appIds = appIdsByBrandId?.[brand?.data?._id];
  const reportId = report?.data?._id;
  const hasApps = appIds?.length > 0;

  const clickSource = (source: any) => {
    setSelectedSource(source);

    if(source.searchable) {
      searchApps(reportId, brand.data.name, source.provider, brand.data._id)
    }
  }

  const addApp = async (app: any) => {
    if(!brand?.data?.imageUrl && app?.iconUrl) {
      reportUpdateBrand(reportId, brandId, { imageUrl: app.iconUrl })
    }


    const newAppData: any = {
      appId: app.appId,
      appProvider: app.appProvider,
      locale: app.locale || 'us',
      data: app,
    }
    if(brand?.data?._id) newAppData.brandId = brand.data._id;
    await reportAddApp(reportId, newAppData)

    setSelectedSource(null);
  }

  const key = [reportId, selectedSource?.provider, brand.data._id].join('_');
  const apps = searchedApps?.[key];
  
  const brandApps = appIds?.map((id: string) => appsById?.[id]);
  const brandAppsByKey = uniqueBy(brandApps, (brand: any) => [brand.data.appId, brand.data.appProvider].join('_'));

  const hasSources = sources?.length > 0;

  const csvFile = (e: any) => {
    createSourceFromFile(reportId, brandId, brandName, e.target.files[0], selectedSource.provider)
  }

  return <div className={styles.brand}>
    <DraftBrandHeader reportId={reportId} brand={brand} reportDeleteBrand={reportDeleteBrand} reportUpdateBrand={reportUpdateBrand} />

    {hasApps && <Apps reportId={reportId} ids={appIds} appsById={appsById} />}
    {!hasApps && (sources?.length === 0) && <>
      {!selectedSource && <SourcePicker onClick={clickSource} />}
      {selectedSource && <SourceResults source={selectedSource} cancel={() => setSelectedSource(null)} reportId={reportId} brand={brand} addApp={addApp} brandAppsByKey={brandAppsByKey} />}
    </>}
    {!hasApps && !(sources?.length === 0) && <SuggestSources sources={sources} reportId={reportId} brandId={brandId} brandName={brand?.data?.name} addApp={addApp} />}
  </div>
}

interface AppsProps {
  ids: string[],
  reportId: string,
  appsById: any
}

const Apps: React.FC<AppsProps> = ({ids, reportId, appsById}) => {
  const [addingSource, setAddingSource] = React.useState(false);
  return <div className={styles.apps}>
    {ids?.map(appId => <App app={appsById?.[appId]} reportId={reportId} key={appId} />)}

    {!addingSource && <div className={styles.addSourceRow}>
      <button className={styles.addSourceButton} onClick={() => setAddingSource(true)}>+ Add another source</button>
    </div>}
    {addingSource && <div className={styles.addSourceContainer}>
      <button onClick={() => setAddingSource(false)}>cancel</button>
    </div>}
  </div>
}

interface AppProps {
  reportId: string,
  app: any
}

const App: React.FC<AppProps> = ({reportId, app}) => {
  const [, {reportDeleteApp}] = useReportsContext();
  if(app == null || app.data == null) return null;
  const appData = app.data;

  const source = sourcesById[appData.appProvider];
  const SourceIcon = source?.icon;

  const onDelete = () => reportDeleteApp(reportId, { metaId: app?.data?.metaId });
  const deleteTooltip = `Delete ${source?.name} ${app?.data?.title} from brand`

  return <div className={styles.app}>
    <SourceIcon className={styles.appSourceIcon} />
    <Icon src={appData.iconUrl} />
    
    <div className={styles.appTitle}>{appData.title}</div>
    <Tooltip tagName="button" content={deleteTooltip} onClick={onDelete} className={styles.appDeleteButton}>
      <Trash />
    </Tooltip>
  </div>
}

interface SourceAppProps extends React.HTMLProps<HTMLDivElement> {
  addApp: any,
  app: any,
  showSource?: boolean
}

const SourceApp: React.FC<SourceAppProps> = ({app, className, addApp, showSource}) => {
  const genres = app?.genres?.slice(0,2).join(', ');
  const score = app?.scoreCurrentVersion || app?.score;
  const _className = [
    styles.app,
    className,
    addApp ? styles.clickable : null,
  ].join(' ');


  const SourceIcon = showSource && sourcesById[app?.appProvider]?.icon;

  return <div className={_className} onClick={() => addApp(app)}>
    {showSource && <SourceIcon className={styles.appSourceIcon} /> }
    <Icon src={app.iconUrl} />
    <div className={styles.appTitle}>{app.title}</div>
    {genres && <div className={styles.appGenres}>{genres}</div>}
    <ShortStars value={score} className={styles.appStars} />
  </div>
}


const SourcePicker: React.FC<React.HTMLProps<HTMLDivElement>> = ({onClick}) => {
  const visibleDefaultSources = defaultSources?.filter(s => !s.hidden);
  const visiblePremiumSources = premiumSources?.filter(s => !s.hidden);

  return <div className={styles.sourcePicker}>
    <div className={styles.sourceRow}>Default data sources</div>
    {visibleDefaultSources?.map(s => <Source key={s.provider} source={s} onClick={onClick} />)}
    <div className={styles.sourceRow}>Premium data sources</div>
    {visiblePremiumSources?.map(s => <Source key={s.provider} source={s} onClick={onClick} />)}
  </div>
}

interface SourceProps extends React.HTMLProps<HTMLDivElement> {
  source: any
}

const Source: React.FC<SourceProps> = ({source, onClick}) => {
  return <div className={styles.source} onClick={ onClick ? () => onClick(source) : null}>
    {source.icon && React.createElement(source.icon, {className: styles.sourceIcon})}
    <div className={styles.sourceName}>{source.name}</div>
  </div>
}


interface SourceResultsProps {
  source: any,
  cancel: any,
  reportId: string,
  brand: any,
  addApp: any,
  brandAppsByKey: any
}

const SourceResults: React.FC<SourceResultsProps> = ({source, cancel, reportId, brand, addApp, brandAppsByKey}) => {
  if(source?.searchable) {
    return <SearchableSourceResults source={source} cancel={cancel} reportId={reportId} brand={brand} addApp={addApp} brandAppsByKey={brandAppsByKey} />
  } else if(source?.provider?.startsWith('csv')) {
    return <CsvSourceResults cancel={cancel} />
  } else if(source?.viaUrl) {
    return <UrlSourceResults cancel={cancel} />
  } else return null;
}

interface SearchableSourceResultsProps {
  source: any,
  cancel: any,
  reportId: string,
  brand: any,
  addApp: any,
  brandAppsByKey: any
}

const SearchableSourceResults: React.FC<SearchableSourceResultsProps> = ({source, cancel, reportId, brand, addApp, brandAppsByKey}) => {
  const [{searchedApps}, {searchApps}] = useAppsContext();

  const brandId = brand.data?._id;
  const brandName = brand.data?.name;
  const sourceProvider = source?.provider;
  React.useEffect(() => {
    searchApps(reportId, brandName, sourceProvider, brandId)
  }, [reportId, brandId, brandName, sourceProvider])


  const key = [reportId, sourceProvider, brandId].join('_');
  const apps = searchedApps?.[key];

  return <div>
    <button onClick={() => cancel(false)}>cancel</button>
    <h2>Let's see what {source.name} has to offer for</h2>
    { apps?.map((app: any) => {
      const key = [app.appId, app.appProvider].join('_');
      if(brandAppsByKey?.[key] != null) return null;

      return <SourceApp app={app} addApp={addApp} key={key} />
    })}
  </div>
}

interface ResultsProps {
  cancel: any
}

const CsvSourceResults: React.FC<ResultsProps> = ({cancel}) => {
  return <div>
    <button onClick={() => cancel(false)}>cancel</button>
    <div>CSV</div>
  </div>
}

const UrlSourceResults: React.FC<ResultsProps> = ({cancel}) => {
  const [urlValue, setUrlValue] = React.useState();
  return <div>
    <button onClick={() => cancel(false)}>cancel</button>
    <div>Via url</div>
  </div>
}


interface SuggestSourcesProps {
  reportId: string,
  brandId: string,
  brandName: string,
  addApp: any,
  sources: any,
}
const SuggestSources: React.FC<SuggestSourcesProps> = ({sources, brandId, brandName, reportId, addApp}) => {
  if( !(sources?.length > 0) ) return null;
  return <div className={styles.suggestions}>
    <div className={styles.suggestedText}>suggested sources</div>
    {sources.map((s: any) => <SuggestedSource key={s} brandId={brandId} brandName={brandName} reportId={reportId} source={s} addApp={addApp} />)}
  </div>
}

interface SuggestedSourceProps {
  reportId: string,
  brandId: string,
  brandName: string,
  source: any,
  addApp: any
}

const SuggestedSource: React.FC<SuggestedSourceProps> = ({reportId, brandId, brandName, source, addApp}) => {
  const [{suggestedApps, suggestedAppsMeta}, {searchSuggestedApps}] = useAppsContext();
  React.useEffect(() => {
    if(reportId == null || brandId == null || brandName == null || source == null) return;

    searchSuggestedApps(reportId, brandName, source, brandId)
  }, [reportId, brandId, brandName, source, searchSuggestedApps])


  const suggestKey = [reportId, brandName, source, brandId].join('_');
  const meta = suggestedAppsMeta?.[suggestKey];

  if(suggestedAppsMeta == null) return null;

  if(meta?.loading) {
    return <div className={styles.suggestedLoading}>
      <Loading className={styles.suggestedSpinner} />
      <span>Getting data from {source}</span>
    </div>
  } else {
    const data = suggestedApps?.[suggestKey]?.[0];
    if(data) return <SourceApp app={data} className={styles.suggested} showSource addApp={addApp} />;
    else return <div className={styles.suggestedError}>Nothing found for <strong>{source}</strong></div>;
  }
}