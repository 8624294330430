import { Link, useLocation } from '@reach/router';
import { useLocalStorage } from '@rehooks/local-storage';
import classnames from 'classnames';
import { groupBy, isArray } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useAuthContext, useReportsContext } from '../../contexts';
import Tooltip from '../../libs/context-tooltip/tooltip';
import { Admin, Back, Comment, Dashboard, Folder, Lightbulb, Madden, OneThing, Pencil, TogglePanel, User } from '../../toneIcons';
import { sharedLocationSearch, sourcesById } from '../../utils';
import FeedbackPopup from '../feedbackPopup/feedbackPopup';
import Icon from '../icon/icon';
import { LogoIcon2 } from '../icons/icons';
import styles from './leftNav.module.scss';


const isPartiallyCurrent = (className: string) => ({ isPartiallyCurrent }: any) => {
  return isPartiallyCurrent ? { className: [className, styles.active].filter(a=>a).join(' ') } : { className }
}

const isCurrent = (className: string) => ({ isCurrent }: any) => {
  return isCurrent ? { className: [className, styles.active].filter(a=>a).join(' ') } : { className }
}

const isQueryCurrent = (className: string) => ({href, location}: any) => {
  const isCurrent = href === (location.pathname + location.search);;
  return {className: [className, isCurrent ? styles.active : null].filter(a=>a).join(" ")}
}

const isQuerylessCurrent = (className: string, partial?: boolean) => ({href, location}: any) => {
  const [querylessHref] = href.split('?');
  const isCurrent = partial ? location.pathname.startsWith(querylessHref) : (querylessHref === location.pathname);
  return {className: [className, isCurrent ? styles.active : null].filter(a=>a).join(" ")}
}

interface FeedbackRowProps extends React.HTMLProps<HTMLDivElement> {
  report: any,
  demoReportId: string
}

const FeedbackRow: React.FC<FeedbackRowProps> = ({report, demoReportId}) => {
  const [showFeedbackPopup, setShowFeedbackPopup] = React.useState(false);
  return <>
    <div className={[styles.row, styles.clickable, styles.feedback].join(' ')} onClick={() => setShowFeedbackPopup(true)}>
      <div className={styles.rowText}>Give Feedback?</div>
      <div className={styles.rowIcon}><OneThing /></div>
    </div>
    {showFeedbackPopup && <FeedbackPopup demoReportId={demoReportId} report={report} onClose={() => setShowFeedbackPopup(false)} />}
  </>
}


function isAppScraping(appData: any) {
  return appData?.start != null && appData?.end == null
}
function isAppNlping(appData: any) {
  return appData?.nlpStart != null && appData?.nlpEnd == null
}
function isAppUnscraped(appData: any) {
  return appData?.start == null
}
function isAppUnnlped(appData: any) {
  return appData?.nlpStart == null
}

interface RowProps extends React.HTMLProps<HTMLDivElement> {
  icon: any,
  text: string,
  to: any,
  appData?: any,
  partial?: boolean,
  queryMatch?: any
}

const Row: React.FC<RowProps> = ({icon, text, to, partial=true, appData, queryMatch, ...rest}) => {
  const Tag = to ? Link : 'div';
  const props: any = {
    ...rest,
    to,
  }
  if(to) {
    if(queryMatch) props.getProps = isQueryCurrent(classnames(styles.row, rest.className));
    else if(queryMatch === false) props.getProps = isQuerylessCurrent(classnames(styles.row, rest.className), partial)
    else if(partial) props.getProps = isPartiallyCurrent(classnames(styles.row, rest.className));
    else props.getProps = isCurrent(classnames(styles.row, rest.className));
  }
  else {
    props.className = [styles.row, rest.className, rest.onClick ? styles.clickable : null].join(" ");
  }

  const isScraping: boolean = isArray(appData) ? (appData).reduce( (acc, data) => isAppScraping(data) || acc, false) : isAppScraping(appData)
  const isNlping: boolean  = isArray(appData) ? (appData).reduce( (acc, data) => isAppNlping(data) || acc, false) : isAppNlping(appData)
  const isUnscraped: boolean  = isArray(appData) ? false : isAppUnscraped(appData)
  const isUnnlped: boolean  = isArray(appData) ? false : isAppUnnlped(appData)

  let processText = ""
  if (appData == null) {
    // do nothing
  } else if (isUnscraped || isUnnlped) {
    processText = "(new) "
  } else if (isScraping) {
    processText = "(scraping) "
  } else if (isNlping) {
    processText = "(sentimenting) "
  }
 
  return <Tag {...props}>
    <div className={styles.rowIcon}>
      {icon}
    </div>
    <div className={styles.rowText}>{processText + text}</div>
  </Tag>
}

const SubRow: React.FC<RowProps> = ({icon, text, to, partial=true, appData, ...rest}) => {
  const Tag = to ? Link : 'div';
  const props: any = {
    ...rest,
    to,
    className: styles.subRow
  }

  const isScraping: boolean = isArray(appData) ? (appData).reduce( (acc, data) => isAppScraping(data) || acc, false) : isAppScraping(appData)
  const isNlping: boolean  = isArray(appData) ? (appData).reduce( (acc, data) => isAppNlping(data) || acc, false) : isAppNlping(appData)
  const isUnscraped: boolean  = isArray(appData) ? false : isAppUnscraped(appData)
  const isUnnlped: boolean  = isArray(appData) ? false : isAppUnnlped(appData)

  let processText = ""
  if (appData == null) {
    // do nothing
  } else if (isUnscraped || isUnnlped) {
    processText = "(new) "
  } else if (isScraping) {
    processText = "(scraping) "
  } else if (isNlping) {
    processText = "(sentimenting) "
  }

  if(to) { props.getProps = isQuerylessCurrent(styles.subRow); }
  else props.className = styles.subRow;

  const scrapeEnd = appData != null && !isArray(appData) && appData.end != null ? moment(appData.end) : null;
  const scrapeDiffText = scrapeEnd != null ? moment.duration( scrapeEnd.diff(moment()) ).humanize(true) : null

  const nlpEnd = appData != null && !isArray(appData) && appData.nlpEnd != null ? moment(appData.nlpEnd) : null;
  const nlpDiffText = nlpEnd != null ? moment.duration( nlpEnd.diff(moment()) ).humanize(true) : null

  const tooltipContent = (scrapeDiffText && `scraped ${scrapeDiffText}`) + (nlpDiffText && `, NLP run ${nlpDiffText}`)

  return <Tag {...props}>
    {icon}
    <div className={styles.rowText}>
    <Tooltip content={tooltipContent}>
      <div>{processText + text}</div>
    </Tooltip>
    </div>
  </Tag>
}


interface ReportsLeftNavProps extends React.HTMLProps<HTMLDivElement> {
  name?: string
}
export const ReportsLeftNav: React.FC<ReportsLeftNavProps> = ({name}) => {
  const [{me, permission}] = useAuthContext();
  const [navMinimized, setNavMinimized] = useLocalStorage('navMinimized', false);

  const isSuperAdmin = permission.isSuperAdmin();


  return <div className={[styles.container, !navMinimized ? styles.opened : null].join(' ')}>
    <Link className={styles.logoRow} to="/reports">
      <div className={styles.logo}><LogoIcon2 /></div>
      <div className={styles.rowText}>{name || "Harmonize"}</div>
    </Link>

    <div className={styles.content}>
      <Row icon={<Pencil />} text="Drafts" to="/drafts" />
      <Row icon={<Dashboard />} text="Reports" to="/reports" />

      {isSuperAdmin && <Row icon={<Lightbulb />} text="Concepts" to="/concepts" />}
      {isSuperAdmin && <Row icon={<Folder />} text="Concept Groups" to="/conceptGroups" />}
    </div>
    <div className={styles.footer}>
      {me?.email && <Row icon={<User />} text={me.email} to="/profile" />}
      <div className={styles.togglePanel} onClick={() => setNavMinimized(!navMinimized)}>
        <TogglePanel />
      </div>
    </div>
  </div>
}

interface ReportLeftNavProps extends React.HTMLProps<HTMLDivElement> {
  report?: any,
  sharedReportId?: string,
  demoReportId?: string,
}

export const ReportLeftNav: React.FC<ReportLeftNavProps> = ({report, sharedReportId, demoReportId}) => {
  const [navMinimized, setNavMinimized] = useLocalStorage('navMinimized', false);
  const [{appIdsByBrandId, appsById, brandsById}] = useReportsContext();
  const [{me, permission}] = useAuthContext();
  const location = useLocation();

  const canComment = permission.canComment();
  const reportBrandIds = report?.data?.brandIds;

  const searchString = sharedLocationSearch(location);

  const showReportNav = report?.data?.status !== 'draft';

  return <div className={[styles.container, !navMinimized ? styles.opened : null].join(' ')}>
    <Link className={styles.logoRow} to="/reports">
      <div className={styles.logo}><LogoIcon2 /></div>
      <div className={styles.rowText}>{report?.data?.name || "Harmonize"}</div>
    </Link>

    <div className={styles.content}>
      {(sharedReportId == null && demoReportId == null) && <Row icon={<Back />} text="Back to all reports" to="../" partial={false} />}
      {showReportNav && report?.data && <>
        <Row icon={<Admin />} text="Admin" to="admin" />
        <Row icon={<Madden />} text="Insights" to="./" partial={false} />
        {canComment && <Row icon={<Comment />} text="Comments" to="comments" />}
        {/* {isSuperAdmin && <Row icon={<Search />} text="Semantic Search" to="semanticSearch" />} */}
        <Row icon={<Dashboard />} text="Compare: Matrix" to="brands" partial={false} />
        {reportBrandIds?.map((brandId: any) => {
          const brand = brandsById?.[brandId];
          const appIds = appIdsByBrandId?.[brandId];
          const apps = appIds?.map((id: any) => appsById?.[id]);

          const appsData = apps?.map( (a:any) => a?.data )
          
          if(brand == null) return null;

          const providers = apps && groupBy(apps, (a: any) => a?.data?.appProvider);
          return <React.Fragment key={brand.data._id}>
            <Row icon={<Icon className={styles.rowBrandIcon} src={brand.data.imageUrl} background={brand?.data?.dominantColor} rounded />} text={brand.data.name} to={`brands/${brand.data._id}?${searchString}`} queryMatch={false} appData={appsData} />
            <div className={styles.subRows}>
              <SubRow to={`brands/${brand.data._id}?${searchString}`} icon={<div className={styles.rowTextAsSvg}>All</div>} text="Sources" partial={false} />
              {apps?.map((app: any, i: number) => {
                if (app?.data == null) { return null }

                const appData = app?.data;
                const otherApps = providers?.[appData?.appProvider];
                const countOfProviders = otherApps && otherApps?.length;
                const source = sourcesById[appData?.appProvider];
                const toLink = `./brands/${brand.data._id}/${appData.metaId}?${searchString}`;
                const isCsv = appData?.appProvider?.startsWith('csv');
                let appTitle = null;
                if(isCsv) {
                  appTitle = [source.name, appData?.title.replace(appData?.name, '')].join(' ');
                } else if(countOfProviders > 1) {
                  appTitle = [appData?.title].join(' ')
                } else {
                  appTitle = source?.name
                }
                let icon = null;
                if(source?.icon) icon = React.createElement(source.icon);

                return <SubRow key={app.appProvider + '_' + i} to={toLink} text={appTitle} icon={icon} partial={false} appData={appData} />
              })}
            </div>
          </React.Fragment>
        })}
      </>}
    </div>
    

    <FeedbackRow report={report} demoReportId={demoReportId} />
    <div className={styles.footer}>
      {me?.email && <Row icon={<User />} text={me.email} to="/profile" />}
      <div className={styles.togglePanel} onClick={() => setNavMinimized(!navMinimized)}>
        <TogglePanel />
      </div>
    </div>
  </div>
}