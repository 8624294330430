import React, { createContext } from 'react'
import { useAuthContext } from './auth.context'
import { AmplitudeContext } from '../libs/react-amplitude'
import { fetchUrl, headersWithXToken } from './utils'
import { reducer, initialState, ContextPropsState } from './reviews.reducer'


type ContextPropsActions = {
  fetchBrandReviews: (reportId: string, id: string, search: any) => void,
  fetchPublicBrandReviews: (reportId: string, id: string, search: any) => void,
  fetchDemoBrandReviews: (reportId: string, id: string, search: any) => void,

  fetchAppReviews: (reportId: string, brandId: string, id: string, search: any) => void,
  fetchPublicAppReviews: (reportId: string, brandId: string, id: string, search: any) => void,
  fetchDemoAppReviews: (reportId: string, brandId: string, id: string, search: any) => void,

  addComment: (reportId: string, reviewId: string, text: string) => void,
  deleteComment: (reportId: string, reviewId: string, commentId: string) => void,
  fetchComments: (reportId: string) => void,

  fetchReview: (reportId: string, reviewId: string) => void,
  fetchDemoReview: (demoReportId: string, reviewId: string) => void,
  fetchPublicReview: (reportId: string, publicReportId: string, reviewId: string) => void,

  fetchTaggedReviews: (reportId: string, tag: string, body?: any) => void,

  addClassification: (reportId: string, brandId: string, reviewId: string, body: any) => void,
  updateClassification: (reportId: string, brandId: string, reviewId: string, classificationId: string, body: any) => void,
  deleteClassification: (reportId: string, brandId: string, reviewId: string, classificationId: string) => void,
  reset: () => void,
}

const initialActions: ContextPropsActions = {
  fetchBrandReviews: () => { throw new Error("fetchBrandReviews not implemented") },
  fetchPublicBrandReviews: () => { throw new Error("fetchPublicBrandReviews not implemented") },
  fetchDemoBrandReviews: () => { throw new Error("fetchDemoBrandReviews not implemented") },

  fetchAppReviews: () => { throw new Error("fetchAppReviews not implemented") },
  fetchPublicAppReviews: () => { throw new Error("fetchPublicAppReviews not implemented") },
  fetchDemoAppReviews: () => { throw new Error("fetchDemoAppReviews not implemented") },

  addComment: () => { throw new Error("addComment not implemented") },
  deleteComment: () => { throw new Error("deleteComment not implemented") },
  fetchComments: () => { throw new Error("fetchComments not implemented") },

  fetchReview: () => { throw new Error("fetchReview not implemented") },
  fetchDemoReview: () => { throw new Error("fetchDemoReview not implemented") },
  fetchPublicReview: () => { throw new Error("fetchPublicReview not implemented") },

  fetchTaggedReviews: () => { throw new Error("fetchTaggedReviews not implemented") },

  addClassification: () => { throw new Error("addClassification not implemented") },
  updateClassification: () => { throw new Error("updateClassification not implemented") },
  deleteClassification: () => { throw new Error("deleteClassification not implemented") },
  reset: () => { throw new Error("reset not implemented") },
}

type ContextProps = [ContextPropsState, ContextPropsActions]


export const ReviewsContext = createContext<ContextProps>([initialState, initialActions]);

export const ReviewsProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [{ user }] = useAuthContext();
  const { amplitudeInstance, amplitudeUserId } = React.useContext(AmplitudeContext);
  
  const fetchBrandReviews = React.useCallback( async (reportId, id, search) => {
    if(reportId == null || id == null) return;
    try {
      dispatch({ type: 'FETCH_REVIEWS_START', reportId, id, search });
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brands/${id}/reviews`;
      const data = await fetchUrl('GET', url, { body: search, headers });
      dispatch({ type: 'FETCH_REVIEWS', data, reportId, id, search })
    } catch (error) { console.error('fetchBrandReviews', error); }
  }, [amplitudeInstance, user])
  const fetchPublicBrandReviews = React.useCallback( async (reportId, id, search) => {
    if(reportId == null || id == null) return;
    try {
      dispatch({ type: 'FETCH_REVIEWS_START', reportId, id, search })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/public/${reportId}/brands/${id}/reviews`
      const data = await fetchUrl('GET', url, { body: search, headers });
      dispatch({ type: 'FETCH_REVIEWS', data, reportId, id, search })
      return data;
    } catch (error) { console.error('fetchPublicBrandReviews', error); }
  }, [amplitudeInstance, user])
  const fetchDemoBrandReviews = React.useCallback( async (reportId, id, search) => {
    if(reportId == null || id == null) return;
    try {
      dispatch({ type: 'FETCH_REVIEWS_START', reportId, id, search })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${reportId}/brands/${id}/reviews`;
      const data = await fetchUrl('GET', url, { body: search, headers });
      dispatch({ type: 'FETCH_REVIEWS', data, reportId, id, search })
    } catch (error) { console.error('fetchPublicBrandReviews', error); }
  }, [amplitudeInstance, user])

  const fetchAppReviews = React.useCallback( async (reportId, brandId, id, search) => {
    if(reportId == null || id == null || brandId == null) return;
    try {
      dispatch({ type: 'FETCH_REVIEWS_START', reportId, id, search });
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/brands/${brandId}/apps/${id}/reviews`;
      const data = await fetchUrl('GET', url, { body: search, headers });
      dispatch({ type: 'FETCH_REVIEWS', data, reportId, id, search })
      return;
    } catch (error) { console.error('fetchAppReviews', error) }
  }, [amplitudeInstance, user])
  const fetchPublicAppReviews = React.useCallback( async (reportId, brandId, id, search) => {
    if(reportId == null || id == null || brandId == null) return;
    try {
      dispatch({ type: 'FETCH_REVIEWS_START', reportId, id, search })

      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/public/${reportId}/brands/${brandId}/apps/${id}/reviews`
      const data = await fetchUrl('GET', url, { body: search, headers });
      dispatch({ type: 'FETCH_REVIEWS', data, reportId, id, search })
      return data;
    } catch (error) { console.error('fetchPublicAppReviews', error); }
  }, [amplitudeInstance, user])
  const fetchDemoAppReviews = React.useCallback( async (reportId, brandId, id, search) => {
    if(reportId == null || id == null || brandId == null) return;
    try {
      dispatch({ type: 'FETCH_REVIEWS_START', reportId, id, search })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${reportId}/brands/${brandId}/apps/${id}/reviews`;
      const data = await fetchUrl('GET', url, { body: search, headers });
      dispatch({ type: 'FETCH_REVIEWS', reportId, data, id, search })
      return data;
    } catch (error) { console.error('fetchDemoAppReviews', error) }
  }, [amplitudeUserId, user])

  const addComment = React.useCallback( async (reportId, reviewId, text) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/reviews/${reviewId}/comments`;
      const body = {text, email: user.email}
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({type: 'ADD_COMMENT', data, reviewId, reportId});
      return data;
    } catch (error) { console.error('addComment', error); }
  }, [amplitudeInstance, user])

  const deleteComment = React.useCallback( async (reportId, reviewId, commentId) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/reviews/${reviewId}/comments/${commentId}`;
      const data = await fetchUrl('DELETE', url, { headers });
      dispatch({type: 'DELETE_COMMENT', data, reviewId, reportId});
      return data;
    } catch (error) { console.error('deleteComment', error); }
  }, [amplitudeInstance, user])

  const fetchComments = React.useCallback( async (reportId) => {
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/comments`;
      const data = await fetchUrl('GET', url, { headers });
      dispatch({type: 'FETCH_COMMENTS', reportId, data});
      return data;
    } catch (error) { console.error('fetchComments', error); }
  }, [amplitudeInstance, user])

  const fetchReview = React.useCallback( async (reportId, reviewId) => {
    if(reportId == null || reviewId == null) return;
    try {
      dispatch({ type: 'FETCH_REVIEW_START', reportId, reviewId })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/reviews/${reviewId}`;
      const data = await fetchUrl('GET', url, {headers});
      dispatch({ type: 'FETCH_REVIEW', reportId, reviewId, ...data })
      return data;
    } catch (error) {
      dispatch({ type: 'FETCH_REVIEW_ERROR', reportId, reviewId })
    }
  }, [amplitudeInstance, user])
  const fetchDemoReview = React.useCallback( async (demoReportId, reviewId) => {
    if(demoReportId == null || reviewId == null) return;
    try {
      dispatch({ type: 'FETCH_REVIEW_START', demoReportId, reviewId })
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/demo/${demoReportId}/reviews/${reviewId}`
      const data = await fetchUrl('GET', url, { headers });
      dispatch({ type: 'FETCH_REVIEW', demoReportId, reviewId, ...data })
      return data;
    } catch (error) {
      dispatch({ type: 'FETCH_REVIEW_ERROR', demoReportId, reviewId })
      console.error('fetchReview', error);
    }
  }, [amplitudeInstance, user])
  const fetchPublicReview = React.useCallback( async (reportId, publicReportId, reviewId) => {
    if(reportId == null || publicReportId == null || reviewId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/public/${publicReportId}/reviews/${reviewId}`

      const data = await fetchUrl('GET', url, { headers });
      dispatch({ type: 'FETCH_REVIEW', reportId, publicReportId, reviewId, ...data })
      return data;
    } catch (error) {
      dispatch({ type: 'FETCH_REVIEW_ERROR', reportId, reviewId })
      console.error('fetchPublicReview', error);
    }
  }, [amplitudeInstance, user])

  const fetchTaggedReviews = React.useCallback( async (reportId, tag, body) => {
    if (reportId == null || tag == null) return null;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/v2/reports/${reportId}/reviews/categories/${tag}`;
      const data = await fetchUrl('GET', url, { headers, body });
      dispatch({ type: 'FETCHED_TAGGED_REVIEWS', reportId, tag, reviews: data && data.reviews });
    } catch (error) {
      console.error('fetchTaggedReviews', error)
    }
  }, [amplitudeInstance, user])

  const addClassification = React.useCallback( async (reportId, brandId, reviewId, body) => {
    if(reportId == null || brandId == null || reviewId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/reviews/${reviewId}/classify`;
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({ type: 'UPDATE_CLASSIFICATION', reportId, reviewId, brandId, reportAppReview: data.reportAppReview })
      return data;
    } catch (error) {
      console.error('addClassification', error)
    }
  }, [amplitudeInstance, user])
  const updateClassification = React.useCallback( async (reportId, brandId, reviewId, classificationId, body) => {
    if(reportId == null || brandId == null || reviewId == null || classificationId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/reviews/${reviewId}/classify/${classificationId}`;
      const data = await fetchUrl('POST', url, { headers, body });
      dispatch({ type: 'UPDATE_CLASSIFICATION', reportId, reviewId, brandId, reportAppReview: data.reportAppReview })
      return data;
    } catch (error) {
      console.error('updateClassification', error)
    }
  }, [amplitudeInstance, user])
  const deleteClassification = React.useCallback( async (reportId, brandId, reviewId, classificationId) => {
    if(reportId == null || brandId == null || reviewId == null || classificationId == null) return;
    try {
      const headers = await headersWithXToken(user, {'X-AMP-DEVICE-ID': amplitudeInstance.options.deviceId});
      const url = `/api/reports/${reportId}/reviews/${reviewId}/classify/${classificationId}`
      const data = await fetchUrl('DELETE', url, { headers });
      dispatch({ type: 'UPDATE_CLASSIFICATION', reportId, reviewId, brandId, reportAppReview: data.reportAppReview })
      return data;
    } catch (error) {
      console.error('deleteClassification', error)
    }
  }, [amplitudeInstance, user])

  const reset = React.useCallback( () => dispatch({ type: 'RESET' }), [])

  const value: ContextProps = [
    state,
    {
      fetchBrandReviews,
      fetchPublicBrandReviews,
      fetchDemoBrandReviews,

      fetchAppReviews,
      fetchPublicAppReviews,
      fetchDemoAppReviews,

      addComment,
      deleteComment,
      fetchComments,

      fetchReview,
      fetchDemoReview,
      fetchPublicReview,

      fetchTaggedReviews,

      addClassification,
      updateClassification,
      deleteClassification,

      reset,
    }
  ]

  return <ReviewsContext.Provider value={value}>
    {children}
  </ReviewsContext.Provider>
}

export const useReviewsContext = () => React.useContext(ReviewsContext)