import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  tone1?: string
}

export const Smile0: React.FC<Props> = ({className}) => {
  const tone1 = "#ccc";
  const strokeWidth1 = '3'
  const style: any = {
    '--tone1-default': tone1,
    '--stroke-width1-default': strokeWidth1
  }
  return <svg fillRule="evenodd" viewBox="0 0 19 10" style={style} className={className}>
    <path d="M1.80000013,7.5 C3.842128,4.16666667 6.40879462,2.5 9.5,2.5 C12.5912054,2.5 15.157872,4.16666667 17.1999999,7.5" strokeLinecap="round" fill="none" style={{stroke: 'var(--tone1, var(--tone1-default))', strokeWidth: 'var(--stroke-width1, var(--stroke-width1-default))'}} />
  </svg>
}

export const Smile1: React.FC<Props> = ({className}) => {
  const tone1 = "#ccc";
  const strokeWidth1 = '3'
  const style: any = {
    '--tone1-default': tone1,
    '--stroke-width1-default': strokeWidth1
  }
  return <svg fillRule="evenodd" viewBox="0 0 19 10" style={style} className={className}>
    <path d="M1.80000013,6.5 C3.842128,4.5 6.40879462,3.5 9.5,3.5 C12.5912054,3.5 15.157872,4.5 17.1999999,6.5" strokeLinecap="round" fill="none" style={{stroke: 'var(--tone1, var(--tone1-default))', strokeWidth: 'var(--stroke-width1, var(--stroke-width1-default))'}} />
  </svg>
}

export const Smile2: React.FC<Props> = ({className}) => {
  const tone1 = "#ccc";
  const strokeWidth1 = '3'
  const style: any = {
    '--tone1-default': tone1,
    '--stroke-width1-default': strokeWidth1
  }
  return <svg fillRule="evenodd" viewBox="0 0 19 10" style={style} className={className}>
    <path d="M1.80000013,3.85000005 C3.842128,5.38333332 6.40879462,6.14999995 9.5,6.14999995 C12.5912054,6.14999995 15.157872,5.38333332 17.1999999,3.85000005" strokeLinecap="round" fill="none" style={{stroke: 'var(--tone1, var(--tone1-default))', strokeWidth: 'var(--stroke-width1, var(--stroke-width1-default))'}} />
  </svg>
}

export const Smile3: React.FC<Props> = ({className}) => {
  const tone1 = "#ccc";
  const strokeWidth1 = '3'
  const style: any = {
    '--tone1-default': tone1,
    '--stroke-width1-default': strokeWidth1
  }
  return <svg fillRule="evenodd" viewBox="0 0 19 10" style={style} className={className}>
    <path d="M1.80000013,2 C3.842128,6.19999993 6.40879462,8.29999989 9.5,8.29999989 C12.5912054,8.29999989 15.157872,6.19999993 17.1999999,2" strokeLinecap="round" fill="none" style={{stroke: 'var(--tone1, var(--tone1-default))', strokeWidth: 'var(--stroke-width1, var(--stroke-width1-default))'}} />
  </svg>
}