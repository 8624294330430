import Color from 'color'
import React from 'react'
import { CircleDelete, Pencil, Plus, Trash } from '../../toneIcons'
import styles from './pills.module.scss'

interface ColorPillProps extends React.HTMLProps<HTMLDivElement> {
  color: string,
  tagName?: any,
}

interface TxtPillProps extends React.HTMLProps<HTMLDivElement> {
  text?: string,
  tagName?: any,
  to?: any
}

interface TogglePillProps extends React.HTMLProps<HTMLDivElement> {
  isOn: boolean,
  onText: string,
  onHoverText: string,
  offText: string,
  offHoverText: string,
  tagName?: any,
}

interface TxtDeletablePillProps extends React.HTMLProps<HTMLDivElement> {
  text?: string,
  onDelete?: any,
}

interface TxtButtonPillProps extends React.HTMLProps<HTMLButtonElement> {
  text?: string,
  tagName?: any,
}

export const ColorPill: React.FC<ColorPillProps> = ({color, onClick}) => {
  if(color == null) return null;

  let textColor = '#333'
  try {
    textColor = Color(color).isLight() ? '#333' : '#fefefe';
  } catch(error) {}

  const style: any = {
    '--color': color,
    minWidth: '90px',
  }

  const colorBoxStyle: any = {
    '--tone1': textColor,
    '--tone2': textColor
  }

  return <div className={[styles.container, onClick ? styles.clickable : null].join(' ')} style={style} onClick={onClick}>
    <div className={styles.colorBox} style={colorBoxStyle}>
      {onClick && <Pencil />}
    </div>
    <div className={styles.text}>{color.toLowerCase()}</div>
  </div>
}

export const EditPill: React.FC<TxtPillProps> = ({text="edit", tagName, onClick, ...props}) => {
  const Tag = tagName || 'button';
  return <Tag className={styles.createContainer} disabled={onClick == null} {...props} onClick={onClick}>
    <div className={styles.box}><Pencil /></div>
  </Tag>
}

export const CreatePill: React.FC<TxtPillProps> = ({text="create", tagName, onClick, ...props}) => {
  const Tag = tagName || 'button';
  return <Tag className={styles.createContainer} disabled={onClick == null} {...props} onClick={onClick}>
    <div className={styles.box}><Plus /></div>
    <div className={styles.text}>{text}</div>
  </Tag>
}

export const DeletePill: React.FC<TxtButtonPillProps> = ({text="delete", onClick}) => {
  return <button className={styles.deleteContainer} disabled={onClick == null} onClick={onClick}>
    <div className={styles.box}>
      <Trash/>
    </div>
  </button>
}

export const TogglePill: React.FC<TogglePillProps> = ({isOn, onText, onHoverText, offText, offHoverText, tagName, onClick, ...props}) => {
  const [hovering, setHovering] = React.useState(false)

  //set the text
  const onMouseover = (e: any) => {
    setHovering(true)
  }
  //clear the text
  const onMouseout = (e: any) => {
    setHovering(false)
  }

  const Tag = tagName || 'button';
  const text = (isOn == true) ?
      (hovering == true) ? onHoverText : onText
    :
      (hovering == true) ? offHoverText : offText

  const colorClass = (isOn == true) ? styles.toggleContainerOn : styles.toggleContainerOff

  return <Tag className={[styles.clickable, colorClass].join(' ')} disabled={onClick == null} {...props} onClick={onClick} onMouseEnter={onMouseover.bind(this)} onMouseLeave={onMouseout.bind(this) } >
    <div className={styles.text}>{text}</div>
  </Tag>
}

export const DeletablePill: React.FC<TxtDeletablePillProps> = ({text, onDelete}) => {
  if(text == null) return null;
  return <div className={styles.container}>
    <div className={styles.text}>{text}</div>
    {onDelete && <div onClick={onDelete} className={styles.delete}><CircleDelete /></div>}
  </div>
}