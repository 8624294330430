import React from 'react'
import commaNumber from 'comma-number'
import FitText from '@kennethormandy/react-fittext'
import * as d3 from 'd3';

import Tooltip from '../../libs/context-tooltip/tooltip'
import { getBubbleColors } from '../../utils'
import {useComponentSize} from '../../hooks/index'
import SelectBox from '../../components/selectBox/selectBox'
import Card from '../card/card'
import Loading from '../../components/loading/loading'
import ConceptSummaryTooltip from '../../components/conceptSummaryTooltip/conceptSummaryTooltip'

import styles from './conceptSummaryCard.module.scss'

interface ConceptSummaryCardProps {
  filteredBrandIds: any,
  concepts: any,
  report: any,
  filter: any,
  setFilter: any,
  brandsById?: any,
}

export const ConceptSummaryCard: React.FC<ConceptSummaryCardProps> = ({filteredBrandIds, concepts, report, filter, setFilter}) => {
  const ref = React.useRef();
  const {width, height} = useComponentSize(ref);
  
  const reportBrandIds = report?.data?.brandIds;
  const reportDataCountsBrands = report?.dataCounts?.brands;

  const reportDataCountsCounts = report?.dataCounts?.count;
  const brandsByCategory = report?.dataCounts?.brandsByCategory;
  const categories = report?.dataCounts?.categories;

  const [_concepts, verbatimCount] = React.useMemo(() => {
    let _concepts = null;
    let verbatimCount = 0;
    if(filteredBrandIds == null) {
      verbatimCount = reportDataCountsCounts;
      _concepts = concepts?.filter((c: any) => c.countInScore).map((concept: any) => {
        const scoreCount = categories?.[concept._id];
        return {
          _id: concept._id,
          name: concept.name,
          count: scoreCount?.counts?.raited,
          positive: scoreCount?.posNegSentiment?.positive,
          negative: scoreCount?.posNegSentiment?.negative,
          neutral: scoreCount?.posNegSentiment?.neutral,
        }
      });
    }
    else {
      verbatimCount = reportBrandIds.reduce((acc: any, brandId: string) => {
        if(filteredBrandIds.indexOf(brandId) < 0) return acc;
        return acc + (reportDataCountsBrands?.[brandId]?.counts?.raited || 0);
      }, 0)

      _concepts = concepts?.filter((c: any) => c.countInScore).map((concept: any) => {
        const categoryScores = brandsByCategory?.[concept._id];

        const data = reportBrandIds.reduce((acc: any, brandId: string) => {
          const brandScore = categoryScores?.[brandId];
          if(filteredBrandIds.indexOf(brandId) < 0) return acc
          return {
            count: acc.count + (brandScore?.counts?.raited || 0),
            positive: acc.positive + (brandScore?.posNegSentiment?.positive || 0),
            negative: acc.negative + (brandScore?.posNegSentiment?.negative || 0),
            neutral: acc.neutral + (brandScore?.posNegSentiment?.neutral || 0),
          }
        }, {count: 0, positive: 0, negative: 0, neutral: 0 })

        return { _id: concept._id, name: concept.name, ...data }
      }).filter((a: any) => a);
    }
    
    const allCount = _concepts?.reduce( (p: number, c: any) => p + (c.count || 0), 0);

    return [_concepts?.map((c: any) => ({...c, reportRatio: c.count / allCount}))
      .filter((c: any) => c.reportRatio)
      .sort((a: any,b: any) => b.reportRatio - a.reportRatio), verbatimCount];
  }, [filteredBrandIds, concepts, brandsByCategory, categories, reportBrandIds, reportDataCountsBrands, reportDataCountsCounts])

  if(report == null || report.data == null) return <LoadingCard />
  if(concepts == null) return <LoadingCard />
  if(report.dataCounts == null) return <LoadingCard />

  const selectFiltes = report?.data?.filters;
  let filters = null;
  if(selectFiltes?.length > 1) {
    filters = <SelectBox options={selectFiltes} value={filter} onChange={(e: any) => setFilter(e.target.value)} borderless />
  }

  const bubbleData = _concepts?.map((c: any) => ({
    id: c._id,
    label: c.name,
    value: (100*c.reportRatio),
    posValue: c.positive/c.count,
    negValue: c.negative/c.count,
    posCount: c.positive,
    negCount: c.negative,
    count: c.count,
  }));
  const highBubbleData = bubbleData?.filter((c: any) => c.value >= 5);
  const lowBubbleData = bubbleData?.filter((c: any) => c.value < 5);

  const size = Math.min(width, height);

  const barStyle: any = { '--color': getBubbleColors(50) }

  return <Card header='Concept Summary'
    description={`What concepts are prospects or customers talking about across <strong>${commaNumber(verbatimCount)}</strong> verbatims.`}
    fileName={`${report?.data?.name}_concept_summary.png`}
    filters={filters}>
    <div className={styles.container} >
      <div className={styles.bubbles} ref={ref}>
        {highBubbleData && size !== 0 && <BubbleChart width={size} height={size} data={highBubbleData} />}
      </div>
      <div className={styles.traces}>
        {lowBubbleData != null && lowBubbleData.length > 0 && <div className={styles.bubbleChartTrace}>
          <div className={styles.bubbleChartTraceHeader}>Trace amounts</div>
          {lowBubbleData.map((d: any) => {
            const value = d.value >= 1 ? d.value.toFixed(0) : d.value.toFixed(1);

            const posBarStyle: any = {'--color': getBubbleColors(100), '--width': `${d.posValue*100}%`}
            const negBarStyle: any = {'--color': getBubbleColors(0), '--width': `${d.negValue*100}%`}
            return <React.Fragment key={d.id}>
              <div className={styles.chartTraceRow}>
                <Tooltip className={styles.legendValue} background="#fff" content={<ConceptSummaryTooltip data={d} />}>{value}%</Tooltip>
                <Tooltip className={styles.legendText} background="#fff" content={<ConceptSummaryTooltip data={d} />}>{d.label}</Tooltip>
              </div>
              <div className={styles.bar} style={barStyle}>
                <div className={styles.posBar} style={posBarStyle} />
                <div className={styles.negBar} style={negBarStyle} />
              </div>
            </React.Fragment>
          })}
        </div>}
      </div>
    </div>
  </Card>
}

export default ConceptSummaryCard;


const LoadingCard = () => <Card header='Concept Summary' description="What concepts are customers talking about">
  <Loading />
</Card>


const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  const x = centerX + (radius * Math.cos(angleInRadians));
  const y = centerY + (radius * Math.sin(angleInRadians));
  return { x, y };
};

const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

  return [
    "M", start.x, start.y,
    "A", radius, radius, 0, arcSweep, 0, end.x, end.y,
    "L", x, y,
    "L", start.x, start.y
  ].join(" ");
};

interface ArcSvgProps {
  x: number,
  y: number,
  r: number,
  start: number,
  end: number,
  fill: string,
  stroke?: string,
  strokeWidth?: number
}

const ArcSvg: React.FC<ArcSvgProps> = ({x, y, r, start, end, fill, stroke="#fff", strokeWidth=2}) => {
  if(start === end) return null;
  if(start === 0 && end === 360) return <circle cx={x} cy={y} r={r} fill={fill} stroke={stroke} strokeWidth={strokeWidth} />
  const neutralD = describeArc(x, y, r, start, end);
  return <path d={neutralD} fill={fill} stroke={stroke} strokeWidth={strokeWidth} />
}

interface BubbleChartProps {
  width: number,
  height: number,
  data: any,
  padding?: number,
  onClick?: any,
}

export const BubbleChart: React.FC<BubbleChartProps> = ({width, height, data, padding, onClick}) => {

  const circles = React.useMemo(() => {
    const pack = d3.pack().size([width, width]).padding(padding || 6);

    // Process the data to have a hierarchy structure;
    if(data?.length > 0) {
      const root = d3.hierarchy({children: data})
        .sum((d: any) => d.value)
        .sort((a, b) => b.value - a.value )
        .each((d: any) => {
          if(d.data.label) {
            d.label = d.data.label;
            d._id = `_${d.data.id}`;
            d.id = d.data.id;
          }
        });
      const packed = pack(root);
      return packed.leaves();
    }
  }, [width, padding, data]);

  return <svg width={width} height={height}>
    {circles?.map((circle: any) => {
      const negAngle = 360*circle.data.negValue;
      const posAngle = 360*(1-circle.data.posValue);

      const content = <ConceptSummaryTooltip data={circle.data} />;
      const onItemClick = onClick ? () => { onClick(circle.id) } : null;
      return <Tooltip key={circle._id} background="#fff" content={content} tagName="g" onClick={onItemClick}>
        <ArcSvg x={circle.x} y={circle.y} r={circle.r} start={0} end={negAngle} fill={getBubbleColors(0)} />
        <ArcSvg x={circle.x} y={circle.y} r={circle.r} start={negAngle} end={posAngle} fill={getBubbleColors(50)} />
        <ArcSvg x={circle.x} y={circle.y} r={circle.r} start={posAngle} end={360} fill={getBubbleColors(100)} />
        <circle cx={circle.x} cy={circle.y} r={circle.r - 6} className={styles.circle} />
        <foreignObject x={circle.x - circle.r} y={circle.y - circle.r} width={circle.r*2} height={circle.r*2}>
          <div className={styles.circleTextContainer}>
            {circle.data.label != null && <div className={styles.circleTextHeader} >
              <FitText compressor={0.8}>{circle.data.label}</FitText>
            </div>}
            <div className={styles.circleTextSubHeader} >
              {Math.round(circle.data.value)}%
            </div>
          </div>
        </foreignObject>
      </Tooltip>
    })}
  </svg>
}
