import React from 'react'
import {useDebouncedCallback} from 'use-debounce';

import Tooltip from '../../libs/context-tooltip/tooltip'
import { PencilIcon, TrashIcon } from '../icons/icons'

import styles from './editableLabel.module.scss'

interface EditableLabelProps extends React.HTMLProps<HTMLDivElement> {
  onInstantChange?: any,
  onDelete?: any,
  deleteTooltip?: any,
  value?: any
}

export const EditableLabel: React.FC<EditableLabelProps> = ({className, value, placeholder, onChange, onInstantChange, onDelete, deleteTooltip}) => {
  const [editing, setEditing] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(value);

  const debounced = useDebouncedCallback(value => onChange(value), 600)
  
  const onInputChange = (e: any) => {
    debounced.callback(e.target.value);
    setInputValue(e.target.value);
    if(onInstantChange) onInstantChange(e.target.value)
  }

  const submitForm = (e: any) => {
    e.preventDefault();
    setEditing(false)
  }

  if(editing) {
    return <form className={[className, styles.container].join(' ')} onSubmit={submitForm}>
      <input placeholder={placeholder} value={inputValue} onChange={onInputChange} onBlur={() => setEditing(false)} autoFocus />
    </form>
  }

  return <div className={[className, styles.container].join(' ')}>
    <div className={styles.text} onClick={() => setEditing(true)}>{value}</div>
    <div className={styles.pencil} onClick={() => setEditing(true)}>
      <PencilIcon />
    </div>
    
    {onDelete != null && <div className={styles.trashIcon}>
      {deleteTooltip && <Tooltip tagName={TrashIcon} content={deleteTooltip} onClick={onDelete} />}
      {!deleteTooltip && <TrashIcon onClick={onDelete} />}
    </div>}
  </div>
}

export default EditableLabel